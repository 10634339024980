import React, { useState, useEffect, Fragment } from 'react';
import Input from '../../components/inputWithIcon/inputWithIcon';
import { Spinner } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Alert from '../../components/Alert/Alert';
import ImageTag from '../../components/ImageTag/ImageTag';
import Axios from '../../utils/Request';
import { Checkbox } from '@material-ui/core';

let intervalTimer;
const SignIn = props => {
   var images = [
      {
         image: require('./assets/1.jpg'),
         p: 'Everything you can imagine',
         name: 'APJ Abdul Kalam',
      },
      {
         image: require('./assets/2.jpg'),
         p: 'What is now proved was once only imagined.',
         name: 'Dhoni',
      },
      {
         image: require('./assets/3.jpg'),
         p: 'if you truly love nature, you will find beauty everywhere.',
         name: 'Albert',
      },
   ];

   const [showPass, setShowPass] = useState(false);
   const [masterLogout, setMasterLogout] = useState(false);
   const [showMasterLogout, setShowMasterLogout] = useState(false);
   const [loader, setLoader] = useState(false);
   const [background, setBackground] = useState(0);
   const [time, setTime] = useState(new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
   const [email, setEmail] = useState('');
   const [password, setPassword] = useState('');
   const [shoModal, setModalShow] = useState(false);
   const [alertContent, setAlertContent] = useState({
      error: '',
      message: '',
   });

   const timer = () => {
      setInterval(() => {
         setTime(new Date().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }));
      }, 1000);
   };

   const get_random = list => {
      let result = list[Math.floor(Math.random() * list.length)];
      if (result !== background) {
         return result;
      } else {
         return list[Math.floor(Math.random() * list.length)];
      }
   };

   const signIn = () => {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      setLoader(true);
      setShowMasterLogout(false);
      if (re.test(email)) {
         Axios('/accounts/login/', {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
            },
            data: JSON.stringify({
               username: email,
               password: password,
               logout: masterLogout,
            }),
         })
            .then(res => {
               if (res.status == 200) {
                  if (res.message !== 'Username or password is wrong') {
                     localStorage.setItem('user', JSON.stringify(res.data));
                     props.history.push({
                        pathname: '/',
                        state: {
                           showSplash: true,
                        },
                     });
                     setLoaderModal();
                     setAlertContent({
                        error: 'Success',
                        message: res.message,
                     });
                  } else {
                     setLoaderModal();
                     ErrorMessage(res.message);
                  }
               } else {
                  setModalShow(true);
                  ErrorMessage(res.message[0]);
               }
            })
            .catch(err => {
               setLoaderModal();
               if (err?.response?.status === 403) {
                  setPassword('');
                  setShowMasterLogout(true);
               }
               ErrorMessage(err.response.data.message);
            });
      } else {
         setLoaderModal();
         ErrorMessage('Email is not in valid format.');
      }
   };

   const setLoaderModal = () => {
      setLoader(false);
      setModalShow(true);
   };

   /**
    * Generic Error Messages
    *
    * @param {String} errMessage
    */
   const ErrorMessage = errMessage => {
      setAlertContent({
         error: 'Error',
         message: errMessage,
      });
   };

   useEffect(() => {
      const user = localStorage.getItem('user');
      if (!user) {
         intervalTimer = setInterval(() => {
            let num = get_random([0, 1, 2]);
            console.log(num, 'num');
            setBackground(num);
         }, 3000);
         timer();
      } else {
         props.history.push({
            pathname: '/',
            state: {
               showSplash: true,
            },
         });
      }

      return () => {
         clearInterval(intervalTimer);
      };
   }, []);

   const handleLogout = () => {
      setMasterLogout(!masterLogout);
   };

   return (
      <div className="container-fluid container-main">
         <Alert
            show={shoModal}
            onHide={() => setModalShow(false)}
            message={alertContent.message}
            Error={alertContent.error}
            className="modal-container-class">
            {showMasterLogout && (
               <div className="logout-all-section">
                  <label>
                     <Checkbox checked={masterLogout} onChange={handleLogout} color="primary" />
                     <span className="logout-text">Logout of all devices</span>
                  </label>

                  {masterLogout && (
                     <Fragment>
                        <Input
                           onChange={x => setPassword(x)}
                           onClick={() => setShowPass(!showPass)}
                           type={showPass ? 'text' : 'password'}
                           placeholder={'Password'}
                           showIcon={true}
                        />

                        <div onClick={signIn} className="button">
                           {loader ? (
                              <Spinner size={'sm'} animation="border" role="status"></Spinner>
                           ) : (
                              <p className="regular s-b">Sign In</p>
                           )}
                        </div>
                     </Fragment>
                  )}
               </div>
            )}
         </Alert>
         <div className="row s-bet">
            <div className="leftSection">
               <div className="header">
                  <ImageTag src={require('./assets/logo.svg')} />
               </div>
               <div className="heading-container">
                  <h1 className="bold responsiveText">Welcome Back!</h1>
                  <p className="regular g-p">Please login to your account.</p>
               </div>
               <form
                  onSubmit={e => {
                     e.preventDefault();
                     signIn();
                  }}>
                  <div>
                     <Input
                        onChange={x => setEmail(x)}
                        isEmail={true}
                        secure={false}
                        type={'email'}
                        placeholder={'Email'}
                        margin={true}
                        showIcon={true}
                     />
                     <Input
                        onChange={x => setPassword(x)}
                        onClick={() => setShowPass(!showPass)}
                        type={showPass ? 'text' : 'password'}
                        placeholder={'Password'}
                        showIcon={true}
                     />
                     <div
                        // onClick={() => props.history.push('/forget')}
                        style={{ cursor: 'pointer' }}
                        className="forget-container">
                        {/* <p className="regular forgot">Forgot your password?</p> */}
                     </div>
                  </div>

                  <div onClick={signIn} className="button">
                     {loader ? (
                        <Spinner size={'sm'} animation="border" role="status"></Spinner>
                     ) : (
                        <p className="regular s-b">Sign In</p>
                     )}
                  </div>
               </form>
               <div className="tradmark">
                  <p style={{ color: 'rgba(0, 0, 0, 0.4)', textAlign: 'center' }} className="regular t-m">
                     © 2020 All Rights Reserverd Blue Logic Digital.
                  </p>
               </div>
            </div>
            <div className="rightSection">
               {background == 0 && (
                  <>
                     <img className="fade-in" src={images[background].image} />
                     <div className="gradient fade-in"></div>
                  </>
               )}
               {background == 1 && (
                  <>
                     <img className="fade-in" src={images[background].image} />
                     <div className="gradient fade-in"></div>
                  </>
               )}
               {background == 2 && (
                  <>
                     <img className="fade-in" src={images[background].image} />
                     <div className="gradient fade-in"></div>
                  </>
               )}

               <div className="message col-lg-12 col-md-12 col-sm-5 col-12">
                  <h3 className="Text bold mt-l-55">Good Morning</h3>
                  <h1 className="timer bolder mt-l-55">{time}</h1>
                  <p className="content medium mt-l-55">
                     <q>{images[background].p}</q>
                  </p>
                  <h6 className="Name medium mt-l-55">{images[background].name}</h6>
               </div>
            </div>
         </div>
      </div>
   );
};

export default withRouter(SignIn);
