import React, { useState } from 'react';
import Input from '../../components/inputWithIcon/inputWithIcon';
import { withRouter } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import ImageTag from '../../components/ImageTag/ImageTag';
import Alert from '../../components/Alert/Alert';
import Axios from '../../utils/Request';

const Forget = props => {
   const [loader, setLoader] = useState(false);
   const [email, setEmail] = useState('');
   const [shoModal, setModalShow] = useState(false);
   const [alertContent, setAlertContent] = useState({
      error: '',
      message: '',
   });

   const submit = () => {
      var mailformat = /\S+@\S+\.\S+/;
      setLoader(true);
      console.log(email);
      if (mailformat.test(email)) {
         Axios('/accounts/forget-password/', {
            method: 'POST',         
            data: JSON.stringify({
               email: email,
            }),
         })
            .then(res => {
               return res.json();
            })
            .then(res => {
               if (res['Reset URL']) {
                  console.log(res, ' eeerrr');
                  alert(res.message);
                  props.history.push('/sendcode', { url: res['Reset URL'] });
               } else {
                  setModalShow(true);
                  setAlertContent({
                     error: 'Error',
                     message: res.message,
                  });
               }
               setLoader(false);
            })
            .catch(err => {
               console.log(err);
               setLoader(false);
               setModalShow(true);
               setAlertContent({
                  error: 'Error',
                  message: 'Something went wrong. Please try again.',
               });
            });
      } else {
         setLoader(false);
         setModalShow(true);
         setAlertContent({
            error: 'Error',
            message: 'Email is not in valid format.',
         });
      }
   };
   return (
      <div className="contaier-fluid forgat-password">
         <Alert
            show={shoModal}
            onHide={() => setModalShow(false)}
            message={alertContent.message}
            Error={alertContent.error}
         />
         <div className="container">
            <div className="row">
               <div
                  onClick={() => {
                     props.history.push('/');
                  }}
                  className="col-1g-12 col-md-12 col-sm-12 col-12 logo-div">
                  <ImageTag
                     className="mt-3"
                     src={require('./assets/logo.svg')}
                     onClick={() => props.history.push('/signin')}
                  />
               </div>
            </div>
         </div>
         <div className="container">
            <div className="row">
               <div className="col-1g-12 col-md-12 col-sm-12 col-12 mt-5 Conent-Box">
                  <div>
                     <ImageTag src={require('./assets/logo2.svg')} />
                  </div>
                  <p className="medium forgot-head">Forget Your Password?</p>
                  <div className="input-div m-b-28">
                     <p className="detail regular">
                        Enter your email address and we'll send you a link to reset your password
                     </p>
                  </div>
                  <div className="input-div">
                     <Input
                        onChange={x => setEmail(x)}
                        isEmail={true}
                        secure={false}
                        type={'email'}
                        placeholder={'Email Address'}
                        margin={true}
                        showIcon={true}
                        marginBottom={28}
                     />
                  </div>
                  <div className="input-div-btn">
                     <div onClick={submit} className="button">
                        {loader ? (
                           <Spinner size={'sm'} animation="border" role="status"></Spinner>
                        ) : (
                           <p className="regular s-b">Submit Email</p>
                        )}
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="container">
            <p className="Copyright-Text regular">© 2020 All Rights Reserved Blue Logic</p>
         </div>
      </div>
   );
};
export default withRouter(Forget);
