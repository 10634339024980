import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import Codes from './countryCodes';
import ImageTag from '../../../components/ImageTag/ImageTag';


const useStyles = makeStyles((theme) => ({
  input: {
    width: 265,
    border:'none',
    height:38,
    borderRadius:100,
    paddingLeft:14,
    fontSize:14,
    letterSpacing:0.14,
    color:"#000"
  },
  listbox: {
    width: "100%",
    margin: 0,
    padding: 0,
    zIndex: 1,
    borderRadius:14,
    listStyle: 'none',
    backgroundColor: "#fff",
    overflow: 'auto',
    border: 'none',
    '& li[data-focus="true"]': {
      backgroundColor: '#43b3e6',
      color: 'white',
      cursor: 'pointer',
    },
    '& li:active': {
      backgroundColor: '#43b3e6',
      color: 'white',
    },
  },
}));
const ChatBotForm = ({isHuman, options, fields, submit}) =>{
  const [values, setValues] = useState({email:"", name:"", number:"", code:"+93"})
    if(!isHuman){
        return(
            <div className="chats-ChatBotForm-parant animated fadeInLeft">
                <div className="image">
                  <ImageTag src={require('../assets/botIcon.svg')} />
                </div>
                <div className="message-container-ChatBotForm">
                    <p className="regular text">Please Enter your Information</p>
                    <div className="input-container">
                      <input onChange={(x)=>{
                        setValues({...values, name:x.target.value})
                      }} placeholder="Name"/>
                    </div>
                    <div className="input-container">
                      <input onChange={(x)=>{
                        setValues({...values, email:x.target.value})
                      }} placeholder="Email Address"/>
                    </div>

                    <div className="input-container input-container-flex">
                        <select onChange={(x)=>{
                            setValues({...values, code:x.target.value})
                          }}>
                            {Codes.map((res, i)=>{
                              return(
                                <option className="regular" key={i}>{res.value}</option>
                              )
                            })}
                        </select>
                        <input onChange={(x)=>{
                            setValues({...values, number:x.target.value})
                          }} className="wd-80" placeholder="Phone Number"/>
                    </div>

                    <div className="input-container mb-0">
                          <div onClick={()=>submit(values)} className="button">
                            Submit
                          </div>
                    </div>
                </div>
            </div>
        )
    }
    else{
        return(
            <div className="chats-ChatBotForm-parant-bot">
                <div className="message-container-ChatBotForm-bot">
                    <p className="regular text">Please Enter your Information</p>
                    <div className="input-container-bot">
                      <input placeholder="Name"/>
                    </div>
                    <div className="input-container-bot">
                      <input placeholder="Email Address"/>
                    </div>

                    <div className="input-container-bot input-container-bot-flex">
                        <select>
                            {Codes.map((res, i)=>{
                              return(
                                <option className="regular" key={i}>{res.value}</option>
                              )
                            })}
                        </select>
                        <input className="wd-80" placeholder="Phone Number"/>
                    </div>
                    <div className="input-container mb-0">
                          <div className="button">
                            Submit
                          </div>
                    </div>
                </div>
                <div className="image-bot">
                  <ImageTag src={require('../assets/humanIcon.svg')} />
                </div>
            </div>
        )
    }
}

export default ChatBotForm;