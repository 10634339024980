import { SHOWHINT, HINTCOUNTCHANGE } from "./types";

export const SHOWHINTBOOL = Bool => ({
   type: SHOWHINT,
   payload: Bool,
});

export const CHANGECOUNT = count => ({
   type: HINTCOUNTCHANGE,
   payload: count,
});
