import React from "react";
import Container from '../../components/Conversations/Conversations';

const Conversation = () => {
  return (
    <div className="conversaion-container">
      <Container></Container>
    </div>
  );
};

export default Conversation;
