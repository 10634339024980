import React, { useState, useEffect } from "react"
import { Table } from "react-bootstrap"
import Left from "../../assets/images/left_arrow.svg"
import Right from "../../assets/images/right_arrow.svg"
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';



const TableC = (props) => {

    const [page, setpage] = useState(1)
    const [total_pages, setTP] = useState(1)

    


    const useStyles = makeStyles((theme) => ({
        root: {
            '& > *': {
                marginTop: theme.spacing(2),
                right: 0
            },
        },
    }));


    useEffect(() => {
        setTP(Math.ceil(props.data.days.length / 5))
        console.log(Math.ceil(props.data.days.length / 5))
    }, [props.data])


    const handleChange = (event, value) => {
        setpage(value);
    };


    return (
        <div className="table-parent">
            <div style={{
                margin: '24px 0px',
                padding: 30,
                border: "1px solid #eeeeee",
                borderRadius: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>

                <Table striped hover style={{
                    borderTopLeftRadius: '1rem',
                    borderTopRightRadius: '1rem',
                    overflow: 'hidden',
                }}>
                    <thead>
                        <tr style={{
                            backgroundColor: '#43b3e6',
                            color: '#fff'
                        }}>
                            <th style={{ paddingLeft: 20 }}>Date</th>
                            <th style={{ textAlign: "center" }}>Messages In</th>
                            <th style={{ textAlign: "center" }}> Messages Out</th>

                        </tr>
                    </thead>
                    <header1 />
                    <tbody>
                        {props.data.days.map((item, key) => {

                            if (Math.ceil(key / 5) !== page) {
                                return null
                            }

                            return (
                                <tr>
                                    <td style={{
                                        minWidth: '10rem'
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            paddingLeft: 8
                                        }}>
                                            <div style={{
                                                backgroundColor: '#43b3e6',
                                                borderRadius: '50%',
                                                width: 35,
                                                height: 35,
                                                marginRight: '1rem'
                                            }}><span style={{
                                                display: 'flex',
                                                width: '100%',
                                                height: '100%',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                fontSize: 18,
                                                color: '#fff',
                                                backgroundColor: "#4a4cab",
                                                borderRadius: 30
                                            }}>
                                                    S
                                            </span>
                                            </div>
                                            {item}
                                        </div></td>
                                    <td className="text-center">{props.data.messagesIn[key]}</td>
                                    <td className="text-center">{props.data.messagesOut[key]}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>

            {/*pagination code */}
            <div className="pagination" style={{ display: total_pages === 1 ? "none" : "flex" }}>
                <div className="page-cont">
                    <div className="p-button" onClick={() => { if (page !== 1) { setpage(page - 1) } }}>
                        <img src={Left} style={{ marginRight: 10 }} />
                        Prev
                    </div>
                    <div className={useStyles().root}>
                        <Pagination count={total_pages} shape="rounded" page={page} hideNextButton={true} hidePrevButton={true} onChange={handleChange} />
                    </div>
                    <div className="p-button" onClick={() => { if (page !== total_pages) { setpage(page + 1) } }}>
                        Next
                    <img src={Right} style={{ marginLeft: 10 }} />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default TableC
