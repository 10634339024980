import React, { useContext } from 'react';
import _ from 'lodash';
import { Tween } from 'react-gsap';
import Button from '../../../components/Button/Button';
import ImageTag from '../../../components/ImageTag/ImageTag';

export default function Otp({ data, remove, language }) {
  let allInputs = [
    {
      id: 'otpOne',
      inputField: 'otpFieldOne',
      delay: 0.2,
      autoFocus: true,
    },
    {
      id: 'otpTwo',
      inputField: 'otpFieldTwo',
      delay: 0.6,
    },
    {
      id: 'otpThree',
      inputField: 'otpFieldThree',
      delay: 0.8,
    },
    {
      id: 'otpFour',
      inputField: 'otpFieldFour',
      delay: 1,
    },
    {
      id: 'otpFive',
      inputField: 'otpFieldFive',
      delay: 1.2,
    },
    {
      id: 'otpSix',
      inputField: 'otpFieldSix',
      delay: 1.3,
    },
  ];


  return (
    <Tween from={{ y: '20px', opacity: 0 }}>
      <div className="otp-container-parant">
        <div className="otp-container-mybot" style={{ position: 'relative' }}>
          <p className="montserrat">
            {language === 'en' ? 'OTP VERIFICATION' : 'التحقق من OTP'}
          </p>
          <div
            className="input-fields"
            style={{
              flexDirection: language === 'ar' ? 'row-reverse' : 'row',
            }}
          >
            {allInputs.map((data, index) => (
              <Tween
                from={{ scale: 0.5, opacity: 0, delay: data.delay }}
                key={index}
              >
                <input
                  type="text"
                  id={data.id}
                  maxLength="1"
                  autoComplete="off"
                  dir={language === 'ar' && 'rtl'}
                />
              </Tween>
            ))}
          </div>

          <Tween from={{ css: { width: '0', padding: 0, delay: 0.5 } }}>
            <Button className="custom-button" id="testTest">
              <span>{language === 'en' ? 'Verify' : 'تحقق'}</span>
            </Button>
          </Tween>
          {/* <div
            className="__edit-icons__"
            style={{ left: '2px', bottom: '-42px', padding: '1px' }}
          >
            <ImageTag
              src="/images/delete.svg"
              width="23px"
              style={{ margin: '5px' }}
              alt="delete"
            />
          </div> */}
        </div>
      </div>
    </Tween>
  );
}
