import React, { useState, useEffect , useRef} from "react"
import Export from "../../assets/images/export.svg"
import { Nav } from "react-bootstrap"
import jsPDF from "jspdf"
import ApexCharts from "apexcharts"
import Chart from "react-apexcharts";
import { convert } from "../../utils/Function"
import da from "../../assets/images/dropdown-arrow.svg"
import Api from "../../axios/request"
import { months } from "../../utils/Utility"
import Loader from "../Loader/Loader"
import { useSelector } from "react-redux"
import html2canvas from 'html2canvas';
import { LogOut } from "../../redux/actions/profile/index"
import { useHistory } from "react-router-dom"

import DateRangePicker from "react-bootstrap-daterangepicker"
import 'bootstrap-daterangepicker/daterangepicker.css';


const LifetimeConversations = () => {

    const picker = useRef(null)
    const tk = JSON.parse(localStorage.getItem("user")).token

    const [date, setdate] = useState({ startDate: null, endDate: null })
    const [caldate, setcaldate] = useState({ startDate: 1599568112000, endDate: 1600172912000 })
    const [focusedInput, setfocusedInput] = useState(null)
    const [type, settype] = useState("session")
    const [filter_type, setftype] = useState("Sessions")
    const [initdate, setid] = useState({ start_date: "01/01/2020", end_date: "02/02/2020" })
    const bot_name = useSelector(state => state.storybuilder.bot_name)
    const history = useHistory()
    const [chartdata, setchartdata] = useState({

        series: [{
            type: 'area',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }],
        options: {
            chart: {
                id: "mcchart",
                zoom: {
                    enabled: false
                },
                height: 350,
                type: 'line',
                width: 1
            },
            stroke: {
                curve: 'smooth',
                width: 3
            },
            fill: {
                type: 'solid',
                opacity: [0.1, 0.1],
            },
            labels: ['Dec 01', 'Dec 02', 'Dec 03', 'Dec 04', 'Dec 05', 'Dec 06', 'Dec 07', 'Dec 08', 'Dec 09 ', 'Dec 10', 'Dec 11'],
            markers: {
                size: 0
            },
            legend: {
                show: false
            },
            xaxis: {
                type: "category",
                labels: {
                    show: true,
                    rotate: -45,
                    rotateAlways: true
                }
            },
            tooltip: {
                enabled: true,
                shared: true,
                intersect: false,
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                    return (
                        '<div class="arrow_box">' +
                        '<div class="number">' +
                        series[seriesIndex][dataPointIndex] +
                        "</div>" +
                        '<div class="text">' +
                        "" +
                        "</div>" +
                        "</span>" +
                        "</div>"
                    );
                }
            },
            colors: ["#43b3e6"]
        },
    })


    useEffect(() => {

        //picker.current.setStartDate('03/01/2014')

        if (bot_name !== null) {
            const end_date = new Date()
            const start_date = new Date(end_date)
            start_date.setDate(start_date.getDate() - 7)

            setid({ start_date: start_date.getMonth() + "/" + start_date.getDate() + "/" + start_date.getFullYear(), end_date: end_date.getMonth() + "/" + end_date.getDate() + "/" + end_date.getFullYear() })
            setdate({
                startDate: months[start_date.getMonth()] + " " + start_date.getDate() + ", " + start_date.getFullYear(),
                endDate: months[end_date.getMonth()] + " " + end_date.getDate() + ", " + end_date.getFullYear()
            })
            setcaldate({ startDate: start_date.getTime(), endDate: end_date.getTime() })
            picker.current.setStartDate(start_date.getMonth() + 1 + "/" + start_date.getDate() + "/" + start_date.getFullYear())
            picker.current.setEndDate(end_date.getMonth() + 1 + "/" + end_date.getDate() + "/" + end_date.getFullYear())

            ApiCall(start_date.getTime(), end_date.getTime(), bot_name, type)
        }

    }, [bot_name])


    const ApiCall = (start_date, end_date, bot_name, type) => {
        const params = new URLSearchParams({
            bot_name: bot_name,
            start_date: start_date,
            end_date: end_date,
            filter_by: type
        }).toString();

        Api.post('/analytics/total-lifetime-conversation?' +
            params, "",
            {
                headers: {
                    Authorization: 'Token ' + tk
                },
            })
            .then(res => {

                if (res.status === 403 || res.status === 401) {
                    return LogOut(history)
                }
                setchartdata({ ...chartdata, series: [{ ...chartdata.series, data: res.data.data }], options: { ...chartdata.options, labels: res.data.days } })
            })
            .catch(xhr => {
                
                let errormesae = JSON.stringify(xhr.message);
                if (errormesae == '"Request failed with status code 401"' || errormesae == '"Request failed with status code 403"') {
                    return LogOut(history);
                }
               
            });

    }

    const filter_by = (type) => {

        settype(type)

        if (type === "session") {
            setftype("Sessions")
            ApiCall(caldate.startDate, caldate.endDate, bot_name, "session")
        }
        else if (type === "message") {
            setftype("Messages")
            ApiCall(caldate.startDate, caldate.endDate, bot_name, "message")
        }
        else if (type === "time") {
            setftype("Minutes")
            ApiCall(caldate.startDate, caldate.endDate, bot_name, "time")
        }
    }


    const ExporttoPdf = () => {
        
        window.scrollTo(0, 0)

        setTimeout(() => {
            html2canvas(document.querySelector(".chart")).then(canvas => { // if you want see your screenshot in body.
                const imgData = canvas.toDataURL('image/png', 1.0);
                var pdf = new jsPDF("p", "mm", "a4");

                var width = pdf.internal.pageSize.getWidth();
                var height = pdf.internal.pageSize.getHeight();
                pdf.addImage(imgData, 'PNG', 5, 10, width - 10, 120);
                pdf.save("download.pdf");
            });
        }, 1000)


    }


    const onApply = (start, end) => {

        setcaldate({ startDate: parseInt(start.substring(0, 13)), endDate: parseInt(end.substring(0, 13)) })

        var date1 = new Date(parseInt(start))
        var date2 = new Date(parseInt(end))
        setdate({
            startDate: months[date1.getMonth()] + " " + date1.getDate() + ", " + date1.getFullYear(),
            endDate: months[date2.getMonth()] + " " + date2.getDate() + ", " + date2.getFullYear()
        })

        // console.log(date1.getFullMonth())

        ApiCall(parseInt(start.substring(0, 13)), parseInt(end.substring(0, 13)), bot_name, type)
    }

    return (
        <div className="LifetimeConversation-parent">
            <Loader />
            <div class="f-buttons">

                <div className="col-md'6" >

                    <div className="head">
                        Period
                    </div>

                    <Nav variant="pills" defaultActiveKey="day">
                        <Nav.Item>
                            <Nav.Link eventKey="day" onClick={() => { filter_by("session") }}>Session</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="week" onClick={() => { filter_by("time") }}>Time</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="month" onClick={() => { filter_by("message") }}>Message</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>

                <div className="col-md'6" style={{ marginLeft: 60 }}>

                    <div className="head">
                        Select Date Range
                    </div>


                    <img className="select-arrow date" src={da} />
                    <DateRangePicker ref={picker} initialSettings={{  /* startDate: "Select Date" , endDate: initdate.end_date  maxSpan: { days: "30" }*/ }} onCallback={(start, end, label) => { onApply(start + "", end + "") }}>
                        <input type="text"/>{/* <input type="text" className="form-control" /> */}
                    </DateRangePicker>
                </div>

                <div className="text-right" onClick={() => { ExporttoPdf() }}>
                    <div className="export-txt">
                        <img src={Export} style={{ marginRight: 10 }} />
                        Export to PDF
                    </div>
                </div>

            </div>


            <div className="chart">
                <div className="ctop-text">
                    User Engagement from {date.startDate === null ? "Sep 08, 2020 - Sep 15, 2020" : date.startDate + " - " + date.endDate}
                </div>
                <Chart options={chartdata.options} series={chartdata.series} type="line" /* type={initialdata.charttype} */ height={430} />
                <div className="cbottom-text">
                    {filter_type}/Day
                </div>
            </div>
        </div>
    )
}

export default LifetimeConversations
