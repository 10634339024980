import React from "react";
import ImageTag from "../ImageTag/ImageTag";
const Input = ({
   margin,
   placeholder,
   type,
   onClick,
   isEmail,
   onChange,
   backgroundColor,
   marginright,
   showIcon,
   width,
   isDropdown,
   data,
   marginBottom,
   value,
   readOnly
}) => {
   return (
      <form
         style={{
            marginBottom: margin ? (marginBottom ? marginBottom : 20) : 0,
            background: backgroundColor ? backgroundColor : "",
            marginRight: marginright ? marginright : 0,
            width: width ? width : "",
         }}
         className="input-container">
         {!isDropdown ?
            <input
               style={{ background: backgroundColor ? backgroundColor : "" }}
               onChange={e => onChange(e.currentTarget.value)}
               placeholder={placeholder}
               className="input-1"
               type={type}
               value={value}
               id="fname"
               name="fname"
               readOnly={readOnly !== undefined ? readOnly : false}
            /> :
            <select style={{ background: backgroundColor ? backgroundColor : "" }} className="input-1" name="cars" id="cars">
               {data.map((res, i) => {
                  return (
                     <option key={i} value={res.value}>{res.label}</option>
                  )
               })}
            </select>
         }
         {showIcon &&
            (!isEmail ? (
               <ImageTag
                  onClick={onClick}
                  style={{ cursor: "pointer" }}
                  src={type == "password" ? require("./assets/eye2.svg") : require("./assets/eye.svg")}
               />
            ) : (
                  <ImageTag src={require("./assets/email.svg")} />
               ))}
      </form>
   );
};
export default Input;


