import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Description from '../../../components/Description/Description';
import ImageTag from '../../../components/ImageTag/ImageTag';
import { convertToDateAndTime, convertToTime } from '../../../utils/Function';
import QuickReply from '../QuickReply/QuickReply';

const ChatMessages = props => {
   const [type, settype] = useState(null);
   const [show, setshow] = useState(false);

   const { quickReplies } = useSelector(state => state.liveagent);

   const SeeMore = () => {
      settype('show');
      setshow(true);
   };

   const AddQuickReplies = () => {
      settype('add');
      setshow(true);
   };

   const chatDirection = data => {
      return data.clientID === 'liveagent' || data.from === 'Bot' || data.from === 'Agent' ? 'right-chat' : 'left-chat';
   };

   return (
      <div className={`chatmessages-container ${props.userList.length === 0 ? 'nouser' : ''}`}>
         <QuickReply
            type={type}
            show={show}
            sendMessage={props.sendMessage}
            onHide={() => {
               setshow(false);
            }}
         />
         <div className="top-section">
            <h1>Live Agent</h1>
            {props.userList.length !== 0 && (
               <div className="add-button" onClick={AddQuickReplies}>
                  Add Quick Replies
               </div>
            )}
         </div>

         <div className="chat-list-container">
            {props.userID &&
               props.userMessage
                  .filter(filterData => filterData.room_id === props.userID)
                  .map((data, key) => (
                     <div className={chatDirection(data)} key={key}>
                        <div className="chat description-14">
                           <Description className="description-14">{data.user_message}</Description>
                        </div>

                        <Description className="description date">
                           {convertToDateAndTime(data.timestamp)} {convertToTime(data.timestamp)}
                        </Description>
                     </div>
                  ))}
         </div>

         {props.userList.length !== 0 && (
            <div className="quick-replies">
               <div className="reply-container">
                  {quickReplies.slice(0, 5).map((data, key) => (
                     <div className="reply" key={key} onClick={event => props.sendMessage(event, data)}>
                        {data}
                     </div>
                  ))}
               </div>
               {quickReplies.length > 5 && (
                  <div className="see-more" onClick={SeeMore}>
                     See More
                     <img src="images/backIcon.svg" alt="" />
                  </div>
               )}
            </div>
         )}
         <div className="bottom-section">
            <form onSubmit={props.sendMessage} autoComplete="off">
               <input
                  type="text"
                  placeholder="Type Message"
                  name="value"
                  value={props.value}
                  onChange={props.handleChange}
               />
            </form>
            <button className="start-button" onClick={props.sendMessage}>
               <ImageTag src="images/LiveAgentSend.svg" /> Send
            </button>

            <button className="pause-button" onClick={props.pauseBot}>
               <ImageTag src="images/LiveAgentLeave.svg" /> Leave
            </button>
         </div>
      </div>
   );
};

export default ChatMessages;
