import { SHOWHINT, HINTCOUNTCHANGE } from "../actions/hint/types";

const initialState = {
   showHint: false,
   hint_count: 0,
};

const HintReducer = (state = initialState, action) => {
   switch (action.type) {
      case SHOWHINT:
         return {
            ...state,
            showHint: action.payload,
         };
      case HINTCOUNTCHANGE:
         console.log("HINTCOUNTCHANGE");
         return {
            ...state,
            showHint: true,
            hint_count: action.payload,
         };
      default:
         return state;
   }
};

export default HintReducer;
