import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Dropdown from '../Components/Dropdown';

export default function FormHandler({ input, handleChange, Codes, handleChangeDropdownActions }) {
   if (input.type === 'text')
      return (
         <div className="input-container">
            <input
               id={input.data}
               name={input.data}
               type={input.type}
               placeholder="Text"
               style={{
                  backgroundColor: 'white',
               }}
               required={input.required}
               value={input.value}
               disabled={!input.edit}
               onChange={e => handleChange(e.target.value, input.data)}
               autocomplete="off"
            />
         </div>
      );
   if (input.type === 'email')
      return (
         <div className="input-container">
            <input
               id={input.data}
               name={input.data}
               type={input.type}
               placeholder="Email"
               style={{
                  backgroundColor: 'white',
               }}
               required={input.required}
               value={input.value}
               disabled={!input.edit}
               onChange={e => handleChange(e.target.value, input.data)}
               autocomplete="off"
            />
         </div>
      );
   if (input.type === 'password')
      return (
         <div className="input-container">
            <input
               id={input.data}
               name={input.data}
               type="text"
               placeholder="Password"
               style={{
                  backgroundColor: 'white',
               }}
               required={input.required}
               value={input.value}
               disabled={!input.edit}
               onChange={e => handleChange(e.target.value, input.data)}
               autocomplete="off"
            />
         </div>
      );
   if (input.type === 'email')
      return (
         <div
            className="input-container"
            // style={{ border: 'none', borderRadius: '0px', borderBottom: '1px solid #e5e5e5', color: 'white' }}
         >
            <input
               id={input.data}
               name={input.data}
               type={input.type}
               placeholder={input.label}
               style={{
                  backgroundColor: 'white',
               }}
               required={input.required}
               value={input.value}
               disabled={!input.edit}
               onChange={e => handleChange(e.target.value, input.data)}
               autocomplete="off"
            />
         </div>
      );
   if (input.type === 'phone')
      return (
         <div className="input-container input-container-flex">
            <select className="phone-select">
               {Codes.map((res, i) => {
                  return (
                     <option className="regular" key={i}>
                        {res.value}
                     </option>
                  );
               })}
            </select>
            <input
               id={input.data}
               className="wd-80"
               placeholder={input.label}
               style={{
                  border: 'none',
                  borderRadius: '0px',
                  borderBottom: '1px solid #e5e5e5',
                  backgroundColor: 'white',
               }}
               required={input.required}
               value={input.value}
               disabled={!input.edit}
               onChange={e => handleChange(e.target.value, input.data)}
               autocomplete="off"
            />
         </div>
      );

   if (input.type === 'date' && input.pastyear)
      return (
         <div className="input-container">
            <DatePicker
               id={input.data}
               // selected={input.value}
               // onChange={(date, e) => handleChange(date, input.data)}
               placeholderText={input.value ? input.value : input.label}
               disabled={!input.edit}
               className="date-picker"
               filterDate={date => Date.now() > date}
               onBlur={e => handleChange(e.target.value, input.data)}
            />
         </div>
      );
   if (input.type === 'date' && !input.pastyear)
      return (
         <div className="input-container">
            <DatePicker
               id={input.data}
               // selected={input.value}
               // onChange={(date, e) => handleChange(date, input.data)}
               placeholderText={input.value ? input.value : input.label}
               disabled={!input.edit}
               className="date-picker"
               filterDate={date => Date.now() < date}
               onBlur={e => handleChange(e.target.value, input.data)}
            />
         </div>
      );

   if (input.type === 'textarea')
      return (
         <textarea
            id={input.data}
            name={input.data}
            type={input.type}
            placeholder={input.label}
            style={{
               backgroundColor: 'white',
            }}
            required={input.required}
            value={input.value}
            disabled={!input.edit}
            onChange={e => handleChange(e.target.value, input.data)}
            autocomplete="off"
         />
      );

   if (input.type === 'dropdown')
      return (
         <Dropdown
            input={input}
            handleChange={handleChange}
            handleChangeDropdownActions={handleChangeDropdownActions}
         />
         // <div className="input-container input-container-flex">
         //    <select className="dropdown-select">
         //       <option className="regular">Select Gender</option>
         //       <option className="regular">Male</option>
         //       <option className="regular">Female</option>
         //    </select>
         //    <div className="actions-placeholder"></div>
         // </div>
      );
   if (input.type === 'submit')
      return (
         <div className="input-container " style={{ border: 'none' }}>
            <div className="button">{input.label}</div>
         </div>
      );
}
