import React, { useState, useEffect } from "react"
import Export from "../../assets/images/export.svg"
import jsPDF from "jspdf"
import da from "../../assets/images/dropdown-arrow.svg"
import { months } from "../../utils/Utility"
import Chart from "react-apexcharts";
import Api from "../../axios/request"
import html2canvas from 'html2canvas';
import DateRangePicker from "react-bootstrap-daterangepicker"
import 'bootstrap-daterangepicker/daterangepicker.css';
import NoMessage from "../../pages/LiveAgent/NoMessage/NoMessage"
import { useSelector } from "react-redux"
import { LogOut } from "../../redux/actions/profile/index"
import { useHistory } from "react-router-dom"
import Left from "../../assets/images/left_arrow.svg"
import Right from "../../assets/images/right_arrow.svg"
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';


const TopIntents = (props) => {

    const [data, setdata] = useState({
        messages: [],
        count: []
    })

    const [date, setdate] = useState({ startDate: null, endDate: null })
    const tk = JSON.parse(localStorage.getItem("user")).token
    const bot_name = useSelector(state => state.storybuilder.bot_name)
    const history = useHistory()
    const [page, setpage] = useState(1)
    const [total_pages, setTP] = useState(1)
    const [api_data, setData] = useState([]);

    const useStyles = makeStyles((theme) => ({
        root: {
            '& > *': {
                marginTop: theme.spacing(2),
                right: 0
            },
        },
    }));

    const handleChange = (event, value) => {
        setpage(value);
        setMessages(api_data, value)
    };

    const [chartdata, setchartdata] = useState({

        series: [{
            name: "All Intents",
            data: []
        }],
        options: {
            chart: {
                id: "mcchart",
                type: 'bar',
                height: "auto"
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    barHeight: '20%'
                }
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                categories: [],
                labels: {
                    show: true,
                    maxWidth: "auto",
                    trim: false
                }
            },
            colors: ["#43b3e6"],

            yaxis: {
                labels: {
                    minWidth: "auto",
                    maxWidth: "auto",
                    left: 0,
                    trim: false
                }
            }
        },

    })

    useEffect(() => {

        if (bot_name !== null) {
            const end_date = new Date()
            const start_date = new Date(end_date)
            start_date.setDate(start_date.getDate() - 7)

            setdate({
                startDate: months[start_date.getMonth()] + " " + start_date.getDate() + ", " + start_date.getFullYear(),
                endDate: months[end_date.getMonth()] + " " + end_date.getDate() + ", " + end_date.getFullYear()
            })

            ApiCall(start_date.getTime(), end_date.getTime(), bot_name)
        }

    }, [bot_name])

    useEffect(() => {
        if (data.messages.length !== 0) {
            setTP(Math.ceil(data.messages.length / 20))
        }
    }, [data])


    const ApiCall = (start_date, end_date, bot_name) => {
        Api.get('/analytics/allIntents/?', {
            params: {
                bot_name: bot_name,
                start_date: start_date,
                end_date: end_date
            },
            headers: {
                Authorization: 'Token ' + tk,
            },
        })
            .then(res => {
                if (res.status === 403 || res.status === 401) {
                    return LogOut(history)
                }
                console.log(res.data)
                setMessages(res.data)
                setData(res.data)
            })
            .catch((error, xhr) => {
                let errormesae = JSON.stringify(xhr.message);
                if (errormesae == '"Request failed with status code 401"' || errormesae == '"Request failed with status code 403"') {
                    return LogOut(history);
                }
                console.log(error);
            });
    };



    const setMessages = (data, page = 1) => {
        var messages = []
        var count = []
        Object.keys(data).forEach(key => {
            messages.push(key)
            count.push(data[key])
        })

        setdata({
            messages: messages,
            count: count
        })

        messages = messages.filter((item, index) => { if (index >= (page - 1) * 20 && index < page * 20) return true })
        count = count.filter((item, index) => { if (index >= (page - 1) * 20 && index < page * 20) return true })

        setchartdata({ ...chartdata, series: [{ name: "All Intents", data: count }], options: { ...chartdata.options, xaxis: { ...chartdata.options.xaxis, categories: messages } } })
    }


    const ExporttoPdf = () => {

        if (data.messages.length !== 0) {
            window.scrollTo(0, 0)

            setTimeout(() => {
                html2canvas(document.querySelector(".chart")).then(canvas => {
                    const imgData = canvas.toDataURL('image/png', 1.0);
                    var pdf = new jsPDF("p", "mm", "a4");

                    var width = pdf.internal.pageSize.getWidth();
                    var height = pdf.internal.pageSize.getHeight();
                    pdf.addImage(imgData, 'PNG', 5, 10, width - 10, 120);
                    pdf.save("download.pdf");
                });
            }, 1000)
        }
    }

    const onApply = (start, end) => {

        var date1 = new Date(parseInt(start))
        var date2 = new Date(parseInt(end))

        setdate({
            startDate: months[date1.getMonth()] + " " + date1.getDate() + ", " + date1.getFullYear(),
            endDate: months[date2.getMonth()] + " " + date2.getDate() + ", " + date2.getFullYear()
        })


        ApiCall(parseInt(start.substring(0, 13)), parseInt(end.substring(0, 13)), bot_name)
    }


    return (
        <div className="MessageOut-parent top-intent-parent">
            <div class="f-buttons">

                <div className="c-container" style={{ marginLeft: 30 }}>
                    <div className="head">
                        Date Range
                    </div>

                    <img className="select-arrow date" src={da} />
                    <DateRangePicker initialSettings={{}} onCallback={(start, end, label) => { onApply(start + "", end + "") }}>
                        <input type="text" placeholder="Containing Text"></input>
                    </DateRangePicker>

                </div>

                <div className="text-right" onClick={() => { ExporttoPdf() }}>
                    <div className="export-txt">
                        <img src={Export} style={{ marginRight: 10 }} />
                        Export to PDF
                    </div>
                </div>

            </div>


            {data.messages.length !== 0 ? <div className="chart">
                <div class="chartt-row">
                    <div className="ctop-text">
                        All Intents
                    </div>
                </div>

                <Chart options={chartdata.options} series={chartdata.series} type="bar" height={"auto"} />

            </div> : <NoMessage />}

            <div className="pagination" style={{ display: total_pages === 1 ? "none" : "flex" }}>
                <div className="page-cont">
                    <div className="p-button" onClick={() => { if (page !== 1) { handleChange(null, page - 1) } }}>
                        <img src={Left} style={{ marginRight: 10 }} />
                        Prev
                    </div>
                    <div className={useStyles().root}>
                        <Pagination count={total_pages} shape="rounded" page={page} hideNextButton={true} hidePrevButton={true} onChange={handleChange} />
                    </div>
                    {(page !== total_pages) &&
                        <div className="p-button" onClick={() => { handleChange(null, page + 1) }}>
                            Next
                            <img src={Right} style={{ marginLeft: 10 }} alt="Right icon" />
                        </div>
                    }
                </div>
            </div>

        </div>
    )
}

export default TopIntents