import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import cross1 from '../../../assets/images/cross1.svg';
import cross2 from '../../../assets/images/cross2.svg';
import { AddQuickReplies, DeleteQuickReplies } from '../../../redux/actions/liveagent';

const QuickReply = props => {
   const [quickInput, setQuickInput] = useState('');
   const [disabled, setDisableButton] = useState(false);
   const { quickReplies } = useSelector(state => state.liveagent);
   const dispatch = useDispatch();

   const handldeChange = event => {
      setQuickInput(event.target.value);
   };

   const addReplies = async event => {
      setDisableButton(true);
      event.preventDefault();
      if (quickInput !== '') {
         if (quickReplies.includes(quickInput)) {
            alert('Item already exist');
            setDisableButton(false);
            return;
         }
         const response = await dispatch(AddQuickReplies(quickInput));
         if (response) setDisableButton(false);
         setQuickInput('');
      }
   };

   const deleteReplies = async data => {
      setDisableButton(true);
      const response = await dispatch(DeleteQuickReplies(data));

      if (response) {
         setDisableButton(false);
      }
   };

   return (
      <div>
         <Modal
            dialogClassName="quickreply-container"
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={props.show}>
            <img
               src={cross1}
               className="cross"
               onClick={() => {
                  props.onHide();
               }}
            />

            <div className="title"> {props.type === 'add' ? 'Add Quick Replies' : 'Select Quick Replies'}</div>
            {props.type === 'add' && (
               <div className="input-reply">
                  <form onSubmit={addReplies}>
                     <input
                        placeholder="Enter Quick Reply"
                        name="quickInput"
                        value={quickInput}
                        onChange={handldeChange}
                     />
                  </form>
               </div>
            )}

            <div className="reply-container">
               {quickReplies.map((data, key) => (
                  <div
                     className="reply"
                     key={key}
                     {...(props.type !== 'add'
                        ? {
                             onClick: event => {
                                props.sendMessage(event, data);
                                props.onHide();
                             },
                          }
                        : {})}>
                     {props.type === 'add' && (
                        <button className="inner-cross" onClick={() => deleteReplies(data)} disabled={disabled}>
                           <img src={cross2} />
                        </button>
                     )}
                     {data}
                  </div>
               ))}
            </div>

            {props.type === 'add' && (
               <div className="btn-container">
                  <button className="add-b" onClick={addReplies}>
                     {disabled ? 'Loading..' : 'Add'}
                  </button>
               </div>
            )}
         </Modal>
      </div>
   );
};

export default QuickReply;
