import React, { useState, useContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import FormHandler from './handlers/FormHandler';
import Button from './Components/Button';
import Sidebar from './Components/Sidebar';
import IOSSwitch from './Components/IosSwitch';

import Codes from './assets/countryCodes';

import { LanguageContext } from '../ChatBot';
import Select from 'react-select';
import { getEntity } from '../../../pages/StoryCreation/requests/requests'

const scrollTo = (height, offsetTop) => {
   var objDiv = document.getElementById('__chatBox__');

   if (objDiv) objDiv.scrollTop = height + offsetTop - 450;
};

const ChatBotForm = ({ data, remove, duplicate, OPTIONS, update }) => {
   const { language } = useContext(LanguageContext);
   // const [values, setValues] = useState({
   //    code: '+93',
   // });
   const [form_name, setform_name] = useState('NewForm');
   const [editFormName, seteditFormName] = useState(false);
   const [datetype, setdatetype] = useState(false);
   const [formData, setformData] = useState({ en: [], ar: [] });
   const [currentButton, setcurrentButton] = useState(null);
   const [showPopUp, setshowPopUp] = useState(false);
   const [entityList, setEntityList] = useState([]);

   useEffect(() => {
      if (entityList.length == 0) {
         getEntities();
      }
      if (data.form_name) {
         if (data.form_name == 'NewForm') seteditFormName(true);
         setform_name(data.form_name);
      } else {
         seteditFormName(true);
      }
      if (
         data &&
         Object.keys(data.response_elements).length > 0 &&
         (data.response_elements.en.length > 0 || data.response_elements.ar.length > 0) &&
         formData.en.length === 0 &&
         formData.ar.length === 0
      ) {
         let newData = { en: [], ar: [] };
         data.response_elements.en.forEach((element, i) => {
            newData.en.push({ ...element, edit: false, showRequiredField: false });
         });
         data.response_elements.ar.forEach((element, i) => {
            newData.ar.push({ ...element, edit: false, showRequiredField: false });
         });
         setformData({ ...newData });
      }
   }, [data, formData.length]);

   const getEntities = async () => {
      try {
         const response = await getEntity();
         const newEntity = [];
         response.forEach(data => {
            newEntity.push({ value: data, label: data });
         });
         setEntityList(newEntity);
      } catch (err) {
         console.log(err);
      }
   }

   const save = newFormData => {
      let newDataen;
      let newDataar;

      //console.log(newFormData);

      newDataen = newFormData.en.map(f => {
         console.log(f)
         let el = { ...f };
         delete el.edit;
         delete el.showRequiredField;
         el.data = form_name.replace(/\s+/g, "_") + '_' + el.label.replace(/\s+/g, "_") + '_' + (new Date().getTime() * 1000);
         return { ...el, label: el.value ? el.value : el.label, value: '' };
      });

      newDataar = newFormData.ar.map(f => {
         let el = { ...f };
         delete el.edit;
         delete el.showRequiredField;
         el.data = form_name.replace(/\s+/g, "_") + '_' + el.label.replace(/\s+/g, "_") + '_' + (new Date().getTime() * 1000);
         return { ...el, label: el.value ? el.value : el.label, value: '' };
      });


      update({ ...data, form_name, response_elements: { en: [...newDataen], ar: [...newDataar] } });
   };
   const selectDateType = () => {
      return (
         <div
            className="datetype"
            style={{ right: language === 'ar' && '312px' }}
            tabindex="0"
            onBlur={() =>
               setTimeout(() => {
                  setdatetype(false);
               }, 150)
            }>
            <div
               onClick={() => {
                  addNewInput('date', true);
                  setdatetype(false);
               }}>
               {language === 'ar' ? 'تاريخ الولادة' : 'Date of Birth'}
            </div>

            <div
               onClick={() => {
                  addNewInput('date', false);
                  setdatetype(false);
               }}>
               {language === 'ar' ? 'تاريخ التعيين' : 'Appointment'}
            </div>
         </div>
      );
   };

   console.log(formData)

   const addNewInput = (type, pastyear) => {
      if (isDisabled()) return;
      let el = {
         type,
         entity: null,
         label: language === 'ar' ? 'ملصق' : 'Label',
         data: `${form_name}_${type}_${uuidv4()}`,
         value: '',
         required: true,
         edit: false,
      };
      if (type === 'date') {
         el.pastyear = pastyear;
         el.pastdate = pastyear;
         el.label = pastyear
            ? language === 'ar'
               ? 'تاريخ الولادة'
               : 'Date of Birth'
            : language === 'ar'
               ? 'تاريخ التعيين'
               : 'Appointment';
      }
      if (type === 'dropdown') {
         el.options = [
            { value: language === 'ar' ? 'الذكر' : 'Male' },
            { value: language === 'ar' ? 'أنثى' : 'Female' },
         ];
         el.label = language === 'ar' ? 'حدد نوع الجنس' : 'Select Gender';
      }
      setformData({ ...formData, [language]: [...formData[language], el] });
      save({ ...formData, [language]: [...formData[language], el] });
   };

   const handleChangeActions = (id, action, value) => {
      const newData = formData[language].map(el => (el.data === id ? { ...el, [action]: !value } : el));
      setformData({ ...formData, [language]: newData });
      if (action === 'edit')
         setTimeout(() => {
            const el = document.getElementById(id);
            if (el) el.focus();
         }, 0);
      if (action === 'required') save({ ...formData, [language]: newData });
   };

   const handleChangeDropdownActions = (options, id) => {
      const newFormData = formData[language].map(el => (el.data === id ? { ...el, options } : el));
      setformData({ ...formData, [language]: newFormData });

      save({ ...formData, [language]: newFormData });
   };

   const handleChange = (value, id) => {
      const newFormData = formData[language].map(el => (el.data === id ? { ...el, value } : el));
      setformData({ ...formData, [language]: newFormData });
      save({ ...formData, [language]: newFormData });
   };

   const handleEntityChange = (entity, id) => {
      const newFormData = formData[language].map(el => (el.data === id ? { ...el, entity: entity.value } : el));
      setformData({ ...formData, [language]: newFormData });
      save({ ...formData, [language]: newFormData });
   }

   const handleDelete = id => {
      const newFormData = formData[language].filter(el => el.data !== id);
      setformData({ ...formData, [language]: newFormData });
      save({ ...formData, [language]: newFormData });
   };

   const updatebutton = (value, index) => {
      console.log(value)
      const submit_data = `submit_${form_name.replace(/ /g, '_').toLowerCase()}`;

      const newFormData = formData[language].map((f, i) =>
         i === index ? { ...f, label: value.title, data: submit_data, payload: value.type } : f
      );
      setformData({ ...formData, [language]: newFormData });

      setshowPopUp(false);
      setcurrentButton(null);
      save({ ...formData, [language]: newFormData });
   };

   const isDisabled = () => {
      let disabled = false;
      formData[language].forEach(data => {
         if (data.entity === null) {
            disabled = true;
         }
      })
      return disabled;
   }

   return (
      <div style={{ position: 'relative' }}>
         <div
            className="chats-ChatBotForm-parent"
            style={{
               flexDirection: language === 'ar' ? 'row-reverse' : 'row',
               marginLeft: language === 'ar' && '-30px',
            }}
            id={data.response_name}>
            <div className="message-container-ChatBotForm" dir={language === 'ar' && 'rtl'}>
               <p className="regular text" style={{ fontWeight: 'bold' }}>
                  {editFormName ? (
                     <input
                        id="title_input"
                        className={`title_input ${form_name == 'NewForm' ? 'title_error' : ''}`}
                        value={form_name}
                        onChange={e => setform_name(e.target.value)}
                        onKeyPress={e => {
                           if (e.key === 'Enter') {
                              e.preventDefault();
                              if (editFormName) save(formData);
                              if (form_name != 'NewForm') seteditFormName(!editFormName);
                           }
                        }}
                        onBlur={e => {
                           if (editFormName) save(formData);
                           if (form_name != 'NewForm') seteditFormName(!editFormName);
                        }}
                     />
                  ) : (
                     form_name
                  )}
                  <img
                     src={require('./assets/edit.svg')}
                     width="14px"
                     alt="edit"
                     onClick={() => {
                        if (editFormName) save(formData);
                        seteditFormName(!editFormName);
                        setTimeout(() => {
                           const el = document.getElementById('title_input');
                           if (el) el.focus();
                        }, 0);
                     }}
                  />
               </p>
               {formData[language].length === 0 && (
                  <div className="no_input">
                     <p className="regular text" style={{ fontWeight: 'bold' }}>
                        {language === 'en' ? 'No Input Added yet' : 'لم تتم إضافة أي إدخال حتى الآن'}!
                     </p>
                  </div>
               )}
               {formData[language].map((el, i) => (
                  <div className="entity-wrapper">
                     <Select
                        className="entity-select"
                        options={entityList}
                        value={el.entity ? { value: el.entity, label: el.entity } : null}
                        onChange={(value) => { handleEntityChange(value, el.data) }}
                     />
                     {el.entity && <div
                        className="input-wrapper"
                        key={i}
                        onMouseLeave={() => handleChangeActions(el.data, 'showRequiredField', true)}>
                        {
                           <FormHandler
                              input={el}
                              handleChange={handleChange}
                              Codes={Codes}
                              handleChangeDropdownActions={handleChangeDropdownActions}
                           />
                        }
                        {el.type === 'submit' && (
                           <span
                              className="submit-edit"
                              style={{
                                 right: language === 'ar' && '187px',
                              }}>
                              <img
                                 src="/images/pencil-edit-button.svg"
                                 width="10px"
                                 alt="edit"
                                 style={{
                                    margin: '4px 4px 8px 6px',
                                 }}
                                 onClick={() => {
                                    handleChangeActions(el.data, 'edit', el.edit);
                                    // const element = document.getElementById(data.response_name);
                                    // if (element) {
                                    //    let height = element.offsetHeight;
                                    //    let offsetTop = element.offsetTop;
                                    //    scrollTo(height, offsetTop);
                                    // }
                                    if (el.type === 'submit') {
                                       setshowPopUp(true);
                                       setcurrentButton(i);
                                    }
                                 }}
                              />
                           </span>
                        )}
                        <div
                           className={`input-actions ${el.type === 'dropdown' ? 'dropdown-icons' : ''}`}
                           style={{
                              right: language === 'ar' && '177px',
                           }}>
                           {el.type !== 'submit' && el.type !== 'dropdown' && (
                              <img
                                 src={require('./assets/required.svg')}
                                 width="12px"
                                 alt="required"
                                 onClick={() => handleChangeActions(el.data, 'showRequiredField', el.showRequiredField)}
                              />
                           )}
                           {el.showRequiredField && (
                              <div
                                 className="required-field"
                                 style={{
                                    flexDirection: language === 'ar' ? 'row-reverse' : 'row',
                                    right: language === 'ar' && '-53px',
                                 }}>
                                 <p>{language === 'ar' ? 'يتطلب حقلا' : 'Required Field'}</p>
                                 <IOSSwitch
                                    checked={el.required}
                                    onChange={e => {
                                       handleChangeActions(el.data, 'required', el.required);
                                       if (el.type === 'submit') {
                                          setshowPopUp(true);
                                          setcurrentButton(i);
                                       }
                                    }}
                                    name="required"
                                 />
                                 <div className="arrow-up"></div>
                              </div>
                           )}

                           {el.type !== 'submit' && (
                              <img
                                 src={require('./assets/edit.svg')}
                                 width="14px"
                                 alt="edit"
                                 onClick={() => {
                                    handleChangeActions(el.data, 'edit', el.edit);
                                 }}
                              />
                           )}

                           <img
                              src={require('./assets/delete.svg')}
                              width="12px"
                              alt="delete"
                              onClick={() => handleDelete(el.data)}
                           />
                        </div>
                     </div>}
                  </div>
               ))}
            </div>
            <div
               className="__edit-icons__"
               style={{ left: language === 'ar' ? '290px' : '74px', bottom: '-26px', paddingRight: '2px' }}>
               <img src="/images/copy.svg" width="20px" alt="copy" onClick={() => duplicate(data.response_name)} />
               <img
                  src="/images/delete.svg"
                  width="23px"
                  style={{ margin: '5px' }}
                  alt="delete"
                  onClick={() => remove(data.response_name)}
               />
            </div>

            <Sidebar
               addNewInput={addNewInput}
               setdatetype={setdatetype}
               datetype={datetype}
               selectDateType={selectDateType}
               language={language}
            />
         </div>
         {showPopUp && (
            <Button
               data={formData[language]}
               language={language}
               setshowPopUp={setshowPopUp}
               OPTIONS={OPTIONS}
               currentIndex={currentButton}
               updatebutton={updatebutton}
            />
         )}
      </div>
   );
};

export default ChatBotForm;
