import Api from '../../../axios/request';
import { SETBOTNAME, SETCUSTOMACTIONS } from './types';

export const GetIntent = (botname, token) => {
   return new Promise((response, reject) => {
      Api.get('/logic/createIntent/', {
         params: {
            bot_name: botname,
         },
         headers: {
            Authorization: 'Token ' + token,
         },
      })
         .then(res => {
            if (res.status === 200) {
               response(res.data.data);
            } else {
               reject(res.data);
            }
         })
         .catch(error => {
            reject(error);
         });
   });
};

export const GetBot = token => {
   return dispatch => {
      Api.get('/logic/bot/', {
         headers: {
            Authorization: 'Token ' + token,
         },
      })
         .then(res => {
            if (res.status === 200) {
               dispatch({ type: SETBOTNAME, payload: res.data.bot_details[0].bot_name });
               localStorage.setItem('bot_name', res.data.bot_details[0].bot_name);
            } else {
               console.log(res.data);
            }
         })
         .catch(error => {
            console.log(error);
         });
   };
};

export const GetCustomActions = (token, bot_name) => {
   return dispatch => {
      Api.get('/logic/customActions', {
         headers: {
            Authorization: 'Token ' + token,
         },
         params: {
            bot_name,
         },
      })
         .then(res => {
            if (res.status === 200) {
               const options = res.data.actions.map(op => {
                  const key = Object.keys(op);
                  return {
                     label: key[0],
                     value: op[key[0]],
                  };
               });
               dispatch({ type: SETCUSTOMACTIONS, payload: options });
            } else {
               console.log(res.data);
            }
         })
         .catch(error => {
            console.log(error);
         });
   };
};
