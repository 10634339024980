import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import ImageTag from '../../../components/ImageTag/ImageTag';


const useStyles = makeStyles((theme) => ({
  input: {
    width: 265,
    border:'none',
    height:38,
    borderRadius:100,
    paddingLeft:14,
    fontSize:14,
    letterSpacing:0.14,
    color:"#000"
  },
  listbox: {
    width: "100%",
    margin: 0,
    padding: 0,
    zIndex: 1,
    borderRadius:14,
    listStyle: 'none',
    backgroundColor: "#fff",
    overflow: 'auto',
    border: 'none',
    '& li[data-focus="true"]': {
      backgroundColor: '#43b3e6',
      color: 'white',
      cursor: 'pointer',
    },
    '& li:active': {
      backgroundColor: '#43b3e6',
      color: 'white',
    },
  },
}));

const UseAutocomplete = ({data, onChange, value}) => {
  const classes = useStyles();
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: 'use-autocomplete-demo',
    options: data,
    getOptionLabel: (option) => option.title,
  });

  return (
    <div>
      <div className="input-container" {...getRootProps()}>
        <input placeholder="Search" value={value} onChange={(e)=>onChange(e.target.value)} className={classes.input} {...getInputProps()} />
        <ImageTag src={require('./assets/search.svg')}/>
      </div>
      {groupedOptions.length > 0 ? (
        <ul className={classes.listbox} {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <li key={index} className="list-Items input-lable" {...getOptionProps({ option, index })}>{option.title}</li>
          ))}
        </ul>
      ) : null}
    </div>
  );
}

const ChatBotSuggestiveSearch = ({isHuman, options}) =>{
    const [value, setValue] = useState("")
    if(!isHuman){
        return(
            <div className="chats-ChatBotSuggestiveSearch-parant">
                <div className="image">
                  <ImageTag src={require('./assets/bot.jpg')} />
                </div>
                <div className="message-container-ChatBotSuggestiveSearch">
                    <p className="regular text">Suggestive Search:</p>
                    <UseAutocomplete value={value} onChange={(x)=>setValue(x)} data={options}/>
                </div>
            </div>
        )
    }
    else{
        return(
            <div className="chats-ChatBotSuggestiveSearch-parant-bot">
                <div className="message-container-ChatBotSuggestiveSearch-bot">
                    <p className="regular text">Suggestive Search:</p>
                    <UseAutocomplete value={value} onChange={(x)=>setValue(x)} data={options}/>
                </div>
                <div className="image-bot">
                  <ImageTag src={require('./assets/bot.jpg')} />
                </div>
            </div>
        )
    }
}

export default ChatBotSuggestiveSearch;