// import JSZip from "jszip";
/**
 * Removes the / from the URL or from the given data
 *
 * @param {*} data
 */
export const replaceUrl = data => data.replace('/', '');

export const generateToken = () => {
   const a = Date.now();
   // const b = "SecretKey";
   // const c = Math.random();
   // const z = a + b + c;

   return a;
};

export const getToken = () => {
   return JSON.parse(localStorage.getItem('user')).token;
};

export const getUsername = () => {
   return JSON.parse(localStorage.getItem('user'))?.username.substring(0, 2).toUpperCase() ?? '</>';
};

/**
 *
 * Generic Function to resolve reducer
 *
 * @param {string} state
 * @param {string} action
 */
export const resolve_redux_state = (state = null, action = null) => {
   if (action && state) {
      return {
         ...state,
         ...action,
      };
   }

   return { ...state };
};

/**
 * Replacing String With These Characters
 *
 * @param {*} str
 *
 */
export const decodeString = str => {
   return str
      .replace(/&amp;/g, '&')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&quot;/g, '"')
      .replace(/&#x27;/g, "'")
      .replace(/&#x2F;/g, '/');
};

/**
 * Prevent Background Scroll when a modal is open
 *
 */

export const preventScroll = () => {
   // When the modal is shown, we want a fixed body
   document.body.style.position = 'fixed';
   document.body.style.top = `-${window.scrollY}px`;
};

/**
 * Enable Backgroundscroll after closing of the Modal
 *
 */

export const enableScroll = () => {
   // When the modal is hidden...
   const scrollY = document.body.style.top;
   document.body.style.position = '';
   document.body.style.top = '';
   window.scrollTo(0, parseInt(scrollY || '0') * -1);
};

/**
 * Display Date with / Example : 0/10/2020
 *
 * @param {*} date
 */
export const dateToDispFormat = date => {
   let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

   if (month.length < 2) month = '0' + month;
   if (day.length < 2) day = '0' + day;

   return [day, month, year].join('/');
};

// Smooth Scrolling into a section on Click
export const scrollSection = element => () => {
   document.querySelector(`#${element}`).scrollIntoView({
      behavior: 'smooth',
   });
};

export const scrollToBottom = element => {
   const messageDIV = document.querySelector(element);
   if (messageDIV !== null) {
      messageDIV.scrollTop = messageDIV.scrollHeight;
   }
};

// Format Json String with Color
export const syntaxHighlight = json => {
   json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
   return json.replace(
      /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
      function (match) {
         var cls = 'number';
         if (/^"/.test(match)) {
            if (/:$/.test(match)) {
               match = match.replace(/[":']/g, '');
               cls = 'key';
            } else {
               match = match.replace(/["]/g, "'");
               cls = 'string';
            }
         } else if (/true|false/.test(match)) {
            cls = 'boolean';
         } else if (/null/.test(match)) {
            cls = 'null';
         }
         return '<span class="' + cls + '">' + match + '</span>';
      }
   );
};
// Outputs the Json highlter on to the DOM
export const output = (element, inp) => {
   const pre = document.querySelector(element);
   if (pre !== null) {
      pre.appendChild(document.createElement('pre')).innerHTML = inp;
   }
};

// Copy to Clopboard
export const copyToClipboard = element => {
   var range = document.createRange();
   range.selectNode(document.getElementById(element));
   window.getSelection().removeAllRanges(); // clear current selection
   window.getSelection().addRange(range); // to select text
   document.execCommand('copy');
   window.getSelection().removeAllRanges(); // to deselect
};

//date transformer
export const convert = str => {
   var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
   return [date.getFullYear(), mnth, day].join('-');
};

//  Convert Timestamp to Time
export const convertToTime = timestamp => {
   var d = new Date(+timestamp);
   var localTime = d.getTime();
   var localOffset = d.getTimezoneOffset() * 60000;
   var utc = localTime + localOffset;
   var offset = 4;
   var dubai = utc + 3600000 * offset;
   var nd = new Date(dubai);
   const currentTime = nd.getTime();
   const convertedTime = new Date(currentTime).toLocaleTimeString('en-US');
   return convertedTime;
};

//  Convert Timestamp to Time
export const convertToDateAndTime = timestamp => {
   const date = new Date(+timestamp).toDateString();
   return date;
};

//Enter functionality

export const OnEnter = event => {
   if (event.keyCode === 13) {
      // Cancel the default action, if needed
      event.preventDefault();
      // Trigger the button element with a click
      document.getElementById('myBtn').click();
   }
};
