import React, { useState, useEffect } from 'react';
import Export from '../../assets/images/export.svg';
import { Nav } from 'react-bootstrap';
//import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import ApexCharts from 'apexcharts';
import jsPDF from 'jspdf';
import da from '../../assets/images/dropdown-arrow.svg';
//import 'react-dates/initialize';
//import 'react-dates/lib/css/_datepicker.css';
import { months } from '../../utils/Utility';
import Chart from 'react-apexcharts';
import TableFunnel from '../Table/TableFunnel';
import { messages_out } from './message_out';
import Api from '../../axios/request';
import html2canvas from 'html2canvas';

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import nomsg from '../../assets/images/nomsg.svg';
import NoMessage from '../../pages/LiveAgent/NoMessage/NoMessage';
import { useSelector } from 'react-redux';
import { LogOut } from '../../redux/actions/profile/index';
import { useHistory } from 'react-router-dom';
import CsvDownload from 'react-json-to-csv';

const MessageOut = props => {
   const [data, setdata] = useState({
      messages: [],
      count: [],
   });

   const [date, setdate] = useState({ startDate: null, endDate: null });
   const tk = JSON.parse(localStorage.getItem('user')).token;
   const bot_name = useSelector(state => state.storybuilder.bot_name);
   const history = useHistory();

   const [chartdata, setchartdata] = useState({
      series: [
         {
            name: 'Messages Count',
            data: [],
         },
      ],
      options: {
         chart: {
            id: 'mcchart',
            type: 'bar',
            height: 350,
         },
         plotOptions: {
            bar: {
               horizontal: true,
               barHeight: '20%',
            },
         },
         dataLabels: {
            enabled: false,
         },
         xaxis: {
            categories: [],
            labels: {
               show: true,
               maxWidth: 240,
               trim: true,
            },
         },
         colors: ['#43b3e6'],
         //https://apexcharts.com/docs/multiline-text-and-line-breaks-in-axes-labels/
         // go to this link to understand how line break works
         yaxis: {
            labels: {
               minWidth: 180,
               maxWidth: 180,
               left: 0,
               trim: true,
            },
         },
      },
   });

   useEffect(() => {
      if (bot_name !== null) {
         const end_date = new Date();
         const start_date = new Date(end_date);
         start_date.setDate(start_date.getDate() - 7);

         //setid({ start_date: start_date.getMonth() + "/" + start_date.getDate() + "/" + start_date.getFullYear(), end_date: end_date.getMonth() + "/" + end_date.getDate() + "/" + end_date.getFullYear() })
         setdate({
            startDate: months[start_date.getMonth()] + ' ' + start_date.getDate() + ', ' + start_date.getFullYear(),
            endDate: months[end_date.getMonth()] + ' ' + end_date.getDate() + ', ' + end_date.getFullYear(),
         });
         //setcaldate({ startDate: start_date.getTime(), endDate: end_date.getTime() })

         ApiCall(start_date.getTime(), end_date.getTime(), bot_name);
      }
   }, [bot_name]);

   const ApiCall = (start_date, end_date, bot_name) => {
      const params = new URLSearchParams({
         bot_name: bot_name,
         start_date: start_date,
         end_date: end_date,
      }).toString();

      Api.post('/analytics/message-out?' + params, '', {
         headers: {
            Authorization: 'Token ' + tk,
         },
      })
         .then(res => {
            if (res.status === 403 || res.status === 401) {
               return LogOut(history);
            }
            console.log(res.data);
            setMessages(res.data);
            //setchartdata({ ...chartdata, series: [{ ...chartdata.series, data: res.data.data }], options: { ...chartdata.options, labels: res.data.days } })
         })
         .catch((error, xhr) => {
            let errormesae = JSON.stringify(error?.response);
            if (error?.response?.status === 403 || error?.response?.status === 401) {
               return LogOut(history);
            }
            console.log(error);
         });
   };

   const setMessages = data => {
      var messages = [];
      var count = [];
      Object.keys(data).forEach(key => {
         messages.push(key);
         count.push(data[key]);
      });

      setdata({
         messages: messages,
         count: count,
      });

      messages = messages.filter((item, index) => {
         if (index < 6) {
            return true;
         }
      });
      count = count.filter((item, index) => {
         if (index < 6) {
            return true;
         }
      });

      setchartdata({
         ...chartdata,
         series: [{ name: 'Messages Count', data: count }],
         options: { ...chartdata.options, xaxis: { ...chartdata.options.xaxis, categories: messages } },
      });
   };

   const ExporttoPdf = () => {
      /* const uri = ApexCharts.exec('mcchart', 'dataURI', chartdata.options).then((res) => {
            var pdf = new jsPDF();
            pdf.addImage(res.imgURI, 'PNG', 0, 0, 210, 100);
            pdf.save("download.pdf");
            console.log(res)
        }).catch((error) => {
            console.log(error)
        }) */

      if (data.messages.length !== 0) {
         window.scrollTo(0, 0);

         setTimeout(() => {
            html2canvas(document.querySelector('.chart')).then(canvas => {
               // if you want see your screenshot in body.
               const imgData = canvas.toDataURL('image/png', 1.0);
               var pdf = new jsPDF('p', 'mm', 'a4');

               var width = pdf.internal.pageSize.getWidth();
               var height = pdf.internal.pageSize.getHeight();
               pdf.addImage(imgData, 'PNG', 5, 10, width - 10, 120);
               pdf.save('download.pdf');
            });
         }, 1000);
      }
   };

   const onApply = (start, end) => {
      var date1 = new Date(parseInt(start));
      var date2 = new Date(parseInt(end));

      setdate({
         startDate: months[date1.getMonth()] + ' ' + date1.getDate() + ', ' + date1.getFullYear(),
         endDate: months[date2.getMonth()] + ' ' + date2.getDate() + ', ' + date2.getFullYear(),
      });

      ApiCall(parseInt(start.substring(0, 13)), parseInt(end.substring(0, 13)), bot_name);
   };

   const ExportToCsv = () => {
      var data_transformed = [];

      var raw_data = data;

      raw_data.messages.forEach((item, index) => {
         data_transformed.push({ Messages: item, Count: raw_data.count[index].toString() });
      });

      console.log(data_transformed);
      return data_transformed;
   };

   return (
      <div className="MessageOut-parent">
         <div class="f-buttons">
            <div className="c-container" style={{ marginLeft: 30 }}>
               <div className="head">Date Range</div>

               <img className="select-arrow date" src={da} />
               <DateRangePicker
                  initialSettings={
                     {
                        /*  startDate: '09/08/2020', endDate: '09/15/2020'  */
                     }
                  }
                  onCallback={(start, end, label) => {
                     onApply(start + '', end + '');
                  }}>
                  <input type="text" placeholder="Containing Text"></input>
               </DateRangePicker>
            </div>

            <div
               className="text-right"
               onClick={() => {
                  ExporttoPdf();
               }}>
               <div className="export-txt">
                  <img src={Export} style={{ marginRight: 10 }} />
                  Export to PDF
               </div>
            </div>
         </div>

         {data.messages.length !== 0 ? (
            <div className="chart">
               <div class="chartt-row">
                  <div className="ctop-text">Top 6 Messages</div>
               </div>

               <Chart
                  options={chartdata.options}
                  series={chartdata.series}
                  type="bar"
                  /* type={initialdata.charttype} */ height={430}
               />
            </div>
         ) : (
            <NoMessage />
         )}
         <div className="csv-bt">
            <CsvDownload data={ExportToCsv()} filename={bot_name + '_MessageOut.csv'} />
         </div>
         <div
            className="text-right"
            style={{ width: 'fit-content', marginTop: 25 }}
            onClick={() => {
               ExportToCsv();
            }}>
            <div className="export-txt">
               <img src={Export} style={{ marginRight: 10 }} />
               Export to CSV
            </div>
         </div>
         {data.messages.length !== 0 ? (
            <TableFunnel
               messages={data.messages}
               count={data.count}
               onClick={message => {
                  props.onClick(message);
               }}></TableFunnel>
         ) : null}
      </div>
   );
};

export default MessageOut;
