import { v4 as uuidv4 } from 'uuid';

export const defaultMessage = () => ({
   response_name: `utter_bot_${uuidv4()}`,
   response_type: 'defaultMsg',
});

export const Message = value => ({
   response_name: `utter_bot_${uuidv4()}`,
   response_type: 'text',
   response_elements: value,
});

export const imageType = url => ({
   response_name: `utter_bot_${uuidv4()}`,
   response_type: 'image',
   response_elements: url,
});

export const cardType = card => ({
   response_name: `utter_bot_${uuidv4()}`,
   response_type: 'card',
   response_elements: card,
});

export const quickRplType = quickrpl => ({
   response_name: `utter_bot_${uuidv4()}`,
   response_type: 'quickreplies',
   response_elements: quickrpl,
});

export const carouselType = carousel => ({
   response_name: `utter_bot_${uuidv4()}`,
   response_type: 'carousel',
   response_elements: carousel,
});

export const form = () => ({
   response_name: `utter_bot_${uuidv4()}`,
   response_type: 'form',
   form_name: '',
   response_elements: [],
});
export const otp = () => ({
   response_name: `utter_bot_${uuidv4()}`,
   response_type: 'get_otp',
   response_elements: [],
});
export const searchbar = () => ({
   response_name: `utter_bot_${uuidv4()}`,
   response_type: 'searchbar',
   response_elements: [],
});
export const customactions = () => ({
   response_name: '',
   response_type: 'custom_actions',
   response_elements: [],
});

// export const defaultMessage = () => ({
//    response_name: `utter_bot_${uuidv4()}`,
//    response_type: 'defaultMsg',
// });

// export const Message = value => ({
//    response_name: `utter_bot_${uuidv4()}`,
//    response_type: 'text',
//    response_elements: { en: value, ar: value },
// });

// export const imageType = url => ({
//    response_name: `utter_bot_${uuidv4()}`,
//    response_type: 'image',
//    response_elements: { en: url, ar: url },
// });

// export const cardType = card => ({
//    response_name: `utter_bot_${uuidv4()}`,
//    response_type: 'card',
//    response_elements: { en: card, ar: card },
// });

// export const quickRplType = quickrpl => ({
//    response_name: `utter_bot_${uuidv4()}`,
//    response_type: 'quickreplies',
//    response_elements: { en: quickrpl, ar: quickrpl },
// });

// export const carouselType = carousel => ({
//    response_name: `utter_bot_${uuidv4()}`,
//    response_type: 'carousel',
//    response_elements: { en: carousel, ar: carousel },
// });

// export const form = () => ({
//    response_name: `utter_bot_${uuidv4()}`,
//    response_type: 'form',
//    form_name: '',
//    response_elements: {},
// });
// export const otp = () => ({
//    response_name: `utter_bot_${uuidv4()}`,
//    response_type: 'get_otp',
//    response_elements: [],
// });
// export const searchbar = () => ({
//    response_name: `utter_bot_${uuidv4()}`,
//    response_type: 'searchbar',
//    response_elements: [],
// });
// export const customactions = () => ({
//    response_name: '',
//    response_type: 'custom_actions',
//    response_elements: [],
// });
