import React from 'react';
import { Toast } from 'react-bootstrap';
import { get } from 'lodash';
import socketIOClient from 'socket.io-client';
import { useDispatch, useSelector } from 'react-redux';
import { CLOSE_ALERT, DELETE_ALERT, FETCH_ALERT } from '../../../redux/actions/notification/types';
import { useHistory } from 'react-router';
import { live_agent } from '../../../utils/Request';

const ENDPOINT = `${live_agent}/liveagent`;
let socket = socketIOClient(ENDPOINT);

// // Custom Actions for managing the events
// const ACTIONS = {
//    FETCH_ALERT: 'FETCH_ALERT',
//    CLOSE_ALERT: 'CLOSE_ALERT',
//    DELETE_ALERT: 'DELETE_ALERT',
// };

// // useReducer for managing Complex State
// function reducer(state, action) {
//    switch (action.type) {
//       case ACTIONS.FETCH_ALERT:
//          const userList = [...state.allAlerts];

//          if (userList.filter(item => item.user_id === action.alerts.user_id).length === 0) {
//             userList.push({ ...action.alerts, show: true });
//          }

//          return {
//             ...state,
//             allAlerts: userList,
//          };
//       case ACTIONS.CLOSE_ALERT:
//          let userRemove = [];
//          userRemove = [...state.allAlerts].map(data => {
//             if (data.user_id === action.user_id) {
//                data.show = false;
//             }
//             return data;
//          });

//          return {
//             ...state,
//             allAlerts: userRemove,
//          };

//       case ACTIONS.DELETE_ALERT:
//          const userDelete = [...state.allAlerts].filter(data => data.user_id !== action.user_id);

//          return {
//             ...state,
//             allAlerts: userDelete,
//          };

//       default:
//          return state;
//    }
// }

// LiveAgentToast Component

const LiveAgentToast = () => {
   // const [{ allAlerts }, dispatch] = useReducer(reducer, { allAlerts: [] });
   const { allAlerts } = useSelector(state => state.users);
   const history = useHistory();
   const dispatch = useDispatch();

   const closeToast = (event, id) => {
      // Auto Close doesnt havent an event parameter
      if (event !== undefined) {
         event.stopPropagation();
      }

      dispatch({
         type: CLOSE_ALERT,
         user_id: id,
      });
      // dispatch({
      //    type: DELETE_ALERT,
      //    user_id: id,
      // });
   };

   // Gets All the user List currently Live
   socket.on('user_list', data => {
      // If user list is Object else if it is Array add it directly
      if (data[0] === undefined) {
         dispatch({
            type: FETCH_ALERT,
            alerts: data,
         });
      }
   });

   return (
      <div
         aria-live="polite"
         aria-atomic="true"
         style={{
            position: 'relative',
         }}>
         <div
            style={{
               position: 'fixed',
               top: 90,
               right: 15,
               zIndex: 99999,
               opacity: 1,
            }}>
            {allAlerts.map(data => (
               <Toast
                  show={data.show}
                  onClose={event => closeToast(event, data.user_id)}
                  delay={20000}
                  autohide={true}
                  animation={false}>
                  <div
                     className="toast-container"
                     style={{ cursor: 'pointer' }}
                     onClick={() => history.push('/liveagent')}>
                     <Toast.Header>
                        <strong className="mr-auto">Live Agent</strong>
                        <small>11 mins ago</small>
                     </Toast.Header>
                     <Toast.Body>
                        A new user has joined the live chat: <strong>{get(data, 'user_id', '').slice(0, 8)}</strong>
                     </Toast.Body>
                  </div>
               </Toast>
            ))}
         </div>
      </div>
   );
};

export default LiveAgentToast;
