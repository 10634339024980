import { v4 as uuidv4 } from 'uuid';

// adding position to each node for maping the story i.e depth
/**
 * enrich
 * @desc take a node and adds attributes that are used for placing a node in a grid
 * @param {object} node 
 * @param {int} depthOffset 
 */
export const enrich = (node, depthOffset = 0) => {
   if (!node.children) {
      return {
         ...node,
         depth: depthOffset,
         descendentsCount: 0,
         heightDiffWithLastDirectChild: 0,
      };
   }

   const enrichedChildren = node.children.map(child => enrich(child, depthOffset + 1));
   const descendentsCount =
      node.children.length + enrichedChildren.reduce((acc, enrichedChild) => acc + enrichedChild.descendentsCount, 0);

   const heightDiffWithLastDirectChild = descendentsCount - enrichedChildren[node.children.length - 1].descendentsCount;
   return {
      ...node,
      children: enrichedChildren,
      depth: depthOffset,
      descendentsCount,
      heightDiffWithLastDirectChild,
   };
};

// flatten the tree into array of objects
/**
 * flatten
 * @desc flatten the tree into array of objects and enriches each object using enrich function define above
 */
export const flatten = node => {
   const { children = [], ...nodeWithoutChildren } = node;
   return [{ ...nodeWithoutChildren }, ...children.map(childNode => flatten(childNode)).flat()];
};

//replace + icon node with option menu
/**
 * updateTreeNode
 * @desc updates replace + icon node with option menu
 * @param {object} node 
 * @param {array} Tree
 */
export const updateTreeNode = (node, Tree) => {
   const updatedTree = Tree.map((n, i) => {
      if (node.id === n.id) {
         return {
            ...n,
            name: 'addNew',
         };
      } else return n;
   });

   return updatedTree;
};

//add childern to story structure
/**
 * * addChildrenToTree
 * @desc whenever we add children to the node 
 * @param {object} node 
 * @param {array} Tree
 * * @param {string} value 
 * @param {array} SB
 */
export const addChildrenToTree = (entity = null, value, node, Tree, SB) => {
   const newNode = {
      name: value?.label,
      entity: entity,
      id: uuidv4(),
      contextOut: [],
      response: [],
      root: node.depth === 1 ? true : false,
   };

   const keys = Object.keys(SB.story_path);

   if (node.depth === 1) {
      const name = `story_${keys.length + Date.now()}`;
      SB.story_path[name] = [];
      SB.story_path[name].push(newNode.id);
      SB.content[newNode.id] = {
         ...newNode,
      };
      SB.order.push(newNode.id);
      return SB;
   }

   //for updating node's parent contextOut
   const parent_id = node.parent_id;

   keys.forEach(story => {
      SB.story_path[story].map(s => s === parent_id && SB.story_path[story].push(newNode.id));
   });

   SB.content[parent_id].contextOut.push(newNode.id);
   SB.content[newNode.id] = {
      ...newNode,
   };

   return SB;
};

// convert the struture into tree w.r.t their children ie contextOut
/**
 * getStoryStructure
 * @desc this function converts the response we see from server and convert it to the map able state
 * @param {array} SB 
 */
export const getStoryStructure = SB => {
   let array = [];

   //recursive function to get all the childrens
   const getStory = (contextOut, id) => {
      contextOut.forEach(story => {
         array.push({
            id: uuidv4(),
            name: 'IF',
            contextOut: [],
            parent_id: id,
            root: true,
            response: [],
         });
         array.push({
            ...SB.content[story],
            parent_id: array[array.length - 1].id,
         });
         if (SB.content[story].contextOut.length > 0) getStory(SB.content[story].contextOut, SB.content[story].id);
         array.push({
            id: uuidv4(),
            name: '+',
            contextOut: [],
            parent_id: SB.content[story].id,
            root: true,
            response: [],
         });
      });
   };

   //main function to arrange the struture for mapping
   /**
    * getArray
    * @desc main function to arrange the struture for mapping
    * @param {array} SB 
    */
   const getArray = SB => {
      array = [];
      array.push({
         name: 'start',
         id: 'start',
         contextOut: ['4e338d26-1e04-478c-885d-89abb5f096b9'],
         parent_id: null,
         root: true,
         response: SB.content.start ? [...SB.content.start.response] : [],
      });

      const keys = Object.keys(SB.story_path);

      keys.forEach(key => {
         if (key === 'start_fallback') return;
         if (SB.story_path[key].length === 0) return;
         const root_id = SB.story_path[key][0];
         array.push({
            id: uuidv4(),
            name: 'IF',
            contextOut: [],
            parent_id: 'start',
            root: true,
            response: [],
         });
         array[array.length - 1].contextOut.push(SB.content[root_id].id);
         array.push({
            ...SB.content[root_id],
            parent_id: array[array.length - 1].id,
         });
         if (SB.content[root_id].contextOut.length > 0)
            getStory(SB.content[root_id].contextOut, SB.content[root_id].id);
         array.push({
            id: uuidv4(),
            name: '+',
            contextOut: [],
            parent_id: SB.content[root_id].id,
            root: false,
            response: [],
         });
      });

      array.push({
         id: uuidv4(),
         name: '+',
         contextOut: [],
         parent_id: 'start',
         root: true,
         response: [],
      });
      array.push({
         id: 'fallback',
         name: 'fallback',
         contextOut: [],
         parent_id: 'start',
         root: true,
         response: SB.content.fallback ? [...SB.content.fallback.response] : [],
      });

      return array;
   };

   return getArray(SB);
};

//remove parent node and all the children nodes
/**
 * deleteParentandChild
 * @desc  it delete the nodes
 * @param {object} node 
 * @param {array} SB 
 * @param {array} topLevelNode 
 */
export const deleteParentandChild = (node, SB, topLevelNode) => {
   const id = node.id;
   const children = [id];

   //recursion function to get all the direct or indirect children
   /**
    * getChildren
    * @desc it return all the children of a certain node
    * @param {array} contextOut 
    */
   const getChildren = contextOut => {
      contextOut.forEach(c => {
         children.push(c);
         if (SB.content[c].contextOut.length > 0) {
            getChildren(SB.content[c].contextOut);
         }
      });
   };

   //populate children array
   getChildren(node.contextOut);

   const deleted_response_name = [];
   Object.keys(SB.content).forEach(key => {
      if (children.includes(key)) {
         return SB.content[key].response.forEach(re => deleted_response_name.push(re.response_name));
      }
   });

   const keys = Object.keys(SB.story_path);

   //looping through story_path keys to remove parent and children id's from story_path
   keys.forEach(story => {
      if (story === 'start_fallback') return;
      if (children.includes(SB.story_path[story][0])) SB.order = SB.order.filter(o => o !== SB.story_path[story][0]);
      SB.story_path[story] = SB.story_path[story].filter(s => !children.includes(s));
      if (SB.story_path[story].length === 0) delete SB.story_path[story];
   });

   const contentKeys = Object.keys(SB.content);

   //looping through content keys to remove the node id from its parent contextOut array
   // all all other nodes completely.
   contentKeys.forEach(key => {
      if (SB.content[key].contextOut.includes(id))
         SB.content[key].contextOut = SB.content[key].contextOut.filter(co => co !== id);
      if (children.includes(key)) delete SB.content[key];
   });

   return { SB, deleted_response_name };
};

export const addStartFallback = SB => {
   if (!SB.story_path.start_fallback) {
      SB.story_path.start_fallback = ['start', 'fallback'];
      SB.content.start = {
         id: 'start',
         name: 'start',
         root: false,
         contextOut: [],
         response: [],
      };
      SB.content.fallback = {
         id: 'fallback',
         name: 'fallback',
         root: false,
         contextOut: [],
         response: [],
      };
   }
};

/**
 * getResponseFormat
 * @desc it return the simple response of certain node with extra properties
 *  that are used for showing specific response type
 * @param {object} c 
 * @param {object} inputType 
 */
export const getResponseFormat = (c, inputType) => {
   if (c.response_type === 'carousel') {
      let ait = [];
      ait = c.response_elements.en.map(el => inputType.cardType(el));
      c.response_elements.ar.forEach((el, i) => {
         const rp = ait[i].response_elements;
         delete ait[i].response_elements;
         ait[i] = { ...ait[i], response_elements: { en: rp, ar: el } };
      });
      return {
         ...c,
         response_elements: ait,
      };
   } else if (
      c.response_type === 'form' ||
      c.response_type === 'get_otp' ||
      c.response_type === 'searchbar' ||
      c.response_type === 'custom_actions'
   )
      return {
         ...c,
         response_elements: c.response_elements,
      };
   return {
      ...c,
      response_elements: { en: c.response_elements.en[0], ar: c.response_elements.ar[0] },
   };
};
/**
 * * saveResponseFormat
* @desc it remove  extra properties and returns simple response that save in server
* @param {object} c 
 */
export const saveResponseFormat = c => {
   if (c.response_type === 'carousel')
      if (Array.isArray(c.response_elements))
         return {
            ...c,
            response_elements: {
               en: c.response_elements.map(el => el.response_elements.en),
               ar: c.response_elements.map(el => el.response_elements.ar),
            },
         };
      else
         return {
            ...c,
            response_elements: {
               en: c.response_elements.en.map(el => el.response_elements),
               ar: c.response_elements.ar.map(el => el.response_elements),
            },
         };
   else if (c.response_type === 'get_otp' || c.response_type === 'searchbar' || c.response_type === 'custom_actions')
      return {
         ...c,
         response_elements: [...c.response_elements],
      };
   else if (c.response_type === 'form') return { ...c, response_elements: { ...c.response_elements } };
   return {
      ...c,
      response_elements: { ...c.response_elements, en: [c.response_elements.en], ar: [c.response_elements.ar] },
   };
};
