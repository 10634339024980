import React, { useState, useEffect, useRef } from "react"
import Export from "../../assets/images/export.svg"
import { Nav } from "react-bootstrap"
//import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
//import 'react-dates/initialize';
//import 'react-dates/lib/css/_datepicker.css';
import da from "../../assets/images/dropdown-arrow.svg"
import Chart from "react-apexcharts";
import ApexCharts from 'apexcharts';
import TableC from "../Table/Table"
import MCBar from "../MCBar/MCBar"
import jsPDF from "jspdf"
import Api from "../../axios/request"
import { months } from "../../utils/Utility"
import { useLocation } from "react-router-dom"
import { useSelector } from "react-redux"
import html2canvas from 'html2canvas';
import { LogOut } from "../../redux/actions/profile/index"
import { useHistory } from "react-router-dom"
import CsvDownload from 'react-json-to-csv'

import DateRangePicker from "react-bootstrap-daterangepicker"
import 'bootstrap-daterangepicker/daterangepicker.css';




const MessageCount = (props) => {

    const picker = useRef(null)
    const tk = JSON.parse(localStorage.getItem("user")).token
    const [date, setdate] = useState({ startDate: null, endDate: null })
    const [start_date, setsdate] = useState(1599568112000)
    const [focusedInput, setfocusedInput] = useState(null)
    const [chart, setchart] = useState("line")
    const location = useLocation()
    const [initdate, setid] = useState({ start_date: "01/01/2020", end_date: "02/02/2020" })
    const [tableData, settableData] = useState({
        days: [],
        messagesIn: [],
        messagesOut: []
    })
    const bot_name = useSelector(state => state.storybuilder.bot_name)
    const history = useHistory()
    const [cardInfo, setCardInfo] = useState({
        card: '',
        title: 'title 1',
        description: ''
    })
    const [chartdata, setchartdata] = useState({

        series: [{
            name: 'Sessions',
            type: 'area',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }, {
            name: 'Engaged Session',
            type: 'area',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }],
        options: {
            chart: {
                id: "mcchart",
                zoom: {
                    enabled: false
                },
                height: 350,
                type: 'line',
                width: 1
            },

            stroke: {
                curve: 'smooth',
                width: 3
            },
            fill: {
                type: 'solid',
                opacity: [0.1, 0.1],
            },
            labels: ['Dec 01', 'Dec 02', 'Dec 03', 'Dec 04', 'Dec 05', 'Dec 06', 'Dec 07', 'Dec 08', 'Dec 09 ', 'Dec 10', 'Dec 11'],
            markers: {
                size: 0
            },
            legend: {
                show: false
            },
            tooltip: {
                enabled: true,
                enabledOnSeries: undefined,
                shared: true,
                followCursor: false,
                intersect: false,
                inverseOrder: false,
                custom: undefined,
                fillSeriesColor: false,
                theme: false,
                style: {
                    fontSize: '12px',
                    fontFamily: undefined,
                    backgroundColor: "#ffffff"
                },
                onDatasetHover: {
                    highlightDataSeries: false,
                },
                x: {
                    show: true,
                    format: 'dd MMM',
                    formatter: undefined,
                },
                y: {
                    formatter: undefined,
                    title: {
                        formatter: (seriesName) => seriesName + ": ",
                    },
                },
                z: {
                    formatter: undefined,
                    title: 'Size: '
                },
                marker: {
                    show: true,
                },
                items: {
                    display: "flex",
                },
                fixed: {
                    enabled: false,
                    position: 'topRight',
                    offsetX: 0,
                    offsetY: 0,
                },
            },
            xaxis: {
                labels: {
                    rotate: -45,
                    rotateAlways: true
                }
            },
            colors: ["#43b3e6", "#4a4cab"]
        },
    })

    useEffect(() => {
        if (props.default === "bar") {
            setchart("bar")
        }
    }, [])

    useEffect(() => {

        if (bot_name !== null) {
            const end_date = new Date()
            const start_date = new Date(end_date)
            start_date.setDate(start_date.getDate() - 7)


            picker.current.setStartDate(start_date.getMonth() + 1 + "/" + start_date.getDate() + "/" + start_date.getFullYear())
            picker.current.setEndDate(end_date.getMonth() + 1 + "/" + end_date.getDate() + "/" + end_date.getFullYear())

            setid({ start_date: start_date.getMonth() + "/" + start_date.getDate() + "/" + start_date.getFullYear(), end_date: end_date.getMonth() + "/" + end_date.getDate() + "/" + end_date.getFullYear() })
            setdate({
                startDate: months[start_date.getMonth()] + " " + start_date.getDate() + ", " + start_date.getFullYear(),
                endDate: months[end_date.getMonth()] + " " + end_date.getDate() + ", " + end_date.getFullYear()
            })
            //setcaldate({ startDate: start_date.getTime(), endDate: end_date.getTime() })

            ApiCall(start_date.getTime(), end_date.getTime(), bot_name)
        }

    }, [bot_name])


    const onMouseEnter = (card) => {
        console.log(card)
        if (card =="session"){
            setCardInfo({
                ...cardInfo,
                title: "The total number of sessions that began on the Chatbot",
                card: card
            });
        }
        else if (card == "engaged"){
            setCardInfo({
                ...cardInfo,
                title: "The number of sessions where the users  interacted with the Chatbot",
                card: card
            });
        }
        else{
            setCardInfo({
                ...cardInfo,
                title: card,
                card: card
            });
        }
        
    }

    const onMouseLeave = () => {
        setCardInfo({
            ...cardInfo,
            card: ''
        });
    }

    const LineChart = () => {
        setchart("line")
    }

    const BarChart = () => {
        setchart("bar")
    }


    const toggle = (value) => {
        ApexCharts.exec('mcchart', 'toggleSeries', value);
    }

    const interval = (type) => {
        var date = new Date(start_date)
        var date1 = new Date(start_date)
        if (type === "day") {
            date.setDate(date.getDate() + 1)
            ApiCall(start_date, date.getTime(), bot_name)
        }
        else if (type === "week") {
            date.setDate(date.getDate() + 7)
            ApiCall(start_date, date.getTime(), bot_name)
        }
        else {
            date.setDate(date.getDate() + 30)
            ApiCall(start_date, date.getTime(), bot_name)
        }

        picker.current.setStartDate(date1.getMonth() + 1 + "/" + date1.getDate() + "/" + date1.getFullYear())
        picker.current.setEndDate(date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear())

        setdate({
            startDate: months[date1.getMonth()] + " " + date1.getDate() + ", " + date1.getFullYear(),
            endDate: months[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear()
        })

        console.log(date.getTime())
    }

    const ExporttoPdf = () => {

        window.scrollTo(0, 0)

        setTimeout(() => {
            html2canvas(document.querySelector(".chart")).then(canvas => { // if you want see your screenshot in body.
                const imgData = canvas.toDataURL('image/png', 1.0);
                var pdf = new jsPDF("p", "mm", "a4");

                var width = pdf.internal.pageSize.getWidth();
                var height = pdf.internal.pageSize.getHeight();
                pdf.addImage(imgData, 'PNG', 5, 10, width - 10, 120);
                pdf.save("download.pdf");
            });
        }, 1000)
    }

    const ApiCall = (start_date, end_date, bot_name) => {
        setsdate(start_date)

        const params = new URLSearchParams({
            bot_name: bot_name,
            start_date: start_date,
            end_date: end_date
        }).toString();

        Api.post('/analytics/activity-page-message-count?' +
            params, "",
            {
                headers: {
                    Authorization: 'Token ' + tk
                },
            })
            .then(res => {

                if (res.status === 403 || res.status === 401) {
                    return LogOut(history)
                }
                setchartdata({ ...chartdata, series: [{ name: 'Sessions', type: 'area', data: res.data.sessions.data }, { name: 'Engaged Session', type: 'area', data: res.data.engaged_sessions.data }], options: { ...chartdata.options, labels: res.data.days } })
                settableData({ days: res.data.days, messagesIn: res.data.incoming.data, messagesOut: res.data.outgoing.data })
            })
            .catch(xhr => {
                let errormesae = JSON.stringify(xhr.message);
                if (errormesae == '"Request failed with status code 401"' || errormesae == '"Request failed with status code 403"') {
                    return LogOut(history);
                }

            });

    }

    const onApply = (start, end) => {

        var date1 = new Date(parseInt(start))
        var date2 = new Date(parseInt(end))

        setdate({
            startDate: months[date1.getMonth()] + " " + date1.getDate() + ", " + date1.getFullYear(),
            endDate: months[date2.getMonth()] + " " + date2.getDate() + ", " + date2.getFullYear()
        })


        ApiCall(parseInt(start.substring(0, 13)), parseInt(end.substring(0, 13)), bot_name)
    }


    const ExportToCsv = () => {
        //var data = [{"Days","Messages In","Messages Out"}]
        var data = [];

        var raw_data = tableData

        raw_data.days.forEach((item, index) => {
            data.push({ Day: item, MessagesIn: raw_data.messagesIn[index].toString(), MessagesOut: raw_data.messagesOut[index].toString() })
        })

        console.log(data)
        return data
    }

    console.log(cardInfo)

    return (
        <div className="MessageCount-parent">
            <div class="f-buttons">

                <div className="col-md'6">

                    <div className="head">
                        Date Range
                    </div>

                    <img className="select-arrow date" src={da} />
                    <DateRangePicker ref={picker} initialSettings={{ /* startDate: '09/08/2020', endDate: '09/15/2020'  */ }} onCallback={(start, end, label) => { onApply(start + "", end + "") }}>
                        <input type="text"></input>{/* <input type="text" className="form-control" /> */}
                    </DateRangePicker>
                </div>

                <div className="col-md'6" style={{ marginLeft: 60 }}>

                    <div className="head">
                        Interval
                    </div>

                    <Nav variant="pills" defaultActiveKey="week">
                        <Nav.Item>
                            <Nav.Link eventKey="day" onClick={() => { interval("day") }}>Day</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="week" onClick={() => { interval("week") }}>Week</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="month" onClick={() => { interval("month") }}>Month</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>

                <div className="text-right" onClick={() => { ExporttoPdf() }}>
                    <div className="export-txt">
                        <img src={Export} style={{ marginRight: 10 }} />
                        Export to PDF
                    </div>
                </div>

            </div>


            <div className="chart">
                <div class="chartt-row">
                    <div className="ctop-text">
                        User Engagement from {date.startDate === null ? "Sep 08, 2020 - Sep 15, 2020" : date.startDate + " - " + date.endDate}
                    </div>
                    <div className="chart-bt">
                        <Nav variant="pills" defaultActiveKey={props.default}>
                            <Nav.Item onClick={() => { LineChart() }}>
                                <Nav.Link eventKey="line">Line</Nav.Link>
                            </Nav.Item>
                            <Nav.Item onClick={() => { BarChart() }}>
                                <Nav.Link eventKey="bar">Bar</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                </div>
                <div className={`c-container ${chart !== "line" ? "none" : ""}`}>
                    {/*chart === "line" ? <Chart options={chartdata.options} series={chartdata.series} type="line" height={430} /> : <MCBar />*/}
                    <Chart id="mcchart" options={chartdata.options} series={chartdata.series} type="line" height={430} width={"100%"} />
                    <div className="cbottom">
                        <div className="cbottom-text">
                            Message Count
                        </div>
                        <div className="cbottom-labels">
                            <div className={`popup ${cardInfo.card}`}>
                                <p className="title">{cardInfo.title}</p>
                                <p className="description">{cardInfo.description}</p>
                            </div>
                            <div className="label-color"></div>
                            <div className="label-text" onClick={() => { toggle("Engaged Session") }} onMouseEnter={() => { onMouseEnter('session') }} onMouseLeave={onMouseLeave}>
                                Sessions
                            </div>
                            <div className="label-color second"></div>
                            <div className="label-text" onClick={() => { toggle("Sessions") }} onMouseEnter={() => { onMouseEnter('engaged') }} onMouseLeave={onMouseLeave}>
                                Engaged Session
                            </div>
                        </div>
                    </div>

                </div>
                {chart !== "line" ? <MCBar sdata={chartdata.series[0].data} edata={chartdata.series[1].data} days={chartdata.options.labels} /> : null}
            </div>
            <div className="csv-bt">
                <CsvDownload data={ExportToCsv()} filename={bot_name + "_MessageCount.csv"} />
            </div>
            <div className="text-right" style={{ width: "fit-content", marginTop: 25 }} onClick={() => { ExportToCsv() }}>
                <div className="export-txt">
                    <img src={Export} style={{ marginRight: 10 }} />
                        Export to CSV
                    </div>
            </div>
            <TableC data={tableData}></TableC>
        </div>
    )
}

export default MessageCount
