import React, { useEffect, useRef, useState } from 'react';
import ChatTime from './chatTime/chatTime';
import ChatMessages from './ChatMessages/ChatMessages';
import ChatAutoSuggestMessage from './ChatAutoSuggestMessage/ChatAutoSuggestMessage';
import ChatbotCard from './ChatbotCard/ChatbotCard';
import ChatbotCardSlider from './ChatbotCardSlider/ChatbotCardSlider';
import ChatImage from './ChatImage/ChatImage';
import ChatBotSuggestiveSearch from './ChatBotSuggestiveSearch/ChatBotSuggestiveSearch';
import ChatBotForm from './ChatBotForm/ChatBotForm';
import Slider from 'react-slick';
import Typing from './Typing/Typing';
import OTP from './ChatOTP/ChatOTP';
import ImageTag from '../../components/ImageTag/ImageTag';
import Axios from 'axios';
import { getToken } from '../../utils/Function';

let socket;
const ChatboatParent = ({ style, onClose }) => {
   const [remoteMessage, setRemoteMessage] = useState([]);
   const [isTyping, setIsTyping] = useState(false);
   const [language, setLanguage] = useState('en');
   const [myMessage, setMyMessage] = useState('');
   const [socketUrl, setSocketURL] = useState('');

   const settings = {
      centerMode: true,
      infinite: true,
      centerPadding: '20px',
      slidesToShow: 1,
      speed: 500,
      arrows: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
   };

   function NextArrow(props) {
      const { onClick } = props;

      return (
         <div className={'right-arrow-slider'} onClick={onClick}>
            <ImageTag src={require('./assets/rightArrow.svg')} />
         </div>
      );
   }

   function PrevArrow(props) {
      const { onClick } = props;
      return (
         <div className={'left-arrow-slider'} onClick={onClick}>
            <ImageTag src={require('./assets/leftArrow.svg')} />
         </div>
      );
   }

   const scrollToBottom = () => {
      var objDiv = document.getElementById('body');
      if (objDiv) {
         objDiv.scrollTop = objDiv.scrollHeight;
      }
   };

   const CarousalMessageSend = (type, text, payload) => {
      setRemoteMessage(Array => [...Array, { type: 'message', text: text, isHuman: true }]);
      socket.send(
         JSON.stringify({
            type: type,
            text: payload,
            user: '97c42b84-aeeb-5505-ced9-7e9b6deb43bd',
            channel: 'socket',
         })
      );
   };

   const sendQuickReplies = (type, payload, title) => {
      setRemoteMessage(Array => [...Array, { type: 'message', text: title, isHuman: true }]);
      socket.send(
         JSON.stringify({
            type: type,
            text: payload,
            user: '97c42b84-aeeb-5505-ced9-7e9b6deb43bd',
            channel: 'socket',
         })
      );
   };

   const sendFormDetails = (type, payload) => {
      socket.send(
         JSON.stringify({
            type: type,
            text: `/form_filled{\"name\":\"${payload.name}\",\"email_addr\":\"${payload.email}\",\"contact_no\":\"${payload.code + payload.number
               }\",\"request_type\":\"Purchases\",\"message\":\"ds\"}","user":"97c42b84-aeeb-5505-ced9-7e9b6deb43bd`,
            channel: 'socket',
         })
      );
   };

   const sendSimpleMessage = async type => {
      socket.send(
         JSON.stringify({
            type: type,
            text: myMessage,
            user: '52a0fd05-efe9-9b07-350d-2d86cb4f8151',
            channel: 'socket',
         })
      );
   };

   /**
    * Starting the Socket
    *
    */
   const startSocket = async () => {
      socket.onopen = () => {
         socket.send(
            JSON.stringify({
               type: 'message',
               text: `/language_selection{\"language\": \"${language}\",\"avatar_name\":\"Leo\"}`,
               user: 'ab9d8843-b4ab-7815-5bab-ad071c1e265b',
               channel: 'socket',
            })
         );
      };
      socket.onmessage = event => {
         let parseData = JSON.parse(event.data);
         if (parseData.type !== 'typing') {
            setIsTyping(false);
            setRemoteMessage(Array => [...Array, parseData]);
         } else {
            setIsTyping(true);
         }
         scrollToBottom();
      };
   };

   useEffect(() => {
      Axios('https://buildertest.bluelogic.ai/api/logic/socketURL/', {
         method: 'GET',
         headers: {
            Authorization: 'Token ' + getToken(),
         },
      }).then(data => {
         setSocketURL(data.data.SocketURL);
      });
   }, []);

   useEffect(() => {
      if (socketUrl) {
         socket = new WebSocket(socketUrl);
         setRemoteMessage([]);
         startSocket();
      }
      // return () => {
      //    console.log('Closiong');
      //    socket.close();
      // };
   }, [language, socketUrl]);

   const top100Films = [
      { title: 'The Shawshank Redemption', year: 1994 },
      { title: 'The Godfather', year: 1972 },
      { title: 'The Godfather: Part II', year: 1974 },
      { title: 'The Dark Knight', year: 2008 },
   ];
   return (
      <div style={style} className="chatbot-parant">
         <div className="card">
            <div style={{ flexDirection: language == 'ar' ? 'row-reverse' : 'row' }} className="header">
               <p className="header-title medium">{language == 'en' ? 'Pizza Shop Assistant' : 'توبلا تاباجإ'}</p>
               <div
                  style={{ flexDirection: language == 'ar' ? 'row-reverse' : 'row' }}
                  className="header-image-container">
                  <div
                     onClick={() => {
                        if (language == 'ar') {
                           setLanguage('en');
                        } else {
                           setLanguage('ar');
                        }
                     }}
                     className="languageDiv">
                     <p className="medium">{language == 'en' ? 'ENG' : 'عربي'}</p>
                  </div>
                  <ImageTag className="header-image-refresh" src={require('./assets/refresh.svg')} />
                  <ImageTag
                     style={{ margin: language == 'ar' ? '0px !important' : '28px 0px 28px 14px !important' }}
                     onClick={onClose}
                     className="header-image-close"
                     src={require('./assets/close.svg')}
                  />
               </div>
            </div>
            <div id="body" className="body">
               {remoteMessage.map((res, i) => {
                  if (res.type == 'otp') {
                     return <OTP language={language} />;
                  }
                  if (res.type == 'suggestive-search') {
                     return <ChatBotSuggestiveSearch options={top100Films} />;
                  }
                  if (res.type === 'message') {
                     return (
                        <>
                           {res.quick_replies ? (
                              <ChatAutoSuggestMessage
                                 language={language}
                                 onClick={(payload, title) => {
                                    sendQuickReplies(res.type, payload, title);
                                 }}
                                 options={res.quick_replies}
                                 message={res.text}
                              />
                           ) : (
                              <>
                                 <ChatTime text="TODAY" />
                                 <ChatMessages isHuman={res.isHuman} message={res.text} />
                              </>
                           )}
                        </>
                     );
                  }

                  if (res.type === 'daynamic_form') {
                     return (
                        <ChatBotForm
                           language={language}
                           fields={res.fields}
                           isHuman={false}
                           submit={x => {
                              sendFormDetails(res.type, x);
                           }}
                        />
                     );
                  }

                  if (res.type === 'image') {
                     return <ChatImage isHuman={false} />;
                  }

                  if (res.type === 'card') {
                     return (
                        <ChatbotCard
                           content="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                           heading="Pizza Menu"
                        />
                     );
                  }

                  if (res.type === 'carousel') {
                     return (
                        <div key={i}>
                           <ChatMessages isHuman={false} message={res.text} />
                           <div className="carousel-container animated fadeInLeft">
                              <Slider {...settings}>
                                 {res.data.map(item => {
                                    console.log(item)
                                    return (
                                       <ChatbotCardSlider
                                          onClick={(x, payload) => CarousalMessageSend(res.type, x, payload)}
                                          image={item.image}
                                          item={item}
                                          content={item.description}
                                          heading={item.label}
                                       />
                                    );
                                 })}
                              </Slider>
                           </div>
                        </div>
                     );
                  }
               })}
               {isTyping && <ChatMessages isHuman={false} isComponent={true} component={Typing} />}
            </div>
            <div className="footer">
               <input
                  value={myMessage}
                  onChange={x => {
                     setMyMessage(x.target.value);
                     scrollToBottom();
                  }}
                  placeholder="Type your message here.."
                  className="footer-text regular"
               />
               <div
                  onClick={async () => {
                     await setRemoteMessage(Array => [...Array, { type: 'message', text: myMessage, isHuman: true }]);
                     scrollToBottom();
                     setMyMessage('');
                     sendSimpleMessage('message');
                  }}
                  className="send-container">
                  <ImageTag className="send" src={require('./assets/send.svg')} />
               </div>
            </div>
         </div>
      </div>
   );
};

export default ChatboatParent;
