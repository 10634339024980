import React, { useState , useEffect } from "react"
import Export from "../../assets/images/export.svg"
import { Nav } from "react-bootstrap"
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import Chart from "react-apexcharts";
import ApexCharts from 'apexcharts';
import TableC from "../Table/Table"


const MCBar = (props) => {

    const [date, setdate] = useState({ startDate: null, endDate: null })
    const [focusedInput, setfocusedInput] = useState(null)
    const [chart, setchart] = useState("bar")
    const [chartdata, setchartdata] = useState({
        series: [{
            name: 'S',
            data: [0, 0, 0, 0, 0, 0, 0]
        }, {
            name: 'ES',
            data: [0, 0, 0, 0, 0, 0, 0]
        }],
        options: {
            chart: {
                id: "mcchartbar",
                type: 'bar',
                height: 1000
            },
            plotOptions: {
                bar: {
                    barHeight: "50%",
                    horizontal: true,
                    dataLabels: {
                        position: 'top',
                    },
                }
            },
            dataLabels: {
                enabled: false,
                offsetX: -5,
                style: {
                    fontSize: '0px',
                    colors: ['#fff']
                }
            },
            stroke: {
                show: true,
                width: 1,
                colors: ['#fff']
            },
            yaxis: {
                labels: {
                    minWidth: 100,
                    maxWidth: 100,
                    left: 0
                }
            },
            xaxis: {
                categories: [2001, 2002, 2003, 2004, 2005, 2006, 2007],
            },
            colors: ['#43b3e6', '#4a4cab'],
            legend: {
                show: false
            },
            tooltip: {
                enabled: true
            }
        },
    })

    const LineChart = () => {
        setchart("line")
    }

    const BarChart = () => {
        setchart("bar")
    }


    const toggle = (value) => {
        ApexCharts.exec('mcchartbar', 'toggleSeries', value);
    }

    useEffect(()=>{
        if(props.edata!= undefined){
            setchartdata({ ...chartdata, series: [{ name: 'S' , data: props.sdata },{ name: 'ES' , data: props.edata }], options: { ...chartdata.options, xaxis: {categories: props.days} , tooltip: {enabled: true} } })
        }
    },[props.edata])




    return (

        <div className="MCBar-parent">
            <div className="chart-bar">
                <Chart options={chartdata.options} series={chartdata.series} type="bar" /* type={initialdata.charttype} */ />
                <div className="cbottom">
                    <div className="cbottom-text">
                        Message Count
                    </div>
                    <div className="cbottom-labels">
                        <div className="label-color"></div>
                        <div className="label-text" onClick={() => { toggle("ES") }}>
                            Sessions
                        </div>
                        <div className="label-color second"></div>
                        <div className="label-text" onClick={() => { toggle("S") }}>
                            Engaged Session
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default MCBar