import React, { useState, useEffect } from "react";

const BillingInfo = ({page}) =>{
   const [active, setActive] = useState(0)

    return(
        <div className="billingInfoMain">
            {page == 1 && <>
                <div className="row-1">
                    <div className="box-1 m-r-12">
                        <div className="icon-container">
                            <p className="bolder text">01</p>
                        </div>
                        <p className="bold box-heading">Standard</p>
                        <p className="regular paragraph">Your current plan. Just the essentials for getting better answers online.</p>
                    </div>
                    <div className="box-1 m-r-12 m-l-12 box-bg">
                        <div className="button">
                            Upgrade
                        </div>
                        <div className="icon-container">
                            <p className="bolder text white">02</p>
                        </div>
                        <p className="bold box-heading white">Pro</p>
                        <p className="regular paragraph white">Your current plan. Just the essentials for getting better answers online.</p>
                    </div>
                    <div className="box-1 m-l-12">
                        <div className="icon-container">
                            <p className="bolder text">03</p>
                        </div>
                        <p className="bold box-heading">Advanced</p>
                        <p className="regular paragraph">Your current plan. Just the essentials for getting better answers online.</p>
                    </div>
                </div>

                <div className="row-1">
                    <div className="box-2 m-r-12">
                        <img className="image" src={require('./assets/box-2-home.svg')}/>
                        <div className="leftbox">
                            <p className="bold content-heading">Billing Address</p>
                            <p className="regular content-text">John Doe</p>
                            <p className="regular content-text">2211 North First Street, San Jose, CA, 953131</p>
                            <p className="regular content-text">+1-202-555-0117</p>
                            <div className="button">
                                <p className="bold btn-text">Update Address</p>
                            </div>

                        </div>
                    </div>
                    <div className="box-2 m-l-12">
                        <img className="image" src={require('./assets/box-2-payment.svg')}/>
                        <div className="leftbox">
                            <p className="bold content-heading">Payment Method</p>
                            <p className="regular content-text">Credit/Debit Card: Master Card XXXX-XXXX-XXXX-1111</p>
                            <p className="regular content-text">02/2019</p>
                            <div className="button mt-58">
                                <p className="bold btn-text">Update Card</p>
                            </div>

                        </div>
                    </div>
                </div>
            </>}
            {page == 2 && <div className="secondPage">
                <div className="leftBox">
                    <div className="top-tabs-container">
                        <div onClick={()=>setActive(0)} className="leftTab active-tab">
                            <p 
                                style={{color:"#000"}} 
                                className="medium leftTabText">01. Choose Plan</p>
                        </div>
                        <div onClick={()=>setActive(1)} className={active == 1 ? "RightTab active-tab" : "RightTab"}>
                            <p 
                                style={{color: active == 1 ? "#000" : "#bbbbbb"}}
                                className="medium RightTabText">02. Payment</p>
                        </div>

                        <div onClick={()=>setActive(2)} className={active == 2 ? "RightTab active-tab" : "RightTab"}>
                            <p 
                                style={{color: active == 2 ? "#000" : "#bbbbbb"}}
                                className="medium RightTabText">03. Billing Address</p>
                        </div>
                    </div>
                
                    <div className="box-container">
                        <div className="Box Box-bg">
                            <div className="typebtn">
                                <p className="medium typebtnText">Monthly</p>
                            </div>
                            <p className="bold AEDText">35 AED<span className="regular">/month</span></p>
                            <p className="regular bildText">billed monthly</p>
                        </div>
                        <div className="Box">
                            <div className="typebtn blue">
                                <p className="medium typebtnText colorWhite">Monthly</p>
                            </div>
                            <p className="bold AEDText colorBlack">29 AED<span className="regular">/month</span></p>
                            <p className="regular bildText colorGray">350 AED billed yearly</p>
                        </div>
                        <div className="Box">
                            <div className="typebtn blue">
                                <p className="medium typebtnText colorWhite">Monthly</p>
                            </div>
                            <p className="bold AEDText colorBlack">120 AED<span className="regular">/month</span></p>
                            <p className="regular bildText colorGray">350 AED billed yearly</p>
                        </div>

                    </div>
                </div>
                <div className="rightImage">
                    <div className="icon-width-text">
                        <div className="icon-parant"></div>
                        <p className="text bold">Summary</p>
                    </div>
                    <div className="text-div">
                        <p className="text regular">Total Conversations: <strong>0</strong></p> 
                    </div>
                    <div className="text-div m-b-4">
                        <p className="text regular">Monthly Package: <strong>AED 0</strong></p> 
                    </div>
                    <p className="text-widthout-border regular">In this package first 120 conversations will be include.</p> 
                    <div className="text-div m-b-4">
                        <p className="text regular">Monthly Package: <strong>AED 0</strong></p> 
                    </div>
                    <p className="text-widthout-border regular">In this package first 120 conversations will be include.</p> 
                    <div className="text-div m-b-4">
                        <p className="text regular">Additional Conversations: <strong>AED 0</strong></p> 
                    </div>
                    <p className="text-widthout-border regular">Addition canversation will cost $1 each conversation.</p> 
                </div>
            </div>}
            {page == 3 && <div className="thirdPage">
                
            </div>}
        </div>
    )
}

export default BillingInfo;