import React, { useState, useEffect } from "react"
import { Nav } from "react-bootstrap"
import da from "../../assets/images/dropdown-arrow.svg"
import arrow from "../../assets/images/funnel-arrow.svg"
import Api from "../../axios/request"
import NoMessage from "../../pages/LiveAgent/NoMessage/NoMessage"
import { useSelector } from "react-redux"
import { LogOut } from "../../redux/actions/profile/index"
import { useHistory } from "react-router-dom"



const MessageFunnel = (props) => {

    const tk = JSON.parse(localStorage.getItem("user")).token
    const [incoming_intent, setincoming_intent] = useState([])
    const [outgoing_intent, setoutgoing_intent] = useState([])
    const [funnel_data, setfunnel_data] = useState([])
    const [isoutgoing, setisoutgoing] = useState(true)
    const [nomsg, setnomsg] = useState(true)
    const bot_name = useSelector(state => state.storybuilder.bot_name)
    const history = useHistory()

    console.log(funnel_data)

    useEffect(() => {
        if (bot_name !== null) {
            funnel_api1(bot_name)
            funnel_api2(bot_name, props.message)
        }
    }, [bot_name])

    useEffect(() => {
        if (funnel_data.after_intents !== undefined) {
            if (funnel_data.after_intents.length !== 0) {
                setnomsg(false)
            }
            else {
                setnomsg(true)
            }

        } else {
            setnomsg(true)
        }
    }, [funnel_data])

    const funnel_api2 = (bot_name, message, type = undefined) => {

        var msg = ""

        if (type == undefined) {
            msg = message.replace(/\s/g, " ")
        }
        else {
            msg = message
        }

        console.log(msg)

        const params = new URLSearchParams({
            bot_name: bot_name,
            current_intent: msg
        }).toString();

        Api.post('/analytics/second-funnel?' +
            params, "",
            {
                headers: {
                    Authorization: 'Token ' + tk
                },
            }).then((res) => {
                if (res.status === 403 || res.status === 401) {
                    return LogOut(history)
                }
                console.log(res.data.funnel[0])
                setfunnel_data(res.data.funnel[0])
            }).catch((error, xhr) => {
                let errormesae = JSON.stringify(xhr.message);
                if (errormesae == '"Request failed with status code 401"' || errormesae == '"Request failed with status code 403"') {
                    return LogOut(history);
                }
                console.log(error)
            })

    }

    const funnel_api1 = (bot_name) => {
        const params = new URLSearchParams({
            bot_name: bot_name
        }).toString();

        Api.post('/analytics/first-funnel?' +
            params, "",
            {
                headers: {
                    Authorization: 'Token ' + tk
                },
            }).then((res) => {
                if (res.status === 403 || res.status === 401) {
                    return LogOut(history)
                }
                console.log(res)
                setincoming_intent(res.data.incoming)
                setoutgoing_intent(res.data.outgoing)
            }).catch((error, xhr) => {
                let errormesae = JSON.stringify(xhr.message);
                if (errormesae == '"Request failed with status code 401"' || errormesae == '"Request failed with status code 403"') {
                    return LogOut(history);
                }
                console.log(error)
            })
    }

    return (
        <div className="message-parent">
            <div class="f-buttons">
                <div className="col-md'6">
                    <div className="head">
                        Direction
                    </div>
                    <Nav variant="pills" defaultActiveKey="outgoing">
                        <Nav.Item>
                            <Nav.Link eventKey="outgoing" onClick={() => { setisoutgoing(true) }}>Outgoing</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="incoming" onClick={() => { setisoutgoing(false) }}>Incoming</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
                <div className="d-one">
                    <div className="head">
                        Top {isoutgoing ? "Outgoing messages" : "Incoming intents"}
                    </div>
                    <img className="select-arrow" src={da} style={{ marginLeft: 180 }} />
                    {isoutgoing ? <select className="dropdown" onChange={(e) => { funnel_api2(bot_name, e.currentTarget.value) }}>
                        <option value="" disabled selected>Choose Intent</option>
                        {outgoing_intent.map((item) => {
                            return <option value={item}>{item}</option>
                        })
                        }
                    </select> : <select className="dropdown" onChange={(e) => { funnel_api2(bot_name, e.currentTarget.value, "incoming") }}>
                        <option value="" disabled selected>Choose Intent</option>
                        {incoming_intent.map((item) => {
                            return <option value={item}>{item}</option>
                        })
                        }
                    </select>}
                </div>
            </div>
            {!nomsg ? <div className="funnel-parent">
                <div className="content">
                    <div className="row">
                        <div className="head">
                            Prior
                    </div>

                        <div className="head">
                            Current
                        </div>
                        <div className="head">
                            After
                        </div>
                    </div>
                    {funnel_data.after_intents !== undefined ? funnel_data.after_intents.map((item, index) => {
                        return (
                            <div className="row">
                                <div className={`child ${funnel_data.prior_intents[index] == undefined ? 'hide' : ''}`} onClick={() => {
                                    if (funnel_data.prior_intents[index] !== undefined) {
                                        funnel_api2(bot_name, funnel_data.prior_intents[index].prior_intent_name)
                                    }
                                }}>
                                    <div className="txt">
                                        {funnel_data.prior_intents[index] !== undefined ? funnel_data.prior_intents[index].prior_intent_name : ""}
                                    </div>
                                </div>

                                <div className={`child ${index !== 0 ? 'hide' : ''}`}>
                                    {index == 0 ? <div className="txt">
                                        {funnel_data.current_intent}
                                    </div> : null}
                                    {index == 0 ? <div className="share">
                                        {funnel_data.current_intent_count}
                                    </div> : null}
                                    {index == 0 ? <img src={arrow} /> : null}
                                </div>

                                <div className="child" onClick={() => { funnel_api2(bot_name, item.after_intent_name) }}>
                                    <div className="txt">
                                        {item.after_intent_name}
                                    </div>
                                    <div className="share">
                                        {item.after_intent_count}
                                    </div>
                                    <img src={arrow} />
                                </div>
                            </div>
                        )
                    }) : null}



                </div>
            </div> : <NoMessage />}
        </div>
    )
}

export default MessageFunnel