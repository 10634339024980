import React, { useState, useEffect } from "react"
import Export from "../../assets/images/export.svg"
import { Nav } from "react-bootstrap"
import Chart from "react-apexcharts";
import TableC from "../Table/Table"
import jsPDF from "jspdf"
import ApexCharts from "apexcharts"
import da from "../../assets/images/dropdown-arrow.svg"
import Api from "../../axios/request"
import { useSelector } from "react-redux"
import html2canvas from 'html2canvas';
import { LogOut } from "../../redux/actions/profile/index"
import { useHistory } from "react-router-dom"
import DateRangePicker from "react-bootstrap-daterangepicker"
import 'bootstrap-daterangepicker/daterangepicker.css';

const EngagedUserByTime = () => {

    const tk = JSON.parse(localStorage.getItem("user")).token
    const [dates, setdates] = useState([])
    const [date, setdate] = useState(null)
    const bot_name = useSelector(state => state.storybuilder.bot_name)
    const history = useHistory()

    const [chartdata, setchartdata] = useState({

        series: [{
            name: '12AM - 2AM',
            data: [0, 0, 0, 0, 0, 0, 0]
        },
        {
            name: '2 AM - 4 AM',
            data: [0, 0, 0, 0, 0, 0, 0]
        },
        {
            name: '4 AM - 6 AM',
            data: [0, 0, 0, 0, 0, 0, 0]
        },
        {
            name: '6 AM - 8 AM',
            data: [0, 0, 0, 0, 0, 0, 0]
        },
        {
            name: '8 AM - 10 AM',
            data: [0, 0, 0, 0, 0, 0, 0]
        },
        {
            name: '10 AM - 12 AM',
            data: [0, 0, 0, 0, 0, 0, 0]
        },
        {
            name: '12 PM - 2 PM',
            data: [0, 0, 0, 0, 0, 0, 0]
        },
        {
            name: '2 PM - 4 PM',
            data: [0, 0, 0, 0, 0, 0, 0]
        },
        {
            name: '4 PM - 6 PM',
            data: [0, 0, 0, 0, 0, 0, 0]
        },
        {
            name: '6 PM - 8 PM',
            data: [0, 0, 0, 0, 0, 0, 0]
        },
        {
            name: '8 PM - 10 PM',
            data: [0, 0, 0, 0, 0, 0, 0]
        }
        ],
        options: {
            plotOptions: {
                heatmap: {
                    radius: 0
                }
            },
            chart: {
                id: "mcchart",
                height: 500,
                type: 'heatmap',
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: 15,
                color: "#ffffff"
            },
            colors: ["#4a4cab"],
            title: {
                text: ''
            },
            xaxis: {
                categories: ["Sun", 'Mun', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                labels: {
                    formatter: (data)=>{
                        return data.split('-')[0];
                    }
                }
            },
            tooltip: {
                enabled: true,
                enabledOnSeries: undefined,
                shared: true,
                followCursor: false,
                intersect: false,
                inverseOrder: false,
                custom: undefined,
                fillSeriesColor: false,
                theme: false,
                style: {
                    fontSize: '12px',
                    fontFamily: undefined
                },
                onDatasetHover: {
                    highlightDataSeries: false,
                },
                x: {
                    show: true,
                    formatter: (day, x) => {
                        return day
                    }
                },
                y: {
                    formatter: undefined,
                    title: {
                        formatter: (seriesName, x) => {
                            return seriesName
                        },
                    },
                }
            }
        }
    })

    useEffect(() => {

        if (bot_name !== null) {
            const start_date = new Date()
            ApiCall(start_date.getTime(), 7, bot_name)
        }

    }, [bot_name])

    const ExporttoPdf = () => {

        window.scrollTo(0, 0)

        setTimeout(() => {
            html2canvas(document.querySelector(".EngagedUserByTime-parent")).then(canvas => { // if you want see your screenshot in body.
                const imgData = canvas.toDataURL('image/png', 1.0);
                var pdf = new jsPDF("p", "mm", "a4");

                var width = pdf.internal.pageSize.getWidth();
                var height = pdf.internal.pageSize.getHeight();
                pdf.addImage(imgData, 'PNG', 5, 10, width - 10, 150);
                pdf.save("download.pdf");
            });
        }, 1000)
    }

    const ApiCall = (date, days, bot_name) => {
        setdate(date)
        const params = new URLSearchParams({
            bot_name: bot_name,
            date: date,
            days: days
        }).toString();

        Api.post('/analytics/engaged-user-by-time?' +
            params, "",
            {
                headers: {
                    Authorization: 'Token ' + tk
                },
            })
            .then(res => {
                if (res.status === 403) {
                    return LogOut(history)
                }
                let categories = []
                res.data.dates.forEach((dates, index) => {
                    categories.push(res.data.days[index] + '-' + dates)
                })
                setdates(res.data.dates)
                setchartdata({
                    ...chartdata, series: res.data.series, options: {
                        ...chartdata.options, xaxis: { ...chartdata.options.xaxis, categories: categories }
                    }
                })
            })
            .catch(error => {
                console.log(error);
            });

    }

    const onApply = (start) => {
        ApiCall(start, 7, bot_name)
    }


    return (
        <div className="EngagedUserByTime-parent">
            <div class="f-buttons">

                <div className="d-one" style={{ marginLeft: 5 }}>
                    <div className="head">
                        Starting On
                    </div>

                    <img className="select-arrow date" src={da} />
                    <DateRangePicker initialSettings={{/*  startDate: '09/10/2020', */ singleDatePicker: true }} onCallback={(start, end, label) => { onApply(start + "") }} >
                        <input className="input" type="text" placeholder="Containing Text" ></input>
                    </DateRangePicker>
                </div>

                <div className="d-one">
                    <div className="head">
                        Interval
                    </div>
                    <img className="select-arrow" src={da} />
                    <select className="dropdown" onChange={(e) => {
                        if (e.currentTarget.value === 7) {
                            ApiCall(date, 7, bot_name)
                        } else {
                            ApiCall(date, 14, bot_name)
                        }
                    }}>
                        <option value={7} selected>7 Days</option>
                        {/* <option value={14}>14 Days</option> */}
                    </select>
                </div>

                <div className="text-right" onClick={() => { ExporttoPdf() }}>
                    <div className="export-txt">
                        <img src={Export} style={{ marginRight: 10 }} />
                        Export to PDF
                    </div>
                </div>

            </div>


            <div className="chart">
                <div class="chartt-row">
                    <div className="ctop-text">
                        User Engagement by Time
                    </div>
                </div>

                <Chart options={chartdata.options} series={chartdata.series} type="heatmap" /* type={initialdata.charttype} */ height={600} />

            </div>
        </div>
    )
}

export default EngagedUserByTime