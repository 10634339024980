import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import CardInput from '../../../components/ChatBot/Card/CardInput';
import TextMessageInput from '../../../components/ChatBot/TextMessageInput';
import Image from '../../../components/ChatBot/Image/Image';
import ChatCarousel from '../../../components/ChatBot/Carousel/ChatCarousel';
import QuickReplies from '../../../components/ChatBot/QuickReplies/QuickReplies';
import DefaultMessage from '../../../components/ChatBot/DefaultMessage/DefaultMessage';
import Card from '../../../components/ChatBot/Card/Card';
import ChatBotForm from '../../../components/ChatBot/ChatBotForm/ChatBotForm';
import Otp from '../../../components/ChatBot/OTP/Otp';
import AutoSuggest from '../../../components/ChatBot/SearchBar/AutoSuggest';
import CustomAction from '../../../components/ChatBot/CustomAction/CustomAction';

import { deleteNodes } from '../requests/requests';

import * as inputType from './inputTypes';

/**
 * @desc iterating through chat and returing component according to their type along with their methods
 * @param {Object} c
 * @param {callback} setchat
 * @param {callback} setcurrentInputType
 * @param {Array} chat
 * @param {array} OPTIONS
 */
export const getChatMessageType = (c, setchat, setcurrentInputType, chat, OPTIONS, bot_name) => {
   
   const removeMsg = response_name => {
      setchat(chat.filter(c => c.response_name !== response_name));
      deleteNodes(bot_name, [response_name]);
      if (chat.length === 1) {
         setchat(pervState => [...pervState, inputType.defaultMessage()]);
         setcurrentInputType();
      }
   };
   const remove = response_name => {
      setchat(chat.filter(c => c.response_name !== response_name));
      deleteNodes(bot_name, [response_name]);
      if (chat.length === 1) {
         setchat(pervState => [...pervState, inputType.defaultMessage()]);
         setcurrentInputType();
      }
   };
   const duplicate = response_name => {
      const element = chat.filter(c => c.response_name === response_name);
      if (element[0].response_type === 'custom_actions') {
         setchat(pervState => [...pervState, { ...element[0], response_name: '' }]);
      } else setchat(pervState => [...pervState, { ...element[0], response_name: `utter_bot_${uuidv4()}` }]);
   };

   const update = (updated, newName) => {
      console.log(updated, newName)
      if (newName) {
         const NotUnique = chat.filter(c => newName === c.response_name);
         if (NotUnique.length > 0) {
            alert(`${newName} already exists`);
            return;
         } else
            setchat(
               chat.map(c => (c.response_name === updated.response_name ? { ...updated, response_name: newName } : c))
            );
      } else setchat(chat.map(c => (c.response_name === updated.response_name ? { ...updated } : c)));
   };

   const updateFormName = (newName) => {

   }

   const updateCarousel = (carousel, response_name) => {
      setchat(pervState =>
         pervState.map(ps => (ps.response_name === response_name ? { ...ps, response_elements: carousel } : ps))
      );
      setcurrentInputType();
   };

   const addCard = card => {
      setchat(pervState => [...pervState, inputType.cardType(card)]);
      setcurrentInputType();
   };

   const addCarousel = carousel => {
      setchat(pervState => [...pervState, inputType.carouselType(carousel)]);
      setcurrentInputType();
   };

   const updateQuickRpl = (quickrpl, response_name) => {
      setchat(pervState =>
         pervState.map(ps => (ps.response_name === response_name ? { ...ps, response_elements: quickrpl } : ps))
      );
      setcurrentInputType();
   };

   const duplicateQuickRpl = value => {
      let newChat = [...chat];
      let quickRpl = { ...value.response_elements };
      let en = quickRpl.en.replies.map(a => ({ ...a }));
      let ar = quickRpl.ar.replies.map(a => ({ ...a }));
      newChat.push({
         ...value,
         response_elements: {
            en: { ...quickRpl.en.response_elements, text: quickRpl.en.text, replies: en },
            ar: { ...quickRpl.ar.response_elements, text: quickRpl.en.text, replies: ar },
         },
         response_name: `utter_bot_${uuidv4()}`,
      });
      setchat(newChat);
   };

   if (c.response_type === 'text')
      return <TextMessageInput response={c} removeMsg={removeMsg} id={c.response_name} update={update} />;

   if (c.response_type === 'defaultMsg') return <DefaultMessage />;

   if (c.response_type === 'image') return <Image data={c} remove={remove} duplicate={duplicate} update={update} />;

   if (c.response_type === 'card')
      return (
         <Card data={c} remove={remove} duplicate={duplicate} addCard={addCard} update={update} OPTIONS={OPTIONS} />
      );

   if (c.response_type === 'carousel')
      return (
         <ChatCarousel
            data={c}
            addCarousel={addCarousel}
            updateCarousel={updateCarousel}
            remove={remove}
            OPTIONS={OPTIONS}
         />
      );

   if (c.response_type === 'quickreplies')
      return (
         <QuickReplies
            data={c}
            updateQuickRpl={updateQuickRpl}
            remove={remove}
            duplicateQuickRpl={duplicateQuickRpl}
            OPTIONS={OPTIONS}
         />
      );

   if (c.response_type === 'form')
      return <ChatBotForm data={c} remove={remove} duplicate={duplicate} OPTIONS={OPTIONS} update={update} />;

   if (c.response_type === 'get_otp') return <Otp data={c} remove={remove} duplicate={duplicate} />;

   if (c.response_type === 'searchbar') return <AutoSuggest data={c} remove={remove} duplicate={duplicate} />;

   if (c.response_type === 'custom_actions')
      return <CustomAction data={c} remove={remove} duplicate={duplicate} update={update} />;
};

/**
 * @desc: place holder for adding new response, till now not save in chat
 * @param {String} type
 * @param {callback} setcurrentInputType
 * @param {Boolean} settoggleChat
 * @param {Array} chat
 * @param {callback} setchat
 * @param {array} OPTIONS
 */
export const handleInputType = (type, setcurrentInputType, settoggleChat, chat, setchat, OPTIONS) => {
   const addMsg = value => {
      setchat(pervState => [...pervState, inputType.Message(value)]);
   };

   const addImg = url => {
      setchat(pervState => [...pervState, inputType.imageType(url)]);
      setcurrentInputType();
   };

   const addCard = card => {
      setchat(pervState => [...pervState, inputType.cardType(card)]);
      setcurrentInputType();
   };

   const addCarousel = carousel => {
      setchat(pervState => [...pervState, inputType.carouselType(carousel)]);
      setcurrentInputType();
   };

   const addQuickRpl = quickRpl => {
      setchat(pervState => [...pervState, inputType.quickRplType(quickRpl)]);
      setcurrentInputType();
   };

   settoggleChat(true);
   if (chat[0]?.response_type === 'defaultMsg') {
      setchat([]);
   }

   if (type === 'text') return setcurrentInputType(<TextMessageInput addMsg={addMsg} />);

   if (type === 'image') return setcurrentInputType(<Image addImg={addImg} />);

   if (type === 'card') return setcurrentInputType(<CardInput addCard={addCard} OPTIONS={OPTIONS} />);

   if (type === 'carousel')
      return setcurrentInputType(<ChatCarousel addCarousel={addCarousel} addCard={addCard} OPTIONS={OPTIONS} />);

   if (type === 'replies') return setcurrentInputType(<QuickReplies addQuickRpl={addQuickRpl} OPTIONS={OPTIONS} />);

   setcurrentInputType();

   if (type === 'form') return setchat(pervState => [...pervState, inputType.form()]);

   if (type === 'get_otp') return setchat(pervState => [...pervState, inputType.otp()]);

   if (type === 'searchbar') return setchat(pervState => [...pervState, inputType.searchbar()]);

   if (type === 'custom_actions') return setchat(pervState => [...pervState, inputType.customactions()]);
};
