import React, { useState, useEffect } from 'react';

//dependencies
import arrayMove from 'array-move';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';
import arrayToTree from 'array-to-tree';
import { useSnackbar } from 'notistack';
import { OverlayTrigger } from 'react-bootstrap';
import { SortableContainer, SortableItem } from './ChatInputHandler/Sortable';

//components
import Diagram from './Diagram/Diagram';
import Alert from '../../components/Alert/Alert';
import Carousel from './Diagram/Carousel/Carousel';
import Tooltip from '../../components/tootip/tootip';
import ChatBot from '../../components/ChatBot/ChatBot';
import ChatBody from '../../pages/ChatboatParent/ChatboatParent';

//utils functions
import {
   flatten,
   enrich,
   getStoryStructure,
   addStartFallback,
   getResponseFormat,
   saveResponseFormat,
} from './Diagram/TreeManipulation';
import StoryValidator from './ChatInputHandler/StoryValidator';
import { getStory, deleteNodes, updateBotStory, trainBot } from './requests/requests';
import { handleInputType, getChatMessageType } from './ChatInputHandler/ChatInputHandler';

//redux
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LogOut } from '../../redux/actions/profile/index';
import { GetIntent } from '../../redux/actions/storybuilder/index';
import { CHANGECOUNT, SHOWHINTBOOL } from '../../redux/actions/hint';

// stories_format
import OSB from './assets/final_stories_format.json';

//response types
import * as inputType from './ChatInputHandler/inputTypes';

//styles
import styles from './Diagram/chatbot.module.scss';
import Axios from '../../utils/Request';

//inital story builder structure
let SB = null;
let OP = [];

function Main(props) {
   //all response elements of current selected node are stored in chat
   //and is used for maping in chatbot
   const [chat, setchat] = useState([inputType.defaultMessage()]);

   const [toggleChat, settoggleChat] = useState(false);

   const [minimize, setminimize] = useState(false);

   const [showChat, setShowChat] = useState(false);

   //when user click on carousel to select response, its type is stored in it before saving it in chat
   const [currentInputType, setcurrentInputType] = useState();

   //current selected node's id
   const [currentNode, setcurrentNode] = useState();

   const [loading, setloading] = useState(SB === null ? true : false);

   const [OPTIONS, setoptions] = useState(OP);

   const [InValidNodes, setinValidNodes] = useState([]);

   const [formErr, setformErr] = useState([]);

   const [formNameErr, setFormNameError] = useState(false)

   const tk = JSON.parse(localStorage.getItem('user')).token;

   const bot_name = useSelector(state => state.storybuilder.bot_name);
   const load = useSelector(state => state.storybuilder.load);

   const history = useHistory();

   const { enqueueSnackbar } = useSnackbar();

   /**
    * @function flatten: definition in "./Diagram/TreeManipulation.js"
    * @function enrich: definition in "./Diagram/TreeManipulation.js"
    * @function getStoryStructure: definition in "./Diagram/TreeManipulation.js"
    */
   const [topLevelNode, setTopLevelNode] = useState(
      flatten(
         enrich({
            ...arrayToTree(getStoryStructure(SB === null ? OSB : SB))[0],
         })
      )
   );

   /**
    * @Starting_function
    * @desc: Fetch intent list, bot story from api and save it in TopLevelNode after formating
    * @function GetIntent(bot_name:String,tk:String): api call to get list of intent
    * @function getStory(bot_name:String): api call to get story
    */
   useEffect(() => {
      if (bot_name !== null) {
         GetIntent(bot_name, tk)
            .then(res => {
               res.length === 0 &&
                  enqueueSnackbar('Intents not found, try refreshing!', {
                     variant: 'error',
                     preventDuplicate: true,
                     anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                     },
                  });
               let options = res.map(item => {
                  return {
                     value: item.replace(/_/g, ' '),
                     label: item.replace(/_/g, ' '),
                  };
               });
               OP = [...options];
               setoptions(options);

               if (SB !== null) return;
               (async () => {
                  const data = await getStory(bot_name, tk);

                  if (data.status) SB = { ...OSB, name: bot_name, bot_id: uuidv4(), description: 'N/A' };
                  else if (Object.keys(data).length === 0) {
                     SB = { ...OSB, name: bot_name, bot_id: uuidv4(), description: 'N/A' };
                     enqueueSnackbar(`Creating new story...`, {
                        variant: 'success',
                        preventDuplicate: true,
                        anchorOrigin: {
                           vertical: 'bottom',
                           horizontal: 'center',
                        },
                     });
                  } else SB = data;

                  setTopLevelNode(flatten(enrich({ ...arrayToTree(getStoryStructure(SB))[0] })));
                  setloading(false);
               })();

            })
            .catch(error => {
               if (error.response.data.detail) {
                  setLoaderModal();
                  ErrorMessage(error.response.data.detail);
               }
               if (error.response.data.detail === 'Invalid token.')
                  setTimeout(() => {
                     LogOut(history);
                  }, 2000);
            });
      } else if (load) {
         enqueueSnackbar('Bot name required!, try refreshing!', {
            variant: 'error',
            preventDuplicate: true,
            anchorOrigin: {
               vertical: 'bottom',
               horizontal: 'center',
            },
         });
         SB = OSB;
         setloading(false);
      }
   }, [bot_name, tk, load]);

   /**
    * @set_chat
    * @params_recived from "./ChatInputHandler/ChatInputHandler.js"
    * @param {{
    *    response_elements: []
    *    response_name: "utter_bot_/unique_id/"
    *    response_type: "type"
    * }} chat
    */
   const set_chat = chat => {
      setchat(chat);
   };

   /**
    * @onSortEnd
    * @desc: to update array to its new indexes after re-arraging the bot responses
    * @param {Int} oldIndex
    * @param {Int} newIndex
    */
   const onSortEnd = ({ oldIndex, newIndex }) => {
      set_chat(arrayMove(chat, oldIndex, newIndex));
   };

   //error handling
   const [shoModal, setModalShow] = useState(false);
   const [alertContent, setAlertContent] = useState({
      error: '',
      message: '',
   });

   const ErrorMessage = errMessage => {
      setAlertContent({
         error: 'Error',
         message: errMessage,
      });
   };
   const setLoaderModal = () => {
      setloading(false);
      setModalShow(true);
   };

   /**
    * @desc: every time error occur this useEffect is called ie: InValidNodes, formErr
    * @param {[`id_of_node`:String]} InValidNodes
    * @param {[response_name:String]} formErr
    */
   useEffect(() => {
      if (InValidNodes.length > 0 && !formNameErr)
         enqueueSnackbar(`Response cannot be empty, please add bot response to continue`, {
            variant: 'error',
            preventDuplicate: true,
            anchorOrigin: {
               vertical: 'bottom',
               horizontal: 'center',
            },
         });
      if (formErr.length > 0) {
         enqueueSnackbar('Form response must have a Submit button', {
            variant: 'error',
            preventDuplicate: true,
            anchorOrigin: {
               vertical: 'bottom',
               horizontal: 'center',
            },
         });
      }
      if (formNameErr) {
         enqueueSnackbar('Please edit form name to continue', {
            variant: 'error',
            preventDuplicate: true,
            anchorOrigin: {
               vertical: 'bottom',
               horizontal: 'center',
            },
         });
      }
   }, [formErr, InValidNodes, enqueueSnackbar, formNameErr]);

   /**
    * @updateTopLevelNode
    * @recived_params from "./Diagram/Diagram.js"
    * @param {array} updatedTopLevelNode
    */

   const updateTopLevelNode = updatedTopLevelNode => {
      setTopLevelNode(updatedTopLevelNode);
   };

   /**
    * @updateStory
    * @recived_params from "./Diagram/Diagram.js"
    * @param {Object} SB
    * @param {[id:String]} deleted_response_name
    */
   const updateStory = ({ SB: Story, deleted_response_name }) => {
      if (deleted_response_name) {
         deleteNodes(bot_name, deleted_response_name);
      }
      setTopLevelNode(
         flatten(
            enrich({
               ...arrayToTree(getStoryStructure(SB))[0],
            })
         )
      );

      settoggleChat(false);
      setcurrentNode(null);
   };

   /**
    * @onEditNodeClick
    * @param_recived from "./Diagram/Node.js"
    * @param {Object} node
    * @function addStartFallback(SB:Object): definition in "./Diagram/TreeManipulation.js"
    * @function getResponseFormat(chat_iterator:Object,InputType): definition in "./Diagram/TreeManipulation.js"
    */
   const onEditNodeClick = node => {
      setchat([inputType.defaultMessage()]);
      setcurrentInputType();
      settoggleChat(true);
      setcurrentNode(node.id);

      if (node.id === 'start' || node.id === 'fallback') {
         addStartFallback(SB);
      }

      if (SB.content[node.id].response.length !== 0) {
         let responseChat = SB.content[node.id].response.map(c => getResponseFormat(c, inputType));
         setchat(responseChat);
      }
   };

   /**
    * @desc: called every time chat or currentNode is updated to save response top SB object
    * @function saveResponseFormat(chat_iterator:Object): definition in "./Diagram/TreeManipulation.js"
    */
   useEffect(() => {
      if (currentNode && chat.length > 0 && chat[0].response_type !== 'defaultMsg') {
         let responseChat = chat.map(c => saveResponseFormat(c));

         SB.content[currentNode] = {
            ...SB.content[currentNode],
            response: [...responseChat],
         };
      }
      if (currentNode && chat.length === 1 && chat[0].response_type === 'defaultMsg') {
         SB.content[currentNode] = {
            ...SB.content[currentNode],
            response: [],
         };
      }
   }, [chat, currentNode]);

   const trainStories = async () => {

      const element = document.getElementById('trained-stories')

      element.classList.add('disatrained');
      settoggleChat(false);
      setloading(true);
      // const element = document.getElementById('foo')

      //       element.classList.add('my-class')

      /**
       *  async @trainBot
       *  @desc:return a promise containing a message.
       * @param {String} bot_name
       */
      try {
         const message = await trainBot(bot_name);
         enqueueSnackbar(message, {
            variant: 'success',
            preventDuplicate: true,
            anchorOrigin: {
               vertical: 'bottom',
               horizontal: 'center',
            },
         });
      } catch (error) {
         const element = document.getElementById('trained-stories')

         element.classList.remove('disatrained');
         console.log(error);
         enqueueSnackbar('failure', {
            variant: 'error',
            preventDuplicate: true,
            anchorOrigin: {
               vertical: 'bottom',
               horizontal: 'center',
            },
         });
         // LogOut(history); //test Logout
      }
      setloading(false);
   };

   const transformData = (SB) => {
      let tempSBobject = SB;

      Object.keys(tempSBobject.content).forEach(node => {
         tempSBobject.content[node].response = SB.content[node].response.map(res => {
            console.log(res.length)
            if (res.length == undefined) {

            }
            else {
               if (res.response_elements.en.length === 0 && res.response_elements.ar.length !== 0) {
                  res.response_elements.en = res.response_elements.ar;
               }
               if (res.response_elements.ar.length === 0 && res.response_elements.en.length !== 0) {
                  res.response_elements.ar = res.response_elements.en;
               }
            }

            return res;
         })
      })

      return tempSBobject;
   }

   /**
    * @handleSaveStories
    * @desc: when user clicks on Save Stories Button
    * @function StoryValidator(SB:Object): definition in "./ChatInputHandler/StoryValidator.js"
    * @function updateBotStory(SB:Object,bot_name:String): definition in "./requests/request.js"
    */
   const handleSaveStories = () => {
      const element = document.getElementById('trained-stories')

      element.classList.remove('disatrained');
      settoggleChat(false);
      setloading(true);

      const { inValidNodes, submitBtn, formName } = StoryValidator(SB);

      setinValidNodes(inValidNodes);
      setformErr(submitBtn);
      setFormNameError(formName);

      setTimeout(() => {
         setinValidNodes([]);
         setformErr([]);
         setFormNameError(false);
      }, 5000);

      const updateStory = async () => {
         try {
            const res = await updateBotStory(transformData(SB), bot_name);
            if (res.status === 403 || res.status === 401) {
               setLoaderModal();
               ErrorMessage('Failed to update, Please try again!');
            }
            setloading(false);
         } catch (error) {
            console.log(error)
            setLoaderModal();
            ErrorMessage('Server Error');
            setloading(false);
         }
      };
      if (inValidNodes.length === 0 && submitBtn.length === 0) updateStory();
      else if (bot_name === null) {
         enqueueSnackbar('Bot name required!, try refreshing!', {
            variant: 'error',
            preventDuplicate: true,
            anchorOrigin: {
               vertical: 'bottom',
               horizontal: 'center',
            },
         });
         setloading(false);
      } else setloading(false);
   };

   // useEffect(() => {
   //    const right = document.querySelector('#chatbot');
   //    const tooltip = document.querySelector('.creat-bot-tootltip');

   //    if (tooltip) {
   //       tooltip.style.top =
   //          Math.floor(tooltip.getBoundingClientRect().height) - (right.getBoundingClientRect().top - 400) + 'px';
   //       tooltip.style.left = Math.floor(right.getBoundingClientRect().left - -120) + 'px';
   //    }
   // }, [props.hint.hint_count]);

   const handleUpdateHint = token => {
      Axios('/accounts/updateHint/', {
         method: 'POST',
         headers: {
            Authorization: `Token ${token}`,
         },
         data: JSON.stringify({
            hint: false,
         }),
      })
         .then(res => {
            console.log(res, ' update res');
         })
         .catch(err => {
            console.log(err, ' update error');
         });
   };

   return (
      <>
         <div className={styles.Container}>
            {showChat && <ChatBody onClose={() => setShowChat(false)} style={{ zIndex: showChat ? 1 : -10 }} />}

            <Link to="/">
               <img src={require('./assets/backIcon.svg')} alt="back" />
            </Link>
            <div className={styles.headings}>
               <OverlayTrigger
                  placement={'top'}
                  show={props.hint.showHint && props.hint.hint_count === 3}
                  trigger="click"
                  overlay={() => (
                     <Tooltip
                        onClosePress={() => {
                           props.isVisivleTooltip(false);
                           const user = localStorage.getItem('user');
                           let usr = { ...JSON.parse(user) };
                           usr.hint = false;
                           console.log(usr, ' asdasdasda');
                           localStorage.setItem('user', JSON.stringify(usr));
                           handleUpdateHint(usr.token);
                        }}
                        heading="Create a Story"
                        paragraph="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
                        onNextPress={x => {
                           props.history.push('/intent');
                        }}
                        onBackPress={() => {
                           console.log(props, ' adsadasd');
                           props.tootipCounter(3);
                           props.history.push('/viewall');
                        }}
                        circles={[1, 2, 3, 4]}
                        height={230}
                        back={true}
                        hintCount={props.hint.hint_count}
                        class="creat-bot-tootltip"
                     />
                  )}>
                  <p>
                     <span style={{ fontFamily: 'M-Bold', color: 'black' }}>Bots</span> / {bot_name && bot_name}
                  </p>
               </OverlayTrigger>
               <span>
                  <button className="chatbot_saveBtn__2nPiM disatrained" id="trained-stories" onClick={trainStories} disabled={loading}>
                     Train Stories
                  </button>
                  <button className={styles.saveBtn} onClick={handleSaveStories} disabled={loading}>
                     Save Stories
                  </button>
                  <button onClick={() => setShowChat(true)} className={styles.button}>
                     Test Bot
                  </button>
               </span>
            </div>
            <Carousel
               isOpen={toggleChat}
               handleInputType={type =>
                  handleInputType(type, setcurrentInputType, settoggleChat, chat, set_chat, OPTIONS)
               }
            />

            <div
               style={{
                  pointerEvents: loading && 'none',
                  opacity: loading ? 0.4 : 1,
               }}>
               <Diagram
                  history={props.history}
                  hint={props.hint}
                  isVisivleTooltip={x => props.isVisivleTooltip(x)}
                  tooltipCounter={x => console.log('asddasdas')}
                  topLevelNode={topLevelNode}
                  updateTopLevelNode={updateTopLevelNode}
                  OPTIONS={OPTIONS}
                  onEditNodeClick={onEditNodeClick}
                  SB={SB}
                  updateStory={updateStory}
                  inValidNodes={InValidNodes}
               />
            </div>
            {loading && (
               <span
                  style={{
                     position: 'absolute',
                     left: '50%',
                     top: '55%',
                  }}>
                  <img src={require('./assets/loader1.svg')} alt="loading" width="104px" />
               </span>
            )}

            {(toggleChat || minimize) && (
               <ChatBot
                  isOpen={toggleChat}
                  setChat={settoggleChat}
                  setminimize={setminimize}
                  scroll={chat.length}
                  currentInputType={currentInputType}>
                  {chat[0]?.response_type === 'defaultMsg' ? (
                     chat.map((c, index) => (
                        <span key={c.response_name}>
                           {getChatMessageType(c, set_chat, setcurrentInputType, chat, OPTIONS)}
                        </span>
                     ))
                  ) : (
                     <SortableContainer onSortEnd={onSortEnd} useDragHandle>
                        {chat.map((c, index) => (
                           <SortableItem
                              key={c.response_name}
                              index={index}
                              c={() => getChatMessageType(c, set_chat, setcurrentInputType, chat, OPTIONS, bot_name)}
                           />
                        ))}
                     </SortableContainer>
                  )}
                  {currentInputType}
               </ChatBot>
            )}
         </div>
         <Alert
            show={shoModal}
            onHide={() => setModalShow(false)}
            message={alertContent.message}
            Error={alertContent.error}
            className="modal-container-class"
         />
      </>
   );
}

const mapStateToProps = ({ hint }) => ({ hint });

const mapDispatchToProps = dispatch => ({
   isVisivleTooltip: data => dispatch(SHOWHINTBOOL(data)),
   tootipCounter: data => dispatch(CHANGECOUNT(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
