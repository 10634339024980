import React, { useState } from 'react';
import Select, { components } from 'react-select';

import styles from './chatbot.module.scss';
import Entity from './Entity';

const boxHeight = 48;
const boxGap = 70;
const linkPositionOffset = 25;

const LinkedBox = ({
   node,
   order,
   options,
   handleIntentMenu,
   intentMenu,
   onEntitySubmit,
   showIntentMenu,
   onEditNodeClick,
   entityValue,
   handleEntityValueSelected,
   onContinue,
   history,
   hint,
   isVisivleTooltip,
   tooltipCounter,
   deleteParent,
   inValidNodes,
}) => {
   const [entityType, setEntityType] = useState('addEntity');
   const [showEntity, setShowEntity] = useState(false);
   const [editIntent, setEditIntent] = useState(false);

   const changeEntityType = data => {
      setEntityType(data);
   };

   console.log(node)

   //check if the node name is in intent menu
   /**
    * @desc return true if the text is in the props.option if return true we will show an editable node
    * @param {string} text
    */
   const valueIsInOptions = text => options.filter(op => op.label === text).length;
   // console.log(options,"====options");
   //get styles for a particular node
   /**
    * getNodeStyle
    * @desc return style accosiated with certain node
    * @param {object} node
    * @param {int} order
    */
   const getNodeStyle = (node, order) => {
      let style = {
         position: 'absolute',
         left: valueIsInOptions(node.name) ? `${node.depth * boxGap * 1.5 + 26}px` : `${node.depth * boxGap * 1.5}px`,
         top: `${order * (boxHeight + boxGap)}px`,
         height: `${boxHeight}px`,
         boxSizing: 'border-box',
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
         width: '110px',
         borderRadius: '100px',
         backgroundColor: '',
         border: '',
         color: 'white',
      };
      if (node.name === '+') style.backgroundColor = '';
      else if (node.name === 'IF') {
         style.backgroundColor = 'white';
         style.border = '1px solid #43b3e6';
         style.color = 'black';
      } else if (valueIsInOptions(node.name)) {
         style.backgroundColor = inValidNodes.includes(node.id) ? 'red' : '#4a4cab';
         style.borderRadius = '100%';
         style.width = '44px';
         style.height = '44px';
      } else style.backgroundColor = inValidNodes.includes(node.id) ? 'red' : '#43b3e6';
      return { ...style };
   };

   console.log(options)
   console.log(intentMenu)
   /**
    * getWidthTop
    * @desc return dynamic width w.r.t node
    */
   const getWidthTop = node => {
      if (node.name === '+') return `${boxGap - linkPositionOffset + 50}px`;
      if (valueIsInOptions(node.name)) return '86px';
      else return `${boxGap - linkPositionOffset + 15}px`;
   };

   /**
    * getCurrenttypeOfNode
    * @desc return complete node w.r.t to its type 
    */
   const getCurrenttypeOfNode = (node, edit = false) => {
      console.log(node.name)
      if (node.name === '+')
         return (
            <img
               src="/images/addnew.svg"
               className={styles.addNewButton}
               alt="addnew"
               style={{
                  cursor: 'pointer',
               }}
               onClick={e => {
                  showIntentMenu(node);
               }}
            />
         );

      if (node.name === 'addNew')
         return (
            <div
               style={{
                  position: 'absolute',
                  width: '245px',
                  left: '0px',
                  backgroundColor: 'white',
                  color: 'black',
                  boxShadow: '0 4px 50px 0 rgba(0, 0, 0, 0.05)',
                  padding: '25px 27px',
                  borderRadius: '8px',
               }}>
               <h5 style={{ marginBottom: '25px', fontFamily: 'M-Medium' }}>Select Intent</h5>

               <Select
                  className="select-box"
                  options={options}
                  defaultInputValue={intentMenu}
                  onChange={value => {
                     handleIntentMenu(value, node);
                  }}
               />
               <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <button
                     onClick={() => {
                        setShowEntity(true);
                        setEntityType('addEntity');
                     }}
                     style={{
                        all: 'unset',
                        marginTop: '30px',
                        color: '#43b3e6',
                        background: 'none',
                        cursor: 'pointer',
                     }}>
                     Add Entity
                  </button>

                  <button
                     onClick={onContinue}
                     style={{
                        all: 'unset',
                        marginTop: '30px',
                        color: '#43b3e6',
                        background: 'none',
                        cursor: 'pointer',
                     }}>
                     Continue
                  </button>
               </div>
            </div>
         );

      if (edit) {
         return (
            <div
               style={{
                  position: 'absolute',
                  width: '245px',
                  left: '0px',
                  backgroundColor: 'white',
                  color: 'black',
                  boxShadow: '0 4px 50px 0 rgba(0, 0, 0, 0.05)',
                  padding: '25px 27px',
                  borderRadius: '8px',
               }}>
               <h5 style={{ marginBottom: '25px', fontFamily: 'M-Medium' }}>Edit Intent</h5>

               <Select
                  className="select-box"
                  options={options}
                  value={node.name}
                  defaultValue={node.name}
                  onChange={value => {
                     handleIntentMenu(value, node);
                  }}
               />
               <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <button
                     onClick={() => {
                        setShowEntity(true);
                        setEntityType('addEntity');
                     }}
                     style={{
                        all: 'unset',
                        marginTop: '30px',
                        color: '#43b3e6',
                        background: 'none',
                        cursor: 'pointer',
                     }}>
                     Add Entity
                  </button>

                  <button
                     onClick={onContinue}
                     style={{
                        all: 'unset',
                        marginTop: '30px',
                        color: '#43b3e6',
                        background: 'none',
                        cursor: 'pointer',
                     }}>
                     Continue
                  </button>
               </div>
            </div>
         );
      }
      if (valueIsInOptions(node.name))
         return (
            <span>
               <span
                  style={{
                     position: 'absolute',
                     top: '-55px',
                     left: '-47px',

                     width: 'max-content',
                     color: '#333333',
                  }}>
                  <span
                     className="montserrat"
                     style={{
                        backgroundColor: '#ecf1f5',
                        padding: '10px 17px',
                        fontWeight: 'bold',
                        fontSize: '18px',
                        borderRadius: '4px',
                        color: inValidNodes.includes(node.id) && 'red',
                     }}>
                     {node.name}
                  </span>
                  <span
                     style={{
                        marginLeft: '7px',
                        backgroundColor: '#ecf1f5',
                        padding: '11.5px 15px',
                     }}
                     onClick={() => {
                        const cd = window.confirm('Are you sure you want to delete this node?');
                        cd && deleteParent(node)
                     }}>
                     <img src="/images/delete-story.svg" alt="" height="38" width="22" />
                  </span>
                  <span
                     style={{
                        marginLeft: '7px',
                        backgroundColor: '#ecf1f5',
                        padding: '11.5px 15px',
                     }}
                     onClick={() => setEditIntent(!editIntent)}>
                     <img src="/images/pencil-edit-button.svg" alt="" height="38" width="22" />
                  </span>
               </span>
               <span className={styles.arrowDown}></span>
               <img
                  src="/images/pencil-edit-button.svg"
                  className={styles.pencilEditButton}
                  alt="edit node"
                  onClick={() => {
                     onEditNodeClick(node);
                  }}
               />
            </span>
         );
      if (node.name === 'IF') return <span style={{ cursor: 'pointer' }}>{node.name}</span>;
      return (
         <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
               onEditNodeClick(node);
            }}>
            {node.name.charAt(0).toUpperCase() + node.name.slice(1)}
         </span>
      );
   };

   return (
      <div
         style={{
            ...getNodeStyle(node, order),
         }}>
         {node.depth > 0 && (
            <span>
               <div
                  style={{
                     position: 'absolute',
                     left: valueIsInOptions(node.name) ? '-85px' : `-${boxGap - linkPositionOffset + 15}px`,
                     top: `${linkPositionOffset - 1}px`,
                     width: getWidthTop(node),
                     borderTop: 'solid 1px grey',
                  }}
               />
               <span
                  style={{
                     position: 'absolute',
                     left: valueIsInOptions(node.name)
                        ? `-${boxGap - linkPositionOffset + 39.9}px`
                        : `-${boxGap - linkPositionOffset + 15.8}px`,
                     top: `${linkPositionOffset - 6.4}px`,
                     width: '12px',
                     height: '12px',
                     border: 'solid 2px #43b3e6',
                     backgroundColor: '#ffffff',
                     borderRadius: '100%',
                  }}
               />
            </span>
         )}

         {node.heightDiffWithLastDirectChild > 0 && (
            <div
               style={{
                  position: 'absolute',
                  top: `${boxHeight - 2}px`,
                  left: valueIsInOptions(node.name) ? `${linkPositionOffset - 2}px` : `${linkPositionOffset + 25}px`,
                  height: `${boxGap + (node.heightDiffWithLastDirectChild - 1) * (boxGap + boxHeight) + linkPositionOffset
                     }px`,
                  borderLeft: 'solid 1px grey',
                  opacity: '0.6',
               }}
            />
         )}
         {getCurrenttypeOfNode(node, editIntent)}
         {showEntity && (
            <Entity
               options={options}
               handleIntentMenu={handleIntentMenu}
               handleEntityValueSelected={handleEntityValueSelected}
               onEntitySubmit={onEntitySubmit}
               entityValue={entityValue}
               closeEntity={() => {
                  setShowEntity(false);
               }}
               changeEntityType={changeEntityType}
               entityType={entityType}
            />
         )}
      </div>
   );
};

export default LinkedBox;
