import React, { useState } from "react"
import Details from "./Details/Details"

const Documentation = (props) => {

    const [SelectedCard, setSC] = useState(0)
    const [open, setopen] = useState(false)

    const OnCardClicked = (card) => {
        setSC(card)
        setopen(true)
    }

    const onNext = () => {
        if (SelectedCard === 5) {
            return setopen(false)
        }
        setSC(SelectedCard + 1)
    }

    return (
        <div className="documentation-parent">
            {!open ? <div className="wrapper">
                <div className="title">Documentation</div>
                <div className="rows">
                    <div className="row">
                        <div className={`card ${SelectedCard === 1 ? "active" : ""}`} onClick={() => { OnCardClicked(1) }}>
                            <div className="number">
                                01
                        </div>
                            <div className="title">
                                How to create a Bot
                        </div>
                            <div className="description">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        </div>
                        </div>
                        <div className={`card ${SelectedCard === 2 ? "active" : ""}`} onClick={() => { OnCardClicked(2) }}>
                            <div className="number">
                                02
                        </div>
                            <div className="title">
                                Story Creation
                        </div>
                            <div className="description">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        </div>
                        </div>
                        <div className={`card ${SelectedCard === 3 ? "active" : ""}`} onClick={() => { OnCardClicked(3) }}>
                            <div className="number">
                                03
                        </div>
                            <div className="title">
                                Intents
                        </div>
                            <div className="description">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className={`card ${SelectedCard === 4 ? "active" : ""}`} onClick={() => { OnCardClicked(4) }}>
                            <div className="number">
                                04
                        </div>
                            <div className="title">
                                Custom Intents
                        </div>
                            <div className="description">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        </div>
                        </div>
                        <div className={`card ${SelectedCard === 5 ? "active" : ""}`} onClick={() => { OnCardClicked(5) }}>
                            <div className="number">
                                05
                        </div>
                            <div className="title">
                                Utterances
                        </div>
                            <div className="description">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        </div>
                        </div>
                        <div className="card disabled">

                        </div>
                    </div>
                </div>
            </div> : <Details current={SelectedCard} onNext={onNext} onBack={() => { setopen(false) }} />}
        </div>
    )
}

export default Documentation