import React, { useEffect } from 'react';
import { ProSidebar, SidebarFooter, SidebarContent } from 'react-pro-sidebar';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ImageTag from '../ImageTag/ImageTag';
import { useLocation } from 'react-router-dom';
import Axios from '../../utils/Request';
const NavBar = props => {
   const location = useLocation();
   function logout() {
      const tk1 = JSON.parse(localStorage.getItem('user')).token;

      Axios('/accounts/logout/', {
         method: 'GET',
         headers: {
            Authorization: 'Token ' + tk1,
         },
      })
         .then(res => {
            console.log('logout' + res.status);

            if (res.status === 200) {
               //
               localStorage.clear();
               props.history.push('/signin');
            }
            console.log('called');
         })
         .catch(error => {
            //console.log(error)
         });
   }

   function Icon(prop) {
      return (
         <ImageTag
            src={!prop.active ? require(`./assets/${prop.name}.svg`) : require(`./assets/${prop.name}-active.svg`)}
         />
      );
   }
   const history = location.pathname;
   const classGenerator = path => {
      return history == path ? 'links-container-sub link-active' : 'links-container-sub';
   };

   const cr = JSON.parse(localStorage.getItem('user'));

   const restricted = cr === null ? [] : JSON.parse(localStorage.getItem('user')).restricted;

   const privateRoutes = [
      {
         icon: '',
         routeName: '/',
         name: 'All Bots',
      },
      {
         icon: '2',
         routeName: '/creatbot',
         name: 'Story Creation',
      },
      {
         icon: '3',
         routeName: '/intent',
         name: 'Intents',
      },
      {
         icon: '4',
         routeName: '/bot-analytics-dashboard',
         routeName2: '/activity',
         name: 'Analytics',
      },
      {
         icon: '5',
         routeName: '/liveagent',
         name: 'Live Agent',
      },
      {
         icon: '6',
         routeName: '#',
         name: 'Help',
      },
      {
         icon: '7',
         routeName: '#',
         name: 'Settings',
      },
   ];

   restricted.forEach(url => {
      let changeRootPath = false;  // Changing default root path to analytics if allbots is disabled
      privateRoutes.forEach(routes => {
         if (routes.routeName.includes(url)) {
            routes.disableRoute = true;
         }
         if (url === "allbots" && routes.routeName === "/") {
            routes.disableRoute = true;
            changeRootPath = true;
         }
         if (changeRootPath && routes.name === "Analytics") {
            routes.routeName = "/";
         }
         // if (!routes.routeName.includes('creatbot')) {
         //    if (routes.routeName.includes(url)) {
         //       routes.disableRoute = true;
         //    }
         // }
      });
   });

   return (
      <div className="sideNavbar-container">
         <ProSidebar popperArrow={true} collapsed={false}>
            <div className="header-container">
               <ImageTag src={require('./assets/logo.svg')} />
            </div>
            <SidebarContent>
               {privateRoutes.map(
                  (data, key) =>
                     !data.disableRoute && (
                        <div className={'links-container'}>
                           <div
                              className={
                                 history === data.routeName || history === data.routeName2
                                    ? 'links-container-sub link-active'
                                    : 'links-container-sub'
                              }>
                              <div className="link-img">
                                 <Icon active={history === data.routeName} name={'icon' + data.icon} />
                              </div>
                              <div className="link-heading">
                                 <Link
                                    className={
                                       history === data.routeName || history === data.routeName2
                                          ? 'bold link link-active-active'
                                          : 'bold link'
                                    }
                                    to={data.routeName}>
                                    {' '}
                                    {data.name}
                                 </Link>
                              </div>
                           </div>
                        </div>
                     )
               )}
            </SidebarContent>
            <SidebarFooter>
               <div onClick={logout} className="footer-container">
                  <div className="footer-container-sub">
                     <div className="link-img">
                        <Icon name="icon8" />
                     </div>
                     <p className="bold link">Logout</p>
                  </div>
               </div>
            </SidebarFooter>
         </ProSidebar>
      </div>
   );
};

export default withRouter(NavBar);
