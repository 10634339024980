import React, { useState, useRef, useEffect } from "react";
import Button from "../../../components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { AddIntent, AddUtterence } from "../../../redux/actions/intent";
import Alert from "../../../components/Alert/Alert";
import { useHistory } from "react-router-dom"

const IntentCard = props => {
   const [intent, setIntent] = useState("");
   const [selectedText, setSelectedText] = useState("");
   const dispatch = useDispatch();
   const [showModal, setModalShow] = useState(false)
   const existing_intents = useSelector(state => state.home.existing_intents)
   const [alertContent, setAlertContent] = useState({
      error: "",
      message: "",
   });
   const tk = JSON.parse(localStorage.getItem('user')).token;
   const bot_name = useSelector(state => state.storybuilder.bot_name)
   const inpt = useRef(null)
   const history = useHistory()

   useEffect(() => {

      inpt.current.addEventListener("keyup", HandleEnter)

      return () => {
         inpt.current.removeEventListener("keyup", HandleEnter)
      }

   }, [intent])

   const HandleEnter = (event) => {
      if (event.keyCode === 13) {
         if (props.type === "intent") {
            onCreate()
            console.log(intent)
         } else {
            onAdd()
         }
      }
   }

   function handleMouseUp() {
      setSelectedText(window.getSelection().toString());
   }

   const onCreate = () => {
      console.log(intent)
      if (intent.trim().length === 0) {
         setModalShow(true)
         setAlertContent({
            error: "",
            message: "Intent cannot be empty!"
         })
         return
      }
      if (props.data.find((item) => { return item.intent.toLowerCase() === intent.toLowerCase() })) {
         setModalShow(true)
         setAlertContent({
            error: "",
            message: "Intent with the same name already exists"
         })
         return
      }
      else {
         dispatch(AddIntent(intent));
         setIntent("");
      }
   };

   const onAdd = async () => {

      const intent_current = props.data.find((item) => { return item.intent.toLowerCase() === props.intent.toLowerCase() })

      if (intent.trim().length === 0) {
         setModalShow(true)
         setAlertContent({
            error: "",
            message: "Utterence cannot be empty!"
         })
      }
      else if (intent_current.utterances.find((item) => { return item.utterance.toLowerCase() === intent.toLowerCase() })) {
         setModalShow(true)
         setAlertContent({
            error: "",
            message: "Utterence with same name already exists!"
         })
         return
      }
      else {
         if (props.intent !== null) {

            await dispatch(AddUtterence(bot_name, props.intent, intent, tk, existing_intents, history));
            props.setTrainDisabled();
            setIntent("");
         }
      }
   };

   return (
      <div style={props.style} className="intentcard-container">
         <Alert
            show={showModal}
            onHide={() => setModalShow(false)}
            message={alertContent.message}
            Error={alertContent.error}
            className="modal-container-class"
         />

         <p className="intent-card-heading">
            {props.type === "intent" ? "Create Intent" : "Create Utterance"}
         </p>
         <div className="flex input-wrap">
            <input
               onChange={e => {
                  setIntent(e.target.value);
                  if (props.type === "intent") {
                     props.onChange()
                  }
               }}
               ref={inpt}
               value={intent}
               onMouseUp={handleMouseUp}
               placeholder="Enter name for Intent"
               className="intent-input"
               type="text"
               disabled={props.type !== "intent" && props.intent === null ? true : false}
            />
            <Button className="button-primary blue" onClick={props.type === "intent" ? onCreate : onAdd}>
               {props.type === "intent" ? "Create" : "Add"}
            </Button>
         </div>
      </div>
   );
};

export default IntentCard;
