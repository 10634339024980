import React, { useState, useEffect, useRef } from "react"
//import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import { Nav } from "react-bootstrap"
import TableEvent from "../Table/TableEvent"
import da from "../../assets/images/dropdown-arrow.svg"
//import 'react-dates/initialize';
//import 'react-dates/lib/css/_datepicker.css';
import { event_logging } from "./event_logging"
import Api from "../../axios/request"
import NoMessage from "../../pages/LiveAgent/NoMessage/NoMessage"

import DateRangePicker from "react-bootstrap-daterangepicker"
import 'bootstrap-daterangepicker/daterangepicker.css';
import { months } from "../../utils/Utility"
import { useSelector } from "react-redux"
import { LogOut } from "../../redux/actions/profile/index"
import { useHistory } from "react-router-dom"

const EventLogging = (props) => {
    const [date, setdate] = useState({ startDate: null, endDate: null })
    const [events, setevents] = useState([])
    const [nomsg, setnomsg] = useState(true)
    const tk = JSON.parse(localStorage.getItem("user")).token
    const [start_date, setsdate] = useState(1599568112000)
    const [event_name, setevent_name] = useState(null)
    const [total_events, settevents] = useState([])
    const bot_name = useSelector(state => state.storybuilder.bot_name)
    const history = useHistory()
    const picker = useRef(null)

    useEffect(() => {

        if (bot_name !== null) {
            const end_date = new Date()
            const start_date = new Date(end_date)
            start_date.setDate(start_date.getDate() - 7)

            //setid({ start_date: start_date.getMonth() + "/" + start_date.getDate() + "/" + start_date.getFullYear(), end_date: end_date.getMonth() + "/" + end_date.getDate() + "/" + end_date.getFullYear() })
            setdate({
                startDate: months[start_date.getMonth()] + " " + start_date.getDate() + ", " + start_date.getFullYear(),
                endDate: months[end_date.getMonth()] + " " + end_date.getDate() + ", " + end_date.getFullYear()
            })

            picker.current.setStartDate(start_date.getMonth() + 1 + "/" + start_date.getDate() + "/" + start_date.getFullYear())
            picker.current.setEndDate(end_date.getMonth() + 1 + "/" + end_date.getDate() + "/" + end_date.getFullYear())

            ApiCall(start_date.getTime(), end_date.getTime(), bot_name)
        }

    }, [bot_name])

    const ApiCall = (start_date, end_date, bot_name) => {

        setsdate(start_date)

        const params = new URLSearchParams({
            bot_name: bot_name,
            start_date: start_date,
            end_date: end_date
        }).toString();


        Api.post('/analytics/event-logging?' +
            params, "",
            {
                headers: {
                    Authorization: 'Token ' + tk
                },
            })
            .then(res => {

                if (res.status === 403 || res.status === 401) {
                    return LogOut(history)
                }

                setevents(res.data.events)
                settevents(res.data.events)

                if (event_name !== null) {
                    SearchByEventName(event_name)
                }

            })
            .catch(xhr => {
                let errormesae = JSON.stringify(xhr.message);
                if (errormesae == '"Request failed with status code 401"' || errormesae == '"Request failed with status code 403"') {
                    return LogOut(history);
                }
            });
    }

    const onApply = (start, end) => {

        var date1 = new Date(parseInt(start))
        var date2 = new Date(parseInt(end))
        console.log(date1, ' lkasdlaskjdlk')
        setdate({
            startDate: months[date1.getMonth()] + " " + date1.getDate() + ", " + date1.getFullYear(),
            endDate: months[date2.getMonth()] + " " + date2.getDate() + ", " + date2.getFullYear()
        })

        ApiCall(parseInt(start.substring(0, 13)), parseInt(end.substring(0, 13)), bot_name)
    }

    const interval = (type) => {
        var date = new Date(start_date)
        var date1 = new Date(start_date)
        if (type === "day") {
            date.setDate(date.getDate() + 1)
            ApiCall(start_date, date.getTime(), bot_name)
        }
        else if (type === "week") {
            date.setDate(date.getDate() + 7)
            ApiCall(start_date, date.getTime(), bot_name)
        }
        else {
            date.setDate(date.getDate() + 30)
            ApiCall(start_date, date.getTime(), bot_name)
        }

        picker.current.setStartDate(date1.getMonth() + 1 + "/" + date1.getDate() + "/" + date1.getFullYear())
        picker.current.setEndDate(date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear())

        setdate({
            startDate: months[date1.getMonth()] + " " + date1.getDate() + ", " + date1.getFullYear(),
            endDate: months[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear()
        })

        console.log(date.getTime())
    }

    useEffect(() => {
        if (events.length !== 0) {
            setnomsg(false)
        } else {
            setnomsg(true)
        }
    }, [events])

    const SearchByEventName = (event) => {
        if (event === "") {
            setevent_name(event)
            return setevents(total_events)
        }
        setevent_name(event)

        const filtered = events.filter((item) => {
            return item.event_name === event
        })

        setevents(filtered)
    }

    return (
        <div className="eventlogging-parent">
            <div>
                <div class="f-buttons" >
                    <div className="d-two">
                        <div className="head">
                            Select Date Range
                        </div>

                        <img className="select-arrow date" src={da} />
                        <DateRangePicker ref={picker} onCallback={(start, end, label) => { onApply(start + "", end + "") }} initialSettings={{ /* startDate: '01/01/2020', endDate: '01/15/2020' */ }}>
                            <input type="text" placeholder="Containing Text"></input>
                        </DateRangePicker>
                    </div>

                    <div className="d-one">
                        <div className="head">
                            Search by Event Name
                        </div>
                        <img className="select-arrow" src={da} />
                        <select className="dropdown" onChange={(e) => { SearchByEventName(e.currentTarget.value) }}>
                            <option value="" disabled selected>Events Name</option>

                            {events.map((item) => {
                                return (<option value={item.event_name} >{item.event_name}</option>)
                            })}
                            <option value="">Remove Filter</option>
                        </select>
                    </div>

                    <div className="d-one" >

                        <div className="head">
                            Interval
                        </div>

                        <Nav variant="pills" defaultActiveKey="week">
                            <Nav.Item>
                                <Nav.Link eventKey="day" onClick={() => { interval("day") }}>Day</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="week" onClick={() => { interval("week") }}>Week</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="month" onClick={() => { interval("month") }}>Month</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>

                </div >

                {!nomsg ? <TableEvent events={events} /> : <NoMessage title="No Logs" message="Sorry, you don't have any logs" />}
            </div>

        </div>
    )
}

export default EventLogging