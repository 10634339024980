import React from "react";
import './card-style.css'

// const useStyles = makeStyles({
//     root: { 
        
//         minWidth: 1038,
//     },
//     bullet: {
//         display: "inline-block",
//         margin: "0 2px",
//         transform: "scale(0.8)",
//     },
//     title: {
//         fontSize: 14,
//     },
//     pos: {
//         marginBottom: 12,
//     },
// });

export default (props) => {
   // const bull = <span className={classes.bullet}>•</span>;

    return (
        <div  variant="outlined">
                <div className="card-container">
                    <div className="columns">
                        <p className="Prior">Prior</p>
                        <div className="Rectangle-53">
                            <p className="Start">Start</p>
                        </div>
                        <div className="Rectangle-53">
                            <p className="Start">Start</p>
                        </div>
                        <div className="Rectangle-53">
                            <p className="Start">Start</p>
                        </div>
                        <div className="Rectangle-53">
                            <p className="Start">Start</p>
                        </div>
                    </div>
                    <div className="columns">
                        <p className="Prior">Current</p>
                        <div className="Rectangle-53">
                            <p className="Start">Start</p>
                        </div>
                        <div className="Rectangle-53">
                            <p className="Start">Start</p>
                        </div>
                        <div className="Rectangle-53">
                            <p className="Start">Start</p>
                        </div>
                        <div className="Rectangle-53">
                            <p className="Start">Start</p>
                        </div>
                    </div>
                    <div className="columns">
                        <p className="Prior">Prior</p>
                        <div className="Rectangle-53">
                            <p className="Start">Start</p>
                        </div>
                        <div className="Rectangle-53">
                            <p className="Start">Start</p>
                        </div>
                        <div className="Rectangle-53">
                            <p className="Start">Start</p>
                        </div>
                        <div className="Rectangle-53">
                            <p className="Start">Start</p>
                        </div>
                    </div>
                </div>


        </div>
    );
};
