import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ImageTag from "../ImageTag/ImageTag";
import { OverlayTrigger } from "react-bootstrap";
import Tooltip from "../tootip/tootip";
import { SHOWHINTBOOL, CHANGECOUNT } from "../../redux/actions/hint";
import { connect, useDispatch } from "react-redux";
import { GetProfile } from "../../redux/actions/profile/index"

import Splash from "../ModalSplash/ModalSplash";
import Axios from "../../utils/Request";

const Dashboard = props => {
   const user = localStorage.getItem("user");
   const [showSplash, setShowSplash] = useState((user && JSON.parse(user).hint));
   const dispatch = useDispatch()

   useEffect(() => {
      if (!user) {
         props.history.push("/signin");
      } else {
         const tk = JSON.parse(localStorage.getItem("user")).token
         dispatch(GetProfile(tk))
      }
      if (showSplash) {
         setTimeout(() => {
            setShowSplash(false)
            props.isVisivleTooltip(true);
         }, 10000)
      }

   }, []);

   useEffect(() => {
      if (props.hint.hint_count == 3) {
         props.isVisivleTooltip(false);
      }
      const right = document.querySelector("#Card2");
      const dashboard = document.querySelector(".dashboard-1");

      if (dashboard) {
         dashboard.style.top =
            Math.floor(right.getBoundingClientRect().top) -
            dashboard.getBoundingClientRect().height -
            10 +
            "px";
         dashboard.style.left = Math.floor(right.getBoundingClientRect().left) + "px";
      }
   }, [props.hint.hint_count]);

   const handleUpdateHint = (token) => {
      Axios('/accounts/updateHint/', {
         method: "POST",
         headers: {
            Authorization: `Token ${token}`
         },
         data: JSON.stringify({
            hint: false
         })
      }).then((res) => {
         console.log(res, ' update res')
      })
         .catch((err) => {
            console.log(err, ' update error')
         })
   }

   return (
      <div className="cont-container">
         <Splash
            onHide={() => {
               setShowSplash(false);
               props.isVisivleTooltip(true)
            }}
            show={showSplash}
         />
         <div className="Navbar-c">
            <div className="row mr-0 ml-0">
               <div className="content-box" id="home-banner">
                  <div className="LeftSection">
                     <p className="leftSection-heading bold">Welcome to Blue Logic Digital Bot</p>
                     <p className="leftSection-p medium">
                        You have no active conversations at the moment. You can create your own bot.
                     </p>
                     <button className="ContentBtn bold">Explore</button>
                  </div>
               </div>
               <div className="headContainer">
                  <p className="bolder content-head">Use ready made templates</p>
                  <Link className="bold view" to="/viewall">
                     {" "}
                     View All
                  </Link>
               </div>
            </div>
            <div className="Card">
               <div className="Card1">
                  <div className="left-section">
                     <p className="cardHeading-first bold">New Year Sale Bot</p>
                     <p className="cardText-text regular">
                        Lipsum generator: Lorem Ipsum - All the factswww.lipsum.com Lorem Ipsum is simply
                        dummy
                     </p>
                     <button className="ContentBtn2 bold">Import Bot</button>
                  </div>
                  <div className="right-section">
                     <ImageTag src={require("./assets/bag.svg")} />
                  </div>
               </div>
               <div
                  style={{
                     zIndex: props.hint.hint_count == 2 ? 200 : 100,
                  }}
                  className="Card2"
                  id="Card2">
                  <div className="iconDIv">
                     <ImageTag src={require("./assets/teeth.svg")} />
                  </div>
                  <p className="cardHeading bold">Dental Clinic</p>
                  <p className="cardText regular">Lorem ipsum dolor sit amet, consectetur commodo consequat.</p>
                  <OverlayTrigger
                     placement={"top"}
                     show={props.hint.hint_count == 2 && props.hint.showHint}
                     trigger="click"
                     overlay={() => (
                        <Tooltip
                           onClosePress={() => {
                              props.isVisivleTooltip(false);
                              let usr = { ...JSON.parse(user) }
                              usr.hint = false;
                              console.log(usr, ' asdasdasda')
                              localStorage.setItem("user", JSON.stringify(usr));
                              handleUpdateHint(usr.token)
                           }}
                           heading="Import Bot"
                           paragraph="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
                           onBackPress={() => {
                              props.tooltipCounter(1);
                           }}
                           onNextPress={() => {
                              props.tooltipCounter(3);
                              props.history.push("/viewall");
                           }}
                           circles={[1, 2, 3, 4]}
                           hintCount={props.hint.hint_count}
                           height={230}
                           back={true}
                           class="dashboard-1"
                        />
                     )}
                  >
                     <button className="cardBtn bold">Import Bot</button>
                  </OverlayTrigger>
               </div>
               <div className="Card2">
                  <div className="iconDIv">
                     <ImageTag src={require("./assets/coffee.svg")} />
                  </div>
                  <p className="cardHeading bold">Coffee Shop</p>
                  <p className="cardText regular">Lipsum generator: Lorem Ipsum - All the factswww.lipsum.com</p>
                  <button className="cardBtn bold">Import Bot</button>
               </div>
            </div>
         </div>
      </div>
   );
};

const mapStateToProps = ({ hint }) => ({ hint });

const mapDispatchToProps = dispatch => ({
   isVisivleTooltip: data => dispatch(SHOWHINTBOOL(data)),
   tooltipCounter: data => dispatch(CHANGECOUNT(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
