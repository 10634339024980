import React from 'react';
import ImageTag from '../../../components/ImageTag/ImageTag';

const NoMessage = ({ title = 'No Message', message = 'Sorry, you don’t have any active message.' }) => {
   return (
      <div className="no-message-container">
         <ImageTag src="images/LiveAgentNoMessage.svg" />
         <h1>{title}</h1>
         <p>{message}</p>
      </div>
   );
};

export default NoMessage;
