import React, { useState, useEffect } from "react"
import { Table } from "react-bootstrap"
import Left from "../../assets/images/left_arrow.svg"
import Right from "../../assets/images/right_arrow.svg"
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';



const TableFunnel = (props) => {

    const data = [{}, {}, {}, {}, {}]
    const [page, setpage] = useState(1)
    const [total_pages, setTP] = useState(1)


    const useStyles = makeStyles((theme) => ({
        root: {
            '& > *': {
                marginTop: theme.spacing(2),
                right: 0
            },
        },
    }));

    const handleChange = (event, value) => {
        setpage(value);
    };

    useEffect(() => {
        setTP(Math.ceil(props.messages.length / 5))
        console.log(Math.ceil(props.messages.length / 5))
    }, [props.messages])


    return (
        <div className="table-parent">
            <div style={{
                margin: '24px 0px',
                padding: 30,
                border: "1px solid #eeeeee",
                borderRadius: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>

                <Table striped hover style={{
                    borderTopLeftRadius: '1rem',
                    borderTopRightRadius: '1rem',
                    overflow: 'hidden',
                }}>
                    <thead>
                        <tr style={{
                            backgroundColor: '#43b3e6',
                            color: '#fff'
                        }}>
                            <th style={{ paddingLeft: 20 }}>Message</th>
                            <th style={{ textAlign: "center" }}>Messages Count</th>
                            <th style={{ textAlign: "center", color: "transparent" }}> Messages Out</th>
                        </tr>
                    </thead>
                    <header1 />
                    <tbody>
                        {props.messages.map((item, index) => {

                            if (Math.ceil(index / 5) !== page) {
                                return null
                            }

                            return (
                                <tr>
                                    <td style={{
                                        minWidth: '10rem'
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            paddingLeft: 8
                                        }}>
                                            <div style={{
                                                backgroundColor: '#43b3e6',
                                                borderRadius: '50%',
                                                width: 35,
                                                height: 35,
                                                marginRight: '1rem'
                                            }}>
                                                <span style={{
                                                    display: 'flex',
                                                    width: '100%',
                                                    height: '100%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    fontSize: 17,
                                                    color: '#fff',
                                                    backgroundColor: "#4a4cab",
                                                    borderRadius: 30
                                                }}>
                                                    {item.charAt(0)}
                                                </span>
                                            </div>
                                            {item}
                                        </div></td>
                                    <td className="text-center">{props.count[index]}</td>
                                    <td className="funnel">
                                        <div className="f-button" onClick={() => { props.onClick(item) }}>
                                            View Funnel
                                    </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>

            <div className="pagination" style={{ display: total_pages === 1 ? "none" : "flex" }}>
                <div className="page-cont">
                    <div className="p-button" onClick={() => { if (page !== 1) { setpage(page - 1) } }}>
                        <img src={Left} style={{ marginRight: 10 }} />
                        Prev
                    </div>
                    <div className={useStyles().root}>
                        <Pagination count={total_pages} shape="rounded" page={page} hideNextButton={true} hidePrevButton={true} onChange={handleChange}  />
                    </div>
                    <div className="p-button" onClick={() => { if (page !== total_pages) { setpage(page + 1) } }}>
                        Next
                    <img src={Right} style={{ marginLeft: 10 }} />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default TableFunnel
