export default [
    {
    "name": "Afghanistan",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Afghanistan.svg",
    "value": "+93"
    },
    {
    "name": "Albania",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/3/36/Flag_of_Albania.svg",
    "value": "+355"
    },
    {
    "name": "Algeria",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_Algeria.svg",
    "value": "+213"
    },
    {
    "name": "Andorra",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Andorra.svg",
    "value": "+376"
    },
    {
    "name": "Angola",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/9/9d/Flag_of_Angola.svg",
    "value": "+244"
    },
    {
    "name": "Anguilla",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/b/b4/Flag_of_Anguilla.svg",
    "value": "+1 264"
    },
    {
    "name": "Antigua and Barbuda",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/8/89/Flag_of_Antigua_and_Barbuda.svg",
    "value": "+1268"
    },
    {
    "name": "Argentina",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/1/1a/Flag_of_Argentina.svg",
    "value": "+54"
    },
    {
    "name": "Armenia",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/2/2f/Flag_of_Armenia.svg",
    "value": "+374"
    },
    {
    "name": "Aruba",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/f/f6/Flag_of_Aruba.svg",
    "value": "+297"
    },
    {
    "name": "Australia",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_Australia_%28converted%29.svg",
    "value": "+61"
    },
    {
    "name": "Austria",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_Austria.svg",
    "value": "+43"
    },
    {
    "name": "Azerbaijan",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/d/dd/Flag_of_Azerbaijan.svg",
    "value": "+994"
    },
    {
    "name": "Bahamas",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/9/93/Flag_of_the_Bahamas.svg",
    "value": "+1 242"
    },
    {
    "name": "Bahrain",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/2/2c/Flag_of_Bahrain.svg",
    "value": "+973"
    },
    {
    "name": "Bangladesh",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/f/f9/Flag_of_Bangladesh.svg",
    "value": "+880"
    },
    {
    "name": "Barbados",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/e/ef/Flag_of_Barbados.svg",
    "value": "+1 246"
    },
    {
    "name": "Belarus",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/8/85/Flag_of_Belarus.svg",
    "value": "+375"
    },
    {
    "name": "Belgium",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_Belgium.svg",
    "value": "+32"
    },
    {
    "name": "Belize",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/e/e7/Flag_of_Belize.svg",
    "value": "+501"
    },
    {
    "name": "Benin",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/0/0a/Flag_of_Benin.svg",
    "value": "+229"
    },
    {
    "name": "Bermuda",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/b/bf/Flag_of_Bermuda.svg",
    "value": "+1 441"
    },
    {
    "name": "Bhutan",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/9/91/Flag_of_Bhutan.svg",
    "value": "+975"
    },
    {
    "name": "Bosnia and Herzegovina",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/b/bf/Flag_of_Bosnia_and_Herzegovina.svg",
    "value": "+387"
    },
    {
    "name": "Botswana",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_Botswana.svg",
    "value": "+267"
    },
    {
    "name": "Bouvet Island",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg",
    "value": "+55"
    },
    {
    "name": "Brazil",
    "flag": "https://upload.wikimedia.org/wikipedia/en/0/05/Flag_of_Brazil.svg",
    "value": "+55"
    },
    {
    "name": "British Indian Ocean Territory",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/6/6e/Flag_of_the_British_Indian_Ocean_Territory.svg",
    "value": "+246"
    },
    {
    "name": "Brunei Darussalam",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Brunei.svg",
    "value": "+673"
    },
    {
    "name": "Bulgaria",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Bulgaria.svg",
    "value": "+359"
    },
    {
    "name": "Burkina Faso",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Burkina_Faso.svg",
    "value": "+226"
    },
    {
    "name": "Burundi",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/5/50/Flag_of_Burundi.svg",
    "value": "+257"
    },
    {
    "name": "Cambodia",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_Cambodia.svg",
    "value": "+855"
    },
    {
    "name": "Cameroon",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/4/4f/Flag_of_Cameroon.svg",
    "value": "+237"
    },
    {
    "name": "Canada",
    "flag": "https://upload.wikimedia.org/wikipedia/en/c/cf/Flag_of_Canada.svg",
    "value": "+1"
    },
    {
    "name": "Cape Verde",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/3/38/Flag_of_Cape_Verde.svg",
    "value": "+238"
    },
    {
    "name": "Cayman Islands",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_the_Cayman_Islands.svg",
    "value": "+1345"
    },
    {
    "name": "Central African Republic",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/6/6f/Flag_of_the_Central_African_Republic.svg",
    "value": "+236"
    },
    {
    "name": "Chad",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/4/4b/Flag_of_Chad.svg",
    "value": "+235"
    },
    {
    "name": "Chile",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/7/78/Flag_of_Chile.svg",
    "value": "+56"
    },
    {
    "name": "China",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_the_People%27s_Republic_of_China.svg",
    "value": "+86"
    },
    {
    "name": "Christmas Island",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/6/67/Flag_of_Christmas_Island.svg",
    "value": "+61"
    },
    {
    "name": "Cocos (Keeling) Islands",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/7/74/Flag_of_the_Cocos_%28Keeling%29_Islands.svg",
    "value": "+61"
    },
    {
    "name": "Colombia",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_Colombia.svg",
    "value": "+57"
    },
    {
    "name": "Comoros",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/9/94/Flag_of_the_Comoros.svg",
    "value": "+269"
    },
    {
    "name": "Congo",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/9/92/Flag_of_the_Republic_of_the_Congo.svg",
    "value": "+242"
    },
    {
    "name": "Cook Islands",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/3/35/Flag_of_the_Cook_Islands.svg",
    "value": "+682"
    },
    {
    "name": "Costa Rica",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Costa_Rica_%28state%29.svg",
    "value": "+506"
    },
    {
    "name": "Croatia",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/1/1b/Flag_of_Croatia.svg",
    "value": "+385"
    },
    {
    "name": "Cuba",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/b/bd/Flag_of_Cuba.svg",
    "value": "+53"
    },
    {
    "name": "Cyprus",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Cyprus.svg",
    "value": "+357"
    },
    {
    "name": "Czech Republic",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_Czech_Republic.svg",
    "value": "+420"
    },
    {
    "name": "Denmark",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Denmark.svg",
    "value": "+45"
    },
    {
    "name": "Djibouti",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/3/34/Flag_of_Djibouti.svg",
    "value": "+253"
    },
    {
    "name": "Dominica",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/c/c4/Flag_of_Dominica.svg",
    "value": "+1 767"
    },
    {
    "name": "Dominican Republic",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_the_Dominican_Republic.svg",
    "value": "+1 849"
    },
    {
    "name": "Ecuador",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/e/e8/Flag_of_Ecuador.svg",
    "value": "+593"
    },
    {
    "name": "Egypt",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Egypt.svg",
    "value": "+20"
    },
    {
    "name": "El Salvador",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/3/34/Flag_of_El_Salvador.svg",
    "value": "+503"
    },
    {
    "name": "Equatorial Guinea",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Equatorial_Guinea.svg",
    "value": "+240"
    },
    {
    "name": "Eritrea",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/2/29/Flag_of_Eritrea.svg",
    "value": "+291"
    },
    {
    "name": "Estonia",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/8/8f/Flag_of_Estonia.svg",
    "value": "+372"
    },
    {
    "name": "Ethiopia",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/7/71/Flag_of_Ethiopia.svg",
    "value": "+251"
    },
    {
    "name": "Falkland Islands (Malvinas)",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_the_Falkland_Islands.svg",
    "value": "+500"
    },
    {
    "name": "Faroe Islands",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/3/3c/Flag_of_the_Faroe_Islands.svg",
    "value": "+298"
    },
    {
    "name": "Fiji",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/b/ba/Flag_of_Fiji.svg",
    "value": "+679"
    },
    {
    "name": "Finland",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Finland.svg",
    "value": "+358"
    },
    {
    "name": "France",
    "flag": "https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg",
    "value": "+33"
    },
    {
    "name": "French Guiana",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/2/29/Flag_of_French_Guiana.svg",
    "value": "+594"
    },
    {
    "name": "French Polynesia",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/d/db/Flag_of_French_Polynesia.svg",
    "value": "+689"
    },
    {
    "name": "Gabon",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/0/04/Flag_of_Gabon.svg",
    "value": "+241"
    },
    {
    "name": "Gambia",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_The_Gambia.svg",
    "value": "+220"
    },
    {
    "name": "Georgia",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_Georgia.svg",
    "value": "+995"
    },
    {
    "name": "Germany",
    "flag": "https://upload.wikimedia.org/wikipedia/en/b/ba/Flag_of_Germany.svg",
    "value": "+49"
    },
    {
    "name": "Ghana",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Ghana.svg",
    "value": "+233"
    },
    {
    "name": "Gibraltar",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/0/02/Flag_of_Gibraltar.svg",
    "value": "+350"
    },
    {
    "name": "Greece",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Greece.svg",
    "value": "+30"
    },
    {
    "name": "Greenland",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/0/09/Flag_of_Greenland.svg",
    "value": "+299"
    },
    {
    "name": "Grenada",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Grenada.svg",
    "value": "+1 473"
    },
    {
    "name": "Guadeloupe",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/0/04/Flag_of_Guadeloupe_%28local%29.svg",
    "value": "+590"
    },
    {
    "name": "Guam",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/0/07/Flag_of_Guam.svg",
    "value": "+1 671"
    },
    {
    "name": "Guatemala",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/e/ec/Flag_of_Guatemala.svg",
    "value": "+502"
    },
    {
    "name": "Guernsey",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_Guernsey.svg",
    "value": "+44"
    },
    {
    "name": "Guinea",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/e/ed/Flag_of_Guinea.svg",
    "value": "+224"
    },
    {
    "name": "Guinea-Bissau",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Guinea-Bissau.svg",
    "value": "+245"
    },
    {
    "name": "Guyana",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/9/99/Flag_of_Guyana.svg",
    "value": "+592"
    },
    {
    "name": "Haiti",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/5/56/Flag_of_Haiti.svg",
    "value": "+509"
    },
    {
    "name": "Heard Island and McDonald Islands",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_Antarctica.svg",
    "value": "+672"
    },
    {
    "name": "Holy See (Vatican City State)",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_the_Vatican_City.svg",
    "value": "+379"
    },
    {
    "name": "Honduras",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/8/82/Flag_of_Honduras.svg",
    "value": "+504"
    },
    {
    "name": "Hong Kong",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/5/5b/Flag_of_Hong_Kong.svg",
    "value": "+852"
    },
    {
    "name": "Hungary",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/c/c1/Flag_of_Hungary.svg",
    "value": "+36"
    },
    {
    "name": "Iceland",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/c/ce/Flag_of_Iceland.svg",
    "value": "+354"
    },
    {
    "name": "India",
    "flag": "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
    "value": "+91"
    },
    {
    "name": "Indonesia",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_Indonesia.svg",
    "value": "+62"
    },
    {
    "name": "Iran",
    "flag": " https://upload.wikimedia.org/wikipedia/commons/c/ca/Flag_of_Iran.svg",
    "value": "+98"
    },
    {
    "name": "Iraq",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/f/f6/Flag_of_Iraq.svg",
    "value": "+964"
    },
    {
    "name": "Ireland",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/4/45/Flag_of_Ireland.svg",
    "value": "+353"
    },
    {
    "name": "Isle of Man",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_the_Isle_of_Man.svg",
    "value": "+44"
    },
    {
    "name": "Israel",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
    "value": "+972"
    },
    {
    "name": "Italy",
    "flag": "https://upload.wikimedia.org/wikipedia/en/0/03/Flag_of_Italy.svg",
    "value": "+39"
    },
    {
    "name": "Jamaica",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/0/0a/Flag_of_Jamaica.svg",
    "value": "+1 876"
    },
    {
    "name": "Japan",
    "flag": "https://upload.wikimedia.org/wikipedia/en/9/9e/Flag_of_Japan.svg",
    "value": "+81"
    },
    {
    "name": "Jersey",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/1/1c/Flag_of_Jersey.svg",
    "value": "+44"
    },
    {
    "name": "Jordan",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/c/c0/Flag_of_Jordan.svg",
    "value": "+962"
    },
    {
    "name": "Kazakhstan",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/d/d3/Flag_of_Kazakhstan.svg",
    "value": "+7"
    },
    {
    "name": "Kenya",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Kenya.svg",
    "value": "+254"
    },
    {
    "name": "Kiribati",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/d/d3/Flag_of_Kiribati.svg",
    "value": "+686"
    },
    {
    "name": "Kuwait",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/a/aa/Flag_of_Kuwait.svg",
    "value": "+965"
    },
    {
    "name": "Kyrgyzstan",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/c/c7/Flag_of_Kyrgyzstan.svg",
    "value": "+996"
    },
    {
    "name": "Lao People's Democratic Republic",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/5/56/Flag_of_Laos.svg",
    "value": "+856"
    },
    {
    "name": "Latvia",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/8/84/Flag_of_Latvia.svg",
    "value": "+371"
    },
    {
    "name": "Lebanon",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/5/59/Flag_of_Lebanon.svg",
    "value": "+961"
    },
    {
    "name": "Lesotho",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/4/4a/Flag_of_Lesotho.svg",
    "value": "+266"
    },
    {
    "name": "Liberia",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/b/b8/Flag_of_Liberia.svg",
    "value": "+231"
    },
    {
    "name": "Liechtenstein",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/4/47/Flag_of_Liechtenstein.svg",
    "value": "+423"
    },
    {
    "name": "Lithuania",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Lithuania.svg",
    "value": "+370"
    },
    {
    "name": "Luxembourg",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/d/da/Flag_of_Luxembourg.svg",
    "value": "+352"
    },
    {
    "name": "Macao",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/6/63/Flag_of_Macau.svg",
    "value": "+853"
    },
    {
    "name": "Madagascar",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Madagascar.svg",
    "value": "+261"
    },
    {
    "name": "Malawi",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/d/d1/Flag_of_Malawi.svg",
    "value": "+265"
    },
    {
    "name": "Malaysia",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/6/66/Flag_of_Malaysia.svg",
    "value": "+60"
    },
    {
    "name": "Maldives",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_Maldives.svg",
    "value": "+960"
    },
    {
    "name": "Mali",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/9/92/Flag_of_Mali.svg",
    "value": "+223"
    },
    {
    "name": "Malta",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/7/73/Flag_of_Malta.svg",
    "value": "+356"
    },
    {
    "name": "Marshall Islands",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/2/2e/Flag_of_the_Marshall_Islands.svg",
    "value": "+692"
    },
    {
    "name": "Martinique",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/5/52/Flag_of_Martinique.svg",
    "value": "+596"
    },
    {
    "name": "Mauritania",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/4/43/Flag_of_Mauritania.svg",
    "value": "+222"
    },
    {
    "name": "Mauritius",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_Mauritius.svg",
    "value": "+230"
    },
    {
    "name": "Mayotte",
    "flag": "https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg",
    "value": "+262"
    },
    {
    "name": "Mexico",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/f/fc/Flag_of_Mexico.svg",
    "value": "+52"
    },
    {
    "name": "Monaco",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/e/ea/Flag_of_Monaco.svg",
    "value": "+377"
    },
    {
    "name": "Mongolia",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/4/4c/Flag_of_Mongolia.svg",
    "value": "+976"
    },
    {
    "name": "Montenegro",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/6/64/Flag_of_Montenegro.svg",
    "value": "+382"
    },
    {
    "name": "Montserrat",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Montserrat.svg",
    "value": "+1664"
    },
    {
    "name": "Morocco",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/2/2c/Flag_of_Morocco.svg",
    "value": "+212"
    },
    {
    "name": "Mozambique",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Mozambique.svg",
    "value": "+258"
    },
    {
    "name": "Myanmar",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/8/8c/Flag_of_Myanmar.svg",
    "value": "+95"
    },
    {
    "name": "Namibia",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_Namibia.svg",
    "value": "+264"
    },
    {
    "name": "Nauru",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/3/30/Flag_of_Nauru.svg",
    "value": "+674"
    },
    {
    "name": "Nepal",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/9/9b/Flag_of_Nepal.svg",
    "value": "+977"
    },
    {
    "name": "Netherlands",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/2/20/Flag_of_the_Netherlands.svg",
    "value": "+31"
    },
    {
    "name": "New Caledonia",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/2/23/Flag_of_New_Caledonia.svg",
    "value": "+687"
    },
    {
    "name": "New Zealand",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/3/3e/Flag_of_New_Zealand.svg",
    "value": "+64"
    },
    {
    "name": "Nicaragua",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Nicaragua.svg",
    "value": "+505"
    },
    {
    "name": "Niger",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/f/f4/Flag_of_Niger.svg",
    "value": "+227"
    },
    {
    "name": "Nigeria",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/7/79/Flag_of_Nigeria.svg",
    "value": "+234"
    },
    {
    "name": "Niue",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Niue.svg",
    "value": "+683"
    },
    {
    "name": "Norfolk Island",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Norfolk_Island.svg",
    "value": "+672"
    },
    {
    "name": "Northern Mariana Islands",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/e/e0/Flag_of_the_Northern_Mariana_Islands.svg",
    "value": "+1 670"
    },
    {
    "name": "Norway",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg",
    "value": "+47"
    },
    {
    "name": "Oman",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/d/dd/Flag_of_Oman.svg",
    "value": "+968"
    },
    {
    "name": "Pakistan",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/3/32/Flag_of_Pakistan.svg",
    "value": "+92"
    },
    {
    "name": "Palau",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Palau.svg",
    "value": "+680"
    },
    {
    "name": "Panama",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/a/ab/Flag_of_Panama.svg",
    "value": "+507"
    },
    {
    "name": "Papua New Guinea",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/e/e3/Flag_of_Papua_New_Guinea.svg",
    "value": "+675"
    },
    {
    "name": "Paraguay",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/2/27/Flag_of_Paraguay.svg",
    "value": "+595"
    },
    {
    "name": "Peru",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/c/cf/Flag_of_Peru.svg",
    "value": "+51"
    },
    {
    "name": "Philippines",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/9/99/Flag_of_the_Philippines.svg",
    "value": "+63"
    },
    {
    "name": "Pitcairn",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_the_Pitcairn_Islands.svg",
    "value": "+870"
    },
    {
    "name": "Poland",
    "flag": "https://upload.wikimedia.org/wikipedia/en/1/12/Flag_of_Poland.svg",
    "value": "+48"
    },
    {
    "name": "Portugal",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Portugal.svg",
    "value": "+351"
    },
    {
    "name": "Puerto Rico",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/2/28/Flag_of_Puerto_Rico.svg",
    "value": "+1 939"
    },
    {
    "name": "Qatar",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_Qatar.svg",
    "value": "+974"
    },
    {
    "name": "Réunion",
    "flag": "https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg",
    "value": "+262"
    },
    {
    "name": "Romania",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/7/73/Flag_of_Romania.svg",
    "value": "+40"
    },
    {
    "name": "Rwanda",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/1/17/Flag_of_Rwanda.svg",
    "value": "+250"
    },
    {
    "name": "Saint Kitts and Nevis",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Saint_Kitts_and_Nevis.svg",
    "value": "+1 869"
    },
    {
    "name": "Saint Lucia",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_Saint_Lucia.svg",
    "value": "+1 758"
    },
    {
    "name": "Saint Pierre and Miquelon",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/7/74/Flag_of_Saint-Pierre_and_Miquelon.svg",
    "value": "+508"
    },
    {
    "name": "Saint Vincent and the Grenadines",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/6/6d/Flag_of_Saint_Vincent_and_the_Grenadines.svg",
    "value": "+1 784"
    },
    {
    "name": "Samoa",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Samoa.svg",
    "value": "+685"
    },
    {
    "name": "San Marino",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/b/b1/Flag_of_San_Marino.svg",
    "value": "+378"
    },
    {
    "name": "Sao Tome and Principe",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/4/4f/Flag_of_Sao_Tome_and_Principe.svg",
    "value": "+239"
    },
    {
    "name": "Saudi Arabia",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/0/0d/Flag_of_Saudi_Arabia.svg",
    "value": "+966"
    },
    {
    "name": "Senegal",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/f/fd/Flag_of_Senegal.svg",
    "value": "+221"
    },
    {
    "name": "Serbia",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/f/ff/Flag_of_Serbia.svg",
    "value": "+381"
    },
    {
    "name": "Seychelles",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/f/fc/Flag_of_Seychelles.svg",
    "value": "+248"
    },
    {
    "name": "Sierra Leone",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/1/17/Flag_of_Sierra_Leone.svg",
    "value": "+232"
    },
    {
    "name": "Singapore",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Singapore.svg",
    "value": "+65"
    },
    {
    "name": "Slovakia",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/e/e6/Flag_of_Slovakia.svg",
    "value": "+421"
    },
    {
    "name": "Slovenia",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/f/f0/Flag_of_Slovenia.svg",
    "value": "+386"
    },
    {
    "name": "Solomon Islands",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/7/74/Flag_of_the_Solomon_Islands.svg",
    "value": "+677"
    },
    {
    "name": "Somalia",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/a/a0/Flag_of_Somalia.svg",
    "value": "+252"
    },
    {
    "name": "South Africa",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/a/af/Flag_of_South_Africa.svg",
    "value": "+27"
    },
    {
    "name": "South Georgia and the South Sandwich Islands",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/e/ed/Flag_of_South_Georgia_and_the_South_Sandwich_Islands.svg",
    "value": "+500"
    },
    {
    "name": "Spain",
    "flag": "https://upload.wikimedia.org/wikipedia/en/9/9a/Flag_of_Spain.svg",
    "value": "+34"
    },
    {
    "name": "Sri Lanka",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Sri_Lanka.svg",
    "value": "+94"
    },
    {
    "name": "Sudan",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Sudan.svg",
    "value": "+249"
    },
    {
    "name": "Suriname",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/6/60/Flag_of_Suriname.svg",
    "value": "+597"
    },
    {
    "name": "Swaziland",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/1/1e/Flag_of_Swaziland.svg",
    "value": "+268"
    },
    {
    "name": "Sweden",
    "flag": "https://upload.wikimedia.org/wikipedia/en/4/4c/Flag_of_Sweden.svg",
    "value": "+46"
    },
    {
    "name": "Switzerland",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/0/08/Flag_of_Switzerland_%28Pantone%29.svg",
    "value": "+41"
    },
    {
    "name": "Syrian Arab Republic",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/5/53/Flag_of_Syria.svg",
    "value": "+963"
    },
    {
    "name": "Taiwan",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/7/72/Flag_of_the_Republic_of_China.svg",
    "value": "+886"
    },
    {
    "name": "Tajikistan",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Tajikistan.svg",
    "value": "+992"
    },
    {
    "name": "Thailand",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/a/a9/Flag_of_Thailand.svg",
    "value": "+66"
    },
    {
    "name": "Timor-Leste",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/2/26/Flag_of_East_Timor.svg",
    "value": "+670"
    },
    {
    "name": "Togo",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/6/68/Flag_of_Togo.svg",
    "value": "+228"
    },
    {
    "name": "Tokelau",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/8/8e/Flag_of_Tokelau.svg",
    "value": "+690"
    },
    {
    "name": "Tonga",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Tonga.svg",
    "value": "+676"
    },
    {
    "name": "Trinidad and Tobago",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/6/64/Flag_of_Trinidad_and_Tobago.svg",
    "value": "+1 868"
    },
    {
    "name": "Tunisia",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/c/ce/Flag_of_Tunisia.svg",
    "value": "+216"
    },
    {
    "name": "Turkey",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/b/b4/Flag_of_Turkey.svg",
    "value": "+90"
    },
    {
    "name": "Turkmenistan",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/1/1b/Flag_of_Turkmenistan.svg",
    "value": "+993"
    },
    {
    "name": "Turks and Caicos Islands",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/a/a0/Flag_of_the_Turks_and_Caicos_Islands.svg",
    "value": "+1 649"
    },
    {
    "name": "Tuvalu",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/3/38/Flag_of_Tuvalu.svg",
    "value": "+688"
    },
    {
    "name": "Uganda",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/4/4e/Flag_of_Uganda.svg",
    "value": "+256"
    },
    {
    "name": "Ukraine",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Ukraine.svg",
    "value": "+380"
    },
    {
    "name": "United Arab Emirates",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_United_Arab_Emirates.svg",
    "value": "+971"
    },
    {
    "name": "United Kingdom",
    "flag": "https://upload.wikimedia.org/wikipedia/en/a/ae/Flag_of_the_United_Kingdom.svg",
    "value": "+44"
    },
    {
    "name": "United States",
    "flag": "https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg",
    "value": "+1"
    },
    {
    "name": "United States Minor Outlying Islands",
    "flag": "https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg",
    "value": "+1581"
    },
    {
    "name": "Uruguay",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Uruguay.svg",
    "value": "+598"
    },
    {
    "name": "Uzbekistan",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/8/84/Flag_of_Uzbekistan.svg",
    "value": "+998"
    },
    {
    "name": "Vanuatu",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Vanuatu.svg",
    "value": "+678"
    },
    {
    "name": "Viet Nam",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_Vietnam.svg",
    "value": "+84"
    },
    {
    "name": "Wallis and Futuna",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/d/d2/Flag_of_Wallis_and_Futuna.svg",
    "value": "+681"
    },
    {
    "name": "Yemen",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/8/89/Flag_of_Yemen.svg",
    "value": "+967"
    },
    {
    "name": "Zambia",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/0/06/Flag_of_Zambia.svg",
    "value": "+260"
    },
    {
    "name": "Zimbabwe",
    "flag": "https://upload.wikimedia.org/wikipedia/commons/6/6a/Flag_of_Zimbabwe.svg",
    "value": "+263"
    }
]