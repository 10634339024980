import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import CardInput from '../Card/CardInput';
import { v4 as uuidv4 } from 'uuid';
import Card from '../Card/Card';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ChatCarousel = ({ addCarousel, data, updateCarousel, remove, OPTIONS }) => {
   useEffect(() => {
      if (data) setcarousel(data.response_elements);
   }, [data]);

   const settings = {
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
   };
   const [carousel, setcarousel] = useState([]);
   const [inputcard, setInputcard] = useState([1]);

   const cardType = card => ({
      response_name: `utter_bot_${uuidv4()}`,
      response_type: 'card',
      response_elements: card,
   });

   const addCard = card => {
      const newcard = cardType(card);
      setcarousel([...carousel, { ...newcard }]);
      setInputcard([]);
      if (!data) addCarousel([...carousel, { ...newcard }]);
      else updateCarousel([...carousel, { ...newcard }], data.response_name);
      setTimeout(() => {
         setInputcard([1]);
      }, 0);
   };

   const removeCard = response_name => {
      const newCarousel = carousel.filter(c => c.response_name !== response_name);
      setcarousel(newCarousel);
      setInputcard([]);

      if (newCarousel.length === 0) remove(data.response_name);
      if (!data) addCarousel(newCarousel);
      else updateCarousel(newCarousel, data.response_name);
      setTimeout(() => {
         setInputcard([1]);
      }, 0);
   };

   const updateCard = updatedCard => {
      const newCarousel = carousel.map(c => (c.response_name === updatedCard.response_name ? { ...updatedCard } : c));
      setcarousel(newCarousel);

      if (!data) addCarousel(newCarousel);
      else updateCarousel(newCarousel, data.response_name);
   };

   const duplicateCard = response_name => {};

   return (
      <div
         className="chat-carousel"
         style={{
            position: 'relative',
            marginTop: '10px',
            width: '100%',
         }}>
         <Slider {...settings}>
            {carousel.map(c => (
               <Card
                  key={JSON.stringify(c.card)}
                  data={c}
                  carousel={'Carousel'}
                  remove={removeCard}
                  value={c}
                  update={updateCard}
                  duplicate={duplicateCard}
                  OPTIONS={OPTIONS}
               />
            ))}
            {inputcard.map((c, i) => (
               <div key={i}>
                  <CardInput addCard={addCard} carousel={'Carousel'} OPTIONS={OPTIONS} />

                  {
                     // carousel.length === 0 && inputcard.length === 1
                     (inputcard.length === 1 || i === inputcard.length - 1) && (
                        <span className="_add-card_">
                           <img
                              src="/images/carouseladdcard.svg"
                              className="Group-52"
                              width="58px"
                              onClick={() => setInputcard([...inputcard, 1])}
                              alt="addnew"
                              style={{
                                 position: 'absolute',
                                 // top:
                                 //    i === inputcard.length - 1 && inputcard.length !== 1 && carousel.length !== 0
                                 //       ? '250px'
                                 //       : '213px',
                                 top: '250px',
                                 right: '30px',
                              }}
                           />
                           <p
                              style={{
                                 fontSize: '10px',
                                 position: 'absolute',
                                 // top: i === inputcard.length - 1 && inputcard.length !== 1 ? '280px' : '244px',
                                 top: '280px',
                                 right: '22px',
                                 fontWeight: 'bold',
                              }}>
                              Add Card
                           </p>
                        </span>
                     )
                  }
                  {inputcard.length > 1 && (
                     <img
                        src="/images/close.svg"
                        alt="remove"
                        width="12px"
                        style={{
                           position: 'absolute',
                           top: '46px',
                           right: '95px',
                           zIndex: 0,
                        }}
                        onClick={() => setInputcard(inputcard.filter((c, i) => i === inputcard.length - 1))}
                     />
                  )}
               </div>
            ))}
         </Slider>
      </div>
   );
};

export default ChatCarousel;
