import React, { useEffect, useState } from "react"
import back from "../../../assets/images/icons_back-outline-grey-32.svg"
import Button from "../../Button/Button"

const Details = (props) => {

    const [title, settitle] = useState("")

    useEffect(() => {
        switch (props.current) {
            case 1: settitle("How to create a Bot")
                break;
            case 2: settitle("Story Creation")
                break;
            case 3: settitle("Intents")
                break;
            case 4: settitle("Custom Intents")
                break;
            case 5: settitle("Utterances")
                break;
        }
    }, [props.current])

    return (
        <div className="details-parent">
            <img src={back} onClick={props.onBack} />
            <div className="title">Documentation</div>
            <div className="heading">
                {title}
            </div>
            <div className="description">
                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.
            </div>
            <div className="heading2">
                Steps:
            </div>
            <div className="description">
                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.
            </div>
            <div className="steps">
                <div className="step">
                    <div className="point"></div>
                    <div className="text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </div>
                </div>
                <div className="step">
                    <div className="point"></div>
                    <div className="text">
                        Duis fermentum mauris in felis vehicula, eget rutrum ante imperdiet.
                    </div>
                </div>
                <div className="step">
                    <div className="point"></div>
                    <div className="text">
                        Nulla a risus nec ligula pharetra consequat vel et risus.
                    </div>
                </div>
                <div className="step">
                    <div className="point"></div>
                    <div className="text">
                        Suspendisse eget metus in lorem lobortis commodo a non neque.
                    </div>
                </div>
                <div className="step">
                    <div className="point"></div>
                    <div className="text">
                        Mauris consectetur dui molestie vehicula tempor.
                    </div>
                </div>
            </div>
            <div className="button">
                <Button className="button-primary blue" onClick={props.onNext}>
                    {props.current === 5 ? "Finish" : "Next Topic"}
                </Button>
            </div>

        </div>
    )
}

export default Details