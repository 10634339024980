import axios from 'axios';

// const Production = process.env.REACT_APP_PRODUCTION_API;
// const Stagging = process.env.REACT_APP_STAGGING_API;

const url = 'https://chatbot.bluelogic.ai';
// const url = "https://buildertest.bluelogic.ai"

export const live_agent = url;

const Stagging = `${url}/api`;
export default axios.create({
   baseURL: Stagging,
   // baseURL: Production,
   headers: {
      'Content-Type': 'application/json',
   },
});
