import React from 'react';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

import styles from '../Diagram/chatbot.module.scss';

const LightTooltip = withStyles(() => ({
   tooltip: {
      backgroundColor: 'white',
      color: '#444444',
      padding: 10,
   },
   arrow: {
      color: 'white',
   },
}))(Tooltip);

export const SortableItem = sortableElement(({ c: sortable }) => (
   <li className={styles.sortableList}>
      <DragHandle />
      {sortable()}
   </li>
));

export const DragHandle = sortableHandle(() => (
   <LightTooltip title="Drag me!" placement="right" arrow>
      <span className={styles.dragbtn}></span>
   </LightTooltip>
));

export const SortableContainer = sortableContainer(({ children }) => {
   return <ul>{children}</ul>;
});
