import React, { useState, useEffect, useRef } from 'react';
import TableChat from '../Table/TableChat';
import da from '../../assets/images/dropdown-arrow.svg';
import { chat_transcripts } from './chat_transcript';
import Api from '../../axios/request';
import NoMessage from '../../pages/LiveAgent/NoMessage/NoMessage';

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { months } from '../../utils/Utility';
import { useSelector } from 'react-redux';
import { LogOut } from '../../redux/actions/profile/index';
import { useHistory } from 'react-router-dom';

const ChatScripts = props => {
   const [date, setdate] = useState({ startDate: null, endDate: null });
   const [focusedInput, setfocusedInput] = useState(null);
   const [nomsg, setnomsg] = useState(true);
   const [containingtext, setct] = useState('');
   const [chattranscripts, setChatTrans] = useState([]);
   const tk = JSON.parse(localStorage.getItem('user')).token;
   const bot_name = useSelector(state => state.storybuilder.bot_name);
   const history = useHistory();
   const picker = useRef(null);

   useEffect(() => {
      if (bot_name !== null) {
         const end_date = new Date();
         const start_date = new Date(end_date);
         start_date.setDate(start_date.getDate() - 7);

         setdate({
            startDate: months[start_date.getMonth()] + ' ' + start_date.getDate() + ', ' + start_date.getFullYear(),
            endDate: months[end_date.getMonth()] + ' ' + end_date.getDate() + ', ' + end_date.getFullYear(),
         });
         picker.current.setStartDate(
            start_date.getMonth() + 1 + '/' + start_date.getDate() + '/' + start_date.getFullYear()
         );
         picker.current.setEndDate(end_date.getMonth() + 1 + '/' + end_date.getDate() + '/' + end_date.getFullYear());

         // alert(props.state.containingtext)
         if (props.state.containingtext != undefined) {
            if (props.state.containingtext.trim().length !== 0) {
               console.log('isnide trim');
               const apiDate = new Date(props.startDateApi?.first_convo_timestamp);
               picker.current.setStartDate(
                  apiDate.getMonth() + 1 + '/' + apiDate.getDate() + '/' + apiDate.getFullYear()
               );

               ApiCall(
                  props.startDateApi?.first_convo_timestamp,
                  end_date.getTime(),
                  bot_name,
                  props.state.containingtext ? props.state.containingtext : ''
               );
               setct('');
            } else {
               console.log('else trim');
               ApiCall(
                  start_date.getTime(),
                  end_date.getTime(),
                  bot_name,
                  props.state.containingtext ? props.state.containingtext : ''
               );
            }
         } else {
            ApiCall(
               start_date.getTime(),
               end_date.getTime(),
               bot_name,
               props.state.containingtext ? props.state.containingtext : ''
            );
         }

         // ApiCall(start_date.getTime(), end_date.getTime(), bot_name, props.state.containingtext ? props.state.containingtext : '')
      }
   }, [props.startDateApi?.first_convo_timestamp]);

   useEffect(() => {
      if (chattranscripts.length !== 0) {
         setnomsg(false);
      } else {
         setnomsg(true);
      }
   }, [chattranscripts]);

   const ApiCall = (start_date, end_date, bot_name, containingtext) => {
      const params = new URLSearchParams({
         bot_name: bot_name,
         start_date: start_date,
         end_date: end_date,
         contains_text: containingtext,
      }).toString();

      Api.post('/analytics/chat-transcript-final?' + params, '', {
         headers: {
            Authorization: 'Token ' + tk,
         },
      })
         .then(res => {
            if (res.status === 403) {
               return LogOut(history);
            }
            setChatTrans(res.data);
         })
         .catch(error => {
            console.log(error);
         });
   };

   const onApply = (start, end) => {
      var date1 = new Date(parseInt(start));
      var date2 = new Date(parseInt(end));
      setdate({
         startDate: months[date1.getMonth()] + ' ' + date1.getDate() + ', ' + date1.getFullYear(),
         endDate: months[date2.getMonth()] + ' ' + date2.getDate() + ', ' + date2.getFullYear(),
      });
      // setdate({ startDate: parseInt(start.substring(0, 13)), endDate: parseInt(end.substring(0, 13)) })
      ApiCall(parseInt(start.substring(0, 13)), parseInt(end.substring(0, 13)), bot_name, '');
   };

   const Sort = type => {
      var scripts = [...chattranscripts];

      scripts = [...scripts].sort((a, b) => b.message_in - a.message_in);

      switch (type) {
         case '1':
            setChatTrans([...scripts].sort((a, b) => b.start_time - a.start_time));
            break;
         case '2':
            setChatTrans([...scripts].sort((a, b) => b.total_time - a.total_time));
            break;
         case '3':
            setChatTrans([...scripts].sort((a, b) => b.message_in - a.message_in));
            break;
         case '4':
            setChatTrans([...scripts].sort((a, b) => b.message_out - a.message_out));
            break;
      }
   };

   const Submit = () => {
      if (containingtext.trim().length !== 0) {
         console.log('isnide');
         ApiCall('1609487511000', '1893484311000', bot_name, containingtext);
         setct('');
      } else {
         ApiCall(date.startDate, date.endDate, bot_name, '');
      }
   };

   return (
      <div className="chatscripts-parent">
         <div>
            <div class="f-buttons">
               <div className="d-one" style={{ marginLeft: 10 }}>
                  <input
                     placeholder="Containing Text"
                     value={containingtext}
                     onChange={e => {
                        setct(e.currentTarget.value);
                     }}></input>
               </div>

               <div className="d-one">
                  <div className="head">Sort By</div>
                  <img className="select-arrow" src={da} />
                  <select
                     className="dropdown"
                     onChange={e => {
                        Sort(e.currentTarget.value);
                     }}>
                     <option value="1" selected>
                        Start Time
                     </option>
                     <option value="2">Duration</option>
                     <option value="3">Messages In</option>
                     <option value="4">Messages Out</option>
                  </select>
               </div>

               <div className="d-two">
                  <div className="head">Select Date Range</div>

                  <img className="select-arrow date" src={da} />
                  <DateRangePicker
                     ref={picker}
                     initialSettings={{
                        /*  startDate: '01/01/2020', endDate: '01/15/2020' */
                        maxDate: new Date(),
                     }}
                     onCallback={(start, end, label) => {
                        onApply(start + '', end + '');
                     }}>
                     <input type="text" />
                     {/* <input type="text" placeholder="Containing Text"></input> */}
                  </DateRangePicker>
               </div>

               <div className="d-one">
                  <div className="bt" onClick={Submit}>
                     Submit
                  </div>
               </div>
            </div>

            {!nomsg ? (
               <TableChat
                  data={chattranscripts}
                  onClick={data => {
                     props.onClick(data);
                  }}
               />
            ) : (
               <NoMessage />
            )}
         </div>
      </div>
   );
};

export default ChatScripts;
