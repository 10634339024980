import React, { useState } from 'react';
import Input from '../../components/inputWithIcon/inputWithIcon';
import { withRouter } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import ImageTag from '../../components/ImageTag/ImageTag';
import Alert from '../../components/Alert/Alert';

const SendCode = props => {
   const [showPass, setShowPass] = useState(false);
   const [showPass2, setShowPass2] = useState(false);
   const [password, setPassword] = useState('');
   const [password2, setPassword2] = useState('');
   const [loader, setLoader] = useState(false);
   const [shoModal, setModalShow] = useState(false);
   const [alertContent, setAlertContent] = useState({
      error: '',
      message: '',
   });

   const state = props.location.state;
   console.log(state, ' saodjasopjdpo');
   if (!state) {
      // props.history.push("/forget");
   }
   const submit = () => {
      setLoader(true);
      if (password === password2) {
         fetch(state.url, {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
            },
            body: JSON.stringify({
               password: password,
            }),
         })
            .then(res => {
               return res.json();
            })
            .then(res => {
               console.log(res, ' eeerrr');
               setModalShow(true);
               setAlertContent({
                  error: 'Success',
                  message: res.message,
               });
               setLoader(false);
            })
            .catch(err => {
               console.log(err);
               setLoader(false);
               setModalShow(true);
               setAlertContent({
                  error: 'Error',
                  message: 'Something went wrong. Please try again.',
               });
            });
      } else {
         setLoader(false);
         setModalShow(true);
         setAlertContent({
            error: 'Error',
            message: "Password doesn't match.",
         });
      }
   };
   return (
      <div className="contaier-fluid sendCode">
         <Alert
            show={shoModal}
            onHide={() => setModalShow(false)}
            message={alertContent.message}
            Error={alertContent.error}
         />
         <div className="container">
            <div className="row">
               <div className="col-1g-12 col-md-12 col-sm-12 col-12">
                  <ImageTag
                     className="mt-3"
                     src={require('./assets/logo.svg')}
                     onClick={() => props.history.push('/signin')}
                  />
               </div>
            </div>
         </div>
         <div className="container">
            <div className="row">
               <div className="col-1g-12 col-md-12 col-sm-12 col-12 mt-5 Conent-Box">
                  <div className="logo-reset">
                     <ImageTag src={require('./assets/logo2.svg')} />
                  </div>
                  <p className="mt-3 medium reset-head m-b-28">Reset Password</p>
                  <div className="input-div">
                     <Input
                        onChange={x => setPassword(x)}
                        secure={showPass}
                        placeholder={'Enter new password'}
                        margin={true}
                        onClick={() => setShowPass(!showPass)}
                        type={showPass ? 'text' : 'password'}
                        marginBottom={28}
                        showIcon={true}
                     />
                  </div>
                  <div className="input-div">
                     <Input
                        onChange={x => setPassword2(x)}
                        secure={showPass2}
                        placeholder={'Re-enter new password'}
                        margin={true}
                        onClick={() => setShowPass2(!showPass2)}
                        type={showPass2 ? 'text' : 'password'}
                        showIcon={true}
                        marginBottom={28}
                     />
                  </div>

                  <div className="input-div text-center">
                     <div onClick={submit} className="button">
                        {loader ? (
                           <Spinner size={'sm'} animation="border" role="status"></Spinner>
                        ) : (
                           <p className="regular s-b">Reset Password</p>
                        )}
                     </div>
                     <p className="medium g-t-l">
                        Go Back To{' '}
                        <span
                           onClick={() => props.history.push('/signin')}
                           style={{ color: '#43b3e6', cursor: 'pointer' }}>
                           Log In
                        </span>
                     </p>
                  </div>
               </div>
            </div>
         </div>
         <div className="container">
            <p className="Copyright-Text regular">© 2020 All Rights Reserved Blue Logic</p>
         </div>
      </div>
   );
};
export default withRouter(SendCode);
