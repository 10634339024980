import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import ImageTag from "../ImageTag/ImageTag";
import { SHOWHINTBOOL, CHANGECOUNT } from "../../redux/actions/hint";
import { OverlayTrigger } from "react-bootstrap";
import { useSelector, useDispatch, connect } from "react-redux";
import Tooltip from "../tootip/tootip";
import Axios from "../../utils/Request";
const ViewAll = (props) => {
   useEffect(()=>{
      props.tooltipCounter(2)              
   },[])
   const history = useHistory();
   const hint = useSelector(state => state.hint);
   const dispatch = useDispatch();

   useEffect(() => {
      dispatch(CHANGECOUNT(2));
   }, [dispatch]);

   // useEffect(() => {
   // }, [props.hint.hint_count]);

   const handleUpdateHint = (token)=>{
      Axios('/accounts/updateHint/',{
         method:"POST",
         headers: {
            Authorization: `Token ${token}`
         },
         body:JSON.stringify({
            hint: false
         })
      }).then((res)=>{
         console.log(res, ' update res')
      })
      .catch((err)=>{
         console.log(err, ' update error')
      })
   }

   return (
         <div className="view-container">
            <div id="Card2" style={{zIndex:props.hint.hint_count == 2 ? 200 : 0}} className="Card2">
               <div className="cardHeading">
                  <div className="cardimg">
                     <ImageTag src={require("./assets/icon.svg")} />
                  </div>
               </div>
               <OverlayTrigger placement={"top"} show={props.hint.hint_count == 2 && props.hint.showHint} trigger="click" overlay={()=><Tooltip 
                  heading="Create a Bot"
                  paragraph="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
                  onBackPress={()=>{
                     props.history.push({
                        pathname: '/',
                        state: {
                          showSplash: false
                        }
                      });
                  }}
                  onClosePress={() => {
                     props.isVisivleTooltip(false);
                     const user = localStorage.getItem("user"); 
                     let usr = {...JSON.parse(user)}
                     usr.hint = false;
                     console.log(usr, ' asdasdasda')
                     localStorage.setItem("user", JSON.stringify(usr));
                     handleUpdateHint(usr.token)
                  }}
                  onNextPress={()=>{
                     props.history.push("/creatbot");
                     props.tooltipCounter(3)
                  }}
                  circles={[1,2,3,4]}
                  hintCount={props.hint.hint_count}
                  height={230}
                  back={true}
                  class="viewAll-tooltip"/>}>
                     <button class="cardBtn">Create a Bot</button>
               </OverlayTrigger>
            </div>
         <div className="Card2">
            <div className="cardHeading">
               <div className="cardimg">
                  <ImageTag src={require("./assets/icon.svg")} />
               </div>
            </div>
            <button class="cardBtn">Select from template</button>
         </div>
         <h2>Recently used bots</h2>
      </div>
   );
};
const mapStateToProps = ({ hint }) => ({ hint });

const mapDispatchToProps = dispatch => ({
   isVisivleTooltip: data => dispatch(SHOWHINTBOOL(data)),
   tooltipCounter: data => dispatch(CHANGECOUNT(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ViewAll);

