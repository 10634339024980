import React, { useEffect, useState } from "react";

const chatTime = ({text}) =>{
    return(
        <div className="time-parant">
            <p className="medium text">{text}</p>
        </div>
    )
}

export default chatTime;