import React, { useState } from "react"
import { Table } from "react-bootstrap"
import { months, days } from "../../utils/Utility"



const TableChat = (props) => {

    console.log("table", props.data)

    const data = [{}, {}, {}, {}, {}]
    const [page, setpage] = useState(1)


    const TimestampToMinutes = (timestamp) => {
        var date = new Date(timestamp)
        let minute = date.getMinutes();
        if (minute < 10) {
            minute = "0" + minute;
        }
        return days[date.getDay()] + ", " + months[date.getMonth()] + " " + date.getDate() + "," + date.getFullYear() + " " + date.getHours() + ":" + minute
    }

    const millisToMinutesAndSeconds = (millis) => {
        var time = ""
        var minutes = Math.floor(millis / 60000);
        var seconds = ((millis % 60000) / 1000).toFixed(0);

        return minutes + " minute " + (seconds < 10 ? '0' : '') + seconds + " seconds";
    }




    return (
        <div className="table-parent">
            <div style={{
                margin: '24px 0px',
                padding: 30,
                //outline: '1px solid #eeeeee',
                //MozOutlineRadius: "5px",
                //outlineOffset: '0rem',
                border: "1px solid #eeeeee",
                borderRadius: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>

                <Table striped hover style={{
                    //margin: '0px 10px',
                    //width: '91%',
                    //marginTop: '3rem',
                    //marginBottom: '2rem', // just for space
                    borderTopLeftRadius: '1rem',
                    borderTopRightRadius: '1rem',
                    overflow: 'hidden',
                }}>
                    <thead>
                        <tr style={{
                            backgroundColor: '#43b3e6',
                            color: '#fff'
                        }}>
                            <th style={{ paddingLeft: 20 }}>Starting Time</th>
                            <th style={{ textAlign: "center" }}>Session Info</th>
                            <th style={{ textAlign: "center" }}>Duration</th>
                            <th style={{ textAlign: "center" }}>Messages In</th>
                            <th style={{ textAlign: "center" }}> Messages Out</th>
                        </tr>
                    </thead>
                    <header1 />
                    <tbody>
                        {props.data.map((item) => {
                            return (
                                <tr onClick={() => { props.onClick(item) }}>
                                    <td style={{
                                        minWidth: '10rem'
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            paddingLeft: 8
                                        }}>
                                            <div style={{
                                                backgroundColor: '#43b3e6',
                                                borderRadius: '50%',
                                                width: 35,
                                                height: 35,
                                                marginRight: '1rem'
                                            }}><span style={{
                                                display: 'flex',
                                                width: 35,
                                                height: '100%',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                fontSize: 18,
                                                color: '#fff',
                                                backgroundColor: "#4a4cab",
                                                borderRadius: 30
                                            }}>
                                                    {TimestampToMinutes(item.start_time).charAt(0)}
                                                </span>
                                            </div>
                                            {TimestampToMinutes(item.start_time)}
                                        </div></td>
                                    <td className="text-center">{item.chat_id}</td>
                                    <td className="text-center">{millisToMinutesAndSeconds(item.total_time)}</td>
                                    <td className="text-center">{item.message_in}</td>
                                    <td className="text-center">{item.message_out}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>

            {/*pagination code */}
            {/* <div className="pagination">
                <div className="page-cont">
                    <div className="p-button" onClick={() => { setpage(page - 1) }}>
                        <img src={Left} style={{ marginRight: 10 }} />
                 Prev
              </div>
                    <div className={useStyles().root}>
                        <Pagination count={10} shape="rounded" page={page} hideNextButton={true} hidePrevButton={true} />
                    </div>
                    <div className="p-button" onClick={() => { setpage(page + 1) }}>
                        Next
                <img src={Right} style={{ marginLeft: 10 }} />
                    </div>
                </div>
            </div> */}
        </div>

    )
}

export default TableChat
