import React, { useState, useEffect, useContext } from 'react';
import MainText from '../../MainText/MainText';
import ImageUpload from '../ImageUpload/ImageUpload';
import { LanguageContext } from '../ChatBot';

function ChatImage({ data, remove, duplicate, update, addImg }) {
   const { language } = useContext(LanguageContext);
   useEffect(() => {
      setshowPopUp(false);
      if (data) seturl({ ...data.response_elements });
   }, [data, language]);

   const [url, seturl] = useState({ en: '', ar: '' });
   const [showPopUp, setshowPopUp] = useState(false);

   const handleUpdate = () => {
      setshowPopUp(true);
   };

   const getEmergencyFoundImg = urlImg => {
      var img = new Image();
      img.src = urlImg;
      img.crossOrigin = 'Anonymous';

      var canvas = document.createElement('canvas'),
         ctx = canvas.getContext('2d');

      canvas.height = img.naturalHeight;
      canvas.width = img.naturalWidth;
      ctx.drawImage(img, 0, 0);

      var b64 = canvas.toDataURL('image/png').replace(/^data:image.+;base64,/, '');
      return b64;
   };

   const onImageChange = base64 => {
      if (!data) addImg({ ...url, [language]: base64 });
      else update({ ...data, response_elements: { ...url, [language]: base64 } });
      setshowPopUp(false);
   };

   const handleSubmit = () => {
      if (url === '') return;
      if (!data) addImg({ ...url });
      else update({ ...data, response_elements: { ...url } });
      setshowPopUp(false);
   };

   const handleUrl = e => {
      seturl({ ...url, [language]: e.target.value });
   };

   return (
      <div
         className="card-input-container"
         style={{
            position: 'relative',
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            marginBottom: '50px',
         }}>
         <div
            style={{
               width: '376px',
               display: 'flex',
               justifyContent: language === 'ar' ? 'flex-end' : 'flex-start',
            }}>
            <MainText className="Image-main-text">
               <span className="montserrat">{language === 'ar' ? 'ةروص' : 'Image'}</span>
            </MainText>
         </div>
         <div
            className="image__container"
            style={{
               height: (!data || !data.response_elements[language]) && '150px',
            }}>
            <span
               style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  height: '100%',
               }}>
               <img
                  src={
                     data && data.response_elements[language] ? data.response_elements[language] : '/images/cardadd.svg'
                  }
                  width={data && '50%'}
                  alt="chatImage"
                  onClick={() => (!data || !data.response_elements[language]) && handleUpdate()}
                  style={{ borderRadius: '10px' }}
               />
               {(!data || !data.response_elements[language]) && (
                  <p className="Add-Image" style={{ fontSize: '12px', marginTop: '6px', fontWeight: 'bold' }}>
                     {language === 'en' ? 'Add Image' : 'ةروص فضأ'}
                  </p>
               )}
            </span>
            {data && (
               <div className="__edit-icons__" style={{ left: language === 'ar' && '248px' }}>
                  <img src="/images/copy.svg" width="23px" alt="copy" onClick={() => duplicate(data.response_name)} />
                  <img
                     src="/images/delete.svg"
                     width="23px"
                     style={{ margin: '5px' }}
                     alt="delete"
                     onClick={() => remove(data.response_name)}
                  />
                  <img src="/images/edit.svg" alt="edit" width="23px" onClick={handleUpdate} />
               </div>
            )}
         </div>
         {showPopUp && (
            <ImageUpload
               setshowPopUp={setshowPopUp}
               handleSubmit={handleSubmit}
               handleUpdate={handleUpdate}
               handleUrl={handleUrl}
               onImageChange={onImageChange}
               language={language}
               type={'image'}
            />
         )}
      </div>
   );
}

export default ChatImage;
