import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

function Button({ addNewCard, data, language, setshowPopUp, currentIndex, OPTIONS }) {
   useEffect(() => {
      if (data && data.response_elements[language].buttons[currentIndex]) {
         setbuttonValue({
            title: data.response_elements[language].buttons[currentIndex].title,
            type: data.response_elements[language].buttons[currentIndex].type,
            payload: data.response_elements[language].buttons[currentIndex].payload,
         });
      }
   }, [data, currentIndex, language]);

   const [buttonValue, setbuttonValue] = useState({
      title: '',
      type: '',
      payload: '',
   });
   const [errors, seterrors] = useState({ title: '', type: '', payload: '' });

   const handleFormChange = e => {
      const name = e.target.name;
      const value = e.target.value;
      if (value.length > 0) seterrors({ ...errors, [name]: '' });
      if (value.length === 0) seterrors({ ...errors, [name]: 'Required' });
      setbuttonValue({ ...buttonValue, [name]: value });
   };

   const validateForm = () => {
      let formerrors = { title: '', type: '', payload: '' };
      if (buttonValue.title === '') formerrors = { ...formerrors, title: 'required' };
      if (buttonValue.type === '') formerrors = { ...formerrors, type: 'required' };
      if (buttonValue.payload === '') formerrors = { ...formerrors, payload: 'required' };
      seterrors(formerrors);
   };

   return (
      <motion.div
         initial={{ y: '-200px', opacity: 0.2 }}
         animate={{ y: '-380px', opacity: 1 }}
         transition={{ duration: 0.5 }}>
         <div
            className="chatbot-Image-container"
            style={{
               marginBottom: '80px',
               zIndex: 99,
            }}>
            <section
               style={{
                  position: 'absolute',
                  top: '154px',
                  left: '19px',
                  width: '87%',
                  backgroundColor: 'white',
                  // boxShadow: 'black 1px 0px 16px 0px',
                  borderRadius: '10px 10px 10px 10px',
                  overflow: 'hidden',
                  // height: '85%',
                  zIndex: 99,
               }}>
               <div
                  className="upload-image-header"
                  style={{
                     color: 'white',
                     display: 'flex',
                     justifyContent: 'space-between',
                  }}>
                  <span>{language === 'en' ? 'Create Button' : 'إنشاء زر'}</span>
                  <span
                     onClick={() => {
                        const arrowprev = document.querySelector('.chat-carousel .slick-prev');
                        const arrownext = document.querySelector('.chat-carousel .slick-next');
                        const addcard = document.querySelector('._add-card_');
                        if (arrowprev) {
                           arrowprev.style['z-index'] = 1;
                           arrownext.style['z-index'] = 1;
                        }
                        if (addcard) {
                           addcard.style.position = 'static';
                        }
                        setshowPopUp(false);
                     }}>
                     <img src="/images/close.svg" alt="remove" width="18px" />
                  </span>
               </div>
               <div style={{ color: 'lightgray', padding: '2px 10px' }} dir={language === 'ar' && 'rtl'}>
                  <div style={{ margin: '6px 2px' }}>
                     <input
                        className={`text-message-input montserrat ${errors.title && 'error'}`}
                        type="text"
                        name="title"
                        placeholder={language === 'en' ? 'Enter button Title' : 'أدخل عنوان الزر'}
                        value={buttonValue.title}
                        onChange={e => handleFormChange(e)}
                        required
                        style={{
                           borderColor: '#f6f8fc',
                        }}
                     />
                  </div>
                  <div style={{ margin: '6px 2px' }}>
                     <select
                        style={{
                           width: '100%',
                           height: '46px',
                           border: 'none',
                           borderBottom: errors.type ? '1px solid# c4302b' : '1px solid grey',
                           borderColor: '#f6f8fc',
                           fontSize: '14px',
                           color: '#666666',
                        }}
                        name="type"
                        value={buttonValue.type}
                        onChange={e => handleFormChange(e)}
                        required>
                        <option value="">{language === 'en' ? 'Select button type' : 'حدد نوع الزر'}</option>
                        <option value="solid button">{language === 'en' ? 'Solid Button' : 'زر صلب'}</option>
                        <option value="primary button">{language === 'en' ? 'Primary Button' : 'الزر الأساسي'}</option>
                     </select>
                  </div>
                  <div style={{ margin: '6px 2px' }}>
                     {/* <input
                        className="text-message-input"
                        type="text"
                        name="payload"
                        placeholder={language === 'en' ? 'Enter post back Value' : 'أدخل قيمة إعادة النشر'}
                        value={buttonValue.payload}
                        onChange={e => handleFormChange(e)}
                        style={{
                           borderColor: '#f6f8fc',
                        }}
                     /> */}

                     <select
                        style={{
                           width: '100%',
                           height: '46px',
                           border: 'none',
                           borderBottom: errors.payload ? '1px solid #c4302b' : '1px solid grey',
                           borderColor: '#f6f8fc',
                           fontSize: '14px',
                           color: '#666666',
                        }}
                        name="payload"
                        value={buttonValue.payload}
                        onChange={e => handleFormChange(e)}
                        required>
                        <option value="">{language === 'en' ? 'Select payload' : 'حدد نوع الزر'}</option>
                        {OPTIONS.map(op => (
                           <option value={op.value}>{op.label}</option>
                        ))}
                     </select>
                     {/* <Select
                        style={{
                           width: '100%',
                           height: '46px',
                           border: 'none',
                           borderBottom: '1px solid grey',
                           borderColor: '#f6f8fc',
                           fontSize: '4px',
                           color: '#666666',
                        }}
                        // className="select-box"
                        value={buttonValue.payload && buttonValue.payload}
                        options={OPTIONS}
                        onChange={value => {
                           setbuttonValue({ ...buttonValue, payload: value.value });
                        }}
                     />*/}
                  </div>
               </div>
               <button
                  className="button"
                  style={{
                     color: 'white',
                     // width: '30%',
                     border: 'none',
                     margin: '10px',
                     float: 'right',
                     width: '85px',
                     height: '41px',
                     borderRadius: '100px',
                     backgroundColor: '#43b3e6',
                  }}
                  onClick={() => {
                     if (buttonValue.title === '' || buttonValue.type === '' || buttonValue.payload === '')
                        return validateForm();
                     const arrowprev = document.querySelector('.chat-carousel .slick-prev');
                     const arrownext = document.querySelector('.chat-carousel .slick-next');
                     if (arrowprev) {
                        arrowprev.style['z-index'] = 1;
                        arrownext.style['z-index'] = 1;
                     }
                     addNewCard(buttonValue, currentIndex);
                  }}>
                  {language === 'en' ? 'Save' : 'حفظ'}
               </button>
            </section>
         </div>
      </motion.div>
   );
}

export default Button;
