import React, { useEffect } from 'react';
import { Popover } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ImageTag from '../ImageTag/ImageTag';

const PopOver = props => {
   const { allAlerts } = useSelector(state => state.users);
   const history = useHistory();
   return (
      <Popover className={props.class ? props.class : ''} id="popover-basic">
         <Popover.Title className="notif-bg-header">
            <p style={{ color: '#fff' }} className="bold t-12">
               Notifications
            </p>
         </Popover.Title>
         <Popover.Content style={{ padding: 0 }}>
            <div>
               <div className="NotificationCard">
                  {allAlerts.length > 0 ? (
                     allAlerts.map((res, i) => {
                        return (
                           <div
                              style={{
                                 borderBottom: i + 1 == props.data.length ? 'none' : 'solid',
                                 marginBottom: i + 1 == props.data.length ? 3 : 0,
                              }}
                              onClick={() => {
                                 history.push('/liveagent');
                              }}
                              className="InnerCard">
                              <div className="logo">
                                 <ImageTag width="100%" height="100%" src={require(`./${i + 1}.png`)} />
                              </div>
                              <div className="message-container">
                                 <p className="NotfyText">{res.user_id}</p>
                              </div>
                              <div className="time-div">2mi</div>
                           </div>
                        );
                     })
                  ) : (
                     <div className="message-container" style={{ padding: 15 }}>
                        <p className="NotfyText">You have no new message</p>
                     </div>
                  )}
               </div>
            </div>
         </Popover.Content>
      </Popover>
   );
};
export default PopOver;
