import React, { useState, useMemo } from 'react';
import Description from '../../../components/Description/Description';
import { convertToTime } from '../../../utils/Function';
import { motion } from 'framer-motion';
import ImageTag from '../../../components/ImageTag/ImageTag';
import Button from '../../../components/Button/Button';
import { useEffect } from 'react';

const UserList = ({ userList, selectedMessageUser, moveFromWaitingList }) => {
   const [activeFilter, setActiveFilter] = useState(1);
   const [filteredData, setFilteredData] = useState([]);

   const changeActiveFitler = filterValue => {
      setActiveFilter(filterValue);
   };

   useEffect(() => {
      if (activeFilter === 1) {
         setFilteredData(userList.filter(user => user.assigned_to !== 'waiting'));
         return;
      }
      setFilteredData(userList.filter(user => user.assigned_to === 'waiting'));
   }, [activeFilter, userList]);

   const inWaiting = useMemo(() => userList.filter(user => user.assigned_to === 'waiting'), [userList, activeFilter]);
   const notWaiting = useMemo(() => userList.filter(user => user.assigned_to !== 'waiting'), [userList, activeFilter]);

   const isWaiting = assigned_to => {
      if (assigned_to === 'waiting') {
         return true;
      }
      return false;
   };
   return (
      <aside className="userlist-container">
         <div className="top-header">
            <button
               className={`top-header-button ${activeFilter === 1 ? 'active' : ''}`}
               onClick={() => changeActiveFitler(1)}>
               Your Chat
               <span className="count">{notWaiting.length}</span>
            </button>
            <button
               className={`top-header-button ${activeFilter === 2 ? 'active' : ''}`}
               onClick={() => changeActiveFitler(2)}>
               Waiting
               <span className="count">{inWaiting.length}</span>
            </button>
         </div>
         {filteredData &&
            filteredData.map(({ user_id = '', timestamp = '', assigned_to = '' }, key) => (
               <motion.div
                  initial={{ opacity: 1 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 1 }}>
                  <label htmlFor={user_id}>
                     {isWaiting(assigned_to) ? null : (
                        <input
                           type="radio"
                           name="user-list"
                           value={user_id}
                           className="selectedUserList"
                           id={user_id}
                        />
                     )}
                     <div
                        className="user-section"
                        {...(!isWaiting(assigned_to) && { onClick: () => selectedMessageUser(user_id) })}
                        key={key}>
                        <div className="left-section">
                           <img src="images/DefaultProfilePic.svg" alt="" />
                           <div className="user-detail">
                              <Description className="description-14 username">{user_id}</Description>
                              {/* <Description className="description">/cancel_appointment</Description> */}
                           </div>
                        </div>
                        <div className="right-section">
                           <div className="date">
                              <img src="images/dateLiveAgent.svg" alt="" />
                              <span>{convertToTime(timestamp)}</span>
                           </div>
                           {isWaiting(assigned_to) && (
                              <Button className="button-primary blue" onClick={() => moveFromWaitingList(user_id)}>
                                 <ImageTag src="images/liveAgentChatIcon.svg" /> Chat Now
                              </Button>
                           )}

                           {/* <div className="onbound">
                        <img src="images/returnLiveAgent.svg" alt="" />
                        <span>9 in/10 cout</span>
                     </div> */}
                        </div>
                     </div>
                  </label>
               </motion.div>
            ))}
      </aside>
   );
};

export default UserList;
