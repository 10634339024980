import {
   ADD_INTENT,
   ADD_EXISTING_INTENTS,
   ADD_CSV_INTENTS,
   DELETE_INTENT,
   ADD_UTTERENCE,
   DELETE_UTTERENCE,
   EDIT_UTTERENCE,
   ADD_ENTITY,
   SAVE_UTTERENCES,
} from '../actions/intent/types';

const initialState = {
   intents: [],
   eddited: [],
   existing_intents: [],
};

const IntentReducer = (state = initialState, action) => {
   switch (action.type) {
      case ADD_INTENT:
         return {
            ...state,
            intents: [...state.intents, { intent: action.payload, utterances: [] }],
            eddited: [...state.eddited, action.payload],
         };
      case DELETE_INTENT:
         return {
            ...state,
            intents: state.intents.filter(item => {
               return item.intent !== action.payload;
            }),
            eddited: state.eddited.filter(item => {
               return item !== action.payload;
            }),
         };
      case ADD_UTTERENCE:
         return {
            ...state,
            intents: state.intents.map(item => {
               if (item.intent === action.payload.name) {
                  item.utterances = item.utterances.concat(action.payload.utterance);
               }
               return item;
            }),
            // eddited: state.eddited.find((item) => { return item === action.payload.name }) === undefined ? [...state.eddited, action.payload.name] : state.eddited
         };
      case DELETE_UTTERENCE:
         return {
            ...state,
            intents: state.intents.map(item => {
               if (item.intent === action.payload.name) {
                  item.utterances = item.utterances.filter(utt => {
                     return utt.id !== action.payload.utterance;
                  });
               }
               return item;
            }),
            //eddited: state.eddited.find((item) => { return item === action.payload.name }) === undefined ? [...state.eddited, action.payload.name] : state.eddited
         };
      case EDIT_UTTERENCE:
         return {
            ...state,
            intents: state.intents.map(item => {
               if (item.intent === action.payload.name) {
                  item.utterances = item.utterances.map(utt => {
                     if (utt.id === action.payload.id) {
                        utt.utterance = action.payload.new;
                     }
                     return utt;
                  });
               }
               return item;
            }),
            //eddited: state.eddited.find((item) => { return item === action.payload.name }) === undefined ? [...state.eddited, action.payload.name] : state.eddited
         };
      case ADD_ENTITY:
         return {
            ...state,
            intents: state.intents.map(item => {
               if (item.intent === action.payload.intent) {
                  item.utterances[action.payload.utt_index].entities.push(action.payload.entity);
                  console.log(action.payload.entity);
               }
               return item;
            }),
            //eddited: state.eddited.find((item) => { return item === action.payload.intent }) === undefined ? [...state.eddited, action.payload.name] : state.eddited
         };
      case ADD_EXISTING_INTENTS:
         return {
            ...state,
            intents: action.payload,
            eddited: [],
            existing_intents: action.payload,
         };
      case ADD_CSV_INTENTS:
         return {
            ...state,
            intents: [...state.intents, ...action.payload.intents],
            eddited: action.payload.eddited
         };
      case SAVE_UTTERENCES:
         return {
            ...state,
            eddited: [],
         };
      default:
         return state;
   }
};

export default IntentReducer;
