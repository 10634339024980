import React, { useState } from "react"
import Theme1 from "./Theme1"
import Theme2 from "./Theme2"
import Theme3 from "./Theme3"


const SelectATheme = (props) => {

    const [theme, settheme] = useState("1")

    const onNext = () => {

        props.setData({ ...props.bot_data, bot_theme: theme })
        props.setTab(props.tab + 1)

    }

    const onBack = () => {
        props.setTab(props.tab - 1)
    }

    return (
        <div className="SelectATheme-parent">
            <div className="title">
                Select a theme
            </div>
            <div className="theme-container">
                <div className={`option ${theme === "1" ? "selected" : ""}`} onClick={() => { settheme("1") }}>
                    <Theme1 img={props.bot_data.bot_avatar} bot_name={props.bot_data.bot_name}/>
                </div>
                <div className={`option ${theme === "2" ? "selected" : ""}`} onClick={() => { settheme("2") }}>
                    <Theme2 img={props.bot_data.bot_avatar} bot_name={props.bot_data.bot_name}/>
                </div>
                <div className={`option ${theme === "3" ? "selected" : ""}`} onClick={() => { settheme("3") }}>
                    <Theme3 img={props.bot_data.bot_avatar} bot_name={props.bot_data.bot_name}/>
                </div>
            </div>

            <div className="buttons"/*  style={{ display: tab === 5 ? "none" : "flex" }} */>
                <div className={`bt-back   `} onClick={() => { onBack() }}>
                    Back
                </div>
                <div className={`bt-next `} onClick={() => { onNext() }}>
                    Next
                </div>
            </div>

        </div>
    )
}

export default SelectATheme