import React from "react";
import { Popover } from "react-bootstrap";
const popover = props => {
   return (
      <>
         <Popover
            style={{ height: props.height ? props.height : "auto" }}
            className={props.class ? props.class : ""}
            id="popover-basic">
            <div
               onClick={() => {
                  props.onClosePress ? props.onClosePress() : console.log("onPress not found");
               }}
               className="cross-icon-container">
               <img src={require('./assets/close.svg')} />
            </div>
            <Popover.Title>
               <p className="head-1 bold">{props.heading ? props.heading : "Default"}</p>
            </Popover.Title>
            <Popover.Content className="tooltip-body">
               <div className="main">
                  <p className="para-1 regular">
                     {props.paragraph
                        ? props.paragraph
                        : "diasghdiusahudihasiudhuaishdksagdkasgdiuyas9dyasdhashdiuasydisadsaydioasioduioasudioausioduasioudoiasuoidioasudiosaudisau"}
                  </p>
                  <div className="t-b-container">
                     {!props.onBackPress &&  <div className="back-blank-btttn">
                        
                     </div>}
                     {props.onBackPress && (
                        <div
                           onClick={() => {
                              props.onBackPress();
                           }}
                           className="back-btttn"
                        >
                           <p className="bold back-btttn-text">Back</p>
                        </div>
                     )}
                     <div className="sm-circle-contaiener">
                        {props.circles &&
                           props.circles.map((res, i) => {
                              let classGenerator =
                                 props.hintCount == i ? "sm-circle active-circle" : "sm-circle";
                              return <div className={classGenerator}></div>;
                           })}
                     </div>
                     <div
                        onClick={() => {
                           props.onNextPress ? props.onNextPress() : console.log("onPress not found");
                        }}
                        className="btttn">
                        <p className="bold btttn-text">{props.isDone ? "Done" : "Next"}</p>
                     </div>
                  </div>
               </div>
            </Popover.Content>
         </Popover>
         {props.isEntity && 
            <div className="entity-container">

               <p className="head-1 bold mb-18">{props.heading ? props.heading : "Default"}</p>
               <div className="textContainer mb-18">
                  <p className="regular">Faqs</p>
               </div>
               <div className="textContainer mb-18">
                  <p className="regular">Help</p>
               </div>
               <div className="button">
                  <p className="regular bold">Add an entity for <span style={{fontWeight:600}}>“help”</span></p>
               </div>
            </div>
         }
      </>
   );
};
export default popover;
