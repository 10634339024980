import React from "react";

const ImageTag = props => {
   return (
      <img
         src={props.src}
         className={props.className}
         id={props.id}
         style={props.style}
         onClick={props.onClick}
         alt=""
         loading="lazy"
      />
   );
};

export default ImageTag;
