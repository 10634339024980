import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { useEffect } from 'react';

const LightTooltip = withStyles(() => ({
   tooltip: {
      backgroundColor: 'white',
      color: '#444444',
      padding: 10,
   },
   arrow: {
      color: 'white',
   },
}))(Tooltip);

export default function Sidebar({ addNewInput, setdatetype, datetype, selectDateType, language }) {
   const [placement, setplacement] = useState('right');

   useEffect(() => setplacement(language === 'ar' ? 'left' : 'right'), [language]);

   return (
      <div className="chatbotform-sidebar-icons">
         <LightTooltip title={language === 'ar' ? 'نص' : 'Text'} placement={placement} arrow>
            <img src={require('../assets/text.svg')} width="23px" alt="text" onClick={() => addNewInput('text')} />
         </LightTooltip>
         <hr />
         <LightTooltip title={language === 'ar' ? 'بريد' : 'Email'} placement={placement} arrow>
            <img src={'images/email.svg'} width="20px" alt="text" onClick={() => addNewInput('email')} />
         </LightTooltip>
         <hr />
         <LightTooltip title={language === 'ar' ? 'كلمه السر' : 'Password'} placement={placement} arrow>
            <img
               src={require('../assets/password.svg')}
               width="23px"
               alt="text"
               onClick={() => addNewInput('password')}
            />
         </LightTooltip>
         <hr />
         <LightTooltip title={language === 'ar' ? 'التقويم' : 'Calendar'} placement={placement} arrow>
            <img
               src={require('../assets/date.svg')}
               width="23px"
               alt="text"
               onClick={() => {
                  setdatetype(!datetype);

                  setTimeout(() => {
                     const el = document.querySelector('.datetype');
                     if (el && !datetype) el.focus();
                  }, 0);
               }}
            // onClick={() => addNewInput('date', true)}
            />
         </LightTooltip>
         <hr />
         <LightTooltip title={language === 'ar' ? 'هاتف' : 'Phone'} placement={placement} arrow>
            <img src={require('../assets/phone.svg')} width="23px" alt="text" onClick={() => addNewInput('phone')} />
         </LightTooltip>
         <hr />
         <LightTooltip title={language === 'ar' ? 'ردود الفعل' : 'Feedback'} placement={placement} arrow>
            <img
               src={require('../assets/feedback.svg')}
               width="23px"
               alt="feed"
               onClick={() => addNewInput('textarea')}
            />
         </LightTooltip>
         <hr />
         <LightTooltip title={language === 'ar' ? 'اسقاط' : 'Dropdown'} placement={placement} arrow>
            <img
               src={require('../assets/dropdown.svg')}
               width="23px"
               alt="text"
               onClick={() => addNewInput('dropdown')}
            />
         </LightTooltip>
         <hr />
         <LightTooltip title={language === 'ar' ? 'زر' : 'Button'} placement={placement} arrow>
            <img src={require('../assets/button.svg')} width="23px" alt="text" onClick={() => addNewInput('submit')} />
         </LightTooltip>
         {datetype && selectDateType()}
      </div>
   );
}
