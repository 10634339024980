import React, { useState } from "react"
import { months, days } from "../../utils/Utility"

const ChatScriptDetails = (props) => {


    const TimestampToMinutes = (timestamp) => {
        var date = new Date(timestamp)
        return days[date.getDay()] + ", " + months[date.getMonth()] + " " + date.getDate() + "," + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes()
    }



    return (
        <div className="chatscriptdetails-parent">
            <div className="chat-list-container">
                {props.data.conversation.map(data => (
                    <div className={data.from === "bot" ? "right-chat" : "left-chat"} key={data.id}>
                        <div className="chat">
                            {data.text}
                        </div>
                        {data.from !== "bot" ? <div className="description">
                            <div className="date">
                                {TimestampToMinutes(data.time)}
                            </div>
                            <div className="id">
                                {data.from}
                            </div>
                        </div> : <div className="description">
                                <div className="id">
                                    {data.from}
                                </div>
                                <div className="date">
                                    {TimestampToMinutes(data.time)}
                                </div>
                            </div>}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ChatScriptDetails