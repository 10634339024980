import React, { useEffect, useState } from "react";
import ImageTag from '../../../components/ImageTag/ImageTag';

const ChatbotCard = ({image, heading, content}) =>{
    return(
        <div className="chatbot-card-parant animated fadeInLeft">
            <div className="chatbot-card">
                <ImageTag className="chatbot-image" src={require('./assets/images.jpg')}/>
                <div className="chatbot-card-body">
                    <div>
                        <p className="chatbot-card-heading bold">{heading}</p>
                        <p className="chatbot-card-content regular">{content}</p>
                    </div>
                    <div className="chatbot-card-button">
                        <p className="bold chatbot-card-button-text">
                            View Details
                        </p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ChatbotCard;