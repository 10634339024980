import React, { useContext } from 'react';
import Button from '../../Button/Button';
import { LanguageContext } from '../ChatBot';

export default function AutoSuggest({ data, remove, duplicate }) {
   const { language } = useContext(LanguageContext);

   return (
      <div
         className="autosuggest-container left-chat"
         style={{
            position: 'relative',
            marginBottom: '65px',
            backgroundColor: 'white',
         }}>
         <p
            className="montserrat"
            style={{
               position: 'absolute',
               top: '10px',
               left: language === 'ar' ? '220px' : '20px',
               fontSize: '14px',
               fontWeight: 'bold',
            }}>
            {language === 'en' ? 'Suggestive Search:' : ':بحث موحي جنسيًا'}
         </p>
         <div
            className="input-section"
            style={{
               flexDirection: language === 'ar' ? 'row-reverse' : 'row',
               borderBottom: '1px solid #e5e5e5',
            }}>
            <input
               className="__otp_inputs__"
               type="text"
               autoComplete="off"
               placeholder="Search"
               disabled
               dir={language === 'ar' && 'rtl'}
            />
            <Button className="circle-btn">
               <img src="/images/search.svg" alt="" />
            </Button>
         </div>

         {/* {!this.state.selectedOption ? (
          <div className="suggestion-list">{this.dynamicSearch()}</div>
        ) : null} */}
         <div className="__edit-icons__" style={{ left: '2px', bottom: '-42px', padding: '1px' }}>
            <img src="/images/copy.svg" width="20px" alt="copy" onClick={() => duplicate(data.response_name)} />
            <img
               src="/images/delete.svg"
               width="23px"
               style={{ margin: '5px' }}
               alt="delete"
               onClick={() => remove(data.response_name)}
            />
         </div>
      </div>
   );
}
