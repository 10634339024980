import { FETCH_ALERT, CLOSE_ALERT, DELETE_ALERT } from '../actions/notification/types';

const initialState = {
   allAlerts: [],
};

export default (state = initialState, action) => {
   switch (action.type) {
      case FETCH_ALERT:
         const userList = [...state.allAlerts];

         if (userList.filter(item => item.user_id === action.alerts.user_id).length === 0) {
            userList.push({ ...action.alerts, show: true, active: true });
         }

         return {
            ...state,
            allAlerts: userList,
         };
      case CLOSE_ALERT:
         let userRemove = [];
         userRemove = [...state.allAlerts].map(data => {
            if (data.user_id === action.user_id) {
               data.show = false;
            }
            return data;
         });

         return {
            ...state,
            allAlerts: userRemove,
         };

      case DELETE_ALERT:
         const userDelete = [...state.allAlerts].filter(data => data.user_id !== action.user_id);

         return {
            ...state,
            allAlerts: userDelete,
         };

      default:
         return state;
   }
};
