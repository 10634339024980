import React from "react"
import bot from "../../../assets/images/bot.svg"
import { useHistory } from "react-router-dom"

const Confirm = (props) => {

    const history = useHistory()

    return (
        <div className="confirm-parent">
            <img src={bot} />
            <div className="text1">
                Awesome!
            </div>
            <div className="text2">
                Your bot has created successfully, please go to the Intent page.
            </div>
            <div className="bt-next" onClick={() => { history.push("/intent") }}>
                Go to Intent
            </div>
        </div>
    )
}

export default Confirm