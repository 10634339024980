/* eslint-disable */
import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Intent from '../Intent/Intent';
import SignIn from '../SignIn/Signin';
import Forget from '../Forget/Forget';
import Activity from '../Activity/Activity';
import SendCode from '../SendCode/SendCode';
import AnalyticsDashboard from '../AnalyticsDashboard/AnalyticsDashboard';
import Header from '../../components/Header/Header';
import Profile from '../../components/Profile/Profile';
import ScrollTop from '../../components/ScrollTop/ScrollTop';
import Dashboard from '../../components/Dashboard/Dashboard';
import ChatboatParent from '../../pages/ChatboatParent/ChatboatParent';
import Nav from '../../components/Nav/Nav';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import LiveAgent from '../LiveAgent/LiveAgent';
import CreatBot from '../StoryCreation/Main';
import Conversation from '../Conversation/Conversation';
import ViewAll from '../../components/ViewAll/ViewAll';
import ChatBot from '../../components/ChatBot/chatbox';
import { AnimatePresence } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import MasterDashboard from '../MasterDashboard/MasterDashboard';
import Documentation from '../../components/Documentation/Documentation';
import ProtectedRoute from '../Routes/ProtectedRoute/ProtectedRoute';
import CreateBot from '../CreateBot/CreateBot';
import { GetProfile, CheckStatus } from '../../redux/actions/profile/index';
import { GetBot } from '../../redux/actions/storybuilder/index';
import MasterDashboardLogin from '../MasterDashboardLogin/MasterDashboardLogin';
import { SnackbarProvider } from 'notistack';
import Api from '../../axios/request';
import LiveAgentToast from '../LiveAgent/LiveAgentToast/LiveAgentToast';
import TopIntents from '../../components/TopIntents/TopIntents';
import { socket } from '../../utils/Socket';
import { guid } from '../../utils/Utility';
import { FETCH_ALERT } from '../../redux/actions/notification/types';

const ChatBotBuilder = () => {
   const location = useLocation();
   const hint = useSelector(state => state.hint);
   const ht = useHistory();

   const dispatch = useDispatch();
   const history = location.pathname;

   socket.emit('agent_register', {
      email: JSON.parse(localStorage.getItem('user'))?.username,
      bot_name: localStorage.getItem('bot_name'),
      token: guid(),
   });

   socket.on('user_list', data => {
      dispatch({
         type: FETCH_ALERT,
         alerts: data,
      });
   });

   useEffect(() => {
      if (localStorage.getItem('user') !== null) {
         const tk = JSON.parse(localStorage.getItem('user')).token;
         CheckStatus(tk, history);
         dispatch(GetProfile(tk));
         dispatch(GetBot(tk));
      }
   }, [localStorage.getItem('user')]);

   useEffect(() => {
      if (localStorage.getItem('user') !== null) {
         const tk = JSON.parse(localStorage.getItem('user')).token;
         CheckStatus(tk, history);
      }
   }, [ht]);

   useEffect(() => {
      if (
         localStorage.getItem('user') === null &&
         !location.pathname.includes('forget') &&
         !location.pathname.includes('MLogin') &&
         !location.pathname.includes('Mdashboard') &&
         !location.pathname.includes('sendcode')
      ) {
         ht.push('/signin');
      }
   }, [location.pathname]);

   /*  useEffect(() => {
      if (localStorage.getItem("user") !== null) {
 
      }
 
      Return403().then((res) => {
         console.log(res)
         LogOut(ht)
         //ht.push("/MLogin")
      })
   }, [])  */

   /**
    * Call this dispatch inside useEffect or inside a funtions for using it
    *
    */
   // dispatch(SHOWHINTBOOL(data));
   // dispatch(CHANGECOUNT(data));

   const matchUrl = new RegExp(/\b(?:signin|forget|sendcode|Mdashboard|liveagent|MLogin)\b/g);
   const matchUrlWithoutLiveAgent = new RegExp(/\b(?:signin|forget|sendcode|Mdashboard|MLogin)\b/g);

   let routContainerClass = history.match(matchUrl) ? 'col-lg-12' : 'col-lg-10'; //this is causing the error

   return (
      <Switch>
         <ScrollTop>
            <LiveAgentToast />
            {hint.showHint && !history.match(matchUrl) && <div className="transparant-background"></div>}
            <Container fluid>
               <Row style={{ flexWrap: 'nowrap' }}>
                  {!history.match(matchUrl) && (
                     <div className="p-0">
                        <Nav />
                     </div>
                  )}
                  <div className={`route-container ${routContainerClass} `} id="page-content-wrapper">
                     {!history.match(matchUrlWithoutLiveAgent) && <Header />}

                     <Route path="/signin" component={SignIn} />
                     <Route path="/forget" component={Forget} />
                     <Route path="/sendcode" component={SendCode} />
                     <Route path="/Mdashboard" component={MasterDashboard} />
                     <Route path="/MLogin" component={MasterDashboardLogin} />

                     {/* {localStorage.getItem('user') !== null ? (
                        <Route path={['/', '/home', '/dashboard']} exact component={Dashboard} />
                     ) : null} */}

                     {localStorage.getItem('user') !== null &&
                     JSON.parse(localStorage.getItem('user')).restricted.includes('allbots') ? (
                        <Route path={['/', '/home', '/dashboard']} exact component={Activity} />
                     ) : (
                        <Route path={['/', '/home', '/dashboard']} exact component={Dashboard} />
                     )}

                     {}

                     {localStorage.getItem('user') !== null ? (
                        <Route path="/messages" exact component={ChatboatParent} />
                     ) : null}
                     {localStorage.getItem('user') !== null ? <Route path="/viewall" component={ViewAll} /> : null}

                     {localStorage.getItem('user') !== null ? <Route path="/intent" component={Intent} /> : null}
                     {localStorage.getItem('user') !== null ? <Route path="/liveagent" component={LiveAgent} /> : null}
                     {localStorage.getItem('user') !== null ? <Route path="/profile" component={Profile} /> : null}
                     {localStorage.getItem('user') !== null ? (
                        <Route path="/conversation" component={Conversation} />
                     ) : null}
                     {localStorage.getItem('user') !== null ? <Route path="/chatBot" component={ChatBot} /> : null}
                     {localStorage.getItem('user') !== null ? (
                        <Route path="/bot-analytics-dashboard" component={Activity} />
                     ) : null}
                     {localStorage.getItem('user') !== null ? <Route path="/activity" component={Activity} /> : null}
                     {localStorage.getItem('user') !== null ? (
                        <SnackbarProvider maxSnack={3}>
                           <Route path="/creatbot" component={CreatBot} />
                        </SnackbarProvider>
                     ) : null}

                     {localStorage.getItem('user') !== null ? (
                        <Route path="/documentation" component={Documentation} />
                     ) : null}
                     {localStorage.getItem('user') !== null ? <Route path="/bot" component={CreateBot} /> : null}
                     {localStorage.getItem('user') !== null ? (
                        <Route path="/topintents" component={TopIntents} />
                     ) : null}

                     {/* <ProtectedRoute path={['/', '/home', '/dashboard']} exact component={Dashboard} />

                     <ProtectedRoute path="/messages" exact component={ChatboatParent} />

                     <Route path="/signin" component={SignIn} />
                     <Route path="/forget" component={Forget} />
                     <Route path="/sendcode" component={SendCode} />
                     <Route path="/Mdashboard" component={MasterDashboard} />
                     <Route path="/MLogin" component={MasterDashboardLogin} />

                     <ProtectedRoute path="/viewall" component={ViewAll} />
                     <ProtectedRoute path="/dashboard" component={Dashboard} />

                     <ProtectedRoute path="/intent" component={Intent} />
                     <ProtectedRoute path="/liveagent" component={LiveAgent} />
                     <ProtectedRoute path="/profile" component={Profile} />
                     <ProtectedRoute path="/conversation" component={Conversation} />
                     <ProtectedRoute path="/chatBot" component={ChatBot} />
                     <ProtectedRoute path="/bot-analytics-dashboard" component={Activity} />
                     <ProtectedRoute path="/activity" component={Activity} />
                     <ProtectedRoute path="/creatbot" component={CreatBot} />

                     <ProtectedRoute path="/documentation" component={Documentation} />
                     <ProtectedRoute path="/bot" component={CreateBot} /> */}
                  </div>
               </Row>
            </Container>
         </ScrollTop>
      </Switch>
   );
};

export default ChatBotBuilder;
