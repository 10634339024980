import {
   ADD_INTENT,
   DELETE_INTENT,
   ADD_UTTERENCE,
   DELETE_UTTERENCE,
   EDIT_UTTERENCE,
   ADD_ENTITY,
   ADD_EXISTING_INTENTS,
   ADD_CSV_INTENTS,
   SAVE_UTTERENCES,
} from './types';
import { generateToken } from '../../../utils/Function';
import Api from '../../../axios/request';
import { LogOut } from '../profile/index';

const utterance = {
   name: null,
   entities: [],
};

const entity = {
   value: null,
   entity: null,
};

export const AddIntent = name => dispatch => {
   dispatch({ type: ADD_INTENT, payload: name });
};

export const DeleteIntent = (botname, name, token, callback, history) => dispatch => {
   Api.post(
      '/logic/deleteIntent/',
      {
         bot_name: botname,
         intent_name: name,
      },
      {
         headers: {
            Authorization: 'Token ' + token,
         },
      }
   )
      .then(res => {
         if (res.status === 403 || res.status === 401) {
            return LogOut(history);
         }
         dispatch({ type: DELETE_INTENT, payload: name });
         callback();
      })
      .catch((error, xhr) => {
         let errormesae = JSON.stringify(xhr.message);
         if (
            errormesae == '"Request failed with status code 401"' ||
            errormesae == '"Request failed with status code 403"'
         ) {
            return LogOut(history);
         }
      });
};

export const TrainNlu = (bot_name, token, history) => {
   return new Promise((response, rej) => {
      Api.post('/logic/train/',
         {
            train_type: "nlu",
            bot_name,
            language: "en"
         },
         {
            headers: {
               Authorization: 'Token ' + token,
               "Content-Type": "application/json"
            },
         }
      )
         .then(res => {
            if (res.status === 403 || res.status === 401) {
               rej(false)
               return LogOut(history);
            } else {
               return response(true)
            }
            console.log(res.data)
            //return here
         })
         .catch((error, xhr) => {
            rej(false)
            console.log(error)
            let errormesae = JSON.stringify(xhr.message);
            if (
               errormesae == '"Request failed with status code 401"' ||
               errormesae == '"Request failed with status code 403"'
            ) {
               return LogOut(history);
            }
         });
   })
};

/**
 *
 * Once you fix the utterance , you can remove this return , checkout like number 14 for reference.
 *
 * @param {*} name
 */

export const AddUtterence = (botname, name, utt, token, existing_intents, history) => dispatch => {
   const utterance = {
      entities: [],
      id: generateToken(),
      utterance: null,
   };

   utterance.utterance = utt;

   if (
      existing_intents.find(item => {
         return item.intent === name;
      })
   ) {
      Api.post(
         '/logic/addUtterance/',
         {
            bot_name: botname,
            data: [
               {
                  intent: name,
                  utterances: [utterance],
               },
            ],
         },
         {
            headers: {
               Authorization: 'Token ' + token,
            },
         }
      )
         .then(res => {
            if (res.status === 403 || res.status === 401) {
               return LogOut(history);
            }
            dispatch({ type: ADD_UTTERENCE, payload: { name: name, utterance: utterance } });
         })
         .catch((error, xhr) => {
            let errormesae = JSON.stringify(xhr.message);
            if (
               errormesae == '"Request failed with status code 401"' ||
               errormesae == '"Request failed with status code 403"'
            ) {
               return LogOut(history);
            }
         });
   } else {
      dispatch({ type: ADD_UTTERENCE, payload: { name: name, utterance: utterance } });
   }
};

export const DeleteUtterence = (botname, name, utt, token, existing_intents, history) => dispatch => {
   if (
      existing_intents.find(item => {
         return item.intent === name;
      })
   ) {
      Api.post(
         '/logic/deleteUtterance/',
         {
            bot_name: botname,
            utter_id: utt,
         },
         {
            headers: {
               Authorization: 'Token ' + token,
            },
         }
      )
         .then(res => {
            if (res.status === 403 || res.status === 401) {
               return LogOut(history);
            }
            dispatch({ type: DELETE_UTTERENCE, payload: { name: name, utterance: utt } });
         })
         .catch((error, xhr) => {
            let errormesae = JSON.stringify(xhr.message);
            if (
               errormesae == '"Request failed with status code 401"' ||
               errormesae == '"Request failed with status code 403"'
            ) {
               return LogOut(history);
            }
         });
   } else {
      dispatch({ type: DELETE_UTTERENCE, payload: { name: name, utterance: utt } });
   }
};

export const EditUtternce = (botname, name, new_utt, id, items, token, existing_intents, history) => dispatch => {
   var intent = items.find(item => {
      return item.intent === name;
   });
   var utterance = intent.utterances.find(utt => {
      return utt.id === id;
   });

   utterance.utterance = new_utt;

   if (
      existing_intents.find(item => {
         return item.intent === name;
      })
   ) {
      Api.post(
         '/logic/updateUtterance/',
         {
            bot_name: botname,
            data: [
               {
                  intent: intent.intent,
                  utterances: [utterance],
               },
            ],
         },
         {
            headers: {
               Authorization: 'Token ' + token,
            },
         }
      )
         .then(res => {
            if (res.status === 403 || res.status === 401) {
               return LogOut(history);
            }
            console.log(res.data);
            dispatch({ type: EDIT_UTTERENCE, payload: { name: name, new: new_utt, id: id } });
         })
         .catch((error, xhr) => {
            let errormesae = JSON.stringify(xhr.message);
            if (
               errormesae == '"Request failed with status code 401"' ||
               errormesae == '"Request failed with status code 403"'
            ) {
               return LogOut(history);
            }
         });
   } else {
      dispatch({ type: EDIT_UTTERENCE, payload: { name: name, new: new_utt, id: id } });
   }
};

export const AddEntity = (botname, name, utt_index, entity, items, token, existing_intents, history) => dispatch => {
   var intent = items.find(item => {
      return item.intent === name;
   });
   var utterance = intent.utterances[utt_index];
   utterance.entities.push(entity);

   if (
      existing_intents.find(item => {
         return item.intent === name;
      })
   ) {
      Api.post(
         '/logic/updateUtterance/',
         {
            bot_name: botname,
            data: [
               {
                  intent: intent.intent,
                  utterances: [utterance],
               },
            ],
         },
         {
            headers: {
               Authorization: 'Token ' + token,
            },
         }
      )
         .then(res => {
            if (res.status === 403 || res.status === 401) {
               return LogOut(history);
            }
            console.log(res.data);
            dispatch({ type: ADD_ENTITY, payload: { intent: intent, utt_index: utt_index, entity: entity } });
         })
         .catch((error, xhr) => {
            let errormesae = JSON.stringify(xhr.message);
            if (
               errormesae == '"Request failed with status code 401"' ||
               errormesae == '"Request failed with status code 403"'
            ) {
               return LogOut(history);
            }
         });
   } else {
      dispatch({ type: ADD_ENTITY, payload: { intent: intent, utt_index: utt_index, entity: entity } });
   }
};

export const GetIntent = (botname, token, callback = null) => {

   console.log("Hey from Get Intent")
   return dispatch => {
      Api.get('/logic/utterances/', {
         params: {
            bot_name: botname,
         },
         headers: {
            Authorization: 'Token ' + token,
         },
      })
         .then(res => {
            if (callback !== null) callback()
            dispatch({ type: ADD_EXISTING_INTENTS, payload: res.data.data });
         })
         .catch(error => {
            if (callback !== null) callback()
            console.log(error);
         });
   };
};

export const SetCsvIntent = (intents) => {

   var eddited = []

   intents.forEach((item) => {
      eddited.push(item.intent)
   })

   console.log("eddited", eddited)

   return dispatch => {
      dispatch({ type: ADD_CSV_INTENTS, payload: { intents: intents, eddited: eddited } });
   };
};

export const SaveUtterences = (botname, intents, token, callback, history) => {
   return dispatch => {
      intents.forEach(intent => {
         Api.post(
            '/logic/createIntent/',
            {
               bot_name: botname,
               data: [intent],
            },
            {
               headers: {
                  Authorization: 'Token ' + token,
               },
            }
         )
            .then(res => {
               if (res.status === 403 || res.status === 401) {
                  return LogOut(history);
               }
               console.log(res.data);
            })
            .catch((error, xhr) => {
               let errormesae = JSON.stringify(xhr.message);
               if (
                  errormesae == '"Request failed with status code 401"' ||
                  errormesae == '"Request failed with status code 403"'
               ) {
                  return LogOut(history);
               }
               console.log(error);
            });
      });

      dispatch({ type: SAVE_UTTERENCES, payload: '' });
      callback();
   };
};
