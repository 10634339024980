import { SETBOTNAME, SETCUSTOMACTIONS } from '../actions/storybuilder/types';

const initialState = {
   bot_name: null,
   custom_actions: [],
   load: false,
};

const ProfileReducer = (state = initialState, action) => {
   switch (action.type) {
      case SETBOTNAME:
         return { ...state, bot_name: action.payload, load: true };
      case SETCUSTOMACTIONS:
         return { ...state, custom_actions: action.payload };
      default:
         return state;
   }
};

export default ProfileReducer;
