import React from "react";
import Card from './card/card';

export default (props) => {
    return (
        <div className="ConversationsMessage-Funnel ">
            <h1 className="text-style-1">Conversations</h1>
            <div className="topbuttons">
                <div className="toggl-btn">
                    <p className="Direction">Direction</p>
                    <div className="Rectangle-1509">
                        <div className="Rectangle-1512">
                            <p className="Incoming">Incoming</p>
                        </div>
                    </div>
                </div>

                <div className="toggl-btn">
                    <p className="Direction">Message Type</p>
                    <div className="Rectangle-1509">
                        <div className="Rectangle-1512">
                            <p className="Incoming">Incoming</p>
                        </div>
                    </div>
                </div>
                <div className="toggl-btn">
                    <p className="Direction">Top incoming intents</p>
                    <div className="Rectangle-1509">
                        <div className="Rectangle-1512">
                            <p className="Incoming">Incoming</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card-holder">
                <Card>
                </Card>
                {/* <Graph/> */}
            </div>

        </div>
    );
};
