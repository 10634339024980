import React, { useEffect, useState, useRef } from 'react';
import IntentCard from './IntentCard/IntentCard';
import Alert from '../../components/Alert/Alert';
import Button from '../../components/Button/Button';
import delIcon from '../../assets/images/trash.png';
import editIcon from '../../assets/images/edit.png';
import addIcon from '../../assets/images/add.png';
import addBlue from '../../assets/images/add-blue.png';
import search from '../../assets/images/search.svg';
import { useDispatch, useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { SHOWHINTBOOL, CHANGECOUNT } from '../../redux/actions/hint';
import { OverlayTrigger } from 'react-bootstrap';
import CsvDownload from 'react-json-to-csv';
import Tooltip from '../../components/tootip/tootip';
import {
   DeleteIntent,
   DeleteUtterence,
   EditUtternce,
   AddEntity,
   GetIntent,
   SaveUtterences,
   SetCsvIntent,
   TrainNlu
} from '../../redux/actions/intent';
import { generateToken } from '../../utils/Function';
import { useHistory } from 'react-router-dom';

import Inputpopup from '../../components/Inputpopup/Inputpopup';
import ImageTag from '../../components/ImageTag/ImageTag';
import CSVReader from 'react-csv-reader';
import { SettingsOverscanRounded } from '@material-ui/icons';
import Axios from '../../utils/Request';

const Intent = props => {
   const [page, setPage] = useState(1);
   const items = useSelector(state => state.home.intents);
   const eddited = useSelector(state => state.home.eddited);
   const existing_intents = useSelector(state => state.home.existing_intents);
   const dispatch = useDispatch();
   const [currentI, setcurrentI] = useState({ intent: null, utterances: [] });
   const [active, setactive] = useState({ name: null, index: null, id: null });
   const [show, setshow] = useState(false);
   const [entity, setentity] = useState({
      start: null,
      end: null,
      value: null,
      entity: null,
   });
   const [title, settitle] = useState('edit');
   const [showSecondTooltip, setshowSecondTooltip] = useState(false);
   const [showThirdtooltip, setshowThirdTooltip] = useState(false);
   const [searchi, setsearchi] = useState('');
   const [result, setresult] = useState([]);
   const [showModal, setModalShow] = useState(false);
   const [sopen, setsopen] = useState(false);
   const [alertContent, setAlertContent] = useState({
      error: '',
      message: '',
   });
   const popup = useRef(null);
   const tk = JSON.parse(localStorage.getItem('user')).token;
   const bot_name = useSelector(state => state.storybuilder.bot_name);
   const [isloading, setloading] = useState(true);
   const [save, setsave] = useState(false);
   const [isTrainDisabled, setTrainDisabled] = useState(true);

   const utt = useRef(null);
   const inpt = useRef([]);
   const search12 = useRef(null);
   const history = useHistory();

   console.log(items);

   //structure for json data to export
   /* const data1 = [{
      pk: 22,
      model: "auth.permission",
      fields: {
         codename: "add_logentry",
         name: "Can add log entry",
         content_type: 8
      }
   }, {
      pk: 22,
      model: "auth.permission",
      fields: {
         codename: "add_logentry",
         name: "Can add log entry",
         content_type: 8
      }
   }
   ] */

   useEffect(() => {
      if (save) {
         Saveutterances(items);
         setsave(false);
      }
   }, [save]);

   useEffect(() => {
      if (items.length !== 0 && currentI.intent === null) {
         setcurrentI(items[0]);
         highlightText(items[0]);
      }
   }, [items]);

   const highlightText = item => {
      setTimeout(() => {
         const { utterances } = item;
         let wordUtterance;
         for (let i = 0; i < utterances.length; i++) {
            // The Complete String
            const { utterance } = utterances[i];
            // The Start and End Position
            const { entities } = utterances[i];
            wordUtterance = utterance.split(' ');

            // Geting the start and end of the Objects inside the entities
            for (let j = 0; j < entities.length; j++) {
               const { start, end } = entities[j];

               // Looping the Every Word
               for (let k = 0; k < wordUtterance.length; k++) {
                  if (wordUtterance[k] === utterance.substring(start, end)) {
                     wordUtterance[k] = "<span class='highlighted-intent'>" + wordUtterance[k] + '</span>';
                     // Adding the highlighted text back to the Array
                     utterances[i].utterance = wordUtterance.join(' ');
                  }
               }
            }
         }

         // Saving that array as state for iterating in the JSX
         setcurrentI(prev => ({
            ...prev,
            utterances,
         }));
      }, 200);

      // // Timeout to make sure the Elements are Loaded => (NEEDS REFACTORING LATER)
      // setTimeout(() => {
      //    // DOM Manipulation
      //    const utterances_input = document.querySelectorAll('.highlight-section');
      //    const { utterances } = item;
      //    // Looping the Utterances
      //    for (let i = 0; i < utterances.length; i++) {
      //       const utterancesArray = utterances_input[i].textContent.split(' ');
      //       const { entities } = utterances[i];
      //       // Looping the Entities
      //       for (let j = 0; j < entities.length; j++) {
      //          const { start, end } = entities[j];
      //          // Looping the Every Word
      //          for (let k = 0; k < utterancesArray.length; k++) {
      //             // console.log(utterancesArray[k]);
      //             if (utterancesArray[k] === utterances_input[i].textContent.substring(start, end)) {
      //                utterancesArray[k] =
      //                   "<span style='background-color:#fac12f;color:white;padding: 0 5px;border-radius: 3px;'>" +
      //                   utterancesArray[k] +
      //                   '</span>';
      //                utterances_input[i].innerHTML = utterancesArray.join(' ');
      //             }
      //          }
      //       }
      //    }
      // }, 200);
   };

   //this code scrolls the window to the popup when it opens up
   useEffect(() => {
      if (show === true) {
         popup.current.scrollIntoView({ behavior: 'smooth' });
      }
   }, [show]);

   //this is listener to close search result list ,so that it can close on outside clicks
   useEffect(() => {
      // Clicked outside the box

      document.addEventListener('click', function (e) {
         const resultContainer = document.querySelector('#result-container');
         if (resultContainer !== null && !resultContainer.contains(e.target)) {
            setresult([]);
         }
      });

      //listener for popup so it can close on outside clicks
      document.addEventListener('click', e => {
         const inputContainer = document.querySelector('#input-pp');
         const icons = e.target.classList.contains('del-icon');
         if (inputContainer !== null && !inputContainer.contains(e.target) && !icons) {
            setshow(false);
         }
      });

      document.addEventListener('click', function (e) {
         /* const suggestionContainer = document.querySelector('.suggestion-container');
         if (suggestionContainer !== null && !suggestionContainer.contains(e.target)) {
            setsopen(false);
         } */
      });

      //this function gets data from backend by making an api call
   }, []);

   useEffect(() => {
      if (bot_name !== null) {
         dispatch(
            GetIntent(bot_name, tk, () => {
               setloading(false);
            })
         );
      }
   }, [bot_name]);

   //function called to delete intent
   const onDelete = name => {
      dispatch(
         DeleteIntent(
            bot_name,
            name,
            tk,
            () => {
               if (currentI.intent === name) {
                  setcurrentI({ intent: null, utterances: [] });
               }
            },
            history
         )
      );
   };

   //function to convert csv format data in js intent data structure
   const csvJSON = csv => {
      var lines = csv;

      var intents = [];

      var headers = lines[0];

      var intent = {
         intent: null,
         utterances: [],
      };

      for (var i = 1; i < lines.length; i++) {
         var currentline = lines[i];

         for (var j = 0; j < headers.length; j++) {
            if (currentline.length < 2) {
            } else if (j === 0) {
               if (currentline[1] !== '' && intent.intent === null) {
                  intent.intent = currentline[1];
               } else if (currentline[1] !== '' && intent.intent !== currentline[1]) {
                  intents.push({ ...intent });
                  intent.intent = currentline[1];
                  intent.utterances = [];
               }
            } else {
               if (currentline[0] !== '') {
                  intent.utterances.push(currentline[0]);
               }
            }
         }

         if (i === lines.length - 1) {
            intents = [...intents, intent];
         }
      }

      //return result; //JavaScript object
      UttToEntity(intents);
      return JSON.stringify(intents); //JSON
   };

   const UttToEntity = intents => {
      const updated_intents = [];

      intents.forEach((item, index1) => {
         updated_intents[index1] = {
            intent: item.intent,
            utterances: [],
         };

         item.utterances.forEach((utt, index) => {
            let utterance = '';
            let entities = [];
            let entity = {
               entity: '',
               value: '',
               start: null,
               end: null,
            };
            let start = null;
            let end = null;
            let intermediate = '';
            let val = '';

            for (let i = 0; i < utt.length; i++) {
               if (utt.charAt(i) === '<') {
                  if (entities.length === 0) {
                     start = i;
                  } else {
                     start = utterance.length;
                  }
                  entity.entity = '';
                  entity.value = '';
                  val = '';
               } else if (start !== null && utt.charAt(i) !== '>') {
                  entity.entity = entity.entity + utt.charAt(i);
                  val = val + utt.charAt(i);
               } else if (utt.charAt(i) === '>') {
                  if (entities.length === 0) {
                     end = i;
                  } else {
                     end = start + val.length;
                  }
                  entity.start = start;
                  entity.end = end;
                  start = null;
               } else if (end !== null && utt.charAt(i) !== ')' && utt.charAt(i) !== '(') {
                  intermediate = intermediate + utt.charAt(i);
               } else if (utt.charAt(i) === ')') {
                  utterance = utterance + entity.entity;
                  intermediate = intermediate.split(':');
                  if (intermediate.length == 2) {
                     entity.value = intermediate[1];
                     //utterance = utterance + intermediate[0]
                     entity.entity = intermediate[0];
                  } else {
                     // entity.value = intermediate[0]
                     entity.value = entity.entity;
                     entity.entity = intermediate[0];
                     //utterance = utterance + intermediate[0]
                  }
                  entity.end = entity.start + val.length;
                  end = null;
                  intermediate = '';
                  val = '';
                  entities.push({ ...entity });
               } else if (utt.charAt(i) !== '(') {
                  utterance = utterance + utt.charAt(i);
               }
            }

            updated_intents[index1].utterances[index] = {
               entities: entities,
               id: generateToken(),
               utterance: utterance,
            };
         });
      });

      dispatch(SetCsvIntent(updated_intents));
      setsave(true);
   };

   //function to transform intents to export to a csv file
   const ExportIntent = () => {
      var data = items;

      var intent_data = [];

      data.forEach((intent, index) => {
         intent.utterances.forEach((utt, index1) => {
            var entity_sorted = utt.entities.sort((ett1, ett2) => {
               return ett1.start - ett2.start;
            });

            var row = {};
            //var trans_utt = ""

            row.utterance = utt.utterance;
            row.Intent = intent.intent;
            // var transformatated_ett = []
            var offset = 0;

            entity_sorted.forEach((ett, ind) => {
               var value = utt.utterance.replace("<span class='highlighted-intent'>", "").replace("<span/>", "").substring(ett.start, ett.end);
               var entity = ett.entity;
               var str = '';

               if (ett.value === value) {
                  str = '<' + value + '>' + '(' + entity + ')';
               } else {
                  str = '<' + value + '>' + '(' + entity + ':' + ett.value + ')';
               }

               var part1 = row.utterance.slice(0, ett.start + offset);
               var part2 = row.utterance.slice(ett.end + offset, row.utterance.length);

               row.utterance = part1 + str + part2;

               // transformatated_ett[ind] = str

               offset = str.length - value.length;

               // console.log(part1)
               //console.log(offset)
            });

            // console.log("transformed", transformatated_ett)

            intent_data.push({ ...row });
         });
      });

      return intent_data;
   };

   //function called to get current selection of text by user
   const handleMouseUp = (name, key) => {
      // The entire sentance of the selected word
      // const completeText = window.getSelection().focusNode.data; // Not usefull for text that has span tag in it
      // The entire sentance without the span tag
      const completeText_without_span = window.getSelection().focusNode.parentElement.textContent;
      // Just the selected word
      const higlightedText = window.getSelection().toString();
      // const selectionStart = window.getSelection().getRangeAt(0).startOffset;
      // const selectionEnd = window.getSelection().getRangeAt(0).endOffset;
      const pos_Highlighted__Start = completeText_without_span.indexOf(higlightedText);
      const pos_Highlighted__End = pos_Highlighted__Start + higlightedText.length;
      console.log(pos_Highlighted__Start, '===pos_Highlighted__Start');
      console.log(pos_Highlighted__End, '====pos_Highlighted__End');
      console.log(higlightedText, '======higlightedText');
      if (higlightedText.length !== 0) {
         setactive({ name: name, index: key, id: null });
         settitle('Add an Entity');
         setentity({
            start: pos_Highlighted__Start,
            end: higlightedText.indexOf(' ') >= 0 ? pos_Highlighted__End - 1 : pos_Highlighted__End,
            value: higlightedText.trim(),
            entity: null,
         });
         highlightText(currentI);
      }
   };

   const OnAddEntity = () => {
      if (entity.start == null || entity.end == null) return
      dispatch(AddEntity(bot_name, currentI.intent, active.index, entity, items, tk, existing_intents, history));
      setshow(false);
      setactive({ name: null, index: null });
      settitle(null);
      highlightText(currentI);
   };

   //funcion called when user edits utterances
   const OnEditUtterence = new_utt => {
      dispatch(EditUtternce(bot_name, currentI.intent, new_utt, active.id, items, tk, existing_intents, history));
      setshow(false);
      setactive({ name: null, index: null });
      settitle(null);
   };

   const handleUpdateHint = token => {
      Axios('/accounts/updateHint/', {
         method: 'POST',
         headers: {
            Authorization: `Token ${token}`,
         },
         data: JSON.stringify({
            hint: false,
         }),
      })
         .then(res => {
            console.log(res, ' update res');
         })
         .catch(err => {
            console.log(err, ' update error');
         });
   };

   //funtion to save everything user created
   const Saveutterances = (data = null) => {
      var tosave = [];
      const unaccepted = [];
      var string = '';

      if (eddited.length === 0) {
         return;
         setAlertContent({
            error: '',
            message: 'Nothing to Save',
         });
         setModalShow(true);
      }

      eddited.forEach((element, key) => {
         tosave[key] = items.find(item => {
            return item.intent === element;
         });
      });

      tosave.forEach((element, key) => {
         if (element.utterances.length < 5) {
            unaccepted.push(element);
            if (string === '') {
               string = string + element.intent;
            } else {
               string = string + ' , ' + element.intent;
            }
         }
      });

      if (string !== '') {
         setAlertContent({
            error: '',
            message: 'Please add atleast 5 Utterences for the following intents :- ' + string,
         });
         setModalShow(true);
      } else {
         dispatch(
            SaveUtterences(bot_name, tosave, tk, () => {
               setTrainDisabled(false);
               setAlertContent(
                  {
                     error: 'Success Message',
                     message: 'Utterances Saved Successfully',
                  },
                  history
               );
               setModalShow(true);
            })
         );
      }
   };

   useEffect(() => {
      if (props.hint.hint_count == 3) {
         if (!showSecondTooltip && !showThirdtooltip) {
            let right = document.querySelector('#intent-tooltip');
            let tooltip = document.querySelector('.intent-tooltip');

            if (tooltip) {
               tooltip.style.top =
                  Math.floor(tooltip.getBoundingClientRect().height) - (right.getBoundingClientRect().top - 220) + 'px';
               tooltip.style.left = Math.floor(right.getBoundingClientRect().left + 80) + 'px';
            }
         } else if (showSecondTooltip && !showThirdtooltip) {
            const right = document.querySelector('#intent-tooltip');
            const tooltip = document.querySelector('.utterance-tooltip');

            if (tooltip) {
               tooltip.style.top =
                  Math.floor(tooltip.getBoundingClientRect().height) - (right.getBoundingClientRect().top - 220) + 'px';
               tooltip.style.left = Math.floor(right.getBoundingClientRect().left + 80) + 'px';
            }
         } else {
            let right = document.querySelector('#intent-tooltip');
            let tooltip = document.querySelector('.entity-tooltip');
            if (tooltip) {
               tooltip.style.top =
                  Math.floor(tooltip.getBoundingClientRect().height) - (right.getBoundingClientRect().top - 220) + 'px';
               tooltip.style.left = Math.floor(right.getBoundingClientRect().left + 80) + 'px';
            }
         }
      }
   }, [showSecondTooltip]);

   //function to search intents
   const searchIntent = Salert => {
      const result = items.filter(intent => {
         return intent.intent.toLowerCase().includes(searchi.toLowerCase());
      });
      if ((result.length === 0) & Salert) {
         setAlertContent({
            error: '',
            message: 'No Intent with ' + searchi + ' found',
         });
         setModalShow(true);
      } else {
         setresult(result);
      }
   };

   useEffect(() => {
      const user = localStorage.getItem('user');
      if (!props.hint.showHint) {
         let usr = { ...JSON.parse(user) };
         usr.hint = false;
         localStorage.setItem('user', JSON.stringify(usr));
         handleUpdateHint(usr.token);
      }
   }, [props.hint.showHint]);

   // console.log(data.substring(el.start, el.end)
   // if (items[0]) {
   //    items[0].utterances.forEach(data =>
   //       data.entities.forEach(el => console.log(data.utterance.substring(el.start, el.end)))
   //    );
   // }

   const showUtteranceList = item => {
      highlightText(item);

      setcurrentI(item);
      utt.current.scrollIntoView({ behavior: 'smooth' });
   };

   const Train = () => {
      if (isTrainDisabled) {
         return;
      }
      setTrainDisabled(true);
      TrainNlu(bot_name, tk, history).then((res) => {
         if (res == true) {
            setAlertContent({
               error: 'Success Message',
               message: 'NLU Trained Successfully'
            });
            setModalShow(true);
         }
      }).catch((error) => {
         setTrainDisabled(false);
      })
   }

   return (
      <div className="row">
         <Alert
            show={showModal}
            onHide={() => setModalShow(false)}
            message={alertContent.message}
            Error={alertContent.error}
            className="modal-container-class"
         />

         <div id="intent-tooltip" className="intent-container">
            {sopen ? (
               <div className="suggestion-container">
                  <div className="suggestion s-active">new suggestion</div>
                  <div className="suggestion">new suggestion</div>
                  <div className="suggestion">new suggestion</div>
                  <div className="suggestion">new suggestion</div>
               </div>
            ) : null}

            {title === 'Edit Utterence' ? null : (
               <Inputpopup
                  ref={popup}
                  show={show}
                  value={entity.value}
                  title={title}
                  onClick={() => {
                     OnAddEntity();
                  }}
                  onChange={e => {
                     setentity({ ...entity, entity: e.target.value });
                  }}
                  onValueChange={e => {
                     setentity({ ...entity, value: e.target.value });
                  }}
               />
            )}
            {title === 'Edit Utterence' ? (
               <Inputpopup
                  ref={popup}
                  show={show}
                  value={active.name}
                  title={title}
                  onClick={new_utt => {
                     OnEditUtterence(new_utt);
                  }}
                  onChange={e => {
                     setentity({ ...entity, entity: e.target.value });
                  }}
                  bttext="Save Changes"
               />
            ) : null}
            <div className="header">
               <div className="cont1">
                  <p className="headings">Intents</p>
                  <div className="search-box" id="search-input">
                     <input
                        ref={search12}
                        className="input"
                        placeholder="Search Intent here..."
                        onChange={e => {
                           setsearchi(e.currentTarget.value);
                           searchIntent(false);
                        }}
                        value={searchi}
                     />
                     <div
                        className="image-container"
                        onClick={() => {
                           searchIntent(true);
                        }}>
                        <img src={search} />
                     </div>
                     <div id="result-container" className={`result-container inactive`}>
                        {result.map(item => {
                           return (
                              <div
                                 className="result"
                                 onClick={() => {
                                    setcurrentI(item);
                                    utt.current.scrollIntoView({ behavior: 'smooth' });
                                    setsearchi('');
                                    setresult([]);
                                 }}>
                                 {item.intent}
                              </div>
                           );
                        })}
                     </div>
                  </div>
               </div>

               <div className="cont2">
                  <CSVReader
                     cssClass="csv-reader-input"
                     label=""
                     onFileLoaded={csvJSON}
                     inputId="ObiWan"
                     inputStyle={{ color: 'transparent' }}
                  />
                  <div className="import-intents">Import Intents</div>
                  <CsvDownload data={ExportIntent()} filename={bot_name + '_Intents.csv'} />
                  <div className="export-intents" onClick={ExportIntent}>
                     Export Intents
                  </div>
               </div>
            </div>

            <OverlayTrigger
               placement={'top'}
               show={props.hint.showHint && props.hint.hint_count == 3 && !showSecondTooltip && !showThirdtooltip}
               trigger="click"
               overlay={() => (
                  <Tooltip
                     heading="Create Intent"
                     paragraph="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
                     onClosePress={() => {
                        props.isVisivleTooltip(false);
                     }}
                     onBackPress={() => {
                        props.history.push('/creatbot');
                     }}
                     onNextPress={() => {
                        setshowSecondTooltip(true);
                     }}
                     circles={[1, 2, 3, 4]}
                     hintCount={props.hint.hint_count}
                     height={230}
                     back={true}
                     class="intent-tooltip"
                  />
               )}>
               <IntentCard
                  style={{
                     zIndex: !showSecondTooltip && !showThirdtooltip && props.hint.hint_count == 3 ? 200 : 100,
                  }}
                  onChange={() => {
                     //setsopen(true);
                  }}
                  type="intent"
                  data={items}
               />
            </OverlayTrigger>

            <div className="intent-list-container">
               <div className="list-header">
                  <p>Existing Intents</p>
                  <p style={{ marginRight: 40 }}>Action</p>
               </div>

               <div className="item-wrap">
                  {items.map((item, key) => (
                     <div
                        className={`item-row ${key % 2 !== 0 ? 'alt' : ''}`}
                        key={key}
                        onClick={() => showUtteranceList(item)}>
                        <div className="item-row2" onClick={() => { }}>
                           <div className="item-circle">{item.intent.charAt(0)}</div>
                           <p className="item-text">{item.intent}</p>
                        </div>
                        <ImageTag
                           className="del-icon"
                           style={{ marginRight: 65 }}
                           src={delIcon}
                           onClick={() => {
                              const cd = window.confirm('Are you sure you want to delete this intent?');
                              if (cd) {
                                 onDelete(item.intent);
                              }
                           }}
                        />
                     </div>
                  ))}
               </div>
               {isloading ? (
                  <div className="item-wrap">
                     <div className="item-row" style={{ justifyContent: 'center', padding: 0 }}>
                        <p className="item-text">Loading...</p>
                     </div>
                  </div>
               ) : null}
            </div>

            <p className="headings ut">Utterances</p>
            <div style={{ opacity: currentI.intent === null ? 0.55 : 1 }}>
               <OverlayTrigger
                  placement={'top'}
                  show={props.hint.showHint && showSecondTooltip && !showThirdtooltip}
                  trigger="click"
                  overlay={() => (
                     <Tooltip
                        onClosePress={() => {
                           props.isVisivleTooltip(false);
                        }}
                        heading="Create Entities/Utterances"
                        paragraph="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
                        onBackPress={() => {
                           setshowSecondTooltip(false);
                        }}
                        onNextPress={() => {
                           setshowSecondTooltip(false);
                           setshowThirdTooltip(true);
                        }}
                        circles={[1, 2, 3, 4]}
                        hintCount={props.hint.hint_count}
                        height={230}
                        back={true}
                        class="utterance-tooltip"
                     />
                  )}>
                  <IntentCard
                     style={{
                        zIndex: props.hint.hint_count == 3 && showSecondTooltip ? 200 : 100,
                     }}
                     intent={currentI.intent}
                     data={items}
                     setTrainDisabled={() => { setTrainDisabled(false) }}
                  />
               </OverlayTrigger>

               <div className="utterances-list-container" ref={utt}>
                  {(currentI.intent !== null) & (currentI.utterances.length === 0) ? null : <p>Utterances</p>}
                  <div className="item-wrap" style={{ paddingBottom: 20 }}>
                     {currentI.utterances.map((item, key) => (
                        <div className="item-row" key={key}>
                           <div
                              className="utterances-input highlight-section"
                              ref={el => (inpt.current[key] = el)}
                              id={item.name}
                              onMouseUp={() => {
                                 handleMouseUp(item.name, key);
                              }}
                              dangerouslySetInnerHTML={{ __html: item.utterance }}></div>
                           <div className="item-row2">
                              <ImageTag
                                 id="icon"
                                 className={`del-icon ${active.index === key ? 'active' : null}`}
                                 src={active.index === key ? addBlue : addIcon}
                                 onClick={() => {
                                    if (active.index === key) {
                                       setshow(true);
                                       popup.current.scrollIntoView({ behavior: 'smooth' });
                                    }
                                 }}
                              />
                              <ImageTag
                                 id="icon"
                                 className="del-icon"
                                 src={editIcon}
                                 onClick={() => {
                                    setactive({
                                       name: item.utterance,
                                       index: null,
                                       id: item.id,
                                    });
                                    settitle('Edit Utterence');
                                    setshow(true);
                                    popup.current.scrollIntoView({ behavior: 'smooth' });
                                 }}
                              />
                              <ImageTag
                                 className="del-icon"
                                 src={delIcon}
                                 onClick={() => {
                                    const cd = window.confirm('Are you sure you want to delete this utterence?');
                                    if (cd) {
                                       dispatch(
                                          DeleteUtterence(
                                             bot_name,
                                             currentI.intent,
                                             item.id,
                                             tk,
                                             existing_intents,
                                             history
                                          )
                                       );
                                    }
                                 }}
                              />
                           </div>
                        </div>
                     ))}
                  </div>
                  {(currentI.intent !== null) & (currentI.utterances.length === 0) ? (
                     <div className="item-wrap">
                        <div className="item-row" style={{ justifyContent: 'center', padding: 0 }}>
                           <p className="item-text" style={{ padding: 0 }}>
                              No Utterances Created
                           </p>
                        </div>
                     </div>
                  ) : null}
               </div>

               <OverlayTrigger
                  placement={'top'}
                  show={props.hint.showHint && props.hint.hint_count == 3 && !showSecondTooltip && showThirdtooltip}
                  trigger="click"
                  overlay={() => (
                     <Tooltip
                        heading="Add values to utterances"
                        paragraph="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
                        onClosePress={() => {
                           props.isVisivleTooltip(false);
                        }}
                        onBackPress={() => {
                           setshowThirdTooltip(false);
                           setshowSecondTooltip(true);
                        }}
                        onNextPress={() => {
                           props.isVisivleTooltip(false);
                        }}
                        circles={[1, 2, 3, 4]}
                        hintCount={props.hint.hint_count}
                        height={230}
                        back={true}
                        isDone={true}
                        isEntity={true}
                        class="entity-tooltip"
                     />
                  )}>
                  <div
                     className={`save-bt ${eddited.length == 0 ? 'disabled-bt' : ''}`}
                     onClick={() => {
                        Saveutterances();
                     }}>
                     Save Utterances
                  </div>
               </OverlayTrigger>
               <div className={`train-bt ${isTrainDisabled ? 'disabled-train' : ''}`} onClick={Train}>Train NLU</div>
            </div>
         </div>
      </div>
   );
};
const mapStateToProps = ({ hint }) => ({ hint });

const mapDispatchToProps = dispatch => ({
   isVisivleTooltip: data => dispatch(SHOWHINTBOOL(data)),
   tooltipCounter: data => dispatch(CHANGECOUNT(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Intent);
