import React from "react"

const Theme3 = () => {

    return (
        <div className="theme3-parent">

        </div>
    )
}

export default Theme3