import React, { useState, useContext } from 'react';import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { LanguageContext } from '../ChatBot';
import CardInput from './CardInput';
const Card = ({ data, remove, duplicate, addCard, update, carousel, OPTIONS,handleInputType, isOpen }) => {
   const { language } = useContext(LanguageContext);
   const [updateCard, setupdateCard] = useState(false);

   const handleClick = value => {
      if (isOpen) handleInputType(value);
   };

   const settings = {
      infinite: false,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 3,
      autoplay: false,
   };
   if (/* updateCard */true)
      return (
         <CardInput data={data} addCard={addCard} setupdateCard={setupdateCard} update={update} OPTIONS={OPTIONS} />
      );
   return (
      <div
         style={{
            backgroundColor: '#f6f8fc',
            height: '90px',
            marginBottom: '0px',
            width: '100%',
            fontSize: '14px',
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
         }}>
         <div
            className="main-diagram-carousel"
            style={{
               height: '120px',
               marginBottom: '10px',
               width: '90%',
               marginLeft: '70px',
               padding: 25,
               opacity: !isOpen ? '0.4' : '1',
               // marginRight: 'auto',
            }}>
            <Slider {...settings}>
               <div onClick={() => handleClick('text')}>
                  <img src="/images/message.svg" alt="carosal text" width="28px" height="25.8px" />
                  <p style={{ marginLeft: '1px' }}>Text</p>
               </div>

               <div onClick={() => handleClick('image')}>
                  <img src="/images/image.svg" alt="carosalImage" width="28px" height="25.8px" />
                  <p style={{ marginLeft: '-3px' }}>Image</p>
               </div>

               <div onClick={() => handleClick('card')}>
                  <img src="/images/card.svg" alt="carosal card" width="28px" height="25.8px" />
                  <p style={{ marginLeft: '-1px' }}>Card</p>
               </div>

               <div onClick={() => handleClick('form')}>
                  <img src="/images/form.svg" alt="carosal button" width="28px" height="25.8px" />
                  <p style={{ marginLeft: '-2px' }}>Form</p>
               </div>

               <div onClick={() => handleClick('carousel')}>
                  <img src="/images/carousel.svg" alt="carosal" width="28px" height="25.8px" />
                  <p style={{ marginLeft: '-10px' }}>Carousel</p>
               </div>

               <div onClick={() => handleClick('replies')}>
                  <img src="/images/replies.svg" alt="carosal rpl" width="28px" height="25.8px" />
                  <p style={{ marginLeft: '-24px' }}>Quick Replies</p>
               </div>
               <div onClick={() => handleClick('get_otp')}>
                  <img src="/images/otp.svg" alt="carosal text" width="28px" height="25.8px" />
                  <p style={{ marginLeft: '1px' }}>OTP</p>
               </div>
               <div onClick={() => handleClick('searchbar')}>
                  <img src="/images/searchbar.svg" alt="carosal text" width="28px" height="25.8px" />
                  <p style={{ marginLeft: '-20px' }}>Search Bar</p>
               </div>
               <div onClick={() => handleClick('custom_actions')}>
                  <img src="/images/customactions.svg" alt="customs_actions" width="28px" height="25.8px" />
                  <p style={{ marginLeft: '-33px' }}>Custom Actions</p>
               </div>
            </Slider>
         </div>
      </div>

      // <div className="chatbot-carousel-container">
      //    <section></section>
      // </div>
   );
};

export default Card;
