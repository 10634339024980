import React, { useState, useEffect, useRef } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { addChildrenToTree, updateTreeNode, deleteParentandChild } from './TreeManipulation';
import LinkedBox from './Node';
import Zoom from './Zoom';

import styles from './chatbot.module.scss';

const stringify = value => JSON.stringify(value);

// only topLevelNode & inValidNodes will cause this component to render
// change here if you want more dependencies!
// true will stop re-render
// false will allow re-render
const areEqual = (prevProps, nextProps) => {
   if (
      stringify(prevProps.topLevelNode) === stringify(nextProps.topLevelNode) &&
      stringify(prevProps.inValidNodes) === stringify(nextProps.inValidNodes) &&
      stringify(prevProps.OPTIONS) === stringify(nextProps.OPTIONS)
   )
      return true;
   return false;
};

function Diagram({
   topLevelNode,
   updateTopLevelNode,
   OPTIONS,
   onEditNodeClick,
   history,
   hint,
   isVisivleTooltip,
   tooltipCounter,
   SB,
   updateStory,
   inValidNodes,
}) {
   //controlled scrolling
   const container = useRef(null);
   const [entityValueSelected, setEntityValueSelected] = useState('');
   const [intentMenu, setIntentMenu] = useState('');
   const [intentSelected, setIntentSelected] = useState({});
   const [XY, setXY] = useState([0, 0]);

   //setting scroll on every change in story structure
   useEffect(() => {
      container.current.getElement().scrollTo(XY[0], XY[1]);
   });

   //zoom value set inital to 115%
   const [zoom, setzoom] = useState(0.6);

   //hanlder function to show intent menu
   /**
    * @showIntentMenu
    * @desc Whenever we click plus icon to add intent this function is called.
    * @param {object} node
    * @function updateTreeNode(node:Object,topLevelNode:Array) definition in "./TreeManipulation"
    * @function updateTopLevelNode(updateTreeNode():function) definition in "../Main"
    */
   const showIntentMenu = node => {
      updateTopLevelNode(updateTreeNode(node, topLevelNode));

      setXY([container.current.getElement().scrollLeft + 100, container.current.getElement().scrollTop]);
   };

   //add new node to story structure form selected intent value
   /**
    * @handleIntentMenu
    * @desc whenever we select intent from the menu after clicking the plus icon this function is called
    * @param {string} value
    * @param {object} node
    * @function addChildrenToTree(value:string,node:object,topLevelNode:arrray,SB:array) definition in "./TreeManipulation
    * @function updateStory(addChildrenToTree():function) definition in "./Main
    */
   const handleIntentMenu = (value, node) => {
      setIntentMenu(value.value);
      setIntentSelected({ value, node });
      console.log(node);
   };

   /**
    * Triggers on click of continue after selecting the Intent
    *
    */
   const onContinue = () => {
      updateStory(addChildrenToTree(entityValueSelected[0]?.value, intentSelected.value, intentSelected.node, topLevelNode, SB));
      setXY([container.current.getElement().scrollLeft, container.current.getElement().scrollTop]);
      setIntentMenu('');
      setEntityValueSelected([])
   };

   /**
    * Triggers when you select an Entity from the dropdown
    *
    * @param {*} value
    */
   const handleEntityValueSelected = value => {
      console.log(value)
      // console.log(value, '===value');
      setEntityValueSelected(value);
   };

   console.log(entityValueSelected)

   /**
    * Sumbit of Entity after Selecting
    *
    * @param {*} node
    */

   const onEntitySubmit = () => {
      console.log(entityValueSelected, '===entityValueSelected');
   };

   //delete handler
   /**
    * @deleteParent
    * @desc when we clck on delete it removes the node and all of its children
    * @param {object} node
    * @function deleteParentandChild(node:object,SB:array,topLevelNode:array) definition in "./TreeManipulation
    * @function updateStory(deleteParentandChild():function) definition in "./Main
    */
   const deleteParent = node => {
      updateStory(deleteParentandChild(node, SB, topLevelNode));
      setXY([container.current.getElement().scrollLeft - 500, container.current.getElement().scrollTop - 200]);
   };

   const Diagram = ({ topLevelNode, history, hint, isVisivleTooltip, tooltipCounter }) => (
      <div className={styles.DiagramContainer}>
         <ScrollContainer className={styles.scrollContainer} ref={container}>
            <div
               style={{
                  position: 'relative',
                  zoom: zoom,
               }}
               id="chatbot">
               {topLevelNode.map((enrichedNode, order) => (
                  <LinkedBox
                     history={history}
                     hint={hint}
                     isVisivleTooltip={x => isVisivleTooltip(x)}
                     tooltipCounter={x => tooltipCounter(x)}
                     node={enrichedNode}
                     intentMenu={intentMenu}
                     handleEntityValueSelected={handleEntityValueSelected}
                     entityValueSelected={entityValueSelected}
                     onEntitySubmit={onEntitySubmit}
                     intentSelected={intentSelected}
                     order={order}
                     key={JSON.stringify(enrichedNode)}
                     options={OPTIONS}
                     handleIntentMenu={handleIntentMenu}
                     onContinue={onContinue}
                     showIntentMenu={showIntentMenu}
                     onEditNodeClick={node => {
                        onEditNodeClick(node);
                        setXY([container.current.getElement().scrollLeft, container.current.getElement().scrollTop]);
                     }}
                     deleteParent={deleteParent}
                     SB={SB}
                     inValidNodes={inValidNodes}
                  />
               ))}
            </div>
         </ScrollContainer>
         <Zoom zoom={zoom} setzoom={setzoom} />
      </div>
   );
   return (
      <Diagram
         history={history}
         hint={hint}
         isVisivleTooltip={x => isVisivleTooltip(x)}
         tooltipCounter={x => tooltipCounter(x)}
         topLevelNode={topLevelNode}
      />
   );
}

export default React.memo(Diagram, areEqual);
