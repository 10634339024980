import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import ImageTag from "../ImageTag/ImageTag";

const SplashModal = props => {
   return (
      <Modal
         onHide={props.onHide}
         {...props}
         size="xl"
         show={props.show}
         aria-labelledby="contained-modal-title-vcenter"
         centered
      >
         <Modal.Header className="bg">
            <div className="heading">
               <div className="imgAndLogo">
                  <div className="l-bg">
                     <ImageTag src={require("./assets/l.png")} />
                  </div>
                  <p style={{ fontSize: 20, color: "#ffffff" }} className="bold">
                     Blue Logic Chatbot Builder
                  </p>
               </div>
               <ImageTag src={require("./assets/bl.png")} />
            </div>
         </Modal.Header>
         <Modal.Body className="modal-body" style={{ background: "#f6f8fc" }}>
            <p className="bold pH">About Product</p>
            <p className="regular fp p-m">
               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
               been the industry's standard dummy text ever since the 1500s, when an unknown printer took
               a galley .
               <br />
               <br />
               It is a long established fact that a reader will be distracted by the readable content of
               a page when looking at its layout. The point of using Lorem Ipsum is that it has a
               more-or-less normal distribution of letters, as opposed to using 'Content here, content
               here', making it look like readable English.
            </p>
            <p className="bold pH">Team Behind this Product</p>
            <p className="regular fp">
               Leo Gill, Konsta Peura, June Cha, Veeti Seppanen, Elliana Palacios, Britney Cooper,
               Eduard, Leah Stevens, Leo Gill, Konsta Peura, June Cha, Veeti Seppanen, Elliana Palacios,
               Britney Cooper, Eduard, Leah Stevens, Leo Gill, Konsta Peura, June, Christopher Gibson,
               Christopher Gibson, Leo Gill, Konsta Peura, June Cha, Veeti Seppanen, Elliana Palacios,
               Britney Cooper, Eduard, Leah Stevens, Leo Gill, Konsta Peura, June Cha, Veeti Seppanen,
               Elliana Palacios, Britney Cooper, Eduard, Leah Stevens, Leo Gill, Konsta Peura, June Cha,
               Veeti Seppanen, Elliana Palacios, Britney Cooper, Eduard, Leah Stevens, Leo Gill, Konsta
               Peura, June, Christopher Gibson, Christopher Gibson, Leo Gill, Konsta Peura, June Cha,
               Veeti Seppanen, Elliana Palacios, Britney Cooper, Eduard, Leah Stevens, Leo Gill, Konsta
               Peura, June Cha, Veeti Seppanen, Elliana Palacios, Britney Cooper, Eduard, Leah Stevens,
               Leo Gill, Konsta Peura, June Cha, Veeti Seppanen, Elliana Palacios, Britney Cooper,
               Eduard, Leah Stevens, Leo Gill, Konsta Peura, June, Christopher Gibson, Christopher
               Gibson, Leo Gill, Konsta Peura, June Cha, Veeti Seppanen, Elliana Palacios.
            </p>
         </Modal.Body>
         <Modal.Footer className="foot">
            <div className="heading">
               <p className="regular" style={{ fontSize: 14 }} className="regular">
                  © 2020 All Rights Reserverd Blue Logic Digital.
               </p>
            </div>
         </Modal.Footer>
      </Modal>
   );
};

export default SplashModal;
