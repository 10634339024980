import React, { useState, useEffect } from "react"
import Chart from "react-apexcharts";
import Axios from "axios"
import { useHistory } from "react-router-dom"

//image imports
import chat from "../../assets/images/MDchat.svg"
import live from "../../assets/images/MDlive.svg"
import bot from "../../assets/images/MDbot.svg"
import tv from "../../assets/images/MDtv.svg"
import ccare from "../../assets/images/MDccare.svg"
import webapp from "../../assets/images/webapp.svg"
import whatsapp from "../../assets/images/whatsapp.svg"
import facebook from "../../assets/images/facebook.svg"

import blogo from '../../assets/images/blogo.svg';
import logoutb from '../../assets/images/logout.svg';

import s1 from "../../assets/images/smiley1.svg"
import s2 from "../../assets/images/smiley2.svg"
import s3 from "../../assets/images/smiley3.svg"

import { useLocation } from 'react-router-dom';

const MasterDashboard = props => {
   const location = useLocation();
   //const history = location.pathname;
   function logout() {
      localStorage.clear();
      props.history.push('/MLogin');
   }

   const [data, setdata] = useState({
      conversation_so_far: 0,
      live_conversations: 0,
      no_of_bots: 0,
      live_agent_count: 0,
      positive: 0,
      negative: 0,
      neutral: 0,
   });

   const [chartData, setchartData] = useState({
      series: [40, 60, 30],
      options: {
         plotOptions: {
            pie: {
               donut: {
                  size: '45%',
               },
            },
         },
         dataLabels: {
            enabled: false,
         },
         legend: {
            show: false,
            position: 'bottom',
         },
         colors: ['#43b3e6', '#4a4cab', '#ffc107'],
         xaxis: {
            categories: ['positive', 'negative', 'neutral'],
         },
      },
   });

    const history = useHistory()

    useEffect(() => {
        ApiCall()
    }, [])


    useEffect(() => {
        console.log("hey")
        if (localStorage.getItem('admin_token') == null) {
            history.push("/MLogin")

        }
    }, [])

    const ApiCall = () => {
        Axios.get("http://ec2-3-129-11-183.us-east-2.compute.amazonaws.com:5000/live-data-main-dashboard", {
            headers: {
                token: localStorage.getItem('admin_token')
            }
        }).then((res) => {
            setchartData({ ...chartData, series: [Math.round(res.data.good_percentage * 10) / 10, Math.round(res.data.bad_percentage * 10) / 10, Math.round(res.data.okay_percentage * 10) / 10] })
            setdata({
               conversation_so_far: res.data.total_conversation_count,
               live_conversations: res.data.live_conversation_count,
               no_of_bots: res.data.total_number_of_bots,
               live_agent_count: res.data.live_agent_count,
               positive: Math.round(res.data.good_percentage * 10) / 10,
               negative: Math.round(res.data.bad_percentage * 10) / 10,
               neutral: Math.round(res.data.okay_percentage * 10) / 10,
            });
            console.log(res.data);
         })
         .catch(error => {
            console.log(error);
         });

      setTimeout(() => {
         ApiCall();
      }, 900000);
   };

   return (
      <div className="Mdas">
         <div class="Header">
            <div class="innerDiv">
               <div class="Bell-logo" onClick={logout}>
                  <img src={logoutb} class="icon" alt="" loading="lazy" />
                  <p>Logout</p>
               </div>
               <div class="Bell-logout">
                  <img src={blogo} class="icon" alt="" loading="lazy" />
               </div>
            </div>
         </div>
         <div className="masterdashboard-parent">
            <div className="heading">Master Dashboard</div>
            <div className="row">
               <div className="row1 child">
                  <img className="image" src={chat} />
                  <p>Number of conversations so far</p>
                  <p className="number">{data.conversation_so_far}</p>
               </div>
               <div className="row1 child">
                  <img className="image" src={live} />
                  <p>Live conversations happening on all bots</p>
                  <p className="number">{data.live_conversations}</p>
               </div>
               <div className="row1 child">
                  <img className="image" src={bot} />
                  <p>Total number of bots</p>
                  <p className="number">{data.no_of_bots}</p>
               </div>
            </div>
            <div className="row">
               <div className="row2 child">
                  <img className="image" src={tv} />
                  <p>Total Channels</p>
                  <div className="icon-row">
                     <div className="icon-container">
                        <img src={webapp} />
                        <div className="icon-text">Web App</div>
                     </div>
                     <div className="icon-container">
                        <img src={whatsapp} />
                        <div className="icon-text">Whatsapp</div>
                     </div>
                     <div className="icon-container">
                        <img src={facebook} />
                        <div className="icon-text">Facebook</div>
                     </div>
                  </div>
               </div>
               <div className="row2 child">
                  <p>Sentiment Analysis Score</p>
                  <div className="chart">
                     <Chart
                        options={chartData.options}
                        series={chartData.series}
                        type="donut"
                        width="180"
                        height="180"
                     />
                     <div className="overlay"></div>
                  </div>
                  <div className="icon-row faces">
                     <div className="face-container">
                        <div className="top-line">
                           <img src={s1} />
                           <div className="face-text">Positive</div>
                        </div>
                        <div className="bottom-line">{data.positive + '%'}</div>
                     </div>
                     <div className="face-container">
                        <div className="top-line">
                           <img src={s2} />
                           <div className="face-text">Negative</div>
                        </div>
                        <div className="bottom-line">{data.negative + '%'}</div>
                     </div>
                     <div className="face-container">
                        <div className="top-line">
                           <img src={s3} />
                           <div className="face-text">Neutral</div>
                        </div>
                        <div className="bottom-line">{data.neutral + '%'}</div>
                     </div>
                  </div>
               </div>
               <div className="row2 child">
                  <img className="image" src={ccare} />
                  <p>Number of Live Agent take overs</p>
                  <p className="number">{data.live_agent_count}</p>
               </div>
            </div>
         </div>
      </div>
   );
};

export default MasterDashboard