import React, { useState } from "react";
import Input from "../inputWithIcon/inputWithIcon";
import AddUser from './adduser';
import BillingInfo from './Billinginfo';
import ProfileDetails from "./ProfileDetails"


const Profile = () => {
   const [type, setType] = useState("profile-details")
   const [active, setActive] = useState(0)
   const [activeBox, setActiveBox] = useState(0)


   return (
      <div className="home-container">
         <div className="tabs-container">
            <div className="tab-d">
            </div>
            {/* <div onClick={() => setType("billing-info")} className={type === "billing-info" ? "tab active-tab" : "tab"}>
               <p className={type === "billing-info" ? "tab-text active-text medium" : " medium tab-text"}>Billing Info</p>
            </div> */}
            <div onClick={() => setType("profile-details")} className={type === "profile-details" ? "tab active-tab mr-both-118" : "tab mr-both-118"}>
               <p className={type === "profile-details" ? "medium tab-text active-text" : "medium tab-text"}>Profile</p>
            </div>
            {/* <div onClick={() => setType("add-user")} className={type === "add-user" ? "tab active-tab" : "tab"}>
               <p className={type === "add-user" ? "tab-text active-text medium" : " medium tab-text"}>Add User</p>
            </div> */}
            <div className="tab-d">
            </div>
         </div>
         <div className="cont-container">
            <p className="bolder t-26">{
               type === "profile-details" ? "Profile" : (type === "add-user" ? "User Access Management" : "Plan & Billing Details")
            }</p>
            {(type === "profile-details" || type === "add-user")
               && <div className="box-profile">
                  {type === "profile-details" && <ProfileDetails />}
                  {type === "add-user" && <AddUser
                     Input={Input}
                     active={active}
                     activeBox={activeBox}
                     setActive={(x) => setActive(x)}
                     setActiveBox={(x) => setActiveBox(x)}
                  />}
               </div>}

            {type === "billing-info" && <BillingInfo page={2} />}

         </div>
      </div>
   );
};
export default Profile;
