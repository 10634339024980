import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import Chart from "react-apexcharts";
import json from "./dashboard_main_api.json";
import { Link } from "react-router-dom";
import s1 from "../../assets/images/smiley1.svg"
import s2 from "../../assets/images/smiley2.svg"
import s3 from "../../assets/images/smiley3.svg"
import nomsg from "../../assets/images/nomsg.svg"

const AnalyticsDashboard = props => {
   const {
      average_conversation_time,
      devices,
      lifetime_conversations,
      message_activity,
      sentiment_analysis,
      top_intents
   } = props.analyticsData

   const {
      active_conversations,
      live_message_rate
   } = props.analytics15MData

   const history = useHistory();


   useEffect(() => {
      if (top_intents !== undefined) {
         SortData(top_intents)
      }
   }, [top_intents])

   console.log(sentiment_analysis)

   const [topintent, settopintents] = useState({ labels: [], data: [] })



   const SortData = (intent_data) => {
      //console.log(Object.keys(intent_data[0]))
      var labels = []
      var data = []
      Object.keys(intent_data[0]).forEach(key => {
         labels.push(key)
         data.push(intent_data[0][key])
      });

      console.log(labels)
      console.log(data)

      settopintents({ labels: labels.reverse(), data: data.reverse() })
   }

   return (
      <div className="analytics-container">
         <div className="cont-container">
            <p className="t-26">Analytics Dashboard</p>
            <div className="box-2">
               <div className="row box-container">
                  <div style={{ marginRight: 16 }} className="box1">
                     <div className="t-16">Avg Conversation Time</div>
                     <div className="timer-text">{average_conversation_time}</div>
                  </div>
                  <div className="box1">
                     <div className="t-16">Lifetime Conversations</div>
                     <div className="timer-text">{lifetime_conversations}</div>
                  </div>
               </div>
            </div>

            <div className="box-2">
               <div className="row box-container">
                  <div className="box2">
                     <div className="t-16 t-1234">Active Conversation</div>
                     <div className="t-64">{active_conversations}</div>
                     <div className="bottom-text">In the last 15 mins</div>
                  </div>
                  <div className="box3">
                     <div className="t-16">Message Activity</div>
                     <Chart
                        options={{
                           series: [
                              {
                                 data: (message_activity && message_activity.length) ? [
                                    message_activity[0].incoming,
                                    message_activity[0].outgoing,
                                 ] : [
                                    json.message_activity[0].incoming,
                                    json.message_activity[0].outgoing,
                                 ],
                              },
                           ],
                           chart: {
                              type: "bar",
                              height: "20px",
                           },
                           grid: {
                              show: false
                           },
                           plotOptions: {
                              bar: {
                                 horizontal: true,
                                 barHeight: '30%',
                                 distributed: true,
                              },

                           },
                           legend: {
                              show: false,
                           },
                           dataLabels: {
                              enabled: false,
                           },
                           colors: ['#43b3e6', '#4a4cab'],
                           xaxis: {
                              categories: ["incoming", "outgoing"],
                              labels: {
                                 show: false
                              },
                              axisBorder: {
                                 show: false
                              },
                              axisTicks: {
                                 show: false
                              }
                           },
                           tooltip: {
                              enabled: true,
                              y: {
                                 formatter: function (val) {
                                    return val + ".00"
                                 },
                                 title: {
                                    formatter: function (seriesName) {
                                       console.log(seriesName)
                                       return ''
                                    }
                                 }
                              }
                           },
                           yaxis: {
                              axisBorder: {
                                 show: false
                              },
                              axisTicks: {
                                 show: false
                              },
                              labels: {
                                 minWidth: 70,
                                 maxWidth: 70,
                                 left: 0
                              },
                           }
                        }}
                        series={[
                           {
                              data: (message_activity && message_activity.length) ? [
                                 message_activity[0].incoming,
                                 message_activity[0].outgoing,
                              ] : [
                                 json.message_activity[0].incoming,
                                 json.message_activity[0].outgoing,
                              ],
                           },
                        ]}
                        type="bar"
                        width="650"
                        height="50%"
                     />
                     <div className="graph-text">
                        <div className="graph-text-sub">
                           <div className="text">Incoming</div>
                           <p className="bolder t-36">{(message_activity && message_activity.length) && message_activity[0].incoming}</p>
                        </div>
                        <div className="graph-text-sub">
                           <div className="text">Outgoing</div>
                           <p className="bolder t-36">{(message_activity && message_activity.length) && message_activity[0].outgoing}</p>
                        </div>
                        <div className="graph-text-sub">
                           <div className="text">Total</div>
                           <p className="bolder t-36">{(message_activity && message_activity.length) && message_activity[0].total}</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div className="box-2">
               <div className="row box-container">
                  <div className="box2">
                     {(devices && devices.length && devices[0].desktop !== 0 && devices[0].mobile !== 0) ? <img className="iconDivDesktop" src={require('./assets/desktop.svg')} /> : null}
                     {(devices && devices.length && devices[0].desktop !== 0 && devices[0].mobile !== 0) ? <img className="iconDivMobile" src={require('./assets/mobile.svg')} /> : null}

                     <p className="medium t-16">Devices</p>
                     {(devices && devices.length && devices[0].desktop !== 0 && devices[0].mobile !== 0) ? <div>
                        <Chart
                           options={{
                              series: {
                                 data: (devices && devices.length) ? [
                                    devices[0].desktop,
                                    devices[0].mobile,
                                 ] : [

                                 ],
                              },
                              chart: {
                                 type: "bar",
                              },
                              plotOptions: {
                                 bar: {
                                    horizontal: true,
                                    barHeight: '30%',
                                    distributed: true,
                                 },
                              },
                              legend: {
                                 show: false,
                              },
                              dataLabels: {
                                 enabled: false,
                              },
                              colors: ['#43b3e6', '#4a4cab'],
                              xaxis: {
                                 categories: ["desktop", "mobile"],
                                 labels: {
                                    show: false
                                 },
                                 axisBorder: {
                                    show: false
                                 },
                                 axisTicks: {
                                    show: false
                                 }
                              },
                              yaxis: {
                                 axisBorder: {
                                    show: false
                                 },
                                 axisTicks: {
                                    show: false
                                 },
                                 labels: {
                                    show: true,
                                    style: {
                                       colors: ['#fff', '#fff'],
                                       fontSize: '14px',
                                       display: 'none'
                                    }
                                 }
                              },
                              grid: {
                                 show: false
                              },
                              tooltip: {
                                 enabled: true,
                                 y: {
                                    formatter: function (val) {
                                       return val + ".00"
                                    },
                                    title: {
                                       formatter: function (seriesName) {
                                          console.log(seriesName)
                                          return ''
                                       }
                                    }
                                 }
                              }
                           }}
                           series={[
                              {
                                 data: (devices && devices.length) ? [
                                    devices[0].desktop,
                                    devices[0].mobile,
                                 ] : [

                                 ],
                              },
                           ]}
                           type="bar"
                           width="300"
                           height="40%"
                        />
                        <div className="graph-text">
                           <div className="graph-text-sub">
                              <p className="regular text">Desktop</p>
                              <p className="bolder t-36">{(devices && devices.length) && devices[0].desktop}</p>
                           </div>
                           <div className="graph-text-sub">
                              <p className="regular text">Mobile</p>
                              <p className="bolder t-36">{(devices && devices.length) && devices[0].mobile}</p>
                           </div>
                        </div>
                     </div> : <img src={nomsg} style={{ padding: 80 }} />}
                  </div>
                  <div className="box3 pd-bottom-20">
                     <div className="live-text-and-p-container">
                        <div className="live-text-container">
                           <div className="live-text">
                              <img src={require('./assets/live.svg')} />
                           </div>
                        </div>
                        <p className="medium t-16 wd-66">Live Message Rate</p>
                     </div>
                     {live_message_rate !== undefined && live_message_rate.length !== 0 ? <div className="chart-bar">
                        <Chart
                           options={{
                              chart: {
                                 type: "bar",
                              },
                              // fill:{
                              //    colors: ["rgba(67, 179, 230, 0.85)","rgba(74, 76, 171, 0.85)","#000000"]
                              // },
                              // colors: ["rgba(67, 179, 230, 0.85)","rgba(74, 76, 171, 0.85)"],
                              // colors: (live_message_rate && live_message_rate.length) ?
                              //    live_message_rate.map(el => { return "#43b3e6" })
                              //    : [],
                              xaxis: {
                                 categories: (live_message_rate && live_message_rate.length) ?
                                    live_message_rate.map(el => { return el.time })
                                    : [],
                                 axisTicks: {
                                    show: false
                                 },
                              },
                              plotOptions: {
                                 bar: {
                                    distributed: true,
                                    columnWidth: '20%'
                                 },
                              },
                              stroke: {
                                 show: true,
                                 width: 2,
                                 colors: ['transparent']
                              },
                              legend: {
                                 show: false,
                              },
                              dataLabels: {
                                 enabled: false,
                              },
                              yaxis: {
                                 labels: {
                                    show: true,
                                    style: {
                                       colors: ['#666666', '#666666', '#666666', '#666666', '#666666', '#666666', '#666666', '#666666',],
                                       fontSize: '14px',
                                    }
                                 }
                              },
                              tooltip: {
                                 enabled: true,
                                 y: {
                                    formatter: function (val) {
                                       return val + ".00"
                                    },
                                    title: {
                                       formatter: function (seriesName) {
                                          console.log(seriesName)
                                          return ''
                                       }
                                    }
                                 }
                              }
                           }}
                           series={[{
                              name: 'Net Profit',
                              data: (live_message_rate && live_message_rate.length) ?
                                 live_message_rate.map(el => { return el.incoming })
                                 : [],
                              colors: ["#000000"]
                           }, {
                              name: 'Free Cash Flow',
                              data: (live_message_rate && live_message_rate.length) ?
                                 live_message_rate.map(el => { return el.outgoing })
                                 : []
                           }]}
                           type="bar"
                           width="650"
                           height="250"
                        />
                     </div> : <img src={nomsg} style={{ alignSelf: "center", marginTop: "auto", marginBottom: "auto" }} />}
                  </div>
               </div>
            </div>

            <div className="box-2">
               <div className="row box-container">
                  <div className="box3 st-12" onClick={() => {
                     history.push({
                        pathname: 'activity',
                        state: {
                           nav: 'Activity',
                           type: 'All Intents'
                        }
                     })
                  }}>
                     <p className="medium t-16">Top Intents</p>
                     <Chart
                        options={{
                           series: [
                              {
                                 data: topintent.data,
                              },
                           ],
                           colors: ["#43b3e6"],
                           chart: {
                              type: "bar",
                           },
                           plotOptions: {
                              bar: {
                                 horizontal: true,
                                 barHeight: '35%',
                              }
                           },
                           legend: {
                              show: true,
                           },
                           dataLabels: {
                              enabled: false,
                              style: {
                                 colors: ['#333333'],
                                 fontSize: "14px"
                              },
                              offsetX: 100
                           },
                           xaxis: {
                              categories: topintent.labels,
                           },
                           yaxis: {
                              labels: {
                                 show: true,
                                 style: {
                                    fontSize: '14px',
                                 }
                              }
                           },
                           grid: {
                              show: false
                           },
                           tooltip: {
                              enabled: true,
                              y: {
                                 formatter: function (val) {
                                    return val + ".00"
                                 },
                                 title: {
                                    formatter: function (seriesName) {
                                       console.log(seriesName)
                                       return ''
                                    }
                                 }
                              }
                           }
                        }}
                        series={[
                           {
                              data: topintent.data,
                           },
                        ]}
                        type="bar"
                        width="650"
                        height="85%"
                     />
                  </div>
                  <div className="box2" style={{ marginBottom: 50, overflow: 'hidden' }}>
                     <div className="t-16" style={{ marginBottom: 10 }}>Sentiment Analysis</div>
                     {(sentiment_analysis && sentiment_analysis.length && sentiment_analysis[0].positive && sentiment_analysis[0].negative && sentiment_analysis[0].neutral) ? <Chart
                        options={{
                           plotOptions: {
                              pie: {
                                 donut: {
                                    size: '45%'
                                 }
                              }
                           },
                           dataLabels: {
                              enabled: false,
                           },
                           legend: {
                              show: false,
                              position: 'bottom'
                           },
                           colors: ["#43b3e6", "#4a4cab", "#ffc107"],
                           xaxis: {
                              //categories: ["positive", "negative", "neutral"],
                           },
                           tooltip: {
                              enabled: true,
                              y: {
                                 formatter: function (val) {
                                    return val + ".00"
                                 },
                                 title: {
                                    formatter: function (seriesName) {
                                       console.log(seriesName)
                                       return ''
                                    }
                                 }
                              }
                           }
                        }}
                        series={(sentiment_analysis && sentiment_analysis.length) ? [
                           sentiment_analysis[0].positive,
                           sentiment_analysis[0].negative,
                           sentiment_analysis[0].neutral,
                        ] : [
                           0,
                           0,
                           0
                        ]}
                        type="donut"
                        width="500"
                        height="180"
                     /> : <img src={nomsg} style={{ padding: 65 }} />}
                     {/* <div className="circle"></div> */}
                     {(sentiment_analysis && sentiment_analysis.length && sentiment_analysis[0].positive && sentiment_analysis[0].negative && sentiment_analysis[0].neutral) ? <div className="graph-text wd-70">
                        <div className="graph-text-sub" onClick={() => {
                           history.push({
                              pathname: 'activity',
                              state: {
                                 containingtext: 'rate_good',
                                 start_date: '1609487511000',
                                 end_date: '1893484311000',
                                 nav: 'Conversations',
                                 type: 'Chat Scripts'
                              }
                           })
                        }}>
                           <div className="text-and-circle-container">
                              <img src={s1} className="simg" />
                              <div className="stext">Positive</div>
                           </div>
                           <p className="f-14">{(sentiment_analysis && sentiment_analysis.length) && sentiment_analysis[0].positive}</p>
                        </div>
                        <div className="graph-text-sub" onClick={() => {
                           history.push({
                              pathname: 'activity',
                              state: {
                                 containingtext: 'rate_bad',
                                 nav: 'Conversations',
                                 type: 'Chat Scripts'
                              }
                           })
                        }}>
                           <div className="text-and-circle-container">
                              <img src={s2} className="simg" />
                              <div className="stext">Negative</div>
                           </div>
                           <p className="f-14">{(sentiment_analysis && sentiment_analysis.length) && sentiment_analysis[0].negative}</p>
                        </div>
                        <div className="graph-text-sub" onClick={() => {
                           history.push({
                              pathname: 'activity',
                              state: {
                                 containingtext: 'rate_okay',
                                 nav: 'Conversations',
                                 type: 'Chat Scripts'
                              }
                           })
                        }}>
                           <div className="text-and-circle-container">
                              <img src={s3} className="simg" />
                              <div className="stext">Neutral</div>
                           </div>
                           <p className="f-14">{(sentiment_analysis && sentiment_analysis.length) && sentiment_analysis[0].neutral}</p>
                        </div>
                     </div> : null}
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};
export default AnalyticsDashboard;
