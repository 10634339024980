import IntentReducer from '../reducer/Intent';
import thunk from 'redux-thunk';
import HintReducer from '../reducer/hint';
import ProfileReducer from '../reducer/profile';
import SBReducer from '../reducer/storybuilder';
import Notification from '../reducer/notification';
import LiveAgent from '../reducer/liveagent';
import { combineReducers, createStore, applyMiddleware } from 'redux';

const rootReducer = combineReducers({
   home: IntentReducer,
   hint: HintReducer,
   profile: ProfileReducer,
   users: Notification,
   storybuilder: SBReducer,
   liveagent: LiveAgent,
});

const configureStore = () => {
   return createStore(rootReducer, applyMiddleware(thunk));
};

export default configureStore;
