import React, { useEffect, useState } from "react";
import ImageTag from '../../../components/ImageTag/ImageTag';

const ChatAutoSuggestMessage = ({message, isHuman, options, onClick, language}) =>{
    const [isDisabled, setIsDesabled] = useState(false)
    return(
        <>
            <div className="suggestion-parant">
                <div className="image">
                    <ImageTag src={require('../assets/botIcon.svg')} />
                </div>
                <div className="message-container">
                    <p className="medium text">{message}</p>
                </div>
            </div>
            <div className="suggestion-parant-options">
                {
                    options.map((res,i)=>{
                        return(
                            <button style={{background:res.selected ? "#43b3e6" : "#fff"}} disabled={isDisabled} onClick={()=>{
                                res.selected = true
                                setIsDesabled(true)
                                onClick(res.payload, res.title)
                            }} key={i} className="suggestionButton">
                                <p className={res.selected ? "button-text button-text-active" : "button-text"}>{res.title}</p>
                            </button>
                        )
                    })
                }
            </div>
        </>
    )
}

export default ChatAutoSuggestMessage;