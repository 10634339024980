import React, { useState, useEffect } from "react"
import Logo from "../../assets/images/Group 5.png"
import Input from '../../components/inputWithIcon/inputWithIcon';
import { Spinner } from 'react-bootstrap';
import Alert from '../../components/Alert/Alert';
import Axios from "axios"
import { useHistory } from "react-router-dom"

const MasterDashboardLogin = (props) => {

    const [showPass, setShowPass] = useState(false);
    const [loader, setLoader] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showModal, setModalShow] = useState(false);
    const [alertContent, setAlertContent] = useState({
        error: '',
        message: '',
    });
    const history = useHistory()

    useEffect(() => {
        if (localStorage.getItem('admin_token') !== null) {
            history.push("/Mdashboard")
        }
    }, [])


    const Login = () => {

        setLoader(true);
        if (email.trim().length !== 0) {
            Axios.get('http://ec2-3-129-11-183.us-east-2.compute.amazonaws.com:5000/login', {
                auth: {
                    username: email,
                    password: password
                }
            }).then(res => {
                console.log(res.data)
                if (res.status == 200) {
                    localStorage.setItem('admin_token', res.data.token);
                    history.push("/Mdashboard")
                    setLoaderModal()
                } else {

                    setModalShow(true);
                    ErrorMessage("Username or Password is wrong");
                }
            }).catch(err => {
                setLoaderModal();
                ErrorMessage("Something went wrong");
            });
        } else {
            setLoaderModal();
            ErrorMessage("Username Cannot be Empty");
        }
    };

    const setLoaderModal = () => {
        setLoader(false);
        setModalShow(true);
    };

    const ErrorMessage = errMessage => {
        setAlertContent({
            error: 'Error',
            message: errMessage,
        });
    };


    return (
        <div className="masterdashboardlogin-parent">
            <Alert
                show={showModal}
                onHide={() => setModalShow(false)}
                message={alertContent.message}
                Error={alertContent.error}
                className="modal-container-class"
            />
            <div className="center-card">
                <div className="logo">
                    <img src={Logo} />
                </div>
                <div className="title">
                    Welcome back!
                </div>
                <div className="desc">
                    Please login to your admin account
                </div>
                <div className="email">
                    <Input
                        onChange={x => setEmail(x)}
                        isEmail={true}
                        secure={false}
                        type={'email'}
                        placeholder={'Username'}
                        margin={true}
                        showIcon={true}
                    />
                </div>
                <div className="pass">
                    <Input
                        onChange={x => setPassword(x)}
                        onClick={() => setShowPass(!showPass)}
                        type={showPass ? 'text' : 'password'}
                        placeholder={'Password'}
                        showIcon={true}
                    />
                </div>
                <div className="button" onClick={() => { Login() }}>
                    {loader ? (
                        <Spinner size={'sm'} animation="border" role="status"></Spinner>
                    ) : (
                            <p className="regular s-b">Sign In</p>
                        )}
                </div>
            </div>
        </div>
    )
}

export default MasterDashboardLogin