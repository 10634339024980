import React from 'react';
import styles from './chatbot.module.scss';

function Zoom({ zoom, setzoom }) {
  return (
    <span className={styles.ZoomBtnContainer}>
      <button
        className={styles.zoomBtn}
        onClick={() => setzoom(zoom > 0.1 ? zoom - 0.05 : zoom)}
      >
        -
      </button>
      <span>{Math.round(zoom * 100 + 40)}%</span>
      <button
        className={styles.zoomBtn}
        onClick={() => setzoom(zoom < 1.8 ? zoom + 0.05 : zoom)}
      >
        +
      </button>
    </span>
  );
}

export default Zoom;
