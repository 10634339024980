import React, { useState, createContext, useEffect } from 'react';

import MainText from '../MainText/MainText';

export const LanguageContext = createContext();

const { Provider } = LanguageContext;

const ChatBot = ({ isOpen, setChat, children, setminimize, scroll, currentInputType }) => {
   useEffect(() => {
      if (isOpen)
         setTimeout(() => {
            setshowChat(true);
            scrollToBottom();
         }, 0);
      else setshowChat(false);
   }, [isOpen]);
   const [showChat, setshowChat] = useState(false);
   const [language, setlanguage] = useState('en');

   useEffect(() => {
      scrollToBottom();
   }, [scroll, currentInputType]);

   const scrollToBottom = () => {
      var objDiv = document.getElementById('__chatBox__');
      if (objDiv) objDiv.scrollTop = objDiv.scrollHeight;
   };

   return (
      <div className="chatbot-container">
         <div
            className="chatbot-header"
            style={{
               backgroundColor: !isOpen ? '#4a4cab7d' : '#4a4cab',
               flexDirection: language === 'ar' ? 'row-reverse' : 'row',
            }}>
            <MainText className="main-text-14-white ">
               <span className="montserrat" style={{ fontWeight: 'bold' }}>
                  {language === 'en' ? 'Bot Responses' : 'استجابة الروبوت'}
               </span>
            </MainText>
            <span
               style={{
                  display: 'flex',
                  width: '30%',
                  justifyContent: 'space-between',
                  flexDirection: language === 'ar' ? 'row-reverse' : 'row',
               }}>
               <div
                  onClick={() => {
                     if (language === 'ar') {
                        setlanguage('en');
                     } else {
                        setlanguage('ar');
                     }
                  }}
                  className="languageDiv"
                  style={{
                     margin: '0px 4px',
                     width: 36,
                     height: 36,
                     borderRadius: 36,
                     border: '1px solid white',
                     display: 'flex',
                     justifyContent: 'center',
                     alignItems: 'center',
                     cursor: 'pointer',
                  }}>
                  <p
                     className="medium montserrat"
                     style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'white',
                        border: '1px solid white',
                        borderRadius: '32px',
                        // padding: '12px',
                        fontStretch: 'normal',
                        fontSize: '10.5px',
                        width: '36px',
                        height: '36px',
                     }}>
                     {language === 'en' ? 'عربى' : 'ENG'}
                  </p>
               </div>
               <img
                  src="images/minimize.svg"
                  onClick={() => {
                     setminimize(true);
                     setChat(!isOpen);
                  }}
                  alt=""
               />
               <img
                  src="images/close.svg"
                  onClick={() => {
                     setChat(false);
                     setminimize(false);
                  }}
                  alt=""
               />
            </span>
         </div>
         {showChat ? (
            <div className="chatbot-body-open" id="__chatBox__">
               <Provider value={{ language }}>{children}</Provider>
            </div>
         ) : (
            <div className="chatbot-body-close"></div>
         )}
      </div>
   );
};

export default ChatBot;
