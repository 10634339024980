import Axios from 'axios';
import { FETCH_QUICK_REPLIES, ADD_QUICK_REPLIES, DELETE_QUICK_REPLIES, SET_LIVEAGENT } from './types';
import axios from '../../../utils/Request';
import { getToken } from '../../../utils/Function';

export const InitiateLiveAgent = () => async dispatch => {
   console.log('Initiated');
   dispatch({
      type: SET_LIVEAGENT,
   });
};

export const FetchQuickReplies = () => async dispatch => {
   try {
      const response = await axios('/liveagent/addReply/', {
         headers: {
            method: 'GET',
            Authorization: 'Token ' + getToken(),
         },
      });
      const { data, status } = response;
      if (status === 200) {
         dispatch({
            type: FETCH_QUICK_REPLIES,
            quickReplies: data.quick_replies,
         });
      }
   } catch (error) {
      console.log(error);
   }
};

export const AddQuickReplies = newQuickReplies => async dispatch => {
   console.log('Runnning');
   console.log(newQuickReplies);
   try {
      const response = await axios('/liveagent/addReply/', {
         method: 'POST',
         headers: {
            Authorization: 'Token ' + getToken(),
         },
         data: JSON.stringify({
            quick_reply: newQuickReplies,
         }),
      });
      const { status } = response;
      if (status === 200) {
         dispatch({
            type: ADD_QUICK_REPLIES,
            quickReplies: newQuickReplies,
         });
         return true;
      }
   } catch (error) {
      console.log(error);
   }
};

export const DeleteQuickReplies = quickReplies => async dispatch => {
   try {
      const response = await axios('/liveagent/deleteReply/', {
         method: 'POST',
         headers: {
            Authorization: 'Token ' + getToken(),
         },
         data: JSON.stringify({
            quick_reply: quickReplies,
         }),
      });
      const { status } = response;
      if (status === 200) {
         dispatch({
            type: DELETE_QUICK_REPLIES,
            quickReplies: quickReplies,
         });
         return true;
      }
   } catch (error) {
      console.log(error);
   }
};
