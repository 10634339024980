import React, { useRef, useEffect, useState } from "react"
import { connectAdvanced } from "react-redux"

const Inputpopup = React.forwardRef((props , ref) => {

    

    const inpt = useRef(null)
    const [bttext, setbttext] = useState("")

    useEffect(() => {
        if (bttext === "" || bttext === null) {
            //inpt.current.value = props.value
            setbttext(props.value)
        }
        if(props.title === "Edit Utterence"){
            inpt.current.value = props.value
        }
    }, [props.value])

    

    return (
        <div ref={ref} id="input-pp" className="popup_body" style={{ display: props.show ? "flex" : "none" }}>
            <div className="title">{props.title}</div>
            <div className="inpt1">
                <input className="utterances-input" ref={inpt} type="text" onChange={(e) => { props.onChange(e) }} />
            </div>
            {props.title !== "Edit Utterence" ? <div className="inpt2">
                <input className="utterances-input" value={props.value} type="text" onChange={(e) => { props.onValueChange(e) }} />
            </div> : null}
            <div className="button" onClick={() => {
                props.onClick(inpt.current.value)
                setbttext("")
                inpt.current.value = ""
            }}>
                {props.title === "Edit Utterence" ? <p>{props.bttext}</p> : <p>Add an entity for "<span>{bttext}</span>"</p>}
            </div>
        </div>
    )

})

export default Inputpopup