import React, { useState, useEffect, useRef } from 'react';
import { Spinner } from 'react-bootstrap';
import Input from '../inputWithIcon/inputWithIcon';
import ImageTag from '../ImageTag/ImageTag';
import { GetProfile, UpdateProfile, UpdatePassword } from '../../redux/actions/profile/index';
import { useDispatch, useSelector } from 'react-redux';
import { propTypes } from 'react-bootstrap/esm/Image';
import { useHistory } from 'react-router-dom';
import Alert from '../Alert/Alert';
import { getUsername } from '../../utils/Function';

const ProfileDetails = props => {
   const [loader, setLoader] = useState(false);
   const [fname, setfname] = useState('');
   const [lname, setlname] = useState('');
   const [role, setrole] = useState('');
   const [newpass, setnewpass] = useState('');
   const [confirmpass, setconfirmpass] = useState('');
   const [oldpass, setoldpass] = useState('');
   const [image, setimage] = useState('');
   const [currentimage, setci] = useState('');
   const dispatch = useDispatch();
   const profile_data = useSelector(state => state.profile.profile_data);
   const tk = JSON.parse(localStorage.getItem('user')).token;
   const [showModal, setModalShow] = useState(false);
   const [alertContent, setAlertContent] = useState({
      error: '',
      message: '',
   });
   const history = useHistory();

   useEffect(() => {
      dispatch(GetProfile(tk));
   }, []);

   useEffect(() => {
      if (profile_data && profile_data.image_data !== '') {
         setci(profile_data.image_data);
         setlname(profile_data.last_name);
         setfname(profile_data.first_name);
      } else if (profile_data && !profile_data.image_data) {
         setci(require('../../pages/SignIn/assets/1.jpg'));
      }
   }, [profile_data]);

   const Update = () => {
      if (fname !== profile_data.first_name || lname !== profile_data.last_name || image !== '') {
         Updateprofile();
      }

      if (newpass.length !== 0 || oldpass.length !== 0) {
         UpdatePass();
      }

      if (
         (fname === profile_data.first_name) &
         (lname === profile_data.last_name) &
         (newpass.length === 0) &
         (oldpass.length === 0) &
         (image === '')
      ) {
         setAlertContent({
            error: '',
            message: 'Nothing to Update',
         });
         setModalShow(true);
      }
   };

   const Updateprofile = () => {
      dispatch(
         UpdateProfile(
            profile_data.username,
            fname,
            lname,
            profile_data.email,
            tk,
            image,
            profile_data.role,
            callback,
            history
         )
      );
   };

   const UpdatePass = () => {
      if (newpass.length === 0) {
         setAlertContent({
            error: '',
            message: 'Please enter new password to continue!',
         });
         setModalShow(true);
         return; //alert("Please enter new password to continue!")
      }
      if (oldpass.length === 0) {
         setAlertContent({
            error: '',
            message: 'Please enter current password to continue!',
         });
         setModalShow(true);
         return; //alert("Please enter new password to continue!")
      }
      if (newpass !== confirmpass) {
         setAlertContent({
            error: '',
            message: 'Please make sure both passwords are same',
         });
         setModalShow(true);
         return; // alert("Please make sure both passwords are same")
      } else {
         UpdatePassword(oldpass, newpass, tk, callback, history);
      }
   };

   const readFile = x => {
      var reader = new FileReader();
      reader.readAsDataURL(x.target.files[0]);
      reader.onload = function () {
         setimage(reader.result);
      };
      reader.onerror = function (error) {
         console.log('Error: ', error);
      };
   };

   const callback = (message, type) => {
      if (type === 'Success Message') {
         setAlertContent({
            error: type,
            message: message,
         });
      } else {
         setAlertContent({
            error: '',
            message: message,
         });
      }

      setModalShow(true);
   };

   return (
      <>
         <div className="left">
            <Alert
               show={showModal}
               onHide={() => setModalShow(false)}
               message={alertContent.message}
               Error={alertContent.error}
               className="modal-container-class"
            />
            <div className="image-container">{getUsername()}</div>
            <p className="bold name">
               {profile_data === null ? 'Andreas Brixen' : profile_data.first_name + ' ' + profile_data.last_name}
            </p>
            <p className="email">{profile_data === null ? 'andreas@gmail.com' : profile_data.email}</p>
         </div>

         <div className="right">
            <p className="bold updateText">Update Profile</p>
            <div style={{ display: 'flex', marginBottom: 20 }}>
               <Input
                  showIcon={false}
                  marginright={24}
                  placeholder={profile_data !== null ? profile_data.first_name : 'First Name'}
                  backgroundColor="#f6f8fc"
                  value={fname}
                  onChange={value => {
                     setfname(value);
                  }}
               />

               <Input
                  showIcon={false}
                  value={lname}
                  placeholder={'Last Name'}
                  backgroundColor="#f6f8fc"
                  onChange={value => {
                     setlname(value);
                  }}
               />
            </div>

            <div className="role">
               <Input
                  showIcon={false}
                  value={profile_data !== null ? profile_data.role : ''}
                  backgroundColor="#f6f8fc"
                  onChange={() => {}}
                  readOnly={true}
               />
            </div>

            <p className="bold t-18">Password</p>
            <div style={{ display: 'flex', marginBottom: 20 }}>
               <Input
                  showIcon={false}
                  marginright={24}
                  placeholder={'New Password'}
                  backgroundColor="#f6f8fc"
                  onChange={value => {
                     setnewpass(value);
                  }}
               />
               <Input
                  showIcon={false}
                  placeholder={'Current Password'}
                  backgroundColor="#f6f8fc"
                  onChange={value => {
                     setoldpass(value);
                  }}
               />
            </div>
            <Input
               width={'50%'}
               placeholder={'Retype New Password'}
               backgroundColor="#f6f8fc"
               onChange={value => {
                  setconfirmpass(value);
               }}
            />

            <div
               className="submit"
               onClick={() => {
                  Update();
               }}>
               {loader ? (
                  <Spinner size={'sm'} animation="border" role="status"></Spinner>
               ) : (
                  <p className="regular update-text">Update</p>
               )}
            </div>
         </div>
      </>
   );
};

export default ProfileDetails;
