import React, { useState, useEffect } from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import LifetimeConversations from '../../components/LifetimeConversations/LifetimeConversations';
import MessageCount from '../../components/MessageCount/MessageCount';
import ConversationCount from '../../components/ConversationCount/ConversationCount';
import EngagedUserByTime from '../../components/EngagedUserByTime/EngagedUserByTime';
import MessageOut from '../../components/MessageOut/MessageOut';
import MessageFunnel from '../../components/MessageFunnel/MessageFunnel';
import ChatScripts from '../../components/ChatScripts/ChatScripts';
import EventLogging from '../../components/EventLogging/EventLogging';
import UnhandledMessages from '../../components/UnhandledMessages/UnhandledMessages';
import ChatScriptDetails from '../../components/ChatScriptDetails/ChatScriptDetails';
import back from '../../assets/images/icons_back-outline-grey-32.svg';
import { useSelector } from 'react-redux';

import Api from '../../axios/request';

import AnalyticsDashboard from '../AnalyticsDashboard/AnalyticsDashboard';
import ImageTag from '../../components/ImageTag/ImageTag';
import TopIntents from '../../components/TopIntents/TopIntents';

function ControlledTabs(props) {
   const tk = JSON.parse(localStorage.getItem('user')).token;

   const [type, settype] = useState('Analytics Dashboard');
   const [nav, setnav] = useState('');
   const [ishovering, sethovering] = useState(false);
   const [chatdata, setchatdata] = useState(null);
   const [analyticsData, setAnalyticsData] = useState({});
   const [analytics15MData, setAnalytics15MData] = useState({});
   const [message, setmessage] = useState(null);
   const history = useHistory();
   const location = useLocation();
   const bot_name = useSelector(state => state.storybuilder.bot_name);

   const exportPdf = () => {
      html2canvas(document.getElementById('chart')).then(canvas => {
         const imgData = canvas.toDataURL('image/png');
         const pdf = new jsPDF('l', 'pt');
         pdf.addImage(imgData, 'PNG', 15, 110, 800, 250);
         pdf.save('download.pdf');
      });
   };

   const onMenuItemClick = page => {
      if (location.pathname.includes('activity')) {
         settype(page);
         setnav('Activity');
         sethovering(false);

         if (page === 'Chat Scripts') {
            setnav('Conversations');
         }
      } else {
         if (page === 'Chat Scripts') {
            history.push({
               pathname: 'activity',
               state: {
                  nav: 'Conversations',
                  type: 'Chat Scripts',
               },
            });
         } else {
            history.push({
               pathname: 'activity',
               state: {
                  nav: 'Activity',
                  type: page,
               },
            });
         }
      }
   };

   useEffect(() => {
      if (bot_name !== null) {
         ApiCall(bot_name);
         MinLive(bot_name);

         setInterval(() => {
            MinLive(bot_name);
         }, 900000);
      }
   }, [bot_name]);

   useEffect(() => {
      //setnav(props?.location?.state?.page)
      console.log(props?.location?.state?.page)
   }, [props.location])

   console.log(nav)

   useEffect(() => {
      if (location.pathname.includes('activity')) {
         if (location.state !== undefined) {
            console.log(location.state)
            setnav(location.state.nav);
            settype(location.state.type);
         } else {
            history.push('/bot-analytics-dashboard');
         }
      }
   }, [location]);

   const ApiCall = bot_name => {
      Api.get('/analytics/dashboard-main', {
         params: {
            bot_name: bot_name,
         },
         headers: {
            Authorization: 'Token ' + tk,
         },
      })
         .then(res => {
            if (res.status == 200) {
               console.log(res.data);
               setAnalyticsData(res.data);
            }
         })
         .catch(error => {
            console.log(error);
         });
   };

   const MinLive = bot_name => {
      Api.get('/analytics/dashboard-live', {
         params: {
            bot_name: bot_name,
         },
         headers: {
            Authorization: 'Token ' + tk,
         },
      })
         .then(res => {
            console.log(res.data)
            if (res.status == 200) {
               setAnalytics15MData(res.data);
            }
         })
         .catch(error => {
            console.log(error);
         });
   };

   console.log(nav, type)

   return (
      <div className="activity-parent">
         <div className="navbar">
            <div className="navoptions">
               <div
                  className={`navoption ${nav === 'Activity' ? 'nav-active' : ''}`}
                  onClick={() => {
                     if (location.pathname.includes('activity')) {
                        setnav('Activity');
                        settype('Lifetime Conversation');
                     } else {
                        history.push({
                           pathname: 'activity',
                           state: {
                              nav: 'Activity',
                              type: 'Lifetime Conversation',
                           },
                        });
                     }
                  }}
                  onMouseEnter={() => {
                     sethovering('activity');
                  }}
                  onMouseLeave={() => {
                     sethovering(null);
                  }}>
                  Activity
               </div>
               <div
                  className={`navoption ${nav === 'Conversations' ? 'nav-active' : ''}`}
                  onClick={() => {
                     if (location.pathname.includes('activity')) {
                        setnav('Conversations');
                        settype('Message Out');
                     } else {
                        history.push({
                           pathname: 'activity',
                           state: {
                              nav: 'Conversations',
                              type: 'Message Out',
                           },
                        });
                     }
                  }}
                  onMouseEnter={() => {
                     sethovering('conversation');
                  }}
                  onMouseLeave={() => {
                     sethovering(null);
                  }}>
                  Conversations
               </div>
               {/* <div
                  className={`navoption ${nav === 'Demographics' ? 'nav-active' : ''}`}
                  onClick={() => {
                     setnav('Demographics');
                  }}>
                  Demographics
               </div> */}
            </div>
         </div>
         <div
            className="dropdown-content"
            style={{ display: ishovering === 'activity' ? 'flex' : 'none' }}
            onMouseEnter={() => {
               sethovering('activity');
            }}
            onMouseLeave={() => {
               sethovering(null);
            }}>
            <div
               className={`items ${type === 'Lifetime Conversation' ? 'active' : ''}`}
               onClick={() => {
                  onMenuItemClick('Lifetime Conversation');
               }}>
               Lifetime Conversation
            </div>
            {/* <div className={`items ${type === "Message Count" ? "active" : ""}`} onClick={() => { onMenuItemClick("Message Count") }}>Message Count</div> */}
            <div
               className={`items ${type === 'Conversation Count' ? 'active' : ''}`}
               onClick={() => {
                  onMenuItemClick('Conversation Count');
               }}>
               Conversation Count
            </div>
            {/* <div className={`items ${type === "Time of the Day" ? "active" : ""}`} onClick={() => { onMenuItemClick("Time of the Day") }}>Time of the Day</div> */}
            <div
               className={`items ${type === 'Engaged User by Time' ? 'active' : ''}`}
               onClick={() => {
                  onMenuItemClick('Engaged User by Time');
               }}>
               Engaged User by Time
            </div>
            <div
               className={`items ${type === 'Event Logging' ? 'active' : ''}`}
               onClick={() => {
                  onMenuItemClick('Event Logging');
               }}>
               Event Logging
            </div>
            <div
               className={`items ${type === 'Unhandled Message' ? 'active' : ''}`}
               onClick={() => {
                  onMenuItemClick('Unhandled Message');
               }}>
               Unhandled Message
            </div>
            <div
               className={`items ${type === 'All Intents' ? 'active' : ''}`}
               onClick={() => {
                  onMenuItemClick('All Intents');
               }}>
               All Intents
            </div>
         </div>
         <div
            className="dropdown-content conversation"
            style={{ display: ishovering === 'conversation' ? 'flex' : 'none' }}
            onMouseEnter={() => {
               sethovering('conversation');
            }}
            onMouseLeave={() => {
               sethovering(null);
            }}>
            <div
               className={`items ${type === 'Message Out' ? 'active' : ''}`}
               onClick={() => {
                  onMenuItemClick('Message Out');
               }}>
               Message Out
            </div>
            <div
               className={`items ${type === 'Chat Scripts' ? 'active' : ''}`}
               onClick={() => {
                  onMenuItemClick('Chat Scripts');
               }}>
               Chat Scripts
            </div>
         </div>

         {type === 'Chat Scripts Detail' ? (
            <div
               className="back"
               onClick={() => {
                  settype('Chat Scripts');
               }}>
               <ImageTag src={back} />
            </div>
         ) : null}

         {type !== 'Analytics Dashboard' ? (
            <div className="col-md-6">
               <span>{nav}</span>/{type}
            </div>
         ) : null}

         <div>
            {type === 'Lifetime Conversation' && <LifetimeConversations />}
            {type === 'Message Count' && <MessageCount default="line" />}
            {type === 'Conversation Count' && <MessageCount default="line" />}
            {type === 'Time of the Day' && <MessageCount default="bar" />}
            {type === 'Engaged User by Time' && <EngagedUserByTime />}
            {type === 'Message Out' && (
               <MessageOut
                  onClick={message => {
                     settype('Message Funnel');
                     setmessage(message);
                  }}
               />
            )}
            {type === 'Message Funnel' && <MessageFunnel message={message} />}
            {(type === 'Chat Scripts' || type === 'Chat Scripts Detail') && (
               <div className={`${type === 'Chat Scripts' ? '' : 'disabled'}`}>
                  <ChatScripts
                     onClick={data => {
                        setchatdata(data);
                        settype('Chat Scripts Detail');
                     }}
                     state={location?.state}
                     startDateApi={analyticsData}
                  />
               </div>
            )}
            {type === 'Event Logging' && <EventLogging />}
            {type === 'Unhandled Message' && <UnhandledMessages />}
            {type === 'All Intents' && <TopIntents />}
            {type === 'Chat Scripts Detail' && <ChatScriptDetails data={chatdata} />}
            {type === 'Analytics Dashboard' && (
               <AnalyticsDashboard analyticsData={analyticsData} analytics15MData={analytics15MData} />
            )}
         </div>
      </div>
   );
}
export default ControlledTabs;
