import React, { useState, useEffect, useContext } from 'react';
import MainText from '../../MainText/MainText';
import ImageUpload from '../ImageUpload/ImageUpload';
import Button from './Button';
import { LanguageContext } from '../ChatBot';

function CardInput({ addCard, data, setupdateCard, update, carousel, OPTIONS }) {
   const { language } = useContext(LanguageContext);

   useEffect(() => {
      setshowUploadMenu(false);
      setcreateBtn(false);
      if (data) {
         seturl({ en: data.response_elements.en.image, ar: data.response_elements.ar.image });
         setform({
            en: {
               label: data.response_elements.en.label,
               description: data.response_elements.en.description,
            },
            ar: {
               label: data.response_elements.ar.label,
               description: data.response_elements.ar.description,
            },
         });
      }
   }, [data, language]);

   const [url, seturl] = useState({ en: '', ar: '' });
   const [form, setform] = useState({ en: { label: '', description: '' }, ar: { label: '', description: '' } });
   const [errors, seterrors] = useState({ label: '', description: '', url: '' });
   const [createBtn, setcreateBtn] = useState(false);

   //for buttons
   const [currentIndex, setcurrentIndex] = useState(null);

   const [showUploadMenu, setshowUploadMenu] = useState(false);

   const addNewCard = (btnValues, index) => {
      if (
         url[language] === '' ||
         url[language] === '' ||
         form[language].label === '' ||
         form[language].description === ''
      )
         return;
      if (data) {
         setupdateCard(false);
         const card = {
            ...data,
            response_elements: {
               ...data.response_elements,
               [language]: {
                  ...data.response_elements[language],
                  image: url[language],
                  label: form[language].label,
                  description: form[language].description,
                  buttons:
                     index !== null
                        ? data.response_elements[language].buttons.map((btn, i) =>
                             i === index ? { ...btnValues } : btn
                          )
                        : [...data.response_elements[language].buttons, { ...btnValues }],
               },
            },
         };
         update(card);
         setcurrentIndex(null);
      } else
         addCard({
            en: {
               image: url.en || url.ar,
               label: form.en.label || form.ar.label,
               description: form.en.description || form.ar.description,
               image_type: '',
               buttons: [{ ...btnValues }],
            },
            ar: {
               image: url.ar || url.en,
               label: form.ar.label || form.en.label,
               description: form.ar.description || form.en.description,
               image_type: '',
               buttons: [{ ...btnValues }],
            },
         });
   };

   const handleFormChange = e => {
      const name = e.target.name;
      const value = e.target.value;

      if (value.length > 0) seterrors({ ...errors, [name]: '' });
      if (value.length === 0) seterrors({ ...errors, [name]: 'Required' });
      setform({ ...form, [language]: { ...form[language], [name]: value } });
   };

   const onImageChange = base64 => {
      if (base64) {
         seturl({ ...url, [language]: base64 });
         setshowUploadMenu(false);
         const arrowprev = document.querySelector('.chat-carousel .slick-prev');
         const arrownext = document.querySelector('.chat-carousel .slick-next');
         const addcard = document.querySelector('._add-card_');
         if (arrowprev) {
            arrowprev.style['z-index'] = 1;
            arrownext.style['z-index'] = 1;
         }
         if (addcard) {
            addcard.style.position = 'static';
         }
      }
   };

   const handleUpdate = () => {
      const arrowprev = document.querySelector('.chat-carousel .slick-prev');
      const arrownext = document.querySelector('.chat-carousel .slick-next');
      const addcard = document.querySelector('._add-card_');
      if (arrowprev) {
         arrowprev.style['z-index'] = -1;
         arrownext.style['z-index'] = -1;
      }
      if (addcard) {
         addcard.style.position = 'relative';
      }
      setshowUploadMenu(true);
   };

   const handleSubmit = () => {
      const arrowprev = document.querySelector('.chat-carousel .slick-prev');
      const arrownext = document.querySelector('.chat-carousel .slick-next');
      const addcard = document.querySelector('._add-card_');
      if (arrowprev) {
         arrowprev.style['z-index'] = 1;
         arrownext.style['z-index'] = 1;
      }
      if (addcard) {
         addcard.style.position = 'static';
      }
      setshowUploadMenu(false);
   };

   const handleUrl = e => {
      seturl({ ...url, [language]: e.target.value });
   };

   const validateForm = () => {
      let formerrors = { label: '', description: '', url: '' };
      if (form[language].label === '') formerrors = { ...formerrors, label: 'required' };
      if (url[language] === '') formerrors = { ...formerrors, url: 'required' };
      if (form[language].description === '') formerrors = { ...formerrors, description: 'required' };
      seterrors(formerrors);
   };

   return (
      <div className="card-input-container __card-input-container__">
         <MainText className="Image-main-text">
            <span
               style={{
                  display: 'flex',
                  flexDirection: language === 'ar' ? 'row-reverse' : 'row',
                  marginRight: language === 'ar' && '86px',
                  marginLeft: language === 'en' && '80px',
               }}
               className="montserrat">
               {carousel ? (language === 'en' ? carousel : 'دائري') : language === 'en' ? 'Card' : 'ةقاطب'}
            </span>
         </MainText>
         <section className="__card-section__">
            <div className="Rectangle-1535 __card-img__">
               <img
                  src={url[language] ? url[language] : '/images/cardadd.svg'}
                  className="Group-52"
                  onClick={() => {
                     const arrowprev = document.querySelector('.chat-carousel .slick-prev');
                     const arrownext = document.querySelector('.chat-carousel .slick-next');
                     const addcard = document.querySelector('._add-card_');
                     if (arrowprev) {
                        arrowprev.style['z-index'] = -1;
                        arrownext.style['z-index'] = -1;
                     }
                     if (addcard) {
                        addcard.style.position = 'relative';
                     }
                     setshowUploadMenu(true);
                     seterrors({ ...errors, url: '' });
                  }}
                  height={url[language] && '150px'}
                  alt="add new"
               />
               {!url[language] && (
                  <p
                     className="Add-Image"
                     style={{ fontSize: '12px', marginTop: '6px', fontWeight: 'bold', color: errors.url && '#c4302b' }}>
                     {language === 'en' ? 'Add Image' : 'ةروص فضأ'}
                  </p>
               )}
            </div>
            <div className="__card-input-wrapper__" dir={language === 'ar' && 'rtl'}>
               <div className="box">
                  <input
                     className={`text-message-input ${errors.label && 'error'}`}
                     type="text"
                     autocomplete="off"
                     autoFocus
                     name="label"
                     placeholder={language === 'en' ? 'Enter label' : 'ناونعلا لخدأ'}
                     onChange={e => handleFormChange(e)}
                     value={form[language].label}
                  />
               </div>
               <div className="box">
                  <input
                     className={`text-message-input ${errors.description && 'error'}`}
                     type="text"
                     autocomplete="off"
                     name="description"
                     placeholder={language === 'en' ? 'Enter Description' : 'أدخل الوصف'}
                     onChange={e => handleFormChange(e)}
                     value={form[language].description}
                  />
               </div>

               {data &&
                  data.response_elements[language].buttons.map((btn, i) => (
                     <button
                        className="button"
                        style={{
                           width: '193px',
                           height: '44px',
                           marginLeft: '25px',
                           borderRadius: '100px',
                           backgroundColor: `${btn.type === 'solid button' ? ' #43b3e6' : '#ffffff'}`,
                           color: `${btn.type === 'solid button' ? '#ffffff' : ' #43b3e6'}`,
                           border: 'none',
                           marginBottom: '0px',
                        }}
                        onClick={() => {
                           if (url[language] === '' || form[language].label === '' || form[language].description === '')
                              return validateForm();
                           const arrowprev = document.querySelector('.chat-carousel .slick-prev');
                           const arrownext = document.querySelector('.chat-carousel .slick-next');
                           const addcard = document.querySelector('._add-card_');
                           if (arrowprev) {
                              arrowprev.style['z-index'] = -1;
                              arrownext.style['z-index'] = -1;
                           }
                           if (addcard) {
                              addcard.style.position = 'relative';
                           }
                           setcurrentIndex(i);
                           setcreateBtn(true);
                        }}>
                        {btn.title}
                     </button>
                  ))}

               {!createBtn && (
                  <button
                     className="button"
                     style={{
                        backgroundColor: 'transparent',
                        color: '#43b3e6',
                        border: 'none',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width: '100%',
                        marginBottom: '20px',
                     }}
                     onClick={() => {
                        if (url[language] === '' || form[language].label === '' || form[language].description === '')
                           return validateForm();
                        const arrowprev = document.querySelector('.chat-carousel .slick-prev');
                        const arrownext = document.querySelector('.chat-carousel .slick-next');
                        const addcard = document.querySelector('._add-card_');
                        if (arrowprev) {
                           arrowprev.style['z-index'] = -1;
                           arrownext.style['z-index'] = -1;
                        }
                        if (addcard) {
                           addcard.style.position = 'relative';
                        }
                        setcurrentIndex(null);
                        setcreateBtn(true);
                     }}>
                     {language === 'en' ? 'Add Button' : 'زر إضافة'}
                  </button>
               )}
            </div>
         </section>

         {showUploadMenu && (
            <ImageUpload
               setshowPopUp={setshowUploadMenu}
               handleSubmit={handleSubmit}
               handleUpdate={handleUpdate}
               handleUrl={handleUrl}
               onImageChange={onImageChange}
               language={language}
               type={'carousel'}
            />
         )}
         {createBtn && (
            <Button
               addNewCard={addNewCard}
               data={data}
               language={language}
               setshowPopUp={setcreateBtn}
               currentIndex={currentIndex}
               OPTIONS={OPTIONS}
            />
         )}
      </div>
   );
}

export default CardInput;
