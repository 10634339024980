import React, { useState, useEffect } from "react"
import Theme1 from "./Theme1"
import Theme2 from "./Theme2"
import Theme3 from "./Theme3"

const CustomizeYourTheme = (props) => {

    const [header_color, setheadercolor] = useState("#313743")
    const [header_text_color, setheadertextcolor] = useState("#ffffff")
    const [button_color, setbuttoncolor] = useState("#43B3E6")
    const [button_text_color, setbuttontextcolor] = useState("#4A4CAB")
    const [button_mouseover_color, setbuttonmouseovercolor] = useState("#FFA216")

    const [font, setfont] = useState("default")


    const onNext = () => {

        props.setData({ ...props.bot_data, font_type: font, header_color: header_color, header_text_color: header_text_color, button_color: button_color, button_text_color: button_text_color, button_mouseover_color: button_mouseover_color })
        props.setTab(props.tab + 1)

    }

    const onBack = () => {
        props.setTab(props.tab - 1)
    }


    useEffect(() => {

        if (props.bot_data.bot_theme === "1") {
            setheadercolor("#138b7e")
            setheadertextcolor("#ffffff")
            setbuttoncolor("#ffffff")
            setbuttontextcolor("#ffffff")
        }
        else if (props.bot_data.bot_theme === "2") {
            setheadercolor("#D0F5F8")
            setheadertextcolor("#000000")
            setbuttoncolor("#184f90")
            setbuttontextcolor("#ffffff")
        }

    }, [])


    return (
        <div className="customizeyourtheme-parent">
            <div className="c1">
                <div className="theme-container">
                    <div className="title">
                        Customize your theme
                </div>
                    <div className="color-container">
                        <div className="top-row">
                            <select className="font-dropdown" onChange={(e)=>{setfont(e.currentTarget.value)}}>
                                <option selected defaultChecked disabled>Pick your font</option>
                                <option value="Lato">Lato</option>
                                <option value="Poppins">Poppins</option>
                            </select>
                            <div className="color">
                                <div className={`check-box ${font === "default" ? "checked" : ""}`} onClick={() => { setfont("default") }}></div>
                                <div className="text2">Use default fonts</div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="color-place">
                                Popup Header Color
                        </div>
                            <div className="color">
                                <input type="color" value={header_color} onChange={(e) => { setheadercolor(e.currentTarget.value) }} />
                                <div className="color-type" style={{ backgroundColor: header_color }}></div>
                                <div className="text1">{header_color}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="color-place">
                                Popup Header Text Color
                        </div>
                            <div className="color">
                                <input type="color" value={header_text_color} onChange={(e) => { setheadertextcolor(e.currentTarget.value) }} />
                                <div className="color-type" style={{ backgroundColor: header_text_color }}></div>
                                <div className="text1">{header_text_color}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="color-place">
                                Popup Button Color
                        </div>
                            <div className="color">
                                <input type="color" value={button_color} onChange={(e) => { setbuttoncolor(e.currentTarget.value) }} />
                                <div className="color-type" style={{ backgroundColor: button_color }}></div>
                                <div className="text1">{button_color}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="color-place">
                                Popup Button Text Color
                        </div>
                            <div className="color">
                                <input type="color" value={button_text_color} onChange={(e) => { setbuttontextcolor(e.currentTarget.value) }} />
                                <div className="color-type" style={{ backgroundColor: button_text_color }}></div>
                                <div className="text1">{button_text_color}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="color-place">
                                Popup Button Mouseover Color
                        </div>
                            <div className="color">
                                <input type="color" value={button_mouseover_color} onChange={(e) => { setbuttonmouseovercolor(e.currentTarget.value) }} />
                                <div className="color-type" style={{ backgroundColor: button_mouseover_color }}></div>
                                <div className="text1">{button_mouseover_color}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border"></div>
                <div className="preview-container">
                    <div className="header">
                        Live Preview
                </div>
                    <div className="option">
                        {props.bot_data.bot_theme === "1" ? <Theme1 header_color={header_color} header_text_color={header_text_color} button_color={button_color} button_text_color={button_text_color} img={props.bot_data.bot_avatar} bot_name={props.bot_data.bot_name} font_family={font} /> : null}
                        {props.bot_data.bot_theme === "2" ? <Theme2 header_color={header_color} header_text_color={header_text_color} button_color={button_color} button_text_color={button_text_color} img={props.bot_data.bot_avatar} bot_name={props.bot_data.bot_name} font_family={font} /> : null}
                        {props.bot_data.bot_theme === "3" ? <Theme3 header_color={header_color} header_text_color={header_text_color} button_color={button_color} button_text_color={button_text_color} img={props.bot_data.bot_avatar} bot_name={props.bot_data.bot_name} /> : null}
                    </div>
                </div>
            </div>

            <div className="buttons"/*  style={{ display: tab === 5 ? "none" : "flex" }} */>
                <div className={`bt-back   `} onClick={() => { onBack() }}>
                    Back
                </div>
                <div className={`bt-next `} onClick={() => { onNext() }}>
                    Next
                </div>
            </div>

        </div>
    )
}

export default CustomizeYourTheme