import React, { useEffect, useState } from "react";
import ImageTag from '../../../components/ImageTag/ImageTag';

import Typing from '../Typing/Typing';

const ChatMessages = ({message, isHuman, isComponent}) =>{
    if(!isHuman){
        return(
            <div className={isHuman ? "chats-parant animated fadeInRight" : "chats-parant animated fadeInLeft"}>
                <div className="image">
                    <ImageTag src={require('../assets/botIcon.svg')} />
                </div>
                <div className="message-container">
                    {!isComponent ? <p className="medium text">{message}</p>
                    :
                    <Typing/>}
                </div>
            </div>
        )
    }
    else{
        return(
            <div className="chats-parant-bot">
                <div className="message-container-bot">
                    <p className="medium text">{message}</p>
                </div>
                <div className="image-bot">
                    <ImageTag src={require('../assets/humanIcon.svg')} />
                </div>
            </div>
        )
    }
}

export default ChatMessages;