import React from 'react';
import { motion } from 'framer-motion';

export default function ImageUpload({ setshowPopUp, onImageChange, handleUrl, handleSubmit, url, language, type }) {
   const encodeImageFileAsURL = (e, cb) => {
      var file = e.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function () {
         cb(reader.result);
      };
   };

   return (
      <motion.div
         initial={{ y: type === 'image' ? '-150px' : '-200px', opacity: 0.2 }}
         animate={{ y: type === 'image' ? '-221px' : '-310px', opacity: 1 }}
         transition={{ duration: 0.5 }}>
         <div
            className="chatbot-Image-container"
            style={{
               marginBottom: '100px',
            }}>
            <section
               style={{
                  position: 'absolute',
                  top: '111px',
                  // left: '29px',
                  width: '376px',
                  backgroundColor: 'white',
                  borderRadius: '10px 10px 10px 10px',
                  overflow: 'hidden',
                  height: '250px',
               }}>
               <div
                  className="upload-image-header"
                  style={{
                     color: 'white',
                     display: 'flex',
                     justifyContent: 'space-between',
                     flexDirection: language === 'ar' ? 'row-reverse' : 'row',
                  }}>
                  <span
                     className="medium"
                     style={{
                        fontSize: 14,
                        fontWeight: 500,
                        color: 'var(--white)',
                        lineHeight: 1,
                     }}>
                     {language === 'en' ? 'Paste your Image URL' : 'ةروصلاب صاخلا طبارلا قصلا '}
                  </span>
                  <span
                     onClick={() => {
                        const arrowprev = document.querySelector('.chat-carousel .slick-prev');
                        const arrownext = document.querySelector('.chat-carousel .slick-next');
                        const addcard = document.querySelector('._add-card_');
                        if (arrowprev) {
                           arrowprev.style['z-index'] = 1;
                           arrownext.style['z-index'] = 1;
                        }
                        if (addcard) {
                           addcard.style.position = 'static';
                        }
                        setshowPopUp(false);
                     }}>
                     <img src="/images/close.svg" alt="remove" />
                  </span>
               </div>
               <div
                  style={{
                     padding: '22px 20px 22px 20px',
                  }}>
                  {/* <MainText className="Image-main-text">
            
          </MainText> */}
                  <form
                     dir={language === 'ar' && 'rtl'}
                     onSubmit={e => {
                        e.preventDefault();
                        handleSubmit();
                     }}>
                     {/* <label>
                {language === 'en'
                  ? 'Paste Image Url'
                  : 'ةروصلاب صاخلا طبارلا قصلا '}
              </label> */}
                     <input
                        placeholder={language === 'en' ? 'Enter Image URL' : 'ةروصلاب صاخلا طبارلا قصلا '}
                        className="imageUploadInput regular"
                        type="text"
                        name="url"
                        value={url}
                        onChange={e => handleUrl(e)}
                        required
                        style={{
                           borderColor: '#f6f8fc',
                        }}
                     />
                  </form>
                  <div
                     style={{
                        color: '#9d9d9d',
                        fontSize: 12,
                        marginBottom: '14px',
                        marginTop: '14px',
                        fontFamily: 'M-Regular',
                     }}>
                     {/* </MainText>
          <span className="montserrat" style={{ marginLeft: '30px' }}></span> */}
                     {language === 'en' ? 'or' : 'أو'}
                  </div>
                  {/* <MainText className="Image-main-text">
            
          </MainText> */}
                  <label className="custom-file-upload medium">
                     <input type="file" name="myImage" onChange={e => encodeImageFileAsURL(e, onImageChange)} />
                     {language === 'en' ? 'Upload from Computer' : 'رتويبمكلا نم ةروصلا لمح'}
                  </label>
                  <div className="custom-file-upload-button" onClick={handleSubmit}>
                     {language === 'en' ? 'Done' : 'فعله'}
                  </div>
               </div>
            </section>
         </div>
      </motion.div>
   );
}
