import React, { useState, useEffect, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { LanguageContext } from '../../ChatBot';

export default function Dropdown({ input, handleChange, handleChangeDropdownActions }) {
   const { language } = useContext(LanguageContext);
   const [dropdown, setdropdown] = useState({});
   const [showdropdown, setshowdropdown] = useState(false);
   const [newInput, setnewInput] = useState(false);
   const [value, setvalue] = useState('');

   useEffect(() => {
      setdropdown({
         ...input,
         options: input.options.map((op, i) => {
            return {
               ...op,
               disabled: dropdown.options && dropdown.options[i] ? dropdown.options[i].disabled : true,
               id: uuidv4(),
            };
         }),
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [input]);

   const onChange = (value, id) => {
      const options = dropdown.options.map(op => {
         const newOp = { ...op };
         if (op.id === id) {
            delete newOp.id;
            delete newOp.disabled;
            return { ...newOp, value };
         } else {
            delete newOp.id;
            delete newOp.disabled;
            return { ...newOp };
         }
      });
      setdropdown({
         ...dropdown,
         options: options.map((op, i) => {
            return { ...op, disabled: dropdown.options[i].disabled, id: uuidv4() };
         }),
      });
      handleChangeDropdownActions([...options], dropdown.data);
   };

   const addNewOption = option => {
      if (option === '') return;
      let options = [...dropdown.options, { value: option }];
      setdropdown({
         ...dropdown,
         options: [...dropdown.options, { value: option, disabled: true, id: uuidv4() }],
      });
      options = options.map(op => {
         const newOp = { ...op };
         delete newOp.id;
         delete newOp.disabled;
         return newOp;
      });
      setvalue('');
      handleChangeDropdownActions([...options], dropdown.data);
   };

   const handleChangeActions = (id, key, value) => {
      setdropdown({
         ...dropdown,
         options: dropdown.options.map(op => (op.id === id ? { ...op, [key]: !value } : op)),
      });
      setTimeout(() => {
         const el = document.getElementById(id);
         if (el) el.focus();
      }, 0);
   };

   const handleDelete = id => {
      let options = dropdown.options.filter(op => op.id !== id);
      setdropdown({
         ...dropdown,
         options,
      });
      options = options.map(op => {
         const newOp = { ...op };
         delete newOp.id;
         delete newOp.disabled;
         return newOp;
      });
      handleChangeDropdownActions([...options], dropdown.data);
   };
   return (
      <>
         <div className="input-container dropdown-wrapper">
            <input
               id={dropdown.data}
               name={dropdown.data}
               type={dropdown.type}
               placeholder={dropdown.label}
               style={{
                  backgroundColor: 'white',
               }}
               required={dropdown.required}
               value={dropdown.value}
               disabled={!dropdown.edit}
               onChange={e => handleChange(e.target.value, dropdown.data)}
               autocomplete="off"
            />
            <img
               src={require('../assets/drop_down.svg')}
               alt=""
               onClick={() => setshowdropdown(!showdropdown)}
               style={{ right: language === 'ar' && '212px' }}
            />
         </div>
         {showdropdown && (
            <div className="dropdown">
               {(newInput || dropdown.options.length === 0) && (
                  <div style={{ display: 'flex', position: 'relative' }}>
                     <input
                        id="new-input"
                        value={value}
                        onChange={e => setvalue(e.target.value)}
                        placeholder={language === 'ar' ? 'إضافة خيار جديد' : 'Add new option'}
                        onKeyPress={e => {
                           if (e.key === 'Enter') {
                              e.preventDefault();
                              addNewOption(e.target.value);
                              setnewInput(!newInput);
                           }
                        }}
                        onBlur={e => {
                           addNewOption(e.target.value);
                           setnewInput(!newInput);
                        }}
                        autoFocus
                     />
                     <img
                        className="save-tick"
                        src={require('../assets/savetick.svg')}
                        width="12px"
                        alt="add new"
                        onClick={() => null}
                        style={{ right: language === 'ar' && '180px' }}
                     />
                  </div>
               )}

               {dropdown.options?.map(op => (
                  <div style={{ display: 'flex', position: 'relative', textAlign: 'justify' }} key={JSON.stringify(op)}>
                     {!op.disabled ? (
                        <input
                           id={op.id}
                           value={op.value}
                           disabled={op.disabled}
                           onChange={e => onChange(e.target.value, op.id)}
                           onKeyPress={e => {
                              if (e.key === 'Enter') {
                                 e.preventDefault();
                                 handleChangeActions(op.id, 'disabled', op.disabled);
                              }
                           }}
                           onBlur={e => {
                              handleChangeActions(op.id, 'disabled', op.disabled);
                           }}
                           autoFocus
                        />
                     ) : (
                        <p>{op.value}</p>
                     )}

                     {op.disabled ? (
                        <>
                           <img
                              src={require('../assets/addnewinput.svg')}
                              width="14px"
                              alt="add new"
                              onClick={() => setnewInput(!newInput)}
                           />

                           <img
                              src={require('../assets/edit.svg')}
                              width="14px"
                              alt="edit"
                              onClick={() => {
                                 handleChangeActions(op.id, 'disabled', op.disabled);
                              }}
                           />
                           <img
                              src={require('../assets/delete.svg')}
                              width="12px"
                              alt="delete"
                              onClick={() => handleDelete(op.id)}
                           />
                        </>
                     ) : (
                        <img
                           className="save-tick"
                           src={require('../assets/savetick.svg')}
                           width="12px"
                           alt="save"
                           onClick={() => null}
                           style={{ right: language === 'ar' && '180px' }}
                        />
                     )}
                  </div>
               ))}
            </div>
         )}
      </>
   );
}
