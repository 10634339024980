import React, { useState, useEffect } from "react";
import user from "../../assets/images/selectuser.png"
import liveagent from "../../assets/images/liveagent.svg"

const AddUser = ({
    active,
    activeBox,
    setActive,
    setActiveBox,
    Input
}) => {

    const data = [{}, {}, {}, {}, {}, {}]

    return (
        <div className="adduserMain">
            <div className="top-tabs-container">
                <div onClick={() => setActive(0)} className="leftTab active-tab">
                    <p
                        style={{ color: "#000" }}
                        className="medium leftTabText">01. Add Profile Details</p>
                </div>
                <div onClick={() => setActive(1)} className={active == 1 ? "RightTab active-tab" : "RightTab"}>
                    <p
                        style={{ color: active == 1 ? "#000" : "#bbbbbb" }}
                        className="medium leftTabText">02. Choose Features Access</p>
                </div>
            </div>
            {active === 0 ? <p className="bold t-18">Profile Details</p> : null}
            {active == 0 ? <>
                <div style={{ display: "flex", marginBottom: 20 }}>
                    <Input
                        showIcon={false}
                        marginright={24}
                        placeholder={"First Name"}
                        backgroundColor="#f6f8fc"
                    />
                    <Input
                        showIcon={false}
                        placeholder={"Last Name"}
                        backgroundColor="#f6f8fc"
                    />
                </div>

                <div style={{ display: "flex", marginBottom: 81 }}>
                    <Input
                        showIcon={false}
                        marginright={24}
                        placeholder={"Email Address"}
                        backgroundColor="#f6f8fc"
                    />
                    <Input
                        showIcon={false}
                        placeholder={"Password"}
                        backgroundColor="#f6f8fc"
                    />
                </div>
            </> : <div className="selectfeatures-container">
                    <p className="bold t-18">Select User</p>
                    <div className="users">
                        {data.map((item, index) => {
                            return (
                                <div className="user">
                                    <div className={`check-box ${index === 2 ? "c-active" : ""}`}></div>
                                    <img src={user} />
                                    <div className="name">Marvin McKinney</div>
                                </div>
                            )
                        })}

                    </div>
                    <p className="bold t-18">Select Features</p>
                    <div className="feature-container">
                        <div onClick={() => setActiveBox(0)} className={activeBox == 0 ? "box box-active" : "box"}>
                            <img className="icon" src={require(activeBox == 0 ? './assets/feature-active.svg' : './assets/feature.svg')} />
                            <p className="regular text text-active">All Features</p>
                        </div>
                        <div onClick={() => setActiveBox(1)} className={activeBox == 1 ? "box box-active" : "box"}>
                            <img className="icon" src={require(activeBox == 1 ? './assets/report-active.svg' : './assets/report.svg')} />
                            <p className="regular text">Reports</p>
                        </div>
                        <div onClick={() => setActiveBox(2)} className={activeBox == 2 ? "box box-active" : "box"}>
                            <img className="icon" src={require(activeBox == 2 ? './assets/bot-active.svg' : './assets/bot.svg')} />
                            <p className="regular text">Bot Customization</p>
                        </div>
                        <div onClick={() => setActiveBox(3)} className={activeBox == 3 ? "box box-active" : "box"}>
                            <img className="icon" src={require(activeBox == 3 ? '../../assets/images/liveagent.svg' : '../../assets/images/liveagent.svg')} />
                            <p className="regular text">Live Agent</p>
                        </div>

                    </div>
                </div>
            }
            <div style={{ justifyContent: active == 0 ? "flex-end" : "space-between" }} className="bottom-button-container">
                {active == 1 && <div onClick={() => setActive(0)} className="back">
                    <p className="regular text">Back</p>
                </div>}
                <div onClick={() => setActive(1)} className="button">
                    <p className="regular text">{active == 1 ? "Update" : "Next"}</p>
                </div>
            </div>
        </div>
    )
}

export default AddUser;