import { GETPROFILE, UPDATEPROFILE } from './types';
import Api from '../../../axios/request';

export const GetProfile = (token, history) => {
   const user = localStorage.getItem('user');

   return dispatch => {
      Api.get('/accounts/profile/', {
         headers: {
            Authorization: 'Token ' + token,
         },
      })
         .then(res => {
            dispatch({ type: GETPROFILE, payload: res.data.user_profile[0] });
         })
         .catch(xhr => {
            let errormesae = JSON.stringify(xhr.message);
            if (
               errormesae == '"Request failed with status code 401"' ||
               errormesae == '"Request failed with status code 403"'
            ) {
               return LogOut(history);
            }

            // return LogOut(history);
         });
   };
};

export const UpdateProfile = (username, firstname, lastname, email, token, image_data, role, callback, history) => {
   return dispatch => {
      Api.post(
         '/accounts/profile/',
         {
            username: username,
            first_name: firstname,
            last_name: lastname,
            email: email,
            image_data: image_data,
         },
         {
            headers: {
               Authorization: 'Token ' + token,
            },
         }
      )
         .then(res => {
            if (res.status === 403 || res.status === 401) {
               return LogOut(history);
            }
            if (res.data.message === 'User details have been saved successfully') {
               callback('User details have been saved successfully', 'Success Message');
               dispatch({
                  type: GETPROFILE,
                  payload: {
                     username: username,
                     first_name: firstname,
                     last_name: lastname,
                     email: email,
                     image_data: image_data,
                     role: role,
                  },
               });
            } else {
               callback('Error while updating Information', '');
            }
         })
         .catch((error, xhr) => {
            let errormesae = JSON.stringify(xhr.message);
            if (
               errormesae == '"Request failed with status code 401"' ||
               errormesae == '"Request failed with status code 403"'
            ) {
               return LogOut(history);
            }
            console.log(error);
            callback('Error while updating Information', '');
         });
   };
};

export const UpdatePassword = (current_pass, new_pass, token, callback, history) => {
   Api.post(
      '/accounts/change-password/',
      {
         current_password: current_pass,
         new_password: new_pass,
      },
      {
         headers: {
            Authorization: 'Token ' + token,
         },
      }
   )
      .then(res => {
         if (res.status === 403 || res.status === 401) {
            return LogOut(history);
         }
         if (res.status === 200) {
            callback('Password Updated Successfully!', 'Success Message');
         } else {
            callback('Error while updating password', '');
         }
      })
      .catch((error, xhr) => {
         //console.log(error)
         let errormesae = JSON.stringify(xhr.message);
         if (
            errormesae == '"Request failed with status code 401"' ||
            errormesae == '"Request failed with status code 403"'
         ) {
            return LogOut(history);
         }
         callback('Error while updating password', '');
      });
};

export const LogOut = history => {
   window.location.reload(false);
   localStorage.clear();
   history.push('/signin');
};

export const CheckStatus = (token, history) => {
   const user = localStorage.getItem('user');
   Api.get('/accounts/profile/', {
      headers: {
         Authorization: 'Token ' + token,
      },
   })
      .then(res => {
         if (res.status === 403 || res.status === 401) {
            //
            Api.get('/accounts/logout/', {
               headers: {
                  Authorization: 'Token ' + token,
               },
            })
               .then(res => {
                  console.log('logout' + res.status);

                  if (res.status === 200) {
                     //
                     return LogOut(history);
                  }
                  console.log('called');
               })
               .catch(xhr => {
                  let errormesae = JSON.stringify(xhr.message);
                  if (
                     errormesae == '"Request failed with status code 401"' ||
                     errormesae == '"Request failed with status code 403"'
                  ) {
                     return LogOut(history);
                  }
                  //console.log(error)
               });

            //  return LogOut(history)
         }
      })
      .catch(xhr => {
         let errormesae = JSON.stringify(xhr.message);
         if (
            errormesae == '"Request failed with status code 401"' ||
            errormesae == '"Request failed with status code 403"'
         ) {
            return LogOut(history);
         }
         //console.log(error)
      });
};
