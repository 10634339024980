import {
   DELETE_QUICK_REPLIES,
   ADD_QUICK_REPLIES,
   FETCH_QUICK_REPLIES,
   SET_LIVEAGENT,
} from '../actions/liveagent/types';
import { live_agent } from '../../utils/Request';
import socketIOClient from 'socket.io-client';

const ENDPOINT = `${live_agent}/liveagent`;
const initialState = {
   quickReplies: [],
   liveAgent: '',
};

const LiveAgent = (state = initialState, action) => {
   switch (action.type) {
      case SET_LIVEAGENT:
         return {
            ...state,
            liveAgent: socketIOClient(ENDPOINT),
         };
      case FETCH_QUICK_REPLIES:
         return {
            ...state,
            ...action,
         };
      case ADD_QUICK_REPLIES:
         return {
            ...state,
            quickReplies: [...state.quickReplies, action.quickReplies],
         };
      case DELETE_QUICK_REPLIES:
         return {
            ...state,
            quickReplies: [...state.quickReplies].filter(data => data !== action.quickReplies),
         };
      default:
         return state;
   }
};

export default LiveAgent;
