import React, { useEffect, useState } from 'react';
import UserList from './UserList/UserList';
import ChatMessages from './ChatMessages/ChatMessages';
import ImageTag from '../../components/ImageTag/ImageTag';
import socketIOClient from 'socket.io-client';
import axios, { live_agent } from '../../utils/Request';
import NoMessage from './NoMessage/NoMessage';
import { Link } from 'react-router-dom';
import { getToken } from '../../utils/Function';
import { get } from 'lodash';
import { useDispatch } from 'react-redux';
import { FetchAlerts } from '../../redux/actions/notification/notification';
import { FETCH_ALERT } from '../../redux/actions/notification/types';
import { guid } from '../../utils/Utility';
import { socket } from '../../utils/Socket';
import { FetchQuickReplies } from '../../redux/actions/liveagent';

// const ENDPOINT = `${live_agent}/liveagent`;
// let socket = socketIOClient(ENDPOINT);

const LiveAgent = () => {
   const [userList, setUserList] = useState([]);
   const [botName, setBotName] = useState('');
   const [userMessage, setUserMessage] = useState([]);
   const [userID, setUserID] = useState('');
   const [defaultMessage, setDefaultMessage] = useState(true);
   const [value, setValue] = useState('');
   const [preview, setPreview] = useState('');
   const dispatch = useDispatch();

   // HandldeChange for Input Box for Sending Messges
   const handleChange = event => {
      setValue(event.target.value);
   };
   // "assigned_to":"waiting"
   const moveFromWaitingList = user_id => {
      socket.emit('change_assigned_to', {
         bot_name: localStorage.getItem('bot_name'),
         user_id: user_id,
         email: JSON.parse(localStorage.getItem('user'))?.username,
      });
   };

   useEffect(() => {
      // Gets All the user List currently Live
      socket.on('user_list', data => {
         // If user list is Object else if it is Array add it directly
         if (data[0] === undefined) {
            const notificationSound = new Audio();
            notificationSound.src = 'sounds/juntos-607.mp3';
            notificationSound.play();
            setUserList(prev => [
               ...prev,
               {
                  ...data,
                  apiCall: false,
               },
            ]);
            dispatch({
               type: FETCH_ALERT,
               alerts: data,
            });
         } else {
            setUserList(data);
         }
      });

      // Get Messages of the Users
      socket.on('bot_message', data => {
         setUserMessage(prev => [...prev, data]);
      });

      dispatch(FetchQuickReplies());

      // socket.emit('agent_register', {
      //    email: JSON.parse(localStorage.getItem('user'))?.username,
      //    bot_name: localStorage.getItem('bot_name'),
      //    token: guid(),
      // });
      socket.emit('get_user_list', localStorage.getItem('bot_name'));
   }, []);

   // Remove User From the list
   socket.on('user_left', data => {
      const updatedUserList = [...userList].filter(user => user.user_id !== data.room_id);
      if (userID === data.room_id) {
         setUserID('');
         setDefaultMessage(true);
      }
      setUserList(updatedUserList);
   });

   // Send Messages of the Server
   const sendMessage = (event, quickReply = '') => {
      event.preventDefault();

      if (quickReply !== '' || value !== '') {
         const messages = [
            ...userMessage,
            {
               user_message: quickReply ? quickReply : value,
               timestamp: Date.now(),
               clientID: 'liveagent',
               room_id: userID,
            },
         ];
         setUserMessage(messages);
         socket.emit('send_message', {
            message: quickReply ? quickReply : value,
            room_id: userID,
            clientID: 'liveagent',
         });
         setValue('');
      }
   };

   const getPreviousMessage = async user_ID => {
      const response = await axios('/logic/chatHistory/', {
         method: 'POST',
         headers: {
            Authorization: 'Token ' + getToken(),
         },
         data: {
            user_id: user_ID,
         },
      });
      const { data = false, status } = response;
      if (status === 200) {
         if (data) {
            const messages = [...userMessage, ...data.conversations];
            setUserMessage(messages);
         }
      }
   };

   /**
    * Stores the SelectedUser from the List to the state
    *
    * @param {String} userID
    */
   const selectedMessageUser = userID => {
      console.log(userID, '====userID');
      setUserID(userID);
      setDefaultMessage(false);
      const updatedUserList = [...userList];
      updatedUserList.forEach(user => {
         if (user.user_id === userID && user.apiCall === false) {
            getPreviousMessage(userID);
            user.apiCall = true;
         }
      });
      scrollBottom();
   };

   const pauseBot = () => {
      socket.emit('leave', {
         room_id: userID,
      });
   };

   useEffect(() => {
      scrollBottom();
   }, [userMessage.length]);

   const scrollBottom = () => {
      let chat_list_container = document.querySelector('.chat-list-container');
      setTimeout(() => {
         if (chat_list_container !== null) {
            chat_list_container.scrollTop = chat_list_container.scrollHeight - chat_list_container.clientHeight;
         }
      }, 10);
   };
   console.log('Render');
   return (
      <div className="liveagent-container">
         {/* <header>
        <div className="logo-section">
          <ImageTag src="images/LogoLiveChat.svg" alt="" />
        </div>
      </header> */}
         <Link to="/" className="back-button">
            <img src="images/backIcon.svg" alt="" />
         </Link>

         {/* {userList.length === 0 && <div className="title">Live Agent</div>} */}

         {userList.length !== 0 && (
            <div className="top-heading">
               <ImageTag src="images/liveTag.svg" />
               <h1 className="heading">{localStorage.getItem('bot_name').replace('_', ' ')}</h1>
            </div>
         )}

         <div className="main-wrapper">
            {userList.length !== 0 && (
               <UserList
                  userList={userList}
                  userMessage={userMessage}
                  selectedMessageUser={selectedMessageUser}
                  userID={userID}
                  moveFromWaitingList={moveFromWaitingList}
               />
            )}

            {defaultMessage ? (
               <NoMessage />
            ) : (
               <ChatMessages
                  userList={userList}
                  userID={userID}
                  pauseBot={pauseBot}
                  userMessage={userMessage}
                  handleChange={handleChange}
                  value={value}
                  sendMessage={sendMessage}
               />
            )}
         </div>
      </div>
   );
};

export default LiveAgent;
