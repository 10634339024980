import React, { useEffect, useState } from "react"
import Category from "./Components/Category"
import NameYourBot from "./Components/NameYourBot"
import ChooseAvatar from "./Components/ChooseAvatar"
import SelectATheme from "./Components/SelectATheme"
import CustomizeYourTheme from "./Components/CustomizeYourTheme"
import Confirm from "./Components/Confirm"

const CreateBot = (props) => {

    const [tab, settab] = useState(1)
    const [bot_data, setbotdata] = useState({
        chatbot_category: null,
        gender: null,
        language: null,
        bot_name: null,
        bot_avatar: null,
        bot_theme: null,
        font_type: null,
        header_color: null,
        header_text_color: null,
        button_color: null,
        button_text_color: null,
        button_mouseover_color: null
    })


    console.log(bot_data)

    return (
        <div className="createbot-parent">
            <div className="title">Create a Bot</div>
            <div className="bot-container">
                <div className="header-tabs">
                    <div className={`tab ${tab === 1 || tab === 2 || tab === 3 || tab === 4 || tab === 5 || tab === 6 ? "active" : ""}`}>
                        01. Choose category
                    </div>
                    <div className={`tab ${tab === 2 || tab === 3 || tab === 4 || tab === 5 || tab === 6 ? "active" : ""}`}>
                        02. Name your bot
                    </div>
                    <div className={`tab ${tab === 3 || tab === 4 || tab === 5 || tab === 6 ? "active" : ""}`}>
                        03. Choose avatar
                    </div>
                    <div className={`tab ${tab === 4 || tab === 5 || tab === 6 ? "active" : ""}`}>
                        04. Configure chat widget
                    </div>
                    <div className={`tab ${tab === 6 ? "active" : ""}`}>
                        05. Confirm
                    </div>
                </div>
                <div className="content">
                    {tab === 1 ? <Category tab={tab} setTab={(tab) => { settab(tab) }} bot_data={bot_data} setData={(data) => { setbotdata(data) }} /> : null}
                    {tab === 2 ? <NameYourBot tab={tab} setTab={(tab) => { settab(tab) }} bot_data={bot_data} setData={(data) => { setbotdata(data) }} /> : null}
                    {tab === 3 ? <ChooseAvatar tab={tab} setTab={(tab) => { settab(tab) }} bot_data={bot_data} setData={(data) => { setbotdata(data) }} /> : null}
                    {tab === 4 ? <SelectATheme tab={tab} setTab={(tab) => { settab(tab) }} bot_data={bot_data} setData={(data) => { setbotdata(data) }} /> : null}
                    {tab === 5 ? <CustomizeYourTheme tab={tab} setTab={(tab) => { settab(tab) }} bot_data={bot_data} setData={(data) => { setbotdata(data) }} /> : null}
                    {tab === 6 ? <Confirm tab={tab} setTab={(tab) => { settab(tab) }} bot_data={bot_data} setData={(data) => { setbotdata(data) }} /> : null}
                </div>
            </div>
        </div>
    )
}

export default CreateBot