import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import Left from '../../assets/images/left_arrow.svg';
import Right from '../../assets/images/right_arrow.svg';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

const TableUnhandled = props => {
   const data = [{}, {}, {}, {}, {}];
   const [page, setpage] = useState(1);

   const useStyles = makeStyles(theme => ({
      root: {
         '& > *': {
            marginTop: theme.spacing(2),
            right: 0,
         },
      },
   }));

   return (
      <div className="table-parent">
         <div
            style={{
               margin: '24px 0px',
               padding: 30,
               //outline: '1px solid #eeeeee',
               //MozOutlineRadius: "5px",
               //outlineOffset: '0rem',
               border: '1px solid #eeeeee',
               borderRadius: 5,
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
            }}>
            <Table
               striped
               hover
               style={{
                  //margin: '0px 10px',
                  //width: '91%',
                  //marginTop: '3rem',
                  //marginBottom: '2rem', // just for space
                  borderTopLeftRadius: '1rem',
                  borderTopRightRadius: '1rem',
                  overflow: 'hidden',
               }}>
               <thead>
                  <tr
                     style={{
                        backgroundColor: '#43b3e6',
                        color: '#fff',
                     }}>
                     <th style={{ paddingLeft: 20 }}>Messages</th>
                     <th style={{ textAlign: 'center' }}>Count</th>
                  </tr>
               </thead>
               <header1 />
               <tbody>
                  {props.messages.map(item => {
                     if (item.message === undefined) {
                        return null
                     }
                     return (
                        <tr>
                           <td
                              style={{
                                 minWidth: '10rem',
                              }}>
                              <div
                                 style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    paddingLeft: 8,
                                 }}>
                                 <div
                                    style={{
                                       backgroundColor: '#43b3e6',
                                       borderRadius: '50%',
                                       width: 35,
                                       height: 35,
                                       marginRight: '1rem',
                                    }}>
                                    <span
                                       style={{
                                          display: 'flex',
                                          width: '100%',
                                          height: '100%',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          fontSize: 17,
                                          color: '#fff',
                                          backgroundColor: '#4a4cab',
                                          borderRadius: 30,
                                       }}>
                                       {item.message ? item.message.charAt(0) : "-"}
                                    </span>
                                 </div>
                                 {item.message}
                              </div>
                           </td>
                           <td className="text-center">{item.confidence}</td>
                        </tr>
                     );
                  })}
               </tbody>
            </Table>
         </div>

         {/*pagination code */}
         {/* <div className="pagination">
                <div className="page-cont">
                    <div className="p-button" onClick={() => { setpage(page - 1) }}>
                        <img src={Left} style={{ marginRight: 10 }} />
                 Prev
              </div>
                    <div className={useStyles().root}>
                        <Pagination count={10} shape="rounded" page={page} hideNextButton={true} hidePrevButton={true} />
                    </div>
                    <div className="p-button" onClick={() => { setpage(page + 1) }}>
                        Next
                <img src={Right} style={{ marginLeft: 10 }} />
                    </div>
                </div>
            </div> */}
      </div>
   );
};

export default TableUnhandled;
