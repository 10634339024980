import {
    GETPROFILE,
    UPDATEPROFILE
} from "../actions/profile/types";

const initialState = {
    profile_data: null
};

const ProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case GETPROFILE:
            return { ...state, profile_data: action.payload }
        default:
            return state;
    }
};

export default ProfileReducer;
