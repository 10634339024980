import React, { useState, useContext, useEffect } from 'react';
import './textmessage.css';
import { LanguageContext } from './ChatBot';

const TextMessage = ({ addMsg, response, removeMsg, id, update }) => {
   const { language } = useContext(LanguageContext);

   useEffect(() => {
      if (response) setText({ ...response.response_elements });
   }, [response]);
   const [text, setText] = useState({
      en: '',
      ar: '',
   });

   return (
      <div>
         <form
            className="form"
            onSubmit={e => {
               e.preventDefault();
               if (text === '') return;
               if (response) update({ ...response, response_elements: { ...text } });
               else addMsg({ ...text });
               setText({ en: '', ar: '' });
            }}>
            <span
               style={{
                  display: 'flex',
                  flexDirection: language === 'ar' ? 'row-reverse' : 'row',
               }}>
               <img src="/images/textmessage.png" width="38px" alt="text message" />
            </span>
            <div className="box" style={{ position: 'relative' }}>
               <input
                  dir={language === 'ar' && 'rtl'}
                  className="text-message-input"
                  type="text"
                  autoFocus={!response}
                  placeholder={language === 'en' ? 'Type your message here..' : 'اكتب رسالتك هنا..'}
                  autoComplete="off"
                  name="text"
                  value={text[language]}
                  onChange={e => {
                     if (response)
                        update({
                           ...response,
                           response_elements: { ...response.response_elements, [language]: e.target.value },
                        });
                     setText({ ...text, [language]: e.target.value });
                  }}
                  required
                  style={{ backgroundColor: 'transparent' }}
               />
               {response && (
                  <span
                     onClick={() => removeMsg(id)}
                     style={{ position: 'absolute', right: language === 'en' ? '0px' : '90%' }}>
                     <img src="/images/close1.svg" alt="remove" width="14px" />
                  </span>
               )}
            </div>
         </form>
      </div>
   );
};

export default TextMessage;
