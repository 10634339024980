import { getToken } from '../../../utils/Function';
import axios from '../../../utils/Request';

/**
 * @end_point /logic/botStories/
 * @Method GET
 * @Authorization Required
 * @desc: api to get story stucture
 * @param {String} bot_name
 */
export const getStory = bot_name => {
   return new Promise(async (resolve, reject) => {
      try {
         const response = await axios('/logic/botStories/', {
            method: 'GET',
            headers: {
               'Content-Type': 'application/json',
               Authorization: `Token ${getToken()}`,
            },
            params: {
               bot_name,
            },
         });
         resolve(response.data.data);
      } catch (error) {
         reject(error);
      }
   });
};

/**
 * @end_point /logic/botStories/
 * @Method Post
 * @Authorization Required
 * @param {Object} SB
 * @param {String} bot_name
 */
export const updateBotStory = async (SB, bot_name) => {
   return new Promise(async (resolve, reject) => {
      try {
         const res = await axios('/logic/botStories/', {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
               Authorization: `Token ${getToken()}`,
            },
            data: JSON.stringify({
               bot_name: bot_name,
               data: { ...SB },
            }),
         });
         resolve(res);
      } catch (error) {
         reject(error);
      }
   });
};

/**
 * @end_point /logic/deleteResponse/
 * @Method Post
 * @Authorization Required
 * @param {Stirng} bot_name
 * @param {Array} deletedNodes
 */
export const deleteNodes = async (bot_name, deletedNodes) => {
   try {
      const res = await axios('/logic/deleteResponse/', {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${getToken()}`,
         },
         data: JSON.stringify({
            bot_name: bot_name,
            data: deletedNodes,
         }),
      });
      console.log(res.data);
   } catch (error) {
      console.log(error);
   }
};

/**
 * @end_point /logic/train/
 * @Method Post
 * @Authorization Required
 * @param {String} bot_name
 * @param {String} train_type
 * @param {String} language
 */
export const trainBot = (bot_name, train_type = 'core', language = 'en') => {
   return new Promise(async (resolve, reject) => {
      try {
         const response = await axios('/logic/train/', {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
               Authorization: `Token ${getToken()}`,
            },
            data: {
               train_type,
               bot_name,
               language,
            },
         });

         resolve(response.data.message);
      } catch (error) {
         reject(error);
      }
   });
};

/**
 * Get All Entites
 *
 */
export const getEntity = async () => {
   try {
      const response = await axios('/logic/entities/', {
         method: 'GET',
         headers: {
            Authorization: `Token ${getToken()}`,
         },
      });
      const { data, status } = response;
      if (status === 200) {
         return data.data;
      }
   } catch (error) {
      console.log(error);
   }
};
/**
 * Create Entites
 *
 */
export const createEntity = async entities => {
   try {
      const response = await axios('/logic/entities/', {
         method: 'POST',
         headers: {
            Authorization: `Token ${getToken()}`,
         },
         data: JSON.stringify({
            bot_name: localStorage.getItem('bot_name'),
            entities,
         }),
      });
      const { status } = response;
      if (status === 200) {
         return true;
      }
   } catch (error) {
      return false;
   }
};

/**
 * Delete Entites
 *
 */
export const deleteEntity = async entity_name => {
   return new Promise(async (resolve, reject) => {
      try {
         const response = await axios('/logic/deleteEntity/', {
            method: 'POST',
            headers: {
               Authorization: `Token ${getToken()}`,
            },
            data: JSON.stringify({
               bot_name: localStorage.getItem('bot_name'),
               entity_name,
            }),
         });
         const { status } = response;
         console.log(response);
         if (status === 200) {
            resolve(true);
         }
      } catch (error) {
         reject(error);
      }
   });
};
