import React, { useContext, useEffect } from 'react';
import _ from 'lodash';
import { Tween } from 'react-gsap';
import Button from '../../Button/Button';
import { LanguageContext } from '../ChatBot';

export default function Otp({ data, remove, duplicate }) {
   const { language } = useContext(LanguageContext);

   const onChange = e => {
      const { maxLength, value, name } = e.target;
      const [, fieldIndex] = name.split('-');

      if (value.length === 0) {
         const nextSibling = document.getElementById(`otpField-${parseInt(fieldIndex, 10) - 1}`);
         if (nextSibling !== null) {
            nextSibling.focus();
         }
      }

      // Check if they hit the max character length
      if (value.length === maxLength) {
         // Check if it's not the last input field
         if (parseInt(fieldIndex, 10) < 6) {
            // Get the next input field
            // const nextSibling = document.querySelector(`input[name=otpField${parseInt(fieldIndex, 10) + 1}]`);
            const nextSibling = document.getElementById(`otpField-${parseInt(fieldIndex, 10) + 1}`);
            // If found, focus the next field
            if (nextSibling !== null) {
               nextSibling.focus();
            }
         }
      }
   };

   useEffect(() => {
      document.getElementById('otpField-1').focus();
   }, []);

   let allInputs = [
      {
         id: 'otpField-1',
         inputField: 'otpField-1',
         delay: 0.2,
      },
      {
         id: 'otpField-2',
         inputField: 'otpField-2',
         delay: 0.6,
      },
      {
         id: 'otpField-3',
         inputField: 'otpField-3',
         delay: 0.8,
      },
      {
         id: 'otpField-4',
         inputField: 'otpField-4',
         delay: 1,
      },
      {
         id: 'otpField-5',
         inputField: 'otpField-5',
         delay: 1.2,
      },
      {
         id: 'otpField-6',
         inputField: 'otpField-6',
         delay: 1.3,
      },
   ];

   return (
      <Tween from={{ y: '20px', opacity: 0 }}>
         <div className="otp-container" style={{ position: 'relative', backgroundColor: 'white' }}>
            <p className="montserrat" style={{ fontWeight: 'bold', fontSize: '12px' }}>
               {language === 'en' ? 'OTP VERIFICATION' : 'التحقق من OTP'}
            </p>
            <div
               className="input-fields"
               style={{
                  flexDirection: language === 'ar' ? 'row-reverse' : 'row',
               }}>
               {allInputs.map((data, index) => (
                  <Tween from={{ scale: 0.5, opacity: 0, delay: data.delay }} key={index}>
                     <input
                        className="__otp_inputs__"
                        type="text"
                        id={data.id}
                        name={data.inputField}
                        maxLength="1"
                        autoComplete="off"
                        dir={language === 'ar' && 'rtl'}
                        onChange={e => onChange(e)}
                        style={{ borderBottom: '1px solid #e5e5e5' }}
                        disabled
                     />
                  </Tween>
               ))}
            </div>

            <Tween from={{ css: { width: '0', padding: 0, delay: 0.5 } }}>
               <Button className="custom-button" id="testTest">
                  <span>{language === 'en' ? 'Verify' : 'تحقق'}</span>
               </Button>
            </Tween>
            <div className="__edit-icons__" style={{ left: '2px', bottom: '-42px', padding: '1px' }}>
               <img src="/images/copy.svg" width="20px" alt="copy" onClick={() => duplicate(data.response_name)} />
               <img
                  src="/images/delete.svg"
                  width="23px"
                  style={{ margin: '5px' }}
                  alt="delete"
                  onClick={() => remove(data.response_name)}
               />
            </div>
         </div>
      </Tween>
   );
}
