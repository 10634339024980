import React from "react";
import { Modal } from "react-bootstrap";
import ImageTag from "../ImageTag/ImageTag";

const Alert = props => {
   return (
      <Modal
         dialogClassName="custom-alert-container"
         {...props} size="lg"
         aria-labelledby="contained-modal-title-vcenter" centered>
         <div className="body">
            <div className="crossContainer">
               <ImageTag
                  onClick={props.onHide}
                  src={require("../../components/Alert/assets/close.svg")}
               />
            </div>
            <Modal.Body>
               <div className="ErrorMessege">
                  <ImageTag src={props.Error === "Success Message" ? require("../../components/Alert/assets/success.svg") : require("../../components/Alert/assets/error.svg")} />
               </div>
               <div className="content-Container">
                  <h4>{props.Error}</h4>
                  <p style={{ textAlign: "center" }}>{props.message}</p>
               </div>

               {props.children}
            </Modal.Body>
         </div>
      </Modal>
   );
};
export default Alert;
