import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

const ScrollToTop = React.memo(props => {
   useEffect(() => {
      window.scrollTo(0, 0);
   }, [props.location.pathname]);

   return props.children;
});

export default withRouter(ScrollToTop);
