import React, { useState } from "react";
import ImageTag from '../../../components/ImageTag/ImageTag';

const ChatbotCardSlider = ({ item, image, heading, content, onClick }) => {
    const [isDisabled, setIsDesabled] = useState(false)
    return (
        <div className="chatbot-card-slider-page">
            <ImageTag className="chatbot-card-slider-page-image" src={image} />
            <div className="chatbot-card-slider-page-body">
                <div>
                    <p className="chatbot-card-slider-page-heading bold">{heading}</p>
                    <p className="chatbot-card-slider-page-content regular">{content}</p>
                </div>
                {item.buttons.map(data => (
                    <button
                        disabled={isDisabled}
                        onClick={() => {
                            setIsDesabled(true)
                            onClick(data.title, data.payload)
                        }} className="chatbot-card-slider-page-button">
                        <p className="chatbot-card-slider-page-button-text bold">
                            {data.title}
                        </p>
                    </button>
                ))}
            </div>
        </div>
    )
}

export default ChatbotCardSlider;