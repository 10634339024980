import React, { useState } from "react"

const Category = (props) => {

    const [category, setcategory] = useState("Educational")
    const [other, setother] = useState("")

    const onNext = () => {
        if (category === "Other") {
            if (other.length !== 0) {
                props.setData({ ...props.bot_data, chatbot_category: other })
                props.setTab(props.tab + 1)
            }else{
                //alert here for no category
                alert("No category to move forward!!")
            }
        }
        else {
            props.setData({ ...props.bot_data, chatbot_category: category })
            props.setTab(props.tab + 1)
        }
    }

    return (
        <div className="category-parent">
            <div className="container">
                <div className="title">Select a Category for your Chatbot</div>
                <div className="categories">
                    <div className="column">
                        <div className={`category ${category === "Educational" ? "selected" : ""}`} onClick={() => { setcategory("Educational") }}>
                            <div className="rect"></div>
                            <div className="text">Educational</div>
                        </div>
                        <div className={`category ${category === "Travel" ? "selected" : ""}`} onClick={() => { setcategory("Travel") }}>
                            <div className="rect"></div>
                            <div className="text">Travel</div>
                        </div>
                        <div className={`category ${category === "Events" ? "selected" : ""}`} onClick={() => { setcategory("Events") }}>
                            <div className="rect"></div>
                            <div className="text">Events</div>
                        </div>
                        <div className={`category ${category === "Hospitality" ? "selected" : ""}`} onClick={() => { setcategory("Hospitality") }}>
                            <div className="rect"></div>
                            <div className="text">Hospitality</div>
                        </div>
                        <div className={`category ${category === "Publishing" ? "selected" : ""}`} onClick={() => { setcategory("Publishing") }}>
                            <div className="rect"></div>
                            <div className="text">Publishing</div>
                        </div>
                    </div>
                    <div className="column">
                        <div className={`category ${category === "Banking/Financial" ? "selected" : ""}`} onClick={() => { setcategory("Banking/Financial") }}>
                            <div className="rect"></div>
                            <div className="text">Banking/Financial</div>
                        </div>
                        <div className={`category ${category === "Insurance" ? "selected" : ""}`} onClick={() => { setcategory("Insurance") }}>
                            <div className="rect"></div>
                            <div className="text">Insurance</div>
                        </div>
                        <div className={`category ${category === "Health Care" ? "selected" : ""}`} onClick={() => { setcategory("Health Care") }}>
                            <div className="rect"></div>
                            <div className="text">Health Care</div>
                        </div>
                        <div className={`category ${category === "Therapy" ? "selected" : ""}`} onClick={() => { setcategory("Therapy") }}>
                            <div className="rect"></div>
                            <div className="text">Therapy</div>
                        </div>
                        <div className={`category ${category === "Real Estate" ? "selected" : ""}`} onClick={() => { setcategory("Real Estate") }}>
                            <div className="rect"></div>
                            <div className="text">Real Estate</div>
                        </div>
                    </div>
                    <div className="column">
                        <div className={`category ${category === "Other" ? "selected" : ""}`} onClick={() => { setcategory("Other") }}>
                            <div className="rect"></div>
                            <div className="text">Other</div>
                        </div>
                        {category === "Other" ? <input placeholder="Enter category name" value={other} onChange={(e) => { setother(e.currentTarget.value) }} /> : null}
                    </div>
                </div>
            </div>

            <div className="buttons"/*  style={{ display: tab === 5 ? "none" : "flex" }} */>
                <div className={`bt-back disabled`} onClick={() => { }}>
                    Back
                </div>
                <div className={`bt-next`} onClick={() => { onNext() }}>
                    Next
                </div>
            </div>


        </div>
    )
}

export default Category


{/* <div className="buttons"/*  style={{ display: tab === 5 ? "none" : "flex" }} >
                {/* <div className={`bt-back  ${tab === 1 ? "disabled" : ""} `} onClick={onBack}>
                    Back
                </div> */}
{/* <div className={`bt-next  ${tab === 5 ? "disabled" : ""} `} onClick={onNext}>
                    Next
                </div> 
           </div>*/}

