import React, { useEffect, useState } from "react";
import ImageTag from '../../../components/ImageTag/ImageTag';

const ChatImage = ({image, isHuman}) =>{
    if(!isHuman){
        return(
            <div className="chats-ChatImage-parant animated fadeInLeft">
                <div className="image">
                    <ImageTag src={require('./assets/bot.jpg')} />
                </div>
                <div className="chatbot-ChatImage-image-container">
                    <ImageTag className="chatbot-ChatImage-image" src={require('./assets/images.jpg')}/>
                </div>
            </div>
        )
    }
    else{
        return(
            <div className="chats-ChatImage-parant-bot">
                <div className="chatbot-ChatImage-image-container-bot">
                    <ImageTag className="chatbot-ChatImage-image" src={require('./assets/images.jpg')}/>
                </div>
                <div className="image-bot">
                    <ImageTag src={require('./assets/bot.jpg')} />
                </div>
            </div>
        )
    }
}

export default ChatImage;