/**
 * @story_structure_validator
 * @desc returns arrays of invalid nodes(that have empty response object) and
 * check if form have submit button
 * @params_recieved from "pages/StoryCreation/Main.js" in function handleSaveStories
 * @param {Object} SB (story structure)
 */
export default function StoryValidator(SB) {
   let inValidNodes = [];
   let submitBtn = [];
   let formName = false;
   let formEntityen = false;
   let formEntityar = false;
   let submitFounden = false;
   let submitFoundar = false;

   Object.keys(SB.content).forEach(node => {
      if (SB.content[node].response.length === 0 && node !== 'start') inValidNodes.push(node);
      else {
         SB.content[node].response.forEach(res => {
            if (res.response_type === 'form') {
               if (res.form_name == 'NewForm') {
                  inValidNodes.push(node);
                  formName = true;
               }
               submitFounden = false;
               submitFoundar = false;
               formEntityen = false;
               formEntityar = false;
               if (Object.keys(res.response_elements).length > 0) {
                  if (res.response_elements.en.length === 0) submitFounden = true;
                  res.response_elements.en.forEach(el => {
                     if (el.type === 'submit') {
                        submitFounden = true;
                     }
                  });
               }
               if (Object.keys(res.response_elements).length > 0) {
                  if (res.response_elements.ar.length === 0) submitFoundar = true;
                  res.response_elements.ar.forEach(el => {
                     if (el.type === 'submit') {
                        submitFoundar = true;
                     }
                  });
               }
               if (
                  Object.keys(res.response_elements).length === 0 ||
                  !submitFounden ||
                  !submitFoundar ||
                  (res.response_elements.ar.length === 0 && res.response_elements.en.length === 0)
               )
                  submitBtn.push({ exists: false, node, response_name: res.response_name });
            }
         });
      }
   });

   return { inValidNodes, submitBtn, formName };
}
