import React, { memo, useEffect } from "react";
import { Widget, addResponseMessage } from "react-chat-widget";
import "react-chat-widget/lib/styles.css";

export default memo(() => {
  useEffect(() => {
    addResponseMessage("Welcome to this awesome chat!");
  }, []);

  const handleNewUserMessage = (newMessage) => {
    console.log(`New message incomig! ${newMessage}`);
    // Now send the message throught the backend API
  };
  return (
    <div xs={12} className="chatbox-mainContainer">
      <Widget
        handleNewUserMessage={handleNewUserMessage}
        profileAvatar="https://digitalsynopsis.com/wp-content/uploads/2019/04/beautiful-gradient-logo-designs-7.jpg"
        title=""
        subtitle="And my cool subtitle"
      />
    </div>
  );
});
