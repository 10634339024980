import React, { useState } from "react"
import male from "../../../assets/images/male.svg"
import female from "../../../assets/images/female.svg"
import flag1 from "../../../assets/images/flag1.svg"
import flag2 from "../../../assets/images/flag2.svg"

const NameYourBot = (props) => {

    const [gender, setgender] = useState("Female")
    const [lang, setlang] = useState("English")
    const [botname, setbotname] = useState("")

    const onNext = () => {
        if (botname.length === 0) {
            alert("Please emter bot name to move forward!")
        }
        else {
            props.setData({ ...props.bot_data, gender: gender, language: lang, bot_name: botname })
            props.setTab(props.tab + 1)
        }
    }

    const onBack = () => {
        props.setTab(props.tab - 1)
    }

    return (
        <div className="nameyourbot-parent">
            <div className="c1">

                <div className="column">
                    <div className="title">
                        Select gender
                </div>
                    <div className="options">
                        <div className={`option ${gender === "Female" ? "selected" : ""}`} onClick={() => { setgender("Female") }}>
                            <img src={female} />
                            <div className="text">
                                Female
                        </div>
                        </div>
                        <div className={`option last ${gender === "Male" ? "selected" : ""}`} onClick={() => { setgender("Male") }}>
                            <img src={male} />
                            <div className="text">
                                Male
                        </div>
                        </div>
                    </div>
                    <div className="title">
                        Enter bot name
                </div>
                    <input placeholder="Bot Name" value={botname} onChange={(e) => { setbotname(e.currentTarget.value) }} />
                </div>
                <div className="column">
                    <div className="title">
                        Select language
                </div>
                    <div className="options">
                        <div className={`option ${lang === "English" ? "selected" : ""}`} onClick={() => { setlang("English") }}>
                            <img src={flag2} />
                            <div className="text">
                                English
                        </div>
                        </div>
                        <div className={`option last ${lang === "لعربية" ? "selected" : ""}`} onClick={() => { setlang("لعربية") }}>
                            <img src={flag1} />
                            <div className="text">
                                العربية
                        </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="buttons"/*  style={{ display: tab === 5 ? "none" : "flex" }} */>
                <div className={`bt-back`} onClick={() => { onBack() }}>
                    Back
                </div>
                <div className={`bt-next`} onClick={() => { onNext() }}>
                    Next
                </div>
            </div>
        </div>
    )
}

export default NameYourBot