import React, { useContext } from 'react';
import MainText from '../../MainText/MainText';
import Description from '../../Description/Description';
import { LanguageContext } from '../ChatBot';

const DefaultMessage = () => {
  const { language } = useContext(LanguageContext);
  return (
    <div className="chatbot-default-message-container">
      <section>
        <img src="images/info.svg" alt="" />
        <MainText className="main-text-18">
          {language === 'en'
            ? 'No response added yet.'
            : '.نآلا ىتح ةباجإ يأ ةفاضإ متت مل'}
        </MainText>
        <Description className="description-14">
          {language === 'en'
            ? 'Please drag & drop bot responses to make your chatbot responsive'
            : 'اًبيجتسم كب صاخلا توب تاشلا لعجل توبلا ةبوجأ ةفاضإ ىجري'}
        </Description>
      </section>
    </div>
  );
};

export default DefaultMessage;
