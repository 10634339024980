import React from "react";

/**
 * Generic Function
 *
 * Reusable for all the Components
 *
 */

export default props => {
   return (
      <div className="button-container">
         <button
            onClick={props.onClick}
            className={props.className}
            id={props.id}
            disabled={props.disabled}
         >
            {props.children}
         </button>
      </div>
   );
};
