import React, { useEffect, useState } from "react"
import theme1avatar from "../../../assets/images/theme1-avatar.png"
import theme1img1 from "../../../assets/images/theme1-img1.svg"
import avatar_img from "../../../assets/images/avatar.svg"

const Theme1 = (props) => {

    const [font, setfont] = useState(null)

    const hexToRgb = (hex) => {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        var rgba = "rgba(" + parseInt(result[1], 16) + "," + parseInt(result[2], 16) + "," + parseInt(result[3], 16) + ",0.35)"

        return rgba
    }

    useEffect(() => {
        if (props.font_family !== undefined && props.font_family !== "default") {
            console.log(props.font_family)
            if (props.font_family === "Lato") {
                setfont("L")
            } else {
                setfont("P")
            }
        } else {
            setfont(null)
        }
    }, [props.font_family])


    return (
        <div className="theme1-parent">
            <div className="top" style={{ backgroundColor: props.header_color === undefined ? "#138b7e" : props.header_color }}>
                <div className="text1" style={{ color: props.header_text_color === undefined ? "#ffffff" : props.header_text_color, fontFamily: font !== null ? font + "-Bold" : "M-SemiBold" }}>
                    Hello!
                </div>
                <div className="text2" style={{ color: props.header_text_color === undefined ? "#ffffff" : props.header_text_color, fontFamily: font !== null ? font + "-Regular" : "M-Regular" }}>
                    Welcome to Camali Clinic
                </div>
                <div className="avatar-cont">
                    <img src={(props.img + "").length === 1 ? avatar_img : props.img} />
                    <div className="avatar-text" style={{ color: props.button_text_color === undefined ? "#ffffff" : props.button_text_color, backgroundColor: props.button_color === undefined ? "rgba(255,255,255,0.35)" : hexToRgb(props.button_color), fontFamily: font !== null ? font + "-Regular" : "M-Medium" }}>
                        Hi, I am {props.bot_name}
                    </div>
                </div>
                <div className="text3" style={{ color: props.header_text_color === undefined ? "#ffffff" : props.header_text_color, fontFamily: font !== null ? font + "-Regular" : "M-Regular" }}>How may I assist you today?</div>
                <div className="card-container">
                    <div className="row">
                        <div className="card" style={{ backgroundColor: props.button_color === undefined ? "rgba(255,255,255,0.35)" : hexToRgb(props.button_color) }}>
                            <img src={theme1img1} />
                            <div style={{ color: props.button_text_color === undefined ? "#ffffff" : props.button_text_color, fontFamily: font !== null ? font + "-Regular" : "M-Regular" }}>
                                Appointments
                            </div>
                        </div>
                        <div className="card" style={{ backgroundColor: props.button_color === undefined ? "rgba(255,255,255,0.35)" : hexToRgb(props.button_color) }}>
                            <img src={theme1img1} />
                            <div style={{ color: props.button_text_color === undefined ? "#ffffff" : props.button_text_color , fontFamily: font !== null ? font + "-Regular" : "M-Regular"}}>
                                Locate A Center
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="card1" style={{ backgroundColor: props.button_color === undefined ? "rgba(255,255,255,0.35)" : hexToRgb(props.button_color) }}>
                            <img src={theme1img1} />
                            <div style={{ color: props.button_text_color === undefined ? "#ffffff" : props.button_text_color , fontFamily: font !== null ? font + "-Regular" : "M-Regular"}}>
                                Insurance<br />Coverage
                            </div>
                        </div>
                        <div className="card1" style={{ backgroundColor: props.button_color === undefined ? "rgba(255,255,255,0.35)" : hexToRgb(props.button_color) }}>
                            <img src={theme1img1} />
                            <div style={{ color: props.button_text_color === undefined ? "#ffffff" : props.button_text_color , fontFamily: font !== null ? font + "-Regular" : "M-Regular"}}>
                                FAQs
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom" style={{ fontFamily: font !== null ? font + "-Regular" : "M-Medium"}}>
                Let's start a conversation
            </div>
        </div>
    )
}

export default Theme1