import React, { useEffect, useState } from "react"
import avatar from "../../../assets/images/theme2-avatar.svg"
import avatar_img from "../../../assets/images/avatar.svg"
import img1 from "../../../assets/images/theme2img1.svg"
import img2 from "../../../assets/images/theme2img2.svg"
import img3 from "../../../assets/images/theme2img3.svg"
import img4 from "../../../assets/images/theme2img4.svg"


const Theme2 = (props) => {

    const [font, setfont] = useState(null)

    useEffect(() => {
        if (props.font_family !== undefined && props.font_family !== "default") {
            console.log(props.font_family)
            if (props.font_family === "Lato") {
                setfont("L")
            } else {
                setfont("P")
            }
        } else {
            setfont(null)
        }
    }, [props.font_family])

    return (
        <div className="theme2-parent">
            <div className="top" style={{ backgroundColor: props.header_color === undefined ? "#D0F5F8" : props.header_color }}>
                <div className="avatar-section">
                    <img src={(props.img + "").length === 1 ? avatar_img : props.img} />
                    <div className="text-section">
                        <div className="text" style={{ color: props.header_text_color === undefined ? "#000000" : props.header_text_color, fontFamily: font !== null ? font + "-Regular" : "M-Medium" }}>
                            Hi, I am <span>{props.bot_name}</span>
                        </div>
                        <div className="text" style={{ color: props.header_text_color === undefined ? "#000000" : props.header_text_color, fontFamily: font !== null ? font + "-Regular" : "M-Medium" }}>
                            Welcome to <span>Aster Online</span>
                        </div>
                    </div>
                </div>

                <div className="card-section">
                    <div className="row">
                        <div className="card" style={{ backgroundColor: props.button_color === undefined ? "#184f90" : props.button_color }}>
                            <img src={img1} className="img1" />
                            <div className="sub-head" style={{ color: props.button_text_color === undefined ? "#ffffff" : props.button_text_color , fontFamily: font !== null ? font + "-Regular" : "M-Regular"}}>Products</div>
                        </div>
                        <div className="card" style={{ backgroundColor: props.button_color === undefined ? "#184f90" : props.button_color }}>
                            <img src={img2} className="img2" />
                            <div className="sub-head" style={{ color: props.button_text_color === undefined ? "#ffffff" : props.button_text_color, fontFamily: font !== null ? font + "-Regular" : "M-Regular" }}>Delivery</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="card" style={{ backgroundColor: props.button_color === undefined ? "#184f90" : props.button_color }}>
                            <img src={img3} className="img3" />
                            <div className="sub-head" style={{ color: props.button_text_color === undefined ? "#ffffff" : props.button_text_color, fontFamily: font !== null ? font + "-Regular" : "M-Regular" }}>Discounts</div>
                        </div>
                        <div className="card" style={{ backgroundColor: props.button_color === undefined ? "#184f90" : props.button_color }}>
                            <img src={img4} className="img4" />
                            <div className="sub-head" style={{ color: props.button_text_color === undefined ? "#ffffff" : props.button_text_color , fontFamily: font !== null ? font + "-Regular" : "M-Regular"}}>Concerns</div>
                        </div>
                    </div>
                </div>
                <div className="button" style={{ color: props.button_text_color === undefined ? "#ffffff" : props.button_text_color , fontFamily: font !== null ? font + "-Regular" : "M-Regular"}}>
                    Let's Talk
                </div>
            </div>
            <div className="bottom">

            </div>
        </div>
    )
}

export default Theme2