import React, { useEffect } from 'react';
import { getToken } from '../../../utils/Function';
import { useSelector, connect } from 'react-redux';
import { GetCustomActions } from '../../../redux/actions/storybuilder/index';

function CustomAction({ data, remove, duplicate, update, GetCustomActions }) {
   const bot_name = useSelector(state => state.storybuilder.bot_name);
   const options = useSelector(state => state.storybuilder.custom_actions);

   const onChange = value => {
      const response_value = options.filter(op => op.label === value);
      update({ ...data }, response_value[0].value);
   };

   useEffect(() => {
      if (options.length === 0) GetCustomActions(getToken(), bot_name);
   }, [bot_name, GetCustomActions, options.length]);

   const getValue = () => {
      const value = options.filter(op => op.value === data.response_name);
      return value[0].label;
   };

   return (
      <div className="custom-actions-wrapper">
         <p>Custom Actions</p>
         <div className="custom-action-card">
            <p>Select Custom Action</p>
            <div className="select-wrapper">
               <select
                  className="dropdown-select"
                  value={data.response_name && options.length > 0 && getValue()}
                  onChange={e => onChange(e.target.value)}>
                  <option className="regular" value="">
                     Select Action
                  </option>
                  {options.map(op => (
                     <option className="regular" value={op.label} key={JSON.stringify(op)}>
                        {op.label}
                     </option>
                  ))}
               </select>
            </div>
         </div>
         <div className="__edit-icons__" style={{ left: '35px', bottom: '-42px', padding: '1px' }}>
            <img src="/images/copy.svg" width="20px" alt="copy" onClick={() => duplicate(data.response_name)} />
            <img
               src="/images/delete.svg"
               width="23px"
               style={{ margin: '5px' }}
               alt="delete"
               onClick={() => remove(data.response_name)}
            />
         </div>
      </div>
   );
}

export default connect(null, { GetCustomActions })(CustomAction);
