import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { getEntity, createEntity, deleteEntity } from '../requests/requests';
import { useSnackbar } from 'notistack';

const Entity = ({
   entityType,
   changeEntityType,
   closeEntity,
   handleEntityValueSelected,
   entityValueSelected,
   onEntitySubmit
}) => {
   const [entityList, setEntityList] = useState([]);
   const [entityValue, setEntityValue] = useState('');

   const { enqueueSnackbar } = useSnackbar();

   const handleChange = event => {
      setEntityValue(event.currentTarget.value);
   };

   const addEntity = async () => {
      const exist = isExist(entityList, entityValue);
      if (!exist) {
         const newEntity = [...entityList, { value: entityValue, label: entityValue }];
         setEntityList(newEntity);
         const filteredEntity = newEntity.map(data => data.value);
         try {
            await createEntity(filteredEntity);
            genericSnackbar(`Created new entity`, 'success');
            setEntityValue('');
         } catch (err) {
            genericSnackbar(`Error creating new entity...`, 'error');
         }
      } else {
         genericSnackbar(`Entity already exist`, 'error');
      }
   };

   const removeEntity = async (event, data) => {
      event.stopPropagation();
      try {
         await deleteEntity(data);
         setEntityList([...entityList].filter(el => el.value !== data));
         genericSnackbar(`Entity has been deleted`, 'success');
      } catch (error) {
         genericSnackbar(`Error deleting entity...`, 'error');
      }
   };

   const isExist = (arrays, input) => {
      return arrays.find(data => data.value === input);
   };

   /**
    *
    * Function to pass the message and type of Message
    *
    * @param {String} message
    * @param {error/success} type
    */
   const genericSnackbar = (message, type) => {
      enqueueSnackbar(message, {
         variant: type,
         preventDuplicate: true,
         anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
         },
      });
   };

   useEffect(() => {
      (async () => {
         try {
            const response = await getEntity();
            const newEntity = [];
            response.forEach(data => {
               newEntity.push({ value: data, label: data });
            });
            setEntityList(newEntity);
         } catch (err) {
            console.log(err);
         }
      })();
   }, []);

   const { Option } = components;

   const EntityCustomOption = props => (
      <Option {...props}>
         {props.data.label}
         <button
            className="delete-button"
            onClick={e => removeEntity(e, props.data.label)}
            style={{ backgroundColor: 'none', border: 'none', float: 'right' }}>
            <img src="/images/delete-story.svg" alt="" />
         </button>
      </Option>
   );

   if (entityType === 'addEntity') {
      return (
         <div
            style={{
               position: 'absolute',
               width: '245px',
               left: '0px',
               backgroundColor: 'white',
               color: 'black',
               boxShadow: '0 4px 50px 0 rgba(0, 0, 0, 0.05)',
               padding: '25px 27px',
               borderRadius: '8px',
            }}>
            <h5 style={{ marginBottom: '25px', fontFamily: 'M-Medium' }}>Select Entity</h5>

            <Select
               className="select-box"
               isMulti
               options={entityList}
               components={{ Option: EntityCustomOption }}
               defaultInputValue={entityValueSelected}
               onChange={handleEntityValueSelected}
            />

            <button
               onClick={closeEntity}
               style={{
                  ...closeButton,
                  color: 'rgb(255 82 82)',
               }}>
               <img style={img} src="images/group.svg" />
            </button>
            <div style={btnParent}>
               <button onClick={() => changeEntityType('createEntity')} style={buttonStyle}>
                  Create
               </button>
               <button style={buttonStyle} onSubmit={onEntitySubmit}>
                  {' '}
                  Submit
               </button>
            </div>
         </div>
      );
   }
   if (entityType === 'createEntity') {
      return (
         <div
            style={{
               position: 'absolute',
               width: '245px',
               left: '0px',
               backgroundColor: 'white',
               color: 'black',
               boxShadow: '0 4px 50px 0 rgba(0, 0, 0, 0.05)',
               padding: '25px 27px',
               borderRadius: '8px',
            }}>
            <button onClick={() => changeEntityType('addEntity')} style={{ ...closeButton, color: 'rgb(255 82 82)' }}>
               <img style={img} src="images/group.svg" />
            </button>
            <h5 style={{ marginBottom: '25px', fontFamily: 'M-Medium' }}>Add Entity</h5>

            <input
            className="input-entity"
               type="text"
               style={{
                  border: '1px solid #ccc',
                  padding: '6px',
                  borderRadius: '5px',
               }}
               value={entityValue}
               onChange={handleChange}
               autoFocus={true}
            />

            <div style={btnParent}>
               <button style={buttonStyle} onClick={addEntity}>
                  Save
               </button>
            </div>
         </div>
      );
   }
};

const buttonStyle = {
   marginTop: '30px',
   color: '#43b3e6',
   background: 'none',
   cursor: 'pointer',
   backgroundColor: 'none',
   border: 'none',
};

const closeButton = {
   textAlign: 'right',
   background: 'none',
   position: 'absolute',
   border: 'none',
   right: '20px',
   top: '15px',
};

const img = {
   width: '16px',
};

const btnParent = { display: 'flex', justifyContent: 'space-between' };

export default Entity;
