import React, { useState, useEffect, useContext } from 'react';
import { LanguageContext } from '../ChatBot';

const QuickReplies = ({ data, addQuickRpl, updateQuickRpl, remove, duplicateQuickRpl, OPTIONS }) => {
   const { language } = useContext(LanguageContext);
   useEffect(() => {
      setshowAnswerMenu(false);
      if (data) {
         setformValues({ ...data.response_elements });
         setupdateCard(false);
      }
   }, [data, language]);
   const [showAnswerMenu, setshowAnswerMenu] = useState(false);
   const [currentAnswer, setcurrrentAnswer] = useState(null);
   const [updateCard, setupdateCard] = useState(true);
   const [formValues, setformValues] = useState({
      en: {
         text: '',
         replies: [
            {
               title: 'Answer 1',
               payload: '',
            },
            {
               title: 'Answer 2',
               payload: '',
            },
         ],
      },
      ar: {
         text: '',
         replies: [
            {
               title: 'الجواب 1',
               payload: '',
            },
            {
               title: 'الجواب 2',
               payload: '',
            },
         ],
      },
   });
   const [errors, seterrors] = useState({ text: '' });

   const handleSubmit = () => {
      if (data) updateQuickRpl(formValues, data.response_name);
      else addQuickRpl(formValues);

      setshowAnswerMenu(false);
      setupdateCard(false);
   };

   const addAnswer = () => {
      setformValues({
         ...formValues,
         [language]: {
            ...formValues[language],
            replies: [
               ...formValues[language].replies,
               {
                  title: `${language === 'en' ? 'Answer' : 'الجواب'} ${formValues[language].replies.length + 1}`,
                  payload: '',
               },
            ],
         },
      });
   };

   const removeRpl = index => {
      if (formValues[language].replies.length === 1) return;
      if (data)
         updateQuickRpl(
            {
               ...formValues,
               [language]: {
                  ...formValues[language],
                  replies: formValues[language].replies.filter((r, i) => i !== index),
               },
            },
            data.response_name
         );
      setformValues({
         ...formValues,
         [language]: { ...formValues[language], replies: formValues[language].replies.filter((r, i) => i !== index) },
      });
   };

   return (
      <>
         <div
            style={{
               width: '376px',
            }}>
            <span
               style={{
                  display: 'flex',
                  flexDirection: language === 'ar' ? 'row-reverse' : 'row',
                  fontFamily: 'M-SemiBold',
                  fontWeight: 600,
                  color: '#000',
                  fontSize: 16,
                  marginBottom: 19,
               }}>
               {language === 'en' ? 'Quick Reply' : 'رد سريع'}
            </span>
         </div>
         <div
            className="card-input-container"
            style={{
               padding: '20px 10px',
               backgroundColor: 'white',
               position: 'relative',
               marginBottom: '40px',
               //  width: 381,
               //  height: 185,
               borderRadius: 16,
            }}>
            <span
               style={{
                  display: 'flex',
                  flexDirection: language === 'ar' ? 'row-reverse' : 'row',
               }}>
               <img
                  style={{
                     transform: language === 'ar' ? 'scaleX(-1)' : 'scaleX(1)',
                  }}
                  src="/images/textmessage.png"
                  width="40px"
                  alt="text message"
               />
            </span>
            <div
               className="box"
               style={{
                  border: 'none',
               }}
               dir={language === 'ar' && 'rtl'}>
               <input
                  className={`text-message-input regular black ${errors.text && 'error'}`}
                  type="text"
                  placeholder={language === 'en' ? 'Type your message here..' : 'اكتب رسالتك هنا..'}
                  name="text"
                  value={formValues[language].text}
                  onChange={e => {
                     const name = e.target.name;
                     const value = e.target.value;
                     if (value.length > 0) seterrors({ ...errors, [name]: '' });
                     if (value.length === 0) seterrors({ ...errors, [name]: 'Required' });
                     setformValues({ ...formValues, [language]: { ...formValues[language], text: value } });
                  }}
                  required
                  disabled={!updateCard}
               />
            </div>
            <span
               style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: language === 'ar' ? 'row-reverse' : 'row',
               }}>
               {formValues[language].replies.map((ans, i) => (
                  <span
                     key={i}
                     style={{
                        position: 'relative',
                     }}>
                     <button
                        className="quickRplies-Btn"
                        onClick={() => {
                           if (formValues.text === '') return seterrors({ text: 'Required' });

                           if (updateCard) {
                              setcurrrentAnswer(i);
                              setshowAnswerMenu(true);
                           }
                        }}>
                        {ans.title}
                     </button>
                     {updateCard && (
                        <img
                           src="/images/x-mark.png"
                           style={{
                              position: 'absolute',
                              right: '15px',
                              top: '-1.5px',
                              zIndex: '99',
                           }}
                           alt=""
                           width="12px"
                           onClick={() => {
                              removeRpl(i);
                           }}
                        />
                     )}
                  </span>
               ))}
               {updateCard && (
                  <div
                     style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                     }}>
                     <img
                        src="/images/addnew.svg"
                        className="Group-52"
                        onClick={addAnswer}
                        width="30px"
                        alt="add new"
                        style={{ marginBottom: 6 }}
                     />
                     <p
                        style={{
                           fontSize: 10,
                           lineHeight: 1.4,
                           color: '#000',
                           fontWeight: 'bold',
                        }}
                        className="regular">
                        {language === 'ar' ? 'أضف إجابة' : 'Add Reply'}
                     </p>
                  </div>
               )}
            </span>

            {showAnswerMenu && (
               // <motion.div initial={{ y: '-200px' }} animate={{ y: '-280px' }} transition={{ duration: 0.5 }}>
               <div
                  className="chatbot-Image-container"
                  style={{
                     marginBottom: '40px',
                     position: 'absolute',
                     top: '98px',
                     left: '4px',
                     width: '90%',
                     marginLeft: '5%',
                     backgroundColor: 'white',
                     borderRadius: '10px 10px 10px 10px',
                     overflow: 'hidden',
                     zIndex: 111,
                  }}>
                  <section
                     style={
                        {
                           // height: '265px',
                        }
                     }>
                     <div
                        className="upload-image-header"
                        style={{
                           color: 'white',
                           display: 'flex',
                           justifyContent: 'space-between',
                           flexDirection: language === 'ar' ? 'row-reverse' : 'row',
                        }}>
                        <span
                           style={{
                              fontSize: 14,
                              fontWeight: 500,
                              color: 'var(--white)',
                              lineHeight: 1,
                           }}>
                           {language === 'ar' ? 'ةعيرس تاباجإ ءاشنإب مق' : 'Create Quick Reply'}
                        </span>
                        <span
                           style={{
                              width: 24,
                              height: 24,
                           }}
                           onClick={() => {
                              setshowAnswerMenu(false);
                           }}>
                           <img src="/images/close.svg" alt="remove" />
                        </span>
                     </div>
                     <div>
                        <div className="box" style={{ height: '45px' }}>
                           <input
                              className="text-message-input"
                              type="text"
                              name="description"
                              placeholder={language === 'ar' ? ' ةعيرسلا ةباجإلا ناونع لخدأ' : 'Enter Answer Title'}
                              onChange={e => {
                                 let newValues = { ...formValues };
                                 newValues[language].replies[currentAnswer].title = e.target.value;
                                 setformValues(newValues);
                              }}
                              style={{
                                 backgroundColor: 'white',
                                 height: 30,
                                 textAlign: language === 'ar' ? 'right' : 'left',
                                 border: 'none',
                              }}
                              value={formValues[language].replies[currentAnswer].title}
                           />
                        </div>

                        <div className="box" style={{ height: '47px' }}>
                           <select
                              style={{
                                 width: '100%',
                                 height: '46px',
                                 border: 'none',
                                 borderBottom: 'none',
                                 borderColor: '#f6f8fc',
                                 fontSize: '14px',
                                 color: '#666666',
                              }}
                              name="payload"
                              value={formValues[language].replies[currentAnswer].payload}
                              onChange={e => {
                                 let newValues = { ...formValues };
                                 newValues[language].replies[currentAnswer].payload = e.target.value;
                                 setformValues(newValues);
                              }}
                              required>
                              <option value="">{language === 'en' ? 'Select payload' : 'حدد نوع الزر'}</option>
                              {OPTIONS.map(op => (
                                 <option value={op.value}>{op.label}</option>
                              ))}
                           </select>
                        </div>
                        <div
                           style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                           }}>
                           <button
                              style={{
                                 width: '89px',
                                 height: '38px',
                                 borderRadius: '100px',
                                 border: 'solid 1px rgba(67, 179, 230, 0.5)',
                                 backgroundColor: '#43b3e6',
                                 marginLeft: '10px',
                                 marginRight: '10px',
                                 color: '#fff',
                                 marginBottom: 25,
                              }}
                              onClick={handleSubmit}>
                              {language === 'ar' ? ' ظفح' : 'Save'}
                           </button>
                        </div>
                     </div>
                  </section>
               </div>
               // </motion.div>
            )}

            {updateCard ||
               (data && (
                  <div
                     className="__edit-icons__"
                     style={{
                        position: 'absolute',
                        bottom: '-41px',
                        left: language === 'en' ? '6px' : '317px',
                        //  display: 'flex',
                        // backgroundColor: 'white',
                     }}>
                     <img src="/images/copy.svg" width="23px" alt="copy" onClick={() => duplicateQuickRpl(data)} />
                     <img
                        src="/images/delete.svg"
                        width="23px"
                        style={{ margin: '5px' }}
                        alt="delete"
                        onClick={() => remove(data.response_name)}
                     />
                     <img src="/images/edit.svg" width="23px" alt="edit" onClick={() => setupdateCard(true)} />
                  </div>
               ))}
         </div>
      </>
   );
};

export default QuickReplies;
