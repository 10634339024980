import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { OverlayTrigger } from 'react-bootstrap';
import Tooltip from '../tootip/tootip';
import NotificationPopup from '../NotificationPopup/NotificationPopup';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ImageTag from '../ImageTag/ImageTag';
import { SHOWHINTBOOL, CHANGECOUNT } from '../../redux/actions/hint';
import { getUsername } from '../../utils/Function';
import Axios from '../../utils/Request';

const Header = props => {
   const [showNotif, setShowNotif] = useState(false);
   const [image, setimage] = useState('');
   const profile_data = useSelector(state => state.profile.profile_data);
   const { allAlerts } = useSelector(state => state.users);
   const [showBade, setShowBadge] = useState(false);
   useEffect(() => {
      if (profile_data && profile_data.image_data !== '') {
         setimage(profile_data.image_data);
      } else if (profile_data && !profile_data.image_data) {
         setimage(require('./assets/img.png'));
      }
   }, [profile_data]);

   useEffect(() => {
      document.addEventListener('click', function (e) {
         const resultContainer = document.querySelector('.notif');
         const resultContainer1 = document.querySelector('.Bell');
         if (resultContainer !== null && resultContainer.contains(e.target)) {
         } else if (resultContainer1 !== null && resultContainer1.contains(e.target)) {
         } else {
            setShowNotif(false);
         }
      });
   });

   const handleUpdateHint = token => {
      Axios('/accounts/updateHint/', {
         method: 'POST',
         headers: {
            Authorization: `Token ${token}`,
         },
         body: JSON.stringify({
            hint: false,
         }),
      })
         .then(res => {
            console.log(res, ' update res');
         })
         .catch(err => {
            console.log(err, ' update error');
         });
   };

   useEffect(() => {
      let alert;
      if (showNotif === true) {
         setShowBadge(false);
         alert = allAlerts?.map(data => {
            data.active = false;
            return data;
         });
      } else {
         console.log(allAlerts);
         const active = allAlerts.every(data => (data.active === true ? true : false));
         console.log(active);
         // if (active === true) {
         //    setShowBadge(true);
         // }
      }
   }, [showNotif, allAlerts]);
   return (
      <div className="Header">
         <div className="innerDiv">
            <OverlayTrigger
               show={props.hint.hint_count === 0 && props.hint.showHint}
               trigger="click"
               overlay={() => (
                  <Tooltip
                     onClosePress={() => {
                        props.isVisivleTooltip(false);
                        const user = localStorage.getItem('user');
                        let usr = { ...JSON.parse(user) };
                        usr.hint = false;
                        console.log(usr, ' asdasdasda');
                        localStorage.setItem('user', JSON.stringify(usr));
                        handleUpdateHint(usr.token);
                     }}
                     heading="User Profile"
                     paragraph="the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                     It is a long established f"
                     onNextPress={() => {
                        props.tooltipCounter(1);
                     }}
                     circles={[1, 2, 3, 4]}
                     hintCount={props.hint.hint_count}
                     class="navToolTip"
                  />
               )}>
               <div style={{ zIndex: props.hint.hint_count == 0 ? 200 : 100 }} className="avatar">
                  <Link to="/profile">{getUsername()}</Link>
               </div>
            </OverlayTrigger>
            <OverlayTrigger
               placement={'bottom'}
               show={(props.hint.hint_count === 1 && props.hint.showHint) || showNotif}
               trigger="click"
               rootClose
               overlay={() => {
                  if (props.hint.showHint) {
                     return (
                        <Tooltip
                           onClosePress={() => {
                              props.isVisivleTooltip(false);
                              const user = localStorage.getItem('user');
                              let usr = { ...JSON.parse(user) };
                              usr.hint = false;
                              console.log(usr, ' asdasdasda');
                              localStorage.setItem('user', JSON.stringify(usr));
                              handleUpdateHint(usr.token);
                           }}
                           heading="Notification"
                           paragraph="the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                           It is a long established f"
                           onBackPress={() => {
                              props.tooltipCounter(0);
                           }}
                           onNextPress={() => {
                              props.tooltipCounter(2);
                           }}
                           circles={[1, 2, 3, 4]}
                           hintCount={props.hint.hint_count}
                           class="navToolTip navToolTip-Notification "
                        />
                     );
                  } else {
                     return (
                        <NotificationPopup
                           data={[1, 2]}
                           class="notif"
                           notif={showNotif}
                           toggleNotif={() => setShowNotif(!showNotif)}
                        />
                     );
                  }
               }}>
               <div
                  style={{ zIndex: props.hint.hint_count == 1 ? 200 : 100 }}
                  onClick={() => setShowNotif(!showNotif)}
                  className="Bell">
                  {showBade && <div className="notification-active"></div>}
                  <ImageTag className="icon" src={require('./assets/Bell.svg')} />
               </div>
            </OverlayTrigger>
         </div>
      </div>
   );
};

const mapStateToProps = ({ hint }) => ({ hint });

const mapDispatchToProps = dispatch => ({
   isVisivleTooltip: data => dispatch(SHOWHINTBOOL(data)),
   tooltipCounter: data => dispatch(CHANGECOUNT(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
