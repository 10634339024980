import React, { useState } from "react"
import avatar_img from "../../../assets/images/avatar.svg"
import add from "../../../assets/images/add.svg"

const ChooseAvatar = (props) => {

    const [avatar, setavatar] = useState({ type: "default", number: 1, uri: null })

    const readFile = (x) => {
        var reader = new FileReader();
        reader.readAsDataURL(x.target.files[0]);
        reader.onload = function () {
            setavatar({ type: "custom", number: 4, uri: reader.result })
        };
        reader.onerror = function (error) {

        };
    }

    const onNext = () => {

        if (avatar.type === "default") {
            props.setData({ ...props.bot_data, bot_avatar: avatar.number })
            props.setTab(props.tab + 1)
        } else {
            props.setData({ ...props.bot_data, bot_avatar: avatar.uri })
            props.setTab(props.tab + 1)
        }
    }

    const onBack = () => {
        props.setTab(props.tab - 1)
    }

    console.log(avatar)

    return (
        <div className="chooseavatar-parent">
            <div className="title">
                Select an avatar or upload your own
            </div>
            <div className="avatar-list">
                <div className={`avatar ${avatar.number === 1 ? "selected" : ""}`} onClick={() => { setavatar({ ...avatar, type: "default", number: 1 }) }}>
                    <img src={avatar_img} />
                </div>
                <div className={`avatar ${avatar.number === 2 ? "selected" : ""}`} onClick={() => { setavatar({ ...avatar, type: "default", number: 2 }) }}>
                    <img src={avatar_img} />
                </div>
                <div className={`avatar ${avatar.number === 3 ? "selected" : ""}`} onClick={() => { setavatar({ ...avatar, type: "default", number: 3 }) }}>
                    <img src={avatar_img} />
                </div>
                {avatar.uri !== null ? <div className={`avatar ${avatar.number === 4 ? "selected" : ""}`} onClick={() => { setavatar({ ...avatar, type: "custom", number: 4 }) }}>
                    <img src={avatar.uri} />
                </div> : null}
                <div className="add">

                    <img src={add} />

                    <input onChange={readFile}
                        type="file"
                        id="avatar" name="avatar"
                        accept="image/png, image/jpeg" />

                    <div className="text">
                        Upload your<br />avatar or image
                    </div>

                </div>
            </div>
            <div className="buttons"/*  style={{ display: tab === 5 ? "none" : "flex" }} */>
                <div className={`bt-back   `} onClick={() => { onBack() }}>
                    Back
                </div>
                <div className={`bt-next `} onClick={() => { onNext() }}>
                    Next
                </div>
            </div>
        </div>
    )
}

export default ChooseAvatar